import React from 'react'
import BaseComponent from '../../shared/baseComponent'
import SupplierComparisonServices from '../../services/domainServices/supplierComparisonServices';
import { decryptionData } from '../../utils/Encryption';
import { DataFormat } from '../../utils/DateFormat';
import { DownloadInputFile } from '../../services/axiosServices/ApiEndPoints';
import axios from 'axios';
import FileViewer from 'react-file-viewer';
import Modal from "react-bootstrap/Modal";
export default class SupplierComparisonDetails extends BaseComponent {
  constructor(props) {
    super(props);
    this.supplierServices = new SupplierComparisonServices();
    // this.handleChange = this.handleChange.bind(this);
    this.state = {
      supplierDetals: [],
      setIsLoad: false,
      supplierId: 0,
      showModal: false,
      fileToView: null,
    };
    this.supplierServices.onUpdateState = this.updateState.bind(this);
  }

  componentDidMount() {
    let params = new URLSearchParams(window.location.search);
    let piId = params.get("");
    this.setState({ supplierId: piId });

    const decryptKey = decryptionData(piId, false);
    // *** getByID decryption ***//
    if (decryptKey > 0) {
      this.supplierServices.onGetSupplierComparisonById(decryptKey);
    }
  }
  
  previewFile = (data) => {
    const downloadUrl = `${DownloadInputFile}?FileName=${encodeURIComponent(data)}&ModuleType=SupplierComparisonInput&ModuleType=SupplierComparisonOutput`;
    this.setState({ setIsLoad: true });
    axios.get(downloadUrl, { responseType: 'blob' })
      .then(response => {
        this.setState({ fileToView: response.data });
        this.setState({ showModal: true });
      })
      .catch(error => {
        console.error('Error downloading the file:', error);
      });
  };
  handleCloseFile = () => {
    this.setState({ showModal: false })
    this.setState({ fileToView: null });
  }
  render() {
    const { showModal } = this.state
    return (
      <>
      <div className="card">
        <div className="card-title-main px-3 py-2">
          <div className="row align-items-center">
            <div className="col-6">
              <h5 className="titletexts font-size-16">Request : {this.state.supplierDetals.requestName}</h5>
            </div>
          </div>
        </div>

        <div className="card-body p-3">
          <div className="row">
            <div className="col-md-4">
              <div className="inner-detail-sec">
                <span className="detail-title">Comparison Type</span>
                &nbsp;
                <span className="detail-desc font-size-16">
                  &nbsp;&nbsp;&nbsp;
                  {this.state.supplierDetals.comparisonType}
                </span>
              </div>
            </div>

            <div className="col-md-4">
              <div className="inner-detail-sec">
                <span className="detail-title">Status</span>
                &nbsp;
                <span className="detail-desc font-size-16">
                  &nbsp;&nbsp;&nbsp;
                  {this.state.supplierDetals.statusName}
                </span>
              </div>
            </div>

            <div className="col-md-4 mb-2">
              <div className="inner-detail-sec">
                <span className="detail-title">Start Date </span>
                &nbsp;
                <span className="detail-desc font-size-16">
                  &nbsp;&nbsp;&nbsp;
                  {
                  
                  this.state.supplierDetals.startDate 
                  }

                
                </span>
              </div>
            </div>

            <div className="col-md-4 mb-2">
              <div className="inner-detail-sec">
                <span className="detail-title">End Date </span>
                &nbsp;
                <span className="detail-desc font-size-16">
                  &nbsp;&nbsp;&nbsp;
                  {
                  
                  this.state.supplierDetals.endDate 
                  }
                </span>
              </div>
            </div>

            <div className="col-md-4 mb-2">
              <div className='inner-detail-sec'>
                <span className='detail-title'> Old File </span>
                &nbsp;&nbsp;&nbsp;
                {this.state.supplierDetals.sourceCount}
                {this.state.supplierDetals.sourceInputFileName !== "" ?


                  <>
                    {this.state.supplierDetals.sourceCount > 0 ? (
                      <span htmlFor="requestName" className="detail-desc ml-3">
                        <a href={`${DownloadInputFile + `?FileName=${this.state.supplierDetals.sourceInputFileName}&ModuleType=SupplierComparisonInput`}`} target="_top">
                          <i className="fa fa-download"></i></a>
                       <img src="/xlsx.png" className="xlsx-file ml-2 cursor-pointer" title="Preview" alt="Text icon" onClick={() => this.previewFile(this.state.supplierDetals.sourceInputFileName)} />
                      </span>
                    ) : null}
                  </>
                  :
                  <span className='detail-desc'>
                    No Record found
                  </span>
                }
              </div>
            </div>

            <div className="col-md-4 mb-2">
              <div className='inner-detail-sec'>
                <span className='detail-title'>New File </span>
                &nbsp;&nbsp;&nbsp;
                {this.state.supplierDetals.destinationCount}
                {this.state.supplierDetals.destinationInputFileName !== "" ?
                  <>
                    {this.state.supplierDetals.destinationCount > 0 ? (
                      <span htmlFor="requestName" className="detail-desc ml-3">
                        <a href={`${DownloadInputFile + `?FileName=${this.state.supplierDetals.destinationInputFileName}&ModuleType=SupplierComparisonInput`}`} target="_top">
                          <i className="fa fa-download"></i></a>
                        <img src="/xlsx.png" className="xlsx-file ml-2 cursor-pointer" title="Preview" alt="Text icon" onClick={() => this.previewFile(this.state.supplierDetals.destinationInputFileName)} />
                      </span>
                    ) : null}
                  </>
                  :
                  <span className='detail-desc'>
                    No Record found
                  </span>
                }
              </div>
            </div>

            <div className="col-md-4">
              <div className='inner-detail-sec'>
                <span className='detail-title'> Output File </span>
                &nbsp;&nbsp;&nbsp;
                {this.state.supplierDetals.outputCount}
                {this.state.supplierDetals.outputFileName !== "" ?
                  <>
                    {this.state.supplierDetals.outputCount > 0 ? (
                      <span htmlFor="requestName" className="detail-desc ml-3">
                        <a href={`${DownloadInputFile + `?FileName=${this.state.supplierDetals.outputFileName}&ModuleType=SupplierComparisonOutput`}`} target="_top">
                          <i className="fa fa-download"></i></a>
                       <img src="/xlsx.png" className="xlsx-file ml-2 cursor-pointer" title="Preview" alt="Text icon" onClick={() => this.previewFile(this.state.supplierDetals.outputFileName)} />
                      </span>
                    ) : null}
                  </>
                  :
                  <span className='detail-desc'>
                    No Record found
                  </span>
                }
              </div>
            </div>

            <div className="col-md-4">
              <div className="inner-detail-sec">
                <span className="detail-title">Error </span>
                &nbsp;
                <span className="detail-desc font-size-16">
                  &nbsp;&nbsp;&nbsp;
                  {this.state.supplierDetals.errorMessage}
                </span>
              </div>
            </div>

          </div>
        </div>
      </div>

      <Modal className="scrolling-model" show={showModal} onHide={this.handleCloseFile}>
      <Modal.Header closeButton >
        <Modal.Title>File Preview</Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <div className="RequestDetails-modal-file">
          {this.state.fileToView && (
            <>
              <div>
                <FileViewer
                  fileType="xlsx"  
                  filePath={URL.createObjectURL(this.state.fileToView)}
                  onError={error => console.error('Error:', error)}
                />
              </div>
            </>
          )
          }
        </div>
      </Modal.Body>
    </Modal>
    </>
    )
  }
}
