import BaseDomainService from './baseDomainServices';
import { SuccessMessage } from "../../utils/Messages";
import { getValidationRuleList } from "../../utils/validation/generateValidationRule";
import moment from 'moment';

const operationalFormField = {
    moduleName: "",
}
const searchHistoryList = {
    searchText: "",
    startDate: "",
    endDate: ""
}

export default class fileManagerServices extends BaseDomainService {

    getAllModuleNameList = this.apiService.fileManagerServices.getAllModuleNameList;
    getModuleFileNameList = this.apiService.fileManagerServices.getModuleFileNameList;
    deleteFileByModuleType = this.apiService.fileManagerServices.deleteFileByModuleType;

    formFields = [
        {
            inputType: "select",
            tittle: "",
            placeholder: "Select Module",
            fieldName: "moduleName",
            options: [],
            optionFieldName: "moduleName",
            subRequest: "1",
            className: 'col-md-6 mb-3',
            validation: [{ type: "require" }]
        },
    ]

    SearchformFields = [
        {
            inputType: "text",
            tittle: "Search By Filename",
            placeholder: "Search By Filename...",
            fieldName: "searchText",
            labelStyleName: 'none',
            className: "col-md-4",
        },
        {
            inputType: "datepicker",
            tittle: "Start Date",
            placeholder: "Start Date",
            fieldName: "startDate",
            className: "col-md-4",
            validation: [{ type: "require" }]
        },
        {
            inputType: "datepicker",
            tittle: "End Date",
            placeholder: "End Date",
            fieldName: "endDate",
            className: "col-md-4",
            validation: [{ type: "require" }]
        },
    ]

    dataItem;
    searchData;

    // gridColmns = [
    //     { name: 'fileName', title: 'File Name' },
    //     { name: 'createdDate', title: 'CreatedDate' },
    // ];

    constructor() {
        super();
        this.dataItem = { ...operationalFormField };
        this.searchData = { ...searchHistoryList };
    }

    onGetAllModuleNameList = (request) => {
        this.apiService.onAPICall("getAllModuleNameList", this.getAllModuleNameList, request, this.onAPIResponse, this.onUpdateState);
    }

    onGetModuleFileNameList = (request) => {
        this.apiService.onAPICall("getModuleFileNameList", this.getModuleFileNameList, request, this.onAPIResponse, this.onUpdateState);
    }

    onDeleteFileByModuleType = (request, moduleName) => {
        this.apiService.onAPICall("deleteFileByModuleType", this.deleteFileByModuleType, request, this.onAPIResponse, this.onUpdateState, moduleName);
    }

    onInitService() {
        //This method only call when we use form component and we can add all items which we on on form load 
        let valRule = getValidationRuleList(this.SearchformFields)
        this.validationRules = valRule;
        this.onUpdateState({
            "validationRules": valRule,
            "resetValidationRule": this.resetValidationRule
        })
    }

    onResetData = () => {
        this.dataItem = { ...searchHistoryList }
        let resetValidation = {
            isValid: false,
            error: {}
        }
        this.onUpdateState({
            searchData: this.dataItem,
            validState: resetValidation
        });
    }

    onAPIResponse = (key, isSuccess, responseData, getRequest) => {
        if (!isSuccess) {
            this.onUpdateState({ "setIsLoad": false, "loading": false });
            return;
        }
        switch (key) {
            case "getAllModuleNameList":
                let itemList1 = responseData;
                if (responseData !== null) {
                    let Types = itemList1.map(item => ({
                        name: item.moduleName,
                    }));
                    this.onUpdateState({
                        setModuleList: Types,
                        "setIsLoad": false,
                    });
                }
                break;

            case "getModuleFileNameList":
                let itemList = responseData;
                if (responseData !== null) {
                    let itemListop = itemList.map(item => ({
                        fileName: item.fileName,
                        createdDate: moment(item.createdDate).format('DD-MM-YYYY')
                    }))

                    itemListop.sort((a, b) => {
                        const dateA = new Date(a.createdDate);
                        const dateB = new Date(b.createdDate);
                        return dateB - dateA; // Compare in descending order
                    });

                    this.onUpdateState({
                        "getList": itemListop,
                        "moduleListData": itemListop,
                        "setIsLoad": false,
                        "TotalCount": responseData.totalCount,
                    });
                }
                break;

            case "deleteFileByModuleType":
                if (responseData !== null) {
                    this.apiService.serviceManager.swalServices.Success(SuccessMessage.Delete_Success.replace("{0}", "File Manager"));

                    let request = {
                        moduleType: getRequest
                    }
                    // const currentOperationalFormField = this.state.operationalFormField;
                    this.onGetModuleFileNameList(request);
                }
                this.onUpdateState({ "setIsLoad": false });
                break;
                default:
                    // Handle default case here
                    break;
        }
    }

}
