//*** Lib */
import { axiosPost, axiosGet } from '../AxiosRequests';
import {
    AssignModuleToServer, GetAllUnAssignServerByModuleId, GetAllAssignServerByModuleId, DeleteServer, GetAllServerConfigurationsList, AddServerConfigurations,
    GetServerConfigurationsById, UpdateServerConfigurations, GetAllActiveServerConfigurationsList, GetAllServerListing, GetAllActiveServerList
} from '../ApiEndPoints';

export default class ServerConfigurationsServices {

    // API function

    async getAllServerConfigurationsList(request) {
        return axiosPost(GetAllServerConfigurationsList, request);
    }
    async addServerConfigurations(request) {
        return axiosPost(AddServerConfigurations, request);
    }
    async getServerConfigurationsById(request) {
        return axiosGet(GetServerConfigurationsById, request)
    }
    async getAllUnAssignServerByModuleId(request) {
        return axiosGet(GetAllUnAssignServerByModuleId, request)
    }
    async assignModuleToServer(request) {
        return axiosPost(AssignModuleToServer, request)
    }
    async deleteServer(request) {
        return axiosPost(DeleteServer, request)
    }
    async updateServerConfigurations(request) {
        return axiosPost(UpdateServerConfigurations, request)
    }
    async getAllAssignServerByModuleId(request) {
        return axiosGet(GetAllAssignServerByModuleId, request)
    }
    async getAllActiveServerConfigurationsList(request) {
        return axiosGet(GetAllActiveServerConfigurationsList, request);
    }
    async getAllActiveServerList(request) {
        return axiosGet(GetAllActiveServerList, request);
    }
    async getAllServerListing(request) {
        return axiosPost(GetAllServerListing, request);
    }
}

