//** Component  */
import React from 'react';

const ButtonLoader = () => {
  return (
    <div className="loader-inner line-scale-pulse-out-rapid">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

export default ButtonLoader
