import { Link } from "react-router-dom";
import { Bars } from "react-loader-spinner";

//** Lib's */
import Table from "../../components/table/Table";
import BaseComponent from "../../shared/baseComponent";
import { encryptionData } from "../../utils/Encryption";

//** Service's  */
import LinkupDomainService from "../../services/domainServices/linkupDomainService";

export default class ManageLinkupRequest extends BaseComponent {

    constructor(props) {
        super(props);
        this.domainServices = new LinkupDomainService();
        this.state = {
            getList: [],
            setIsLoad: false,
            pagination: this.domainServices.pagination
        };
        this.domainServices.onUpdateState = this.updateState.bind(this);
        this.domainServices.onActionCall = this.onActionCall.bind(this);
    }

    componentDidMount() {
        this.domainServices.onGetList(this.state.pagination);
    }

    //** Action Method's */
    onActionCall = (type, data) => {
        const encryptKey = encryptionData(data, 1);
        switch (type) {
            case "View":
                window.open(`/LinkupRequestDetail/id?=${encryptKey}`, "_blank");
                break;
            default:
                // Handle default case here
                break;
        }
    };

    render() {
        return (
            <div>
                <div className="top-search-add-button-sec mb-3">
                    <div className="row left-searchbar-sec"></div>
                    <div className="right-button-sec">
                        <Link to="/AddLinkupRequest" className="tab-title btn-hover color-3 active" >
                            Add <span>+</span>
                        </Link>
                    </div>
                </div>
                <div className="Linkup-list">
                    {this.state.setIsLoad ? (
                        <Bars
                            className="row"
                            wrapperStyle={{ marginTop: "150px" }}
                            color="#3c4b64"
                            height={55}
                            width="100%"
                            visible={this.state.setIsLoad}
                        />
                    ) : (
                        <Table
                            columns={this.domainServices.gridColmns}
                            rows={this.state.getList}
                            sortingColumns={[]}
                            pagination={this.state.pagination}
                            totalCount={this.state.TotalCount}
                            totalResultes={this.state.TotalCount}
                            setPagination={this.domainServices.onPageChange.bind(this)}
                            customScope={[
                                {
                                    column: "linkupRequestId",
                                    renderTableData: this.domainServices.gridActionsViewCol.bind(this),
                                }
                            ]}
                        />
                    )}
                </div>
            </div>
        )
    }
}