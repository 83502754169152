import React from 'react';

//** Lib's */
import { Navigate } from 'react-router-dom';
import Button from '../../../components/common/Button';
import { ErrorMessage } from '../../../utils/Messages';
import BaseComponent from '../../../shared/baseComponent';
import { decryptionData } from '../../../utils/Encryption';
import FormFieldGroup from '../../../components/UI Element/FormGrop/FormFieldGroup';

//** Service */
import RegulationDomainService from '../../../services/domainServices/regulationDomainService';
import { Bars } from 'react-loader-spinner';

export default class AddRegulation extends BaseComponent {

    constructor(props) {
        super(props)
        this.domainServices = new RegulationDomainService();
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            ...this.state,
            regulationId: 0,
            setIsLoad: false,
            selectedDate: null,
            formField: this.domainServices.dataItem,
        }
        this.domainServices.onUpdateState = this.updateState.bind(this);
    }

    componentDidMount() {
        let params = new URLSearchParams(window.location.search);
        let regulationId = params.get("");
        if (regulationId !== null && regulationId !== undefined) {
            const decryptKey = decryptionData(regulationId, false);
            this.setState({ regulationId: decryptKey })
            this.domainServices.onGetById(decryptKey);
        }
        this.domainServices.onInitService();
    }

    //** Handle Changes */
    handleChange(name, value) {
        let detail = this.state.formField;
        detail[name] = value;
        this.setState({ formField: detail });
    }

    handleFileChange(event) {
        const { name } = event.target;
        const File = event.target.files[0];
        if (File && (File.type === "text/csv" || File.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
            if (File.size > 0) {
                // Update the state based on which file upload it is
                let updatedState = { ...this.state.formField };

                if (name === 'productFile') {
                    updatedState = {
                        ...updatedState,
                        inputProductFileFile: File,
                        productFile: File.name,
                        FileName: File.name,
                    };
                    this.setState({ formField: updatedState });
                } else if (name === 'regulationFile') {
                    updatedState = {
                        ...updatedState,
                        inputRegulationFile: File,
                        regulationFile: File.name,
                        FileName: File.name,
                    };
                    this.setState({ formField: updatedState });
                }


            }
        } else {
            this.domainServices.apiService.serviceManager.swalServices.Toaster(ErrorMessage.InvalidFileTypeCSV);
        }
    }

    //** API's Call */
    add = () => {
        if (this.domainServices.isValidSubmit(this.state.formField, this.state.validationRules, this.state.validState)) {
            let request = {
                RegulationName: this.state.formField.regulationName,
                Type: this.state.formField.type,
                Organization: this.state.formField.organization,
                Country: this.state.formField.country,
                NumberOfEntries: this.state.formField.numberOfEntries,
                Description: this.state.formField.description,
                SourceURL: this.state.formField.sourceURL,
                Importance: this.state.formField.importance,
                Notes: this.state.formField.notes,
                UpdatePriority: this.state.formField.updatePriority,
                UpdateFrequency: this.state.formField.updateFrequency,
                DataSourceLastRevieweddate: this.state.formField.dataSourceLastRevieweddate,
                DataSourceLastUpdateddate: this.state.formField.dataSourceLastUpdateddate,
                DataSourceNextUpdatedate: this.state.formField.dataSourceNextUpdatedate,
                ChemidexLastDataUpload: this.state.formField.chemidexLastDataUpload,
                LastReviewedDate: this.state.formField.lastReviewedDate,
                LastReviewedBy: this.state.formField.lastReviewedBy,
                IsEnable: this.state.formField.isEnable
            }
            this.domainServices.onAdd(request);
        }
    }

    update = () => {
        if (this.domainServices.isValidSubmit(this.state.formField, this.state.validationRules, this.state.validState)) {
            let request = {
                RegulationID: this.state.formField.regulationID,
                RegulationName: this.state.formField.regulationName,
                Type: this.state.formField.type,
                Organization: this.state.formField.organization,
                Country: this.state.formField.country,
                NumberOfEntries: this.state.formField.numberOfEntries,
                Description: this.state.formField.description,
                SourceURL: this.state.formField.sourceURL,
                Importance: this.state.formField.importance,
                Notes: this.state.formField.notes,
                UpdatePriority: this.state.formField.updatePriority,
                UpdateFrequency: this.state.formField.updateFrequency,
                DataSourceLastRevieweddate: this.state.formField.dataSourceLastRevieweddate,
                DataSourceLastUpdateddate: this.state.formField.dataSourceLastUpdateddate,
                DataSourceNextUpdatedate: this.state.formField.dataSourceNextUpdatedate,
                ChemidexLastDataUpload: this.state.formField.chemidexLastDataUpload,
                LastReviewedDate: this.state.formField.lastReviewedDate,
                LastReviewedBy: this.state.formField.lastReviewedBy,
                IsEnable: this.state.formField.isEnable
            }
            this.domainServices.onUpdate(request);
        }
    }


    render() {
        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} />
        }
        return (
            <div className="mb-4">
                <div className="accordion" id="accordionExample">
                    <div className="card accordion-item active">
                        <div id="accordionOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <div className="card-body">
                                    {this.state.setIsLoad ?
                                        <Bars
                                            className="row"
                                            wrapperStyle={{ marginTop: "150px" }}
                                            color="#3c4b64"
                                            height={55}
                                            width="100%"
                                            visible={this.state.setIsLoad}
                                        /> :
                                        <div className="row mb-4">
                                            {this.domainServices.formFields.map((Item, index) => {
                                                if (this.state.regulationId) {
                                                    if ((Item.fieldName !== "productFile" || Item.inputType !== "file") && (Item.fieldName !== "regulationFile" || Item.inputType !== "file"))
                                                        return (
                                                            <>
                                                                <FormFieldGroup
                                                                    column={Item}
                                                                    hide={this.state.regulationId ? 'd-none' : ''}
                                                                    selectedDate={Item.inputType === 'datepicker' ? this.state.formField[Item.fieldName] : ''}
                                                                    value={this.state.formField[Item.fieldName]}
                                                                    error={this.state.validState.error[Item.fieldName]}
                                                                    onKeyDown={Item.onkeydown ? Item.onkeydown.bind(this) : null}
                                                                    options={Item.Options ? Item.Options : null}
                                                                    checked={Item.checked === true ? this.state.formField[Item.fieldName] : null}
                                                                    onChange={Item.inputType === 'checkbox' ? (event) => this.handleChange(Item.fieldName, event.target.checked)
                                                                        : Item.inputType === "file" ? (event) => this.handleFileChange(event) :
                                                                            Item.inputType === "select" || Item.inputType === "text" ? (event) => this.handleChange(Item.fieldName, event.target.value) :
                                                                                Item.inputType === 'datepicker' ? (e) => this.handleChange(Item.fieldName, e)
                                                                                    : (event) => this.handleChange(Item.fieldName, event.target.value)}
                                                                    key={index}
                                                                    disabled={(this.state.regulationId && Item.fieldName) === 'productFileName' ? true : (this.state.regulationId && Item.fieldName) === 'regulationFileName' ? true : false}
                                                                    onBlur={() => this.domainServices.validateField(Item.fieldName, this.state.formField, this.state.validationRules, this.state.validState)}
                                                                />
                                                            </>)

                                                } else {
                                                    if ((Item.fieldName !== "productFileName" || Item.inputType !== "text") && (Item.fieldName !== "regulationFileName" || Item.inputType !== "text")) {
                                                        return (
                                                            <>
                                                                <FormFieldGroup
                                                                    column={Item}
                                                                    hide={this.state.regulationId ? 'd-none' : ''}
                                                                    selectedDate={Item.inputType === 'datepicker' ? this.state.formField[Item.fieldName] : ''}
                                                                    value={this.state.formField[Item.fieldName]}
                                                                    error={this.state.validState.error[Item.fieldName]}
                                                                    onKeyDown={Item.onkeydown ? Item.onkeydown.bind(this) : null}
                                                                    options={Item.Options ? Item.Options : null}
                                                                    onChange={Item.inputType === 'checkbox' ? (event) => this.handleChange(Item.fieldName, event.target.checked)
                                                                        : Item.inputType === "file" ? (event) => this.handleFileChange(event) :
                                                                            Item.inputType === "select" || Item.inputType === "text" ? (event) => this.handleChange(Item.fieldName, event.target.value) :
                                                                                Item.inputType === 'datepicker' ? (e) => this.handleChange(Item.fieldName, e)
                                                                                    : (event) => this.handleChange(Item.fieldName, event.target.value)}
                                                                    key={index}
                                                                    onBlur={() => this.domainServices.validateField(Item.fieldName, this.state.formField, this.state.validationRules, this.state.validState)}
                                                                />
                                                            </>
                                                        );
                                                    }
                                                }
                                            })}
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="addnewuser-button mb-4">
                                <button className="tab-title tab-title--active  btn-hover  color-3 active" onClick={() => { this.state.regulationId ? this.update() : this.add() }}>
                                    {this.state.regulationId ? 'Update' : 'Save'}
                                </button>
                                <Button type='cancel' title="Cancel" className="ml-2" onClick={() => this.domainServices.onRedirectoList()} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
