import BaseDomainService from "./baseDomainServices";
import { DataFormat } from "../../utils/DateFormat";
import { getValidationRuleList } from "../../utils/validation/generateValidationRule";

const searchExceptionList = {
    searchText: ""
}
export default class exceptionService extends BaseDomainService {
    getErrorTraceList = this.apiService.exceptionServices.getErrorTraceList;
    getErrorTraceById = this.apiService.exceptionServices.getErrorTraceById;

    gridColmns = [
        { name: 'CreatedDate', title: 'Date & Time' },
        { name: 'IPAddress', title: 'IP Address' },
        { name: 'CustomErrorMessage', title: 'Custom Error Message' },
        { name: 'ModuleName', title: 'Module Name' },
        { name: 'isSolved', title: 'Solved' },
        { name: 'errorLogId', title: 'Action' }
    ];

    formFieldsSearch = [
        {
            inputType: "text",
            tittle: "",
            placeholder: "Search ...",
            fieldName: "searchText",
            mode: 1,
            className: 'col-md-4 mb-0'
        },
    ]

    formFields = [
        {
            inputType: "search",
            placeholder: "Search ...",
            fieldName: "searchText",
            labelStyleName: 'none'
        },
    ]
    constructor() {
        super();
        this.onPaginationUpdate = this.onExceptionList.bind(this);
        this.actionKey = "errorLogId";
        this.ipActionKey = "IPAddress";
        this.actionActive = "isSolved";
        // this.dataItem = { ...ExceptionUpdate };
        this.SearchdataItem = { ...searchExceptionList };
    }

    onExceptionList = (requst) => {
        this.apiService.onAPICall("getErrorTraceList", this.getErrorTraceList, requst, this.onAPIResponse, this.onUpdateState);
    }

    onGetErrorTraceById = (requst) => {
        this.apiService.onAPICall("getErrorTraceById", this.getErrorTraceById, requst, this.onAPIResponse, this.onUpdateState);
    };

    onInitService() {
        let valRule = getValidationRuleList(this)
        this.validationRules = valRule;
        this.onUpdateState({
            "validationRules": valRule,
        })
    }
    onRedirectoList = () => {
        this.onRedirect("/Exception");
    }

    onAPIResponse = (key, isSuccess, responseData, errorLogId) => {
        if (!isSuccess) {
            this.onUpdateState({ "setIsLoad": false });
            return;
        }
        switch (key) {
            case "getErrorTraceList":
                if (responseData.itemList !== null) {
                    let itemList = responseData.itemList;
                    itemList.forEach(function (i) {
                        i.createdDate = i.createdDate ? DataFormat(i.createdDate) : "-";
                        i.ipAddress = i.ipAddress ? i.ipAddress : '-'
                    });
                }
                this.onUpdateState({
                    dataList: responseData.itemList,
                    TotalCount: responseData.totalCount,
                    setIsLoad: false
                });
                break;

            case "getErrorTraceById":
                this.onUpdateState({
                    dataList: responseData,
                    setIsLoad: false,
                    customErrorMessage: responseData.customErrorMessage,
                    innerSystemErrorMessage: responseData.innerSystemErrorMessage,
                    trackTrace: responseData.trackTrace,
                    errorLogId: responseData.errorLogId,
                    systemErrorMessage: responseData.systemErrorMessage,
                    createdDate: responseData.createdDate,
                });
                break;
            default:
                // Handle default case here
                break;
        }
    }
}

