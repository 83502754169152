//** Component  */
import React from 'react'
import VerticalProgressBar from '../../components/addNewOperational/VerticalProgressBar';
import VerticalProgressBarGroup from '../../components/addNewOperational/VerticalProgressBarGroup';

const data = [
    {
        id: '1',
        tabTitle: "Log Details",
        tabContent: <VerticalProgressBar />
    },
    {
        id: '2',
        tabTitle: "Log Details group",
        tabContent: <VerticalProgressBarGroup />
    }
]
function LogTable() {

    // Hooks
    const [visibleTab, setVisibleTab] = React.useState(data[0].id)
    const listTitles = data.map((item) =>
        <li onClick={() => setVisibleTab(item.id)} className={visibleTab === item.id ? "tab-title tab-title--active  btn-hover-1 color-3 active " : "btn-hover-1 color-3 tab-title"}>{item.tabTitle}</li>
    )
    const listContent = data.map((item) =>
        <p style={visibleTab === item.id ? {} : { display: 'none' }}>
            {item.tabContent}
        </p>
    )
    return (
        <>
            <div className='container'>
                <div className="tabs new-btn-width ">
                    <ul className="tabs-titles justify-content-left new-border pl-0 ">
                        {listTitles}
                    </ul>
                    <div className="tab-content mt-2 ">
                        {listContent}
                    </div>
                </div>
            </div>

        </>
    )
}

export default LogTable
