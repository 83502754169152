import { SuccessMessage } from "../../utils/Messages";
import { getValidationRuleList } from "../../utils/validation/generateValidationRule";
import BaseDomainService from "./baseDomainServices";

const serverDetail = {
    serverId: "",
    serverName: "",
    serverPort: "",
    serverIP: "",
    isActive: false,
    operatingSystem: "",
    clientTypeId: "",
    clientLocationId: "",
    parentServerIP: "",
    serverLocation: "",
    middlewareServerIP: "",
    isVM: false,
    isVPN: false,
    hostServerId: ""
}


export default class serverDomainServices extends BaseDomainService {

    // API Get
    getList = this.apiService.serverConfigurationsServices.getAllServerConfigurationsList;
    getbyId = this.apiService.serverConfigurationsServices.getServerConfigurationsById;
    add = this.apiService.serverConfigurationsServices.addServerConfigurations;
    update = this.apiService.serverConfigurationsServices.updateServerConfigurations;
    getoperationalModuleList = this.apiService.manageOperationalServices.getAllOperationalModuleList;
    getAllAssignServerByModuleId = this.apiService.serverConfigurationsServices.getAllAssignServerByModuleId;
    getAllUnAssignServerByModuleId = this.apiService.serverConfigurationsServices.getAllUnAssignServerByModuleId;
    assignModuleToServer = this.apiService.serverConfigurationsServices.assignModuleToServer;
    deleteServer = this.apiService.serverConfigurationsServices.deleteServer;
    getAllServerList = this.apiService.serverConfigurationsServices.getAllServerListing;



    //Table Grid columns
    gridColmns = [
        { name: 'serverName', title: 'Server Client Name' },
        { name: 'hostServerName', title: 'Host Server Name' },
        { name: 'serverIP', title: 'Server ClientIP' },
        { name: 'serverPort', title: 'Server Client Port' },
        { name: 'isActive', title: 'Active' },
        { name: 'serverId', title: 'Action' },
    ];

    // Define the state variables
    dataItem;
    serverResetItem = {
        serverId: "",
        serverName: "",
        serverPort: "",
        serverIP: "",
        isActive: false,
        operatingSystem: "",
        clientTypeId: "",
        clientLocationId: "",
        parentServerIP: "",
        serverLocation: "",
        middlewareServerIP: "",
        isVM: false,
        isVPN: false,

    }

    // define the form fields
    formFields = [
        {
            inputType: "select",
            tittle: "Server Name",
            placeholder: "Select Server Name",
            fieldName: "hostServerId",
            options: [],
            optionFieldName: "hostServerId",
            className: 'col-md-6',
            validation: [{ type: "require" }]
        },
        {
            inputType: "text",
            tittle: "Server Client Name",
            placeholder: "Server Client Name",
            fieldName: "serverName",
            className: 'col-md-6',
            validation: [{ type: "require" }]
        },
        {
            inputType: "text",
            tittle: "Server Client Port",
            placeholder: "Server Client Port",
            fieldName: "serverPort",
            className: 'col-md-6',
            maxLength: 4,
            onkeydown: (e) => {
                /^[\s\a-zA-Z,\b][0-9,\b]*$/.test(e.key) && e.preventDefault()
            },
            validation: [{ type: "require" }]
        },
        {
            inputType: "text",
            tittle: "Server Client IP",
            placeholder: "Server Client IP",
            fieldName: "serverIP",
            maxLength: 15,
            onkeydown: (e) => {
                /^[\s\a-zA-Z,\b][0-9,\b]*$/.test(e.key) && e.preventDefault()
            },
            className: 'col-md-6',
            validation: [{ type: "require" }]
        },
        {
            inputType: "text",
            tittle: "Server Location",
            placeholder: "Server Location",
            fieldName: "serverLocation",
            onkeydown: (e) => {
                /^[0-9\b]+$/.test(e.key) && e.preventDefault()
            },
            className: 'col-md-6',
            validation: [{ type: "require" }]
        },
        {
            inputType: "text",
            tittle: "Middleware Server IP",
            placeholder: "Middleware Server IP",
            fieldName: "middlewareServerIP",
            maxLength: 15,
            className: 'col-md-6',
            validation: [{ type: "require" },],
            onkeydown: (e) => {
                /^[\s\a-zA-Z,\b][0-9,\b]*$/.test(e.key) && e.preventDefault()
            },
        },
        {
            inputType: "select",
            tittle: "Operating System",
            placeholder: "Select",
            fieldName: "operatingSystem",
            isDefault: true,
            Options: [
                { name: "Window", id: "1" },
                { name: "Linux", id: "2" },
            ],
            className: 'col-md-6 mb-3'
        },
        {
            inputType: "select",
            tittle: "Client Type",
            placeholder: "Select",
            fieldName: "clientTypeId",
            isDefault: true,
            Options: [
                { name: "Doker", id: "1" },
                { name: "VM", id: "2" },
                { name: "Desktop", id: "3" },
            ],
            className: 'col-md-6 mb-3'
        },
        {
            inputType: "select",
            tittle: "Add Location",
            placeholder: "Select",
            fieldName: "clientLocationId",
            isDefault: true,
            Options: [
                { name: "India", id: "1" },
                { name: "USA", id: "2" },
            ],
            className: 'col-md-6 mb-3'
        },
        {
            inputType: "checkbox",
            tittle: "IsActive ?",
            fieldName: "isActive",
            checked: true,
            className: 'col-xl-4 col-6 ServerChekbox',
        },
        {
            inputType: "checkbox",
            tittle: "IsVirtualMachine ?",
            fieldName: "isVM",
            checked: true,
            className: 'col-xl-4 col-6 ServerChekbox',
        },
        {
            inputType: "checkbox",
            tittle: "IsVPN ?",
            fieldName: "isVPN",
            checked: true,
            className: 'col-xl-4 col-6 mt-4',
        },
    ]
    configformFields = [
        {
            inputType: "text",
            tittle: "System",
            placeholder: "System",
            fieldName: "System",
            className: 'col-md-6',
            validation: [{ type: "require" }]
        },
        {
            inputType: "text",
            tittle: "Node_name",
            placeholder: "node_name",
            fieldName: "node_name",
            className: 'col-md-6',
            validation: [{ type: "require" }],
        },
        {
            inputType: "text",
            tittle: "Version",
            placeholder: "Version",
            fieldName: "Version",
            className: 'col-md-6',
            validation: [{ type: "require" }],
        },
        {
            inputType: "text",
            tittle: "Machine",
            placeholder: "Machine",
            fieldName: "Machine",
            className: 'col-md-6',
            validation: [{ type: "require" }],
        },
        {
            inputType: "text",
            tittle: "Available_Memory",
            placeholder: "Available_Memory",
            fieldName: "Available_Memory",
            className: 'col-md-6',
            validation: [{ type: "require" }],
        },
        {
            inputType: "text",
            tittle: "Total_Memory",
            placeholder: "Total_Memory",
            fieldName: "Total_Memory",
            className: 'col-md-6',
            validation: [{ type: "require" }],
        },
        {
            inputType: "text",
            tittle: "Used_Memory",
            placeholder: "Used_Memory",
            fieldName: "Used_Memory",
            className: 'col-md-6',
            validation: [{ type: "require" }],
        },
    ]

    serverMappingformFields = [
        {
            inputType: "select",
            tittle: "Module Name",
            placeholder: "Module Name",
            fieldName: "moduleId",
            options: [],
            optionFieldName: "moduleName",
            subRequest: "1",
            className: 'col-md-6',
            validation: [{ type: "require" }],
        },
    ]
    // Validation 

    validationRules = {}
    resetValidationRule = {}

    constructor() {
        super();
        this.onPaginationUpdate = this.onGetServer.bind(this);
        this.actionKey = "serverId";
        this.actionActive = "isActive";
        this.actionRequestType = "requestType";
        this.dataItem = { ...serverDetail };
    }

    //** API Calls 

    onGetServer = (requst) => {
        this.apiService.onAPICall("getServer", this.getList, requst, this.onAPIResponse, this.onUpdateState);
    }
    onDelete = (requst) => {
        this.apiService.onAPICall("delete", this.deleteServer, requst, this.onAPIResponse, this.onUpdateState);
    }

    onAdd = (request) => {
        this.apiService.onAPICall("addServer", this.add, request, this.onAPIResponse, this.onUpdateState);
    }

    onUpdate = (request) => {
        this.apiService.onAPICall("update", this.update, request, this.onAPIResponse, this.onUpdateState);
    }

    // On Update Password
    onUpdatePassword = (request) => {
        this.apiService.onAPICall("upserver", this.updateServer, request, this.onAPIResponse, this.onUpdateState);
    }

    onGetByid = (request) => {
        this.apiService.onAPICall("getServerByid", this.getbyId, request, this.onAPIResponse, this.onUpdateState);
    }
    onGetoperationalModuleList = (requst) => {
        this.apiService.onAPICall("getoperationalModuleList", this.getoperationalModuleList, requst, this.onAPIResponse, this.onUpdateState);
    }
    onGetAllAssignServerByModuleId = (requst) => {
        this.apiService.onAPICall("getAllAssignServerByModuleId", this.getAllAssignServerByModuleId, requst, this.onAPIResponse, this.onUpdateState);
    }
    onGetAllUnAssignServerByModuleId = (requst) => {
        this.apiService.onAPICall("getAllUnAssignServerByModuleId", this.getAllUnAssignServerByModuleId, requst, this.onAPIResponse, this.onUpdateState);
    }
    onAssignModuleToServer = (requst) => {
        this.apiService.onAPICall("assignModuleToServer", this.assignModuleToServer, requst, this.onAPIResponse, this.onUpdateState);
    }
    onGetAllServerList = (requst) => {
        this.apiService.onAPICall("getAllServerList", this.getAllServerList, requst, this.onAPIResponse, this.onUpdateState);
    }



    //** Service Event */

    onInitService() {
        //This method only call when we use form component and we can add all items which we on on form load 
        let valRule = getValidationRuleList(this.formFields)
        this.validationRules = valRule;
        this.onUpdateState({
            "validationRules": valRule,
            "resetValidationRule": this.resetValidationRule
        })

    }

    onResetData = () => {
        this.dataItem = { ...this.serverDetail }
        this.onUpdateState({ addServerDetails: this.dataItem });
    }
    //** Methods     */
    //  When we get response from  API

    onRedirectoList = () => {
        this.onRedirect("/ServerClientConfigurations");
    }
    onViewRequestDetail = () => {
        this.onRedirect("/ServerClientConfigurations");
    }

    onAPIResponse = (key, isSuccess, responseData) => {
        if (!isSuccess) {
            this.onUpdateState({ "setIsLoad": false });
            return;
        }
        switch (key) {
            case "getServer":
                this.onUpdateState({
                    "dataList": responseData.itemList,
                    "TotalCount": responseData.totalCount,
                    "setIsLoad": false
                });
                break;

            case "getServerByid":
                let userDet = ({ ...responseData });
                this.dataItem = { ...userDet };
                this.onUpdateState({
                    addServerDetails: {
                        serverName: userDet.serverName,
                        isActive: userDet.isActive,
                        serverIP: userDet.serverIP,
                        serverPort: userDet.serverPort,
                        parentServerIP: userDet.parentServerIP,
                        middlewareServerIP: userDet.middlewareServerIP,
                        operatingSystem: userDet.operatingSystem,
                        clientTypeId: userDet.clientTypeId,
                        clientLocationId: userDet.clientLocationId,
                        serverLocation: userDet.serverLocation,
                        isVM: userDet.isVM,
                        isVPN: userDet.isVPN,
                        hostServerId: userDet.hostServerId,
                    },
                    updateServer: userDet.serverId,
                    "setIsLoad": false
                });

                break;
            case "addServer":
                // Need to check if user is exist or not.
                if (responseData.errorType === "EXIST" && responseData.errorColumn === "Server IP") {
                    this.apiService.serviceManager.swalServices.Error(SuccessMessage.Already_Exist.replace("{0}", "This Server IP"));
                }
                else if (responseData.errorType === "EXIST" && responseData.errorColumn === "Server Name") {
                    this.apiService.serviceManager.swalServices.Error(SuccessMessage.Already_Exist.replace("{0}", "This Server Name"));
                }
                else if (responseData.errorType === "EXIST" && responseData.errorColumn === "Server Port") {
                    this.apiService.serviceManager.swalServices.Error(SuccessMessage.Already_Exist.replace("{0}", "This Server Port"));
                }
                else {
                    this.apiService.serviceManager.swalServices.Success(SuccessMessage.Add_Success.replace("{0}", "This Client Configurations"));
                    this.onRedirectoList()
                }
                this.onUpdateState({ "setIsLoad": false });
                break;
            case "update":
                if (responseData.errorType === "EXIST" && responseData.errorColumn === "Server IP") {
                    this.apiService.serviceManager.swalServices.Error(SuccessMessage.Already_Exist.replace("{0}", "This Server IP"));
                }
                else if (responseData.errorType === "EXIST" && responseData.errorColumn === "Server Name") {
                    this.apiService.serviceManager.swalServices.Error(SuccessMessage.Already_Exist.replace("{0}", "This Server Name"));
                }
                else if (responseData.errorType === "EXIST" && responseData.errorColumn === "Server Port") {
                    this.apiService.serviceManager.swalServices.Error(SuccessMessage.Already_Exist.replace("{0}", "This Server Port"));
                }
                else {
                    this.apiService.serviceManager.swalServices.Success(SuccessMessage.Update_Success.replace("{0}", "This Client Configurations"));
                    this.onRedirectoList();
                    this.onUpdateState({ "setIsLoad": false });
                }
                this.onUpdateState({ "setIsLoad": false });
                break;
            case "getoperationalModuleList":
                let moduleList = responseData;
                let moduleData = moduleList?.map((data) => ({
                    id: data.moduleId,
                    name: data.moduleName,
                }));
                this.onUpdateState({
                    setOperationalList: moduleData

                });
                break;
            case "getAllAssignServerByModuleId":
                if (responseData !== null) {
                    this.onUpdateState({
                        serverByModuleId: responseData,
                        elementsB: responseData
                    });
                }
                else {
                    this.onUpdateState({
                        elementsB: null
                    });
                }

                break;
            case "delete":
                this.apiService.serviceManager.swalServices.Success(SuccessMessage.Delete_Success.replace("{0}", "Module"));
                this.onUpdateState({ "setIsLoad": false });
                this.onGetServer(this.pagination);
                break;
            case "getAllUnAssignServerByModuleId":
                if (responseData !== null) {
                    this.onUpdateState({
                        serverByModuleId: responseData,
                        elementsA: responseData
                    });
                }
                else {
                    this.onUpdateState({
                        elementsA: null
                    });
                }
                break;
            case "assignModuleToServer":
                break;

            case "getAllServerList":
                if (responseData !== null) {
                    let serverList = responseData.itemList;
                    let serverData = serverList?.map((data) => ({
                        id: data.hostServerId,
                        name: data.serverName,
                        serverIP:data.serverIp,
                        serverLocation:data.serverLocationId,
                    }));
                    this.onUpdateState({
                        "serverOptionList": serverData,
                        "setIsLoad": false
                    });
                }
                break;
                default:
                    // Handle default case here
                    break;
        }
    }
}