
//** Component  */
import React from 'react';

//** Services */
import DashboardDomainServices from "../../services/domainServices/dashboardServices";
import DashboardProgressbar from '../../components/addNewOperational/DashboardProgressbar';

//*** Lib */
import { Navigate } from 'react-router-dom';
import { Bars } from 'react-loader-spinner';
import BaseComponent from '../../shared/baseComponent';
import { encryptionData } from '../../utils/Encryption';
import { getCookie } from '../../utils/cookies/CookieHandler';

export default class Dashboard extends BaseComponent {
  constructor(props) {
    super(props)
    this.dashboardService = new DashboardDomainServices();
    this.dashboardService.onUpdateState = this.updateState.bind(this);
    this.dashboardService.onActionCall = this.onActionCall.bind(this);
    this.state = {
      dataList: [],
      ServerId: [],
      setIsLoad: false,
      dataImportList: {},
      ActiveClientsList: []
    }
  }

  componentDidMount() {
    this.dashboardService.onGetDashboardCounts(this.state.pagination);
    this.dashboardService.onGetRunningRequest();
    this.dashboardService.onGetActiveServer();
  }

  onActionCall = (type, data) => {
    switch (type) {
      case "module":
        this.setState({ redirect: "/RequestDetails/id?=" + data });
        break;
      case "server":
        window.open(`/AddServer/id?=${data}`, '_blank');
        break;
      default:
        // Handle default case here
        break;
    }
  }

  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <>
        <nav id="layout-navbar">
        </nav>

        <div className="row">
          {this.dashboardService.getAllCardList.map((data) => (
            <div className='col-md-6 col-lg-4 col-xxl-4" mb-4'>
              <div className="card-New card-margin" style={{ paddingTop: '10px' }}>
                <div className="card-body name1">
                  <div className="card-title d-flex align-items-start justify-content-between">
                    <div className="avatar flex-shrink-0 ">
                      <img src={data.cardImages} alt="Credit Card" className="rounded imgcenter" />
                    </div>
                    <div className="dropdown">
                      <button className="btn p-0" type="button" id="cardOpt4" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="bx bx-dots-vertical-rounded"></i>
                      </button>
                      <div className="dropdown-menu dropdown-menu-end" aria-labelledby="cardOpt4">
                        <a className="dropdown-item" href={data.href}> {data.cardBtn} </a>
                      </div>
                    </div>
                  </div>
                  <span className="fw-semibold d-block mb-1 cardmargintop text-color margin-t"> {data.cardName} </span>
                  {data.cardName === "Active Module" ? <>{this.state.dataImportList.totalModules ? this.state.dataImportList.totalModules : 0}</> : null}
                  {data.cardName === "Client / Server Configure" ? <div className='Client-server-card'>
                    <span>{this.state.dataImportList.totalServer ? this.state.dataImportList.totalServer : 0}</span> -
                    <span> {this.state.dataImportList.totalHostServer ? this.state.dataImportList.totalHostServer : 0}</span>

                  </div> : null}
                  {data.cardName === "Currently Running Request" ? <>{this.state.dataImportList.totalRunningRequests ? this.state.dataImportList.totalRunningRequests : 0}</> : null}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="card mb-30">
          <h5 className="card-header">Working On Following Items</h5>
          <div className='card-body'>
            <div className="table-responsive text-nowrap text-shadow-pd">
              <table className="table table-hover table-striped table-scroll ">
                <thead>
                  <tr className='text-header'>
                    <th className="text-left">Module Name</th>
                    <th className='text-left'>Supplier Name</th>
                    <th className='text-center'>Stages</th>
                  </tr>
                </thead>
                <tbody className="table-border-bottom-0 text-center borderColor ">
                  {this.state.setIsLoad ?
                    <Bars
                      className="row"
                      wrapperStyle={{ margin: '60px 130px' }}
                      color="#3c4b64"
                      height={55}
                      width="100%"
                      visible={this.state.setIsLoad} />
                    :
                    <>
                      {this.state.dataList && this.state.dataList.length > 0 ? this.state.dataList.map((OperationalModuleList, index) => (
                        <tr>

                          <td className='align-middle cursor-pointer ' onClick={() => this.onActionCall("module", encryptionData(OperationalModuleList.middlewareRequestId))}>
                            {/* <a className='url-links-tag' herf={OperationalModuleList.moduleName}> */}
                              <strong >{OperationalModuleList.moduleName}</strong>
                            {/* </a> */}
                          </td>
                          <td className='align-middle'>
                            <strong>{OperationalModuleList.supplierName}</strong>
                          </td>
                          <td className='dashboard-progress-part'>
                            <DashboardProgressbar dataParentToChild={OperationalModuleList} />
                          </td>
                        </tr>
                      )) : <span className='detail-desc'>
                        No Running Process
                      </span>}
                    </>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="card">
          <h5 className="card-header">Active Server</h5>
          <div className='card-body'>
            <div className="table-responsive text-nowrap text-shadow-pd">
              <table className="table table-hover table-striped table-borderless table-scroll ">
                <thead className='text-center'>
                  <tr>
                    <th>Server Name</th>
                    <th>Server Client IP</th>
                    <th>Location</th>
                  </tr>
                </thead>
                <tbody className="table-border-bottom-0 text-center borderColor body-half-screen cursor-pointer">
                  {this.state.setIsLoad ?
                    <Bars
                      className="row"
                      wrapperStyle={{ margin: '60px 0' }}
                      color="#3c4b64"
                      height={55}
                      width="100%"
                      visible={this.state.setIsLoad} />
                    :
                    <>
                      {this.state.ActiveClientsList ? this.state.ActiveClientsList.map((ActiveModuleList, index) => (
                        <tr>
                          <td onClick={() => this.onActionCall("server", encryptionData(ActiveModuleList.serverId))}>
                            {/* <a className='url-links-tag' herf={ActiveModuleList.serverId} > */}
                              <strong>{ActiveModuleList.serverName} </strong>
                            {/* </a> */}
                          </td>
                          <td>
                            <strong>{ActiveModuleList.serverIp}</strong>
                          </td>
                          <td>
                            <strong>{ActiveModuleList.serverLocationId}</strong>
                          </td>
                          {/* <td>
                            <InputText disabled className="form-check-input form-input-position ms-4" type="checkbox" id="inlineFormCheck"
                              checked={ActiveModuleList.location} /></td> */}
                        </tr>
                      )) : null}
                    </>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    )
  }
}
