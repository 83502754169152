//** Component  */
import React from 'react';

const Button = (props) => {
    return (
        props.type === 'save' ?
            <button className={`btn-hover color-3 active ${props.className} ${props.isLoading ? "disabled:cursor-not-allowed" : ""}`} onClick={() => props.onClick()} disabled={props.isLoading}>
                {props.isLoading ?
                    <div className="loader-inner line-scale-pulse-out-rapid">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    : <span className={`${props.isLoading ? "ml-1" : ""}`}>{props.title}</span>
                }
            </button>
            :
            props.type === 'cancel' ?
                <button className={`btn-hover color-3 ${props.className} ${props.isLoading ? "disabled:cursor-not-allowed" : ""}`} onClick={() => props.onClick()} disabled={props.isLoading}>
                    {props.isLoading ?
                        <div className="loader-inner line-scale-pulse-out-rapid">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        : <span className={`${props.isLoading ? "ml-1" : ""}`}>{props.title}</span>
                    }
                </button>
                :
                <button className="btn border-slate-200 hover:border-slate-300 text-slate-600" onClick={() => props.onClick()}>
                    {props.title}
                </button>
    )
}

export default Button;
