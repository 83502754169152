//** Component  */
import Table from "../../components/table/Table";
import BaseComponent from "../../shared/baseComponent";

//** Services */
import manageServerServices from "../../services/domainServices/manageServerServices";
//*** Lib */
import { Navigate } from "react-router";
import { Bars } from "react-loader-spinner";
import { encryptionData } from "../../utils/Encryption";
import { Link } from "react-router-dom";

export default class ViewServer extends BaseComponent {
  constructor(props) {
    super(props);
    this.manageserverService = new manageServerServices();
    this.state = {
      pagination: this.manageserverService.pagination,
      serverList: [],
      setIsLoad: false,
    };
    this.manageserverService.onUpdateState = this.updateState.bind(this);
    this.manageserverService.onActionCall = this.onActionCall.bind(this);
  }
  //** Constructor  and Component method */
  componentDidMount() {
    this.manageserverService.onGetAllServerList(this.state.pagination);
  }

  // *** Other function **//

  onActionCall = (type, data) => {
    // *** getByID encryption ***//
    const encryptKey = encryptionData(data, 1);
    switch (type) {
      case "Edit":
        window.open(`/AddServer/id?=${encryptKey}`, "_blank");
        break;
      case "Delete":
        this.manageserverService.apiService.serviceManager.swalServices
          .Confirm(
            "Are you sure you want to delete the Server?",
            "",
            "Yes",
            "No"
          )
          .then(async (confirm) => {
            if (confirm) {
              let request = {
                ServerId: data,
              };
              this.manageserverService.onDeleteServer(request);
            }
          });
        break;
      default:
        // Handle default case here
        break;
    }
  };

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <>
        <div className="">
          <div className="top-search-add-button-sec mb-3">
            <div className="row left-searchbar-sec"></div>
            <div className="right-button-sec">
              <Link
                to="/AddServer"
                className="tab-title btn-hover color-3 active"
              >
                Add New Server <span>+</span>
              </Link>
            </div>
          </div>
          <div className="manage-Server-list">
            {this.state.setIsLoad ? (
              <Bars
                className="row"
                wrapperStyle={{ marginTop: "150px" }}
                color="#3c4b64"
                height={55}
                width="100%"
                visible={this.state.setIsLoad}
              />
            ) : (
              <Table
                columns={this.manageserverService.gridColmns}
                rows={this.state.serverList}
                sortingColumns={[]}
                pagination={this.state.pagination}
                totalCount={this.state.TotalCount}
                totalResultes={this.state.TotalCount}
                setPagination={this.manageserverService.onPageChange.bind(this)}
                customScope={[
                  {
                    column: "isActive",
                    renderTableData:
                      this.manageserverService.gridCheckBoxCol.bind(this),
                  },
                  {
                    column: "hostServerId",
                    isDeleteRequired: true,
                    renderTableData:
                      this.manageserverService.gridActionsCol.bind(this),
                  },
                ]}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}
