//** Component  */
import React from "react";

//** Services */
import { DownloadInputFile } from "../../../services/axiosServices/ApiEndPoints";

//*** Lib */
import BaseComponent from "../../../shared/baseComponent";
import axios from 'axios';
import FileViewer from 'react-file-viewer';
import { Modal } from "react-bootstrap";

export default class ProductDataInitiate extends BaseComponent {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      fileToView: null,
      fileContent: null,
      setIsLoad: false
    }
  }

  handleClose = () => {
    this.setState({ showModal: false })
    this.setState({ fileToView: null });
  }

  previewFile = () => {
    const downloadUrl = `${DownloadInputFile}?FileName=${encodeURIComponent(this.props.productDataSyncDetail.fileName)}&ModuleType=ProductSyncInput`;
    this.setState({ setIsLoad: true });
    axios.get(downloadUrl, { responseType: 'blob' })
      .then(response => {
        this.setState({ fileToView: response.data });
        this.setState({ showModal: true });
      })
      .catch(error => {
        console.error('Error downloading the file:', error);
      });
  };

  render() {
    const { showModal } = this.state
    return (
      <>
        <div className="card">
          <div className="card-title-main px-3 py-2">
            <h6 className="titletexts font-size-16">Intiate File</h6>
          </div>
          <div className="card-body custom-card-body">
            <div className="inner-detail-sec">
              <span className="detail-title"> Intiate File </span>
              <span className="detail-desc">
                &nbsp;&nbsp;&nbsp;
                {this.props.productDataSyncDetail.intiateCount}
                {this.props.productDataSyncDetail.fileName !== "" ? (
                  <>
                    {this.props.productDataSyncDetail.intiateCount > 0 ? (
                      <span htmlFor="requestName" className="detail-desc ml-3 font-size-16">
                        <a
                          href={`${DownloadInputFile +
                            `?FileName=${this.props.productDataSyncDetail.fileName}&ModuleType=ProductSyncInput`
                            }`}
                          target="_top"
                        >
                          <i className="fa fa-download"></i>
                        </a>
                        <img src="/csv.png" className="csv-file ml-2 cursor-pointer" title="Preview" alt="Text icon" onClick={this.previewFile} />
                      </span>
                    ) : null}
                  </>
                ) : (
                  <span className="detail-desc">No Record found</span>
                )}
              </span>
            </div>
          </div>
        </div>
        <Modal className="scrolling-model" show={showModal} onHide={this.handleClose}>
          <Modal.Header closeButton >
            <Modal.Title>File Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body >
            <div className="RequestDetails-modal-file">
              {this.state.fileToView && (
                <>
                  <div>
                    <FileViewer
                      fileType="csv" // Set the file type to csv
                      filePath={URL.createObjectURL(this.state.fileToView)}
                      onError={error => console.error('Error:', error)}
                    />
                  </div>
                </>
              )
              }
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
