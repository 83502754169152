//** Component  */
import React from "react";
import BaseComponent from "../../shared/baseComponent";
//** Services */
//*** Lib */
import { Navigate } from "react-router";
import Button from "../../components/common/Button";
import ButtonLoader from "../../components/common/buttonLoader/ButtonLoader";
import FormFieldGroup from "../../components/UI Element/FormGrop/FormFieldGroup";
import manageServerServices from "../../services/domainServices/manageServerServices";
import { decryptionData } from "../../../src/utils/Encryption";

export default class AddServer extends BaseComponent {
  //** Constructor  and Component method */
  constructor(props) {
    super(props);
    this.manageserverService = new manageServerServices();
    this.manageserverService.onUpdateState = this.updateState.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      ...this.state,
      serverId: 0,
      IsUpdatedMode: false,
      serverDetail: this.manageserverService.dataItem,
      setIsLoad: false,
    };
  }

  componentDidMount() {
    this.manageserverService.onInitService();
    let params = new URLSearchParams(window.location.search);
    let serverId = params.get("");
    if (serverId !== null && serverId !== undefined) {
      // *** getByID decryption ***//
      const decryptKey = decryptionData(serverId, false);
      this.setState({ serverId: decryptKey });

      if (decryptKey > 0) {
        this.manageserverService.onGetServerById(decryptKey);
      }
    }
  }

  // *** Event function **//
  handleChange(name, value) {
    let detail = this.state.serverDetail;
    detail[name] = value;
    this.setState({ serverDetail: { ...detail } });
  }

  add = () => {
    if (
      this.manageserverService.isValidSubmit(
        this.state.serverDetail,
        this.state.validationRules,
        this.state.validState
      )
    ) {
      let request = {
        serverName: this.state.serverDetail.serverName,
        serverIp: this.state.serverDetail.serverIp,
        serverLocationId: this.state.serverDetail.serverLocationId,
        isActive: this.state.serverDetail.isActive,
        createdBy: this.manageserverService.authUser.adminUserId,
      };
      this.manageserverService.onAdd(request);
    }
  };

  update() {
    if (
      this.manageserverService.isValidSubmit(
        this.state.serverDetail,
        this.state.validationRules,
        this.state.validState
      )
    ) {
      let request = {
        serverId: this.state.serverId,
        serverName: this.state.serverDetail.serverName,
        serverIp: this.state.serverDetail.serverIp,
        serverLocationId: this.state.serverDetail.serverLocationId,
        isActive: this.state.serverDetail.isActive,
        updatedBy: this.manageserverService.authUser.adminUserId,
      };
      this.manageserverService.onUpdate(request);
    }
  }


  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <div className="mb-4">
        <div className="accordion" id="accordionExample">
          <div className="card accordion-item active">

            <div
              id="accordionOne"
              className="accordion-collapse collapse show"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <div className="card-body">
                {this.state.serverId > 0 ? 
                  <div>
                    <h6><b>Server Id : {this.state.serverId}</b></h6>
                  </div> 
                  : null }
                  <div className="row">
                    {this.manageserverService.formFields.map((Item, index) => {
                      return (
                        <>
                          <FormFieldGroup
                            column={Item}
                            value={this.state.serverDetail[Item.fieldName]}
                            error={this.state.validState.error[Item.fieldName]}
                            checked={
                              Item.checked === true
                                ? this.state.serverDetail[Item.fieldName]
                                : null
                            }
                            onChange={
                              Item.inputType === "checkbox"
                                ? (event) =>
                                  this.handleChange(
                                    Item.fieldName,
                                    event.target.checked
                                  )
                                : (event) =>
                                  this.handleChange(
                                    Item.fieldName,
                                    event.target.value
                                  )
                            }
                            options={Item.Options ? Item.Options : null}
                            onKeyDown={
                              Item.onkeydown ? Item.onkeydown.bind(this) : null
                            }
                            onBlur={() =>
                              this.manageserverService.validateField(
                                Item.fieldName,
                                this.state.serverDetail,
                                this.state.validationRules,
                                this.state.validState
                              )
                            }
                          />
                        </>
                      );
                    })}
                    <div className="col-md-12 addnewuser-button">
                      {this.state.IsUpdatedMode ? (
                        <button
                          className="tab-title tab-title--active ml-2 btn-hover color-3 active"
                          onClick={() => this.update()}
                        >
                          {this.state.setIsLoad ? <ButtonLoader /> : "Update "}
                        </button>
                      ) : (
                        <button
                          className="tab-title tab-title--active ml-2 btn-hover color-3 active"
                          onClick={() => this.add()}
                        >
                          {this.state.setIsLoad ? <ButtonLoader /> : "Save "}
                        </button>
                      )}
                      <Button
                        type="cancel"
                        title="Cancel"
                        className="btn-hover color-3 tab-title ml-2"
                        onClick={() => this.manageserverService.onRedirectoList()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
