
//** Component  */
import React from "react";

//** Services */
import { DownloadInputFile } from "../../services/axiosServices/ApiEndPoints";

//*** Lib */
import TabProgressBarStyle from "../../styles/TabProgressBarStyle";
import RequestDomainServices from "../../services/domainServices/requestServices";
import BaseComponent from "../../shared/baseComponent";
import { decryptionData } from "../../utils/Encryption";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { Bars } from "react-loader-spinner";
import "../../scss/scrollTable.scss";
import FileViewer from "react-file-viewer";
export default class RequestInitiate extends BaseComponent {
  constructor(props) {
    super(props);
    this.requestServices = new RequestDomainServices();
    this.state = {
      productsCounts: [],
      showModal: false,
      fileToView: null,
      fileContent: null,
      setIsLoad: false,
    };
    this.requestServices.onUpdateState = this.updateState.bind(this);
  }

  //** Constructor  and Component method */
  componentDidMount() {
    let params = new URLSearchParams(window.location.search);
    let reqId = params.get("");
    const decryptKey = decryptionData(reqId, false);
    if (reqId > 0) {
      this.requestServices.onGettingCountById(decryptKey);
    }
  }

  // handleShow = () => {
  //   this.setState({ showModal: true })
  // }

  handleClose = () => {
    this.setState({ showModal: false });
    this.setState({ fileContent: null });
  };

  // previewCsvFile = (inputFile) => {

  //   const downloadUrl = `${DownloadInputFile}?FileName=${encodeURIComponent(
  //     inputFile
  //   )}&ModuleType=Input`;
  //   this.setState({ setIsLoad: true });
  //   axios.get(downloadUrl, { responseType: 'blob' })
  //     .then(response => {
  //       this.setState({ fileToView: response.data });
  //       this.setState({ showModal: true });
  //     })
  //     .catch(error => {
  //       console.error('Error downloading the file:', error);
  //     });
  // };
  // previewTxtFile = (inputFile) => {

  //       const downloadUrl = `${DownloadInputFile}?FileName=${encodeURIComponent(inputFile)}&ModuleType=Input`;
  //       this.setState({ setIsLoad: true });
  //       axios.get(downloadUrl)
  //           .then(response => {
  //               const fileContent = response.data;
  //               this.setState({ fileContent, showModal: true, setIsLoad: false });
  //           })
  //           .catch(error => {
  //               console.error('Error downloading the file:', error);
  //               this.setState({ setIsLoad: false });
  //           });
  //         }
  getFileExtension = (fileName) => {
    return fileName?.split(".").pop().toLowerCase();
  };

  getFileIcon = (fileExtension) => {

    switch (fileExtension) {
      case "csv":
        return "/csv.png";
      case "xlsx":
        return "/xlsx.png";
      case "txt":
        return "/txt.png";
      default:
        return "/default.png"; // Default icon if no match is found
    }
  };

  previewFile = (inputFile) => {
    const fileExtension = this.getFileExtension(inputFile);
    const downloadUrl = `${DownloadInputFile}?FileName=${encodeURIComponent(inputFile)}&ModuleType=Input`;
    this.setState({ setIsLoad: true });

    if (fileExtension === "csv" || fileExtension === "xlsx") {
      axios
        .get(downloadUrl, { responseType: "blob" })
        .then((response) => {
          this.setState({ fileToView: response.data, showModal: true, setIsLoad: false });
        })
        .catch((error) => {
          console.error("Error downloading the file:", error);
          this.setState({ setIsLoad: false });
        });
    } else if (fileExtension === "txt") {
      axios
        .get(downloadUrl)
        .then((response) => {
          const fileContent = response.data;
          this.setState({ fileContent, showModal: true, setIsLoad: false });
        })
        .catch((error) => {
          console.error("Error downloading the file:", error);
          this.setState({ setIsLoad: false });
        });
    }
  };

  render() {
    const { fileContent, setIsLoad, showModal, fileToView } = this.state;
    const fileExtension = this.getFileExtension(this.props.productsDetails.inputFile);
    return (
      <>
        <TabProgressBarStyle />
        <div className="card">
          <div className="card-title-main px-3 py-2">
            <h6 className="titletexts font-size-16">Requested data</h6>
          </div>
          <div className="card-body custom-card-body">
            <div className="inner-detail-sec">
              <span className="detail-title font-size-16">
                Files to be Scrape{" "}
              </span>
              &nbsp;
              {this.props.productsDetails.requestType === 1 || this.props.productsDetails.requestType === 2 ||
                this.props.productsDetails.requestType === 3 ? (
                <>
                  <span for="inputFile" className=" ml-2">
                    {this.props.productsCounts != null
                      ? this.props.productsCounts.inputLinkCount > 0
                        ? this.props.productsCounts.inputLinkCount
                        : null
                      : null}
                  </span>


                  <span
                    for="requestName"
                    className="detail-desc ml-3 font-size-16"
                  >
                    &nbsp;&nbsp;&nbsp;
                    <a
                      href={`${DownloadInputFile + `?FileName=${this.props.productsDetails.inputFile}&ModuleType=Input`
                        }`}
                      target="_top"
                    >
                      <i className="fa fa-download  cursor-pointer"></i>
                    </a>

                    <img
                      src={this.getFileIcon(fileExtension)}
                      className="file-extention  ml-5 cursor-pointer"
                      title="Preview"
                      alt="Text icon"
                      onClick={() =>
                        this.previewFile(
                          this.props.productsDetails.inputFile
                        )
                      }
                    />

                  </span>

                </>
              ) : (
                <>
                  {this.props.productsCounts ? (
                    <>
                      <div className="inner-detail-sec">
                        <span for="inputFile" className=" ml-2">
                          {this.props.productsCounts.inputLinkCount > 0
                            ? this.props.productsCounts.inputLinkCount
                            : null}
                        </span>
                        {this.props.productsCounts.inputLinkCount > 0 ? (
                          <span for="requestName" className="detail-desc ml-3">
                            <a
                              href={`${DownloadInputFile +
                                `?FileName=${this.props.productsDetails.inputFile}&ModuleType=Input`
                                }`}
                              target="_top"
                            >
                              <i className="fa fa-download"></i>
                            </a>


                            <img
                              src={this.getFileIcon(fileExtension)}
                              className="file-extention  ml-5 cursor-pointer"
                              title="Preview"
                              alt="Text icon"
                              onClick={() =>
                                this.previewFile(
                                  this.props.productsDetails.inputFile
                                )
                              }
                            />

                          </span>
                        ) : (
                          <span className="detail-desc">No Record found</span>
                        )}
                      </div>
                    </>
                  ) : (
                    <span className="detail-desc">No Record found</span>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        {this.props.productsDetails?.subType === 3 ? (
          <React.Fragment>
            {this.props.priceOutputFileList ? (
              <div className="card mt-4">
                <div className="card-title-main px-3 py-2">
                  <h6 className="titletexts font-size-16">
                    Price Output Files
                  </h6>
                </div>
                <div className="card-body custom-card-body">
                  <div className="row">
                    {this.props.priceOutputFileList?.length > 0 ?
                      <>
                        {this.props.priceOutputFileList.map((data, index) => {
                          const outputFileExtension = this.getFileExtension(data?.fileName);
                          return (
                            <div className="col-md-6" key={index}>
                              <div className="inner-detail-sec">
                                <span className="detail-title font-size-16">
                                  {data?.outputFileType === "CleanPriceImportFile"
                                    ? "Clean Price Import File"
                                    : "Error Price Import File"}
                                </span>
                                <span
                                  for="requestName"
                                  className="detail-desc ml-3 font-size-16"
                                >
                                  &nbsp;&nbsp;&nbsp;
                                  <a
                                    href={`${DownloadInputFile +
                                      `?FileName=${data?.fileName}&ModuleType=Input`
                                      }`}
                                    target="_top"
                                  >
                                    <i className="fa fa-download"></i>
                                  </a>

                                  <img
                                    src={this.getFileIcon(outputFileExtension)}
                                    className="file-extention ml-5 cursor-pointer"
                                    title="Preview"
                                    alt="Text icon"
                                    onClick={() => this.previewFile(data?.fileName)}
                                  />

                                </span>
                              </div>
                            </div>
                          );
                        })}
                      </>
                      : <span className="detail-desc">No Record found</span>
                    }
                  </div>
                </div>
              </div>
            ) : (
              null
            )}
          </React.Fragment>
        ) : null}
        <Modal
          className="scrolling-model"
          show={showModal}
          onHide={this.handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>File Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="RequestDetails-modal-file">
              {fileToView ? (
                <FileViewer
                  fileType={fileExtension}
                  filePath={URL.createObjectURL(fileToView)}
                  onError={(error) => console.error("Error:", error)}
                />
              ) : (
                <>
                  {setIsLoad ? (
                    <div className="text-center">
                      <Bars color="#3c4b64" height={30} visible={setIsLoad} />
                    </div>
                  ) : (
                    <ul className="file-content">
                      {fileContent ? (
                        fileContent.split("\n").map((line, index) => <li key={index}>{line}</li>)
                      ) : (
                        <li>No content available</li>
                      )}
                    </ul>
                  )}
                </>
              )}
            </div>
          </Modal.Body>


        </Modal>
      </>
    );
  }
}


