//** Component  */
import React from 'react'

const RequestOperationalModuleListTab = () => {
  return (
    <style jsx global>
      {
        `  
        * {
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          outline: none;
        }
        
        body {
          margin: 0;
          background: #F1F2F6;
        }
        
        h1 {
          text-align: center;
          font-weight: 300;
          color: #777
        }
        
        h1 span {
          font-weight: 600;
        }
        
        .container {
        
          padding: 20px 0;
        
          position: relative;
          overflow: hidden;
        }
        
        .timeline-sec {
          padding-left: 25px
        }
        
        .timeline-sec:before {
          content: '';
          position: absolute;
          top: 40px;
          left: 40px;
          width: 2px;
          height: calc(100% - 140px);
          background: url(/dashed-line.png);
          z-index: 1
        }
        .colorheader{   
         background-color: #8182a736;
         border-radius: 7px;
    overflow: hidden;
        }
        .colorheader1{
          padding: 20px;
    border-radius: 15px;
    overflow: hidden;
        }
        
        .tab-2:before {
          top: 15%;
          height: 65%;
        }
        
        .timeline-block {
          width: -webkit-calc(50% + 8px);
          width: -moz-calc(50% + 8px);
          width: calc(50% + 8px);
          display: -webkit-box;
          display: -webkit-flex;
          display: -moz-box;
          display: flex;
          -webkit-box-pack: justify;
          -webkit-justify-content: space-between;
          -moz-box-pack: justify;
          justify-content: space-between;
          clear: both;
        }
        
        .timeline-block-right {
          float: right;
        }
        
        
        .marker {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background: #b8b8b8;
          margin-top: 0px;
          z-index: 9999;
          text-align: center;
          position: relative;
          left: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
       
        
        .marker.main-big-icon {
          left: -0.3%;
          background: #fff;
          border: none;
          height: 45px;
          width: 45px;
          border-radius: 100%;
        }
        
        .marker.active {
          width: 40px;
          height: 37px;
          background: #5bd040;
          left: 0px;
        }
        
        
        
        i.fa.active {
          position: absolute;
          top: 50%;
          left: 50%;
          transform:translate(-50%, -50%);
          color: #fff;
          font-size:12px;
        }
        
        .timecolor {
          color: #696cff;
        }
        
        .timecolor1 {
          color: #ff4b4b;
        }
        
        .timecolor2 {
          color: #75c948;
        }
        
        .timeline-block-left {
          float: left;
          direction: rtl
        }
        
        .timeline-content {
          width: 96%;
          padding: 0 15px 0 5px;
          color: #666
        }
        
        .timeline-content h3 {
          margin-top: 8px;
          margin-bottom: 5px;
          font-size: 20px;
          font-weight: 500
        }
        
        .timeline-content h5 {
          margin-bottom: 0px
        }
        
        .timeline-content span {
          font-size: 15px;
          color: #a4a4a4;
        }
        
        .timeline-content p {
          font-size: 14px;
          line-height: 1.5em;
          word-spacing: 1px;
          color: #888;
        }
        
        .main-top-title .timeline-content {
          padding-top: 0;
        }
        
        .main-top-title .timeline-content h3 {
          margin: 0;
        }
        .main-top-title .timeline-content span{
         font-size: 12px;
        }
        
        
        .container:before {
          left: 19px;
          width: 2px;
        }
        
        .timeline-block {
          width: 100%;
          margin-bottom: 35px;
        }
        
        .timeline-block-right {
          float: none;
        }
        
        .timeline-block-left {
          float: none;
          direction: ltr;
        }
        
        .timeline-block:last-child {
          margin-bottom: 0
        }
        .timeline-part{
          margin-bottom:55px
        }
        .timeline-part:last-child{
          margin-bottom:0;
        }
       
        
        .tab-content {
          padding: 5px;
          margin: 0;
        }

        {/* buttons */}

        .buttons {
    margin: 10%;
    text-align: center;
}
.tabs-titles{
  list-style-type: none;
    display: flex;
}

        
                `
      }

    </style>
  )
}

export default RequestOperationalModuleListTab
