//** Component  */
import React from 'react';
import Button from '../../components/common/Button';
import BaseComponent from '../../shared/baseComponent';

//** Services */
import operationalDomainService from '../../services/domainServices/operationalService';

//*** Lib */
import { Navigate } from 'react-router';
import APISererviceManager from '../../libs/apiSererviceManager';
import ButtonLoader from '../../components/common/buttonLoader/ButtonLoader';
import FormFieldGroup from '../../components/UI Element/FormGrop/FormFieldGroup';
import { decryptionData, encryptionData } from '../../../src/utils/Encryption';
import { DataFormat } from '../../utils/DateFormat';

export default class AddNewComponent extends BaseComponent {

    //** Constructor  and Component method */
    constructor(props) {
        super(props)
        this.operationalService = new operationalDomainService();
        this.apiSererviceManager = new APISererviceManager();
        this.state = {
            ...this.state,
            addOperationalModule: this.operationalService.dataItem,
            userReset: this.operationalService.userResetItem,
            resetValidationRule: {},
            setOperationalList: [],
            ScrapperList: [],
            CurrencyList: [],
            TypeProcess: [],
            CleanerList: [],
            GetRequestbyId: [],
            requestName: "",
            supplierName: "",
            endDate: "",
            requestType: "",
            statusName: "",
            CatalogCleanerList: [],
            setIsLoad: false,
            moduleList: [],
            middlewareRequestId: "",
            LinkBuilderList: []
        }
        this.handleChange = this.handleChange.bind(this);
        this.operationalService.onUpdateState = this.updateState.bind(this);
        this.operationalService.onActionCall = this.onActionCall.bind(this);

    }
    componentDidMount() {
        this.operationalService.onInitService();
        let params = new URLSearchParams(window.location.search);
        const moduleId = params.get("");

        if (moduleId !== null && moduleId !== undefined) {
            const decryptKey = decryptionData(moduleId, false);    // *** getByID decryption ***//
            let rpStateId = 1
            if (decryptKey > 0) {
                this.operationalService.onGetById(decryptKey);
                this.operationalService.onGetLatestRequestById(decryptKey);
                this.operationalService.onGetByIdMiddleware(this.state.middlewareRequestId, rpStateId);
            }
        }
        this.operationalService.onGetSupplierList();
        this.operationalService.onGetAllCurrencies();
        this.operationalService.onGetAllModuleRequestProcessType();
    }

    onActionCall = (type, data) => {
        switch (type) {
            case "module":
                window.open(`/RequestDetails/id?=${data}`, '_blank');
                // this.setState({ redirect: "/RequestDetails/id?=" + data });
                break;
                default:
                    // Handle default case here
                    break;
        }
    }
 

    // // *** handleChange function **//
    handleChange(key, value) {
        let detail = this.state.addOperationalModule;
        detail[key] = value;
        if (key === 'isCleaner' && value === true) {
            detail.isScrapping = true;
        } else if (key === 'isScrapping' && value === false) {
            detail.isCleaner = false;
        }
        if (key === 'isCleaner' && value === false) {
            detail.cleanerVersionId = '';
        } else if (key === 'isCatalogCleaner' && value === false) {
            detail.catalogCleanerVersionId = '';
        }
        if (key === 'supplierID') {
            this.operationalService.onGetScrapperList(value);
        }
        this.setState({ addOperationalModule: { ...detail } });
    }


    //** API Function  */
    addNewOperationalModule = () => {
    
        let OM = this.state.addOperationalModule;
        if ((OM.scrapperVersionId || OM.cleanerVersionId || OM.catalogCleanerVersionId || OM.linkBuilderVersionId) && OM.supplierID && OM.currencyId != null) {
            this.setState({ setIsLoad: true })
            if (OM.isScrapping && OM.isCleaner && OM.isDBUpdates || OM.isCatalogCleaner) {
                OM.isDBUpdates = true;
            }
            else {
                OM.isDBUpdates = false;
            }
            if (this.operationalService.isValidSubmit(OM, this.state.validationRules, this.state.validState)) {
                let request = {
                    moduleTypeId: 4,
                    moduleName: OM.moduleName,
                    isActive: OM.isActive,
                    isDBUpdates: (OM.isDBUpdates) ? OM.isDBUpdates : false,
                    isVPN: (OM.isVPN) ? OM.isVPN : false,
                    isScrapping: (OM.isScrapping) ? OM.isScrapping : false,
                    isCleaner: OM.isCleaner ? OM.isCleaner : false,
                    isLinkBuilder: OM.isLinkBuilder ? OM.isLinkBuilder : false,
                    supplierID: OM.supplierID ? OM.supplierID : null,
                    currencyId: OM.currencyId ? OM.currencyId : null,
                    threshold: OM.threshold ? OM.threshold : null,
                    supplierDisplayName: OM.supplierDisplayName ? OM.supplierDisplayName : "",
                    supplierName: OM.supplierName ? OM.supplierName : null,
                    isCatalogCleaner: OM.isCatalogCleaner ? OM.isCatalogCleaner : false,
                    isTor: (OM.isTor) ? OM.isTor : false,
                    scrapperVersionId: OM.scrapperVersionId ? OM.scrapperVersionId : "",
                    cleanerVersionId: OM.cleanerVersionId ? OM.cleanerVersionId : "",
                    catalogCleanerVersionId: OM.catalogCleanerVersionId ? OM.catalogCleanerVersionId : "",
                    linkBuilderVersionId: OM.linkBuilderVersionId ? OM.linkBuilderVersionId : "",
                    moduleRequestProcessTypeId: OM.moduleRequestProcessTypeId ? OM.moduleRequestProcessTypeId : "",
                }
                this.operationalService.onAdd(request);
            }
            this.setState({ setIsLoad: false });
        }
        else {
            this.operationalService.apiService.serviceManager.swalServices.Error("This supplier doesn't have any added module. Please add module")
        }
    }

    updateOperationalModule = () => {
     
        let OM = this.state.addOperationalModule;
        if (OM.isScrapping && (OM.isCleaner || OM.isCatalogCleaner) && OM.isDBUpdates) {
            OM.isDBUpdates = true;
        }
        else if ((OM.isCleaner || OM.isCatalogCleaner) && OM.isDBUpdates) {
            OM.isDBUpdates = true;
        }
        else if (OM.isScrapping && !(OM.isCleaner || OM.isCatalogCleaner) && (OM.isDBUpdates)) {
            OM.isDBUpdates = false;
        }
        let request = {
            moduleId: this.state.setmoduleId,
            moduleName: OM.moduleName,
            isActive: OM.isActive,
            isDBUpdates: (OM.isDBUpdates) ? OM.isDBUpdates : false,
            isVPN: (OM.isVPN) ? OM.isVPN : false,
            isScrapping: (OM.isScrapping) ? OM.isScrapping : false,
            isCleaner: OM.isCleaner ? OM.isCleaner : false,
            isLinkBuilder: OM.isLinkBuilder ? OM.isLinkBuilder : false,
            supplierID: OM.supplierID ? OM.supplierID : null,
            currencyId: OM.currencyId ? OM.currencyId : null,
            threshold: OM.threshold ? OM.threshold : null,
            supplierDisplayName: OM.supplierDisplayName ? OM.supplierDisplayName : "",
            supplierName: OM.supplierName ? OM.supplierName : null,
            isCatalogCleaner: OM.isCatalogCleaner ? OM.isCatalogCleaner : false,
            isTor: (OM.isTor) ? OM.isTor : false,
            scrapperVersionId: OM.scrapperVersionId ? OM.scrapperVersionId : "",
            cleanerVersionId: OM.cleanerVersionId ? OM.cleanerVersionId : "",
            catalogCleanerVersionId: OM.catalogCleanerVersionId === null ? "" : OM.catalogCleanerVersionId,
            linkBuilderVersionId: OM.linkBuilderVersionId ? OM.linkBuilderVersionId : "",
            moduleRequestProcessTypeId: OM.moduleRequestProcessTypeId ? OM.moduleRequestProcessTypeId : "",
        }
        if (this.operationalService.isValidSubmit(OM, this.state.validationRules, this.state.validState)) {
            this.operationalService.onUpdate(request);
        }
    }

    render(props) {
        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} />
        }
        const encryptKey = encryptionData(this.state.middlewareRequestId);
        return (

            <div>
                <div className="mb-4">
                    <div className="accordion" id="accordionExample">
                        <div className="card accordion-item active">
                            <div id="accordionOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    {this.state.setIsLoad ?
                                        <div className='text-center'>
                                            <ButtonLoader />
                                        </div>
                                        :
                                        <div>
                                            <div className="card-body">
                                                <div className="row mb-4">
                                                    {this.operationalService.formFields.map((Item, index) => {
                                                        return (<>
                                                            {Item.inputType !== 'checkbox' ?
                                                                <>
                                                                    {
                                                                     
                                                                        (this.state.addOperationalModule.isCleaner === true && Item.fieldName === 'cleanerVersionId') ||
                                                                            (this.state.addOperationalModule.isCatalogCleaner === true && Item.fieldName === 'catalogCleanerVersionId') ||
                                                                            (Item.fieldName === 'scrapperVersionId' && this.state.ScrapperList.length > 0) ||
                                                                            (this.state.addOperationalModule.isLinkBuilder === true && Item.fieldName === 'linkBuilderVersionId' && this.state.LinkBuilderList.length > 0) ?
                                                                            <FormFieldGroup
                                                                        
                                                                                column={Item}
                                                                                value={this.state.addOperationalModule[Item.fieldName]}
                                                                                error={this.state.validState.error[Item.fieldName]}
                                                                                onChange={(event) => this.handleChange(Item.fieldName, event.target.value)}
                                                                                onBlur={() => this.operationalService.validateField(Item.fieldName, this.state.addOperationalModule, this.state.validationRules, this.state.validState)}
                                                                                onKeyDown={event => { if (event.key === "." || event.key === "-" || event.key === "+") { event.preventDefault(); } }}
                                                                                key={index}
                                                                                options={
                                                                                    (this.state.addOperationalModule.isCleaner === true && Item.fieldName === 'cleanerVersionId') ? this.state.CleanerList :
                                                                                        (this.state.addOperationalModule.isCatalogCleaner === true && Item.fieldName === 'catalogCleanerVersionId') ? this.state.CatalogCleanerList :
                                                                                            (this.state.addOperationalModule.isLinkBuilder === true && Item.fieldName === 'linkBuilderVersionId') ? this.state.LinkBuilderList :
                                                                                                (this.state.addOperationalModule.isScrapping === true && Item.fieldName === 'scrapperVersionId') ? this.state.ScrapperList : null
                                                                                }
                                                                                selectDisabled={this.state.addOperationalModule.supplierID === "" && (Item.fieldName === 'cleanerVersionId') ? true : false}
                                                                            />
                                                                            : Item.fieldName !== 'cleanerVersionId' && Item.fieldName !== 'catalogCleanerVersionId' && Item.fieldName !== 'linkBuilderVersionId' && Item.fieldName !== 'scrapperVersionId' ?
                                                                                <FormFieldGroup
                                                                                    column={Item}
                                                                                    value={this.state.addOperationalModule[Item.fieldName]}
                                                                                    onChange={Item.inputType === 'checkbox' ?
                                                                                        (event) => this.handleChange(Item.fieldName, event.target.checked) :
                                                                                        (event) => this.handleChange(Item.fieldName, event.target.value)}
                                                                                    onKeyDown={event => { if (event.key === "." || event.key === "-" || event.key === "+") { event.preventDefault(); } }}
                                                                                    onBlur={Item.inputType !== 'checkbox' ?
                                                                                        () => this.operationalService.validateField(Item.fieldName, this.state.addOperationalModule, this.state.validationRules, this.state.validState)
                                                                                        : null}
                                                                                    error={this.state.validState.error[Item.fieldName]}
                                                                                    options={
                                                                                        Item.fieldName === "supplierID" ? this.state.setOperationalList :
                                                                                            Item.fieldName === "currencyId" ? this.state.CurrencyList :
                                                                                                Item.fieldName === "moduleRequestProcessTypeId" ? this.state.TypeProcess : null
                                                                                    }
                                                                                    selectDisabled={this.state.addOperationalModule.supplierID === "" && (Item.fieldName === 'scrapperVersionId') ? true : false}
                                                                                />
                                                                                : null
                                                                    }
                                                                </>
                                                                : null}
                                                        </>)
                                                    })}
                                                </div>
                                                <div className="row">
                                                    {this.operationalService.formFields.map((Item, index) => {
                                                        return (<>
                                                            {Item.inputType === 'checkbox' ?
                                                                <FormFieldGroup
                                                                    column={Item}
                                                                    onChange={(event) => this.handleChange(Item.fieldName, event.target.checked)}
                                                                    checked={
                                                                        (this.state.ScrapperList.length > 0 && Item.fieldName === 'isScrapping') ? this.state.addOperationalModule[Item.fieldName] :
                                                                            (this.state.CleanerList.length > 0 && Item.fieldName === 'isCleaner') ? this.state.addOperationalModule[Item.fieldName] :
                                                                                (this.state.CatalogCleanerList.length > 0 && Item.fieldName === 'isCatalogCleaner') ? this.state.addOperationalModule[Item.fieldName] :
                                                                                    (this.state.LinkBuilderList.length > 0 && Item.fieldName === 'isLinkBuilder') ? this.state.addOperationalModule[Item.fieldName] :
                                                                                        (Item.fieldName !== 'isScrapping' && Item.fieldName !== 'isCleaner' && Item.fieldName !== 'isCatalogCleaner' && Item.fieldName !== 'isLinkBuilder') ? this.state.addOperationalModule[Item.fieldName] : false
                                                                    }
                                                                    disabled={
                                                                        (this.state.ScrapperList.length === 0 && Item.fieldName === 'isScrapping') ? true :
                                                                            (this.state.CleanerList.length === 0 && Item.fieldName === 'isCleaner') ? true :
                                                                                (this.state.CatalogCleanerList.length === 0 && Item.fieldName === 'isCatalogCleaner') ? true :
                                                                                    (this.state.LinkBuilderList.length === 0 && Item.fieldName === 'isLinkBuilder') ? true : false
                                                                    }
                                                                    key={index} />
                                                                : null}
                                                        </>)
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="addnewuser-button mb-3">
                                        {this.state.setmoduleId ?
                                            <button onClick={() => this.updateOperationalModule()} className="tab-title tab-title--active  btn-hover  color-3 active">{this.state.setIsLoad ? <ButtonLoader /> : "Update"}</button>
                                            :
                                            <button onClick={() => this.addNewOperationalModule()}
                                                className="tab-title tab-title--active  btn-hover  color-3 active">{this.state.setIsLoad ? <ButtonLoader /> : "Save"}</button>
                                        }
                                        <Button type='cancel' title="Cancel" className="ml-2" onClick={() => this.operationalService.onRedirectoList()} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.setmoduleId ?
                            <table className="table table-hover table-shadow table-striped table-borderless mt-4">
                                <thead>
                                    <tr className='text-left'>
                                        <th>Request Name</th>
                                        <th>Supplier Name</th>
                                        <th>End Date</th>
                                        <th>Request Type</th>
                                        <th>Status Name</th>
                                    </tr>
                                </thead>
                                <tbody className="table-border-bottom-0 text-left">
                                    {
                                        this.state.GetRequestbyId.length === 0 ? <div className='className="text-center p-4 no-records-found'><i className="fas fa-empty-set pr-2"></i>No Records Found</div> :
                                            <tr>
                                            <td onClick={() => this.onActionCall("module", encryptKey)}>
                                                    {/* <a className='url-links-tag' href={encryptKey} > */}
                                                        <strong className=' up_RPA_module cursor-pointer '>{this.state.requestName} </strong>
                                                    {/* </a> */}
                                                </td>
                                                <td> {this.state.supplierName} </td>
                                                <td>{DataFormat(this.state.endDate, "MM-DD-YYYY h:mm a")}</td>
                                                <td> {this.state.requestType === 1 ? "LinkUpdate" : this.state.requestType === 2 ? "CatalogUpdate" : this.state.requestType === 3 ? "PartialUpdate" : null} </td>
                                                <td> {this.state.statusName} </td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                            : null}
                </div>
            </div>
        )
    }
}
