import React, { useRef, useEffect } from "react";

const FileField = (request) => {
    let props = request.request;
    const inputRef = useRef(null);
    useEffect(() => {
        if (props.value === null || props.value === "" || props.value === undefined) {
            inputRef.current.value = null;
        }
    }, [props.value])

    return (<>
        <input
            name={props.column.fieldName}
            className="form-control file"
            type={props.column.inputType}
            onChange={props.onChange}
            onBlur={props.onBlur}
            id={props.column.fieldName}
            ref={inputRef}
            defaultValue={props.defaultValue} />
    </>)
}


export default FileField;
