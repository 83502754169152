import { Bars } from "react-loader-spinner";
import Table from "../../components/table/Table";
import BaseComponent from "../../shared/baseComponent";
import emailValidationDomainServices from "../../services/domainServices/emailValidationServices";
import { encryptionData } from "../../utils/Encryption";
import { Link } from "react-router-dom";

export default class EmailValidation extends BaseComponent {
  constructor(props) {
    super(props);
    this.emailValidationServices = new emailValidationDomainServices();
    this.state = {
      pagination: this.emailValidationServices.pagination,
      getList: [],
      setIsLoad: false,
    };
    this.emailValidationServices.onUpdateState = this.updateState.bind(this);
    this.emailValidationServices.onActionCall = this.onActionCall.bind(this);
  }

  componentDidMount() {
    this.emailValidationServices.onGetEmailValidationList(
      this.state.pagination
    );
  }

  onActionCall = (type, data) => {
    // *** getByID encryption ***//
    const encryptKey = encryptionData(data, 1);
    switch (type) {
      case "View":
        window.open(`/EmailValidationDetail/id?=${encryptKey}`, "_blank");
        break;
      default:
        // Handle default case here
        break;
    }
  };

  render() {
    return (
      <div>
        <div className="top-search-add-button-sec mb-3">
          <div className="row left-searchbar-sec"></div>
          <div className="right-button-sec">
            <Link to="/AddEmail" className="tab-title btn-hover color-3 active">
              Add Email <span>+</span>
            </Link>
          </div>
        </div>
        <div className="email-validation-list">
          {this.state.setIsLoad ? (
            <Bars
              className="row"
              wrapperStyle={{ marginTop: "150px" }}
              color="#3c4b64"
              height={55}
              width="100%"
              visible={this.state.setIsLoad}
            />
          ) : (
            <Table
              columns={this.emailValidationServices.gridColmns}
              rows={this.state.getList}
              sortingColumns={[]}
              pagination={this.state.pagination}
              totalCount={this.state.TotalCount}
              totalResultes={this.state.TotalCount}
              setPagination={this.emailValidationServices.onPageChange.bind(this)}
              customScope={[
                {
                  column: "EmailValidationId",
                  renderTableData:
                    this.emailValidationServices.gridActionsViewCol.bind(this),
                },
              ]}
            />
          )}
        </div>
      </div>
    );
  }
}
