import BaseDomainService from './baseDomainServices';
import { ErrorMessage, SuccessMessage } from "../../utils/Messages";
import { getValidationRuleList } from "../../utils/validation/generateValidationRule";

const searchHistoryList = {
    searchText: ""
}
const operationalFormField = {
    redirect: '',
    supplierID: "",
    supplierDisplayName: "",
    supplierName: "",
    currencyName: "",
    moduleTypeId: 4,
    moduleName: "",
    isActive: true,
    isScrapping: false,
    isDBUpdates: false,
    isVPN: false,
    isCleaner: false,
    isCatalogCleaner: false,
    isLinkBuilder: false,
    isTor: false,
    scrapperVersionId: "",
    cleanerVersionId: "",
    catalogCleanerVersionId: "",
    linkBuilderVersionId: '0',
    currencyId: "",
    threshold: "1000",
    productDataSyncImportTypeId: '',
    moduleRequestProcessTypeId: '',
}

export default class operationalService extends BaseDomainService {

    // API Get
    getList = this.apiService.operationalModuleServices.getOperationalModuleTypeList;
    getLatestRequestById = this.apiService.operationalModuleServices.getLatestRequestById;
    getActiveList = this.apiService.manageOperationalServices.getAllOperationalModuleListActiveInactive;
    add = this.apiService.manageOperationalServices.addOperationalModule;
    Currencies = this.apiService.manageOperationalServices.getAllCurrencies;
    getAllModuleRequestProcessType = this.apiService.manageOperationalServices.getAllModuleRequestProcessType;
    update = this.apiService.manageOperationalServices.updateOptaionalModule;
    getbyId = this.apiService.operationalModuleServices.getOperationalModuleDetailsById;
    delete = this.apiService.operationalModuleServices.deleteOperationalModuleType;
    supplierList = this.apiService.manageSupplierServices.getAllSupplierNameList;
    getScrapperList = this.apiService.codeManagementService.getAllScriptList;
    getByIdMiddleware = this.apiService.manageOperationalServices.getMiddlewareRequestById;


    //Table Grid columns
    gridColmns = [
        { name: 'moduleName', title: 'Module Name' },
        { name: 'supplierDisplayName', title: 'Supplier Name' },
        { name: 'noOfDoneRequest', title: 'Request Done' },
        { name: 'isActive', title: 'Active' },
        { name: 'moduleId', title: 'Actions' }
    ]
    opertaionalgridColmns = [
        { name: "requestName", title: "Request Name" }
    ];

    // Define the state variables
    dataItem;
    dataItems;
    supplierListData;
    userResetItem = {
        redirect: '',
        supplierID: "",
        currencyId: "",
        supplierDisplayName: "",
        supplierName: "",
        currencyName: "",
        moduleTypeId: 4,
        moduleName: "",
        isActive: true,
        isScrapping: false,
        isDBUpdates: false,
        isVPN: false,
        isCleaner: false,
        isCatalogCleaner: false,
        isLinkBuilder: false,
        isTor: false,
        scrapperVersionId: "",
        cleanerVersionId: "",
        catalogCleanerVersionId: "",
        moduleRequestProcessTypeId : ""
    }

    // define the form fields
    formFields = [
        {
            inputType: "text",
            tittle: "Module Name",
            placeholder: "Module Name",
            fieldName: "moduleName",
            className: 'col-md-6',
            maxLength: 200,
            validation: [{ type: "require" }]
        },
        {
            inputType: "select",
            tittle: "Supplier Name",
            placeholder: "Select",
            fieldName: "supplierID",
            isDefault: true,
            options: [],
            className: 'col-md-6',
        },
        {
            inputType: "select",
            tittle: "Scrapper File Name and Version",
            placeholder: "Please Select Scrapper File Name and Version",
            fieldName: "scrapperVersionId",
            className: 'col-md-6 PartielStage',
            options: [],
            validation: [{ type: "require" }]
        },
        {
            inputType: "select",
            tittle: "Link Builder File Name and Version",
            placeholder: "Please Select Link Builder File Name and Version",
            fieldName: "linkBuilderVersionId",

            className: 'col-md-6 PartielStage',
            options: [],
            validation: [{ type: "require" }]
        },
        {
            inputType: "select",
            tittle: "Currency",
            placeholder: "Please Select Currency",
            fieldName: "currencyId",
            className: 'col-md-6 ',
            isDefault: true,
            options: [],
            validation: [{ type: "require" }]
        },
        {
            inputType: "number",
            tittle: "Thershold",
            placeholder: "Thershold ",
            fieldName: "threshold",
            subRequest: "1",
            className: 'col-md-6',
            validation: [{ type: "require" }],
        },
        {
            inputType: "select",
            tittle: "Cleaner File Name and Version",
            placeholder: "Please Select Cleaner File Name and Version",
            fieldName: "cleanerVersionId",
            className: 'col-md-6',
            options: [],
            validation: [{ type: "require" }]
        },
        {
            inputType: "select",
            tittle: "Request Process type",
            placeholder: "Please Select Request Process type",
            fieldName: "moduleRequestProcessTypeId",
            className: 'col-md-6',
            options: [],
            validation: [{ type: "require" }]
        },
        {
            inputType: "select",
            tittle: "Catalog Cleaner File Name and Version",
            placeholder: "Please Select Catalog Cleaner File Name and Version",
            fieldName: "catalogCleanerVersionId",
            className: 'col-md-6 PartielStage',
            options: [],
            validation: [{ type: "require" }]
        },
        {
            inputType: "checkbox",
            tittle: "IsActive",
            fieldName: "isActive",
            checked: true,
            className: 'col-md-3 mb-4'
        },
        {
            inputType: "checkbox",
            tittle: "Has Scrapping Configure?",
            fieldName: "isScrapping",
            checked: false,
            className: 'col-md-3'
        },
        {
            inputType: "checkbox",
            tittle: "Has Cleaner Configure?",
            fieldName: "isCleaner",
            checked: false,
            className: 'col-md-3'
        },
        {
            inputType: "checkbox",
            tittle: "Has CatalogCleaner Configure?",
            fieldName: "isCatalogCleaner",
            checked: false,
            className: 'col-md-3'
        },
        {
            inputType: "checkbox",
            tittle: "Has DBUpdates Configure?",
            fieldName: "isDBUpdates",
            checked: false,
            className: 'col-md-3'
        },
        {
            inputType: "checkbox",
            tittle: "Is LinkBuilder",
            fieldName: "isLinkBuilder",
            checked: false,
            className: 'col-md-3'
        },
        {
            inputType: "checkbox",
            tittle: "Has VPN Configure?",
            fieldName: "isVPN",
            checked: false,
            className: 'col-md-3'
        },
        {
            inputType: "checkbox",
            tittle: "Has Enable Tor?",
            fieldName: "isTor",
            checked: false,
            className: 'col-md-3'
        },
    ]

    SearchformFields = [
        {
            inputType: "text",
            placeholder: "Search ...",
            fieldName: "searchText",
            labelStyleName: 'none'
        },
    ]

    // Validation 
    validationRules = {}


    constructor(props) {
        super(props);
        this.actionKey = "moduleId";
        this.actionActive = "isActive";
        this.dataItem = { ...operationalFormField };
        this.dataItems = { ...searchHistoryList };
        this.onPaginationUpdate = this.onGetActiveList.bind(this);
        this.onPaginationUpdateActive = this.onGetList.bind(this);
    }

    //** API Calls 
    onGetList = (requst) => {
        this.apiService.onAPICall("getOperationalList", this.getList, requst, this.onAPIResponse, this.onUpdateState);
    }
    onGetLatestRequestById = (requst) => {
        this.apiService.onAPICall("getLatestRequestById", this.getLatestRequestById, requst, this.onAPIResponse, this.onUpdateState);
    }
    onGetByIdMiddleware = (requst) => {
        this.apiService.onAPICall("getByIdMiddleware", this.getByIdMiddleware, requst, this.onAPIResponse, this.onUpdateState);
    };
    onGetScrapperList = (requst) => {
        this.apiService.onAPICall("getScrapperList", this.getScrapperList, requst, this.onAPIResponse, this.onUpdateState);
    }
    onGetActiveList = (requst) => {
        this.apiService.onAPICall("getOperationalActiveList", this.getActiveList, requst, this.onAPIResponse, this.onUpdateState);
    }
    onGetAllCurrencies = (requst) => {
        this.apiService.onAPICall("getAllCurrencies", this.Currencies, requst, this.onAPIResponse, this.onUpdateState);
    }
    onGetAllModuleRequestProcessType = (requst) => {
        this.apiService.onAPICall("getAllModuleRequestProcessType", this.getAllModuleRequestProcessType, requst, this.onAPIResponse, this.onUpdateState);
    }
    onGetById = (request) => {
        this.apiService.onAPICall("getbyId", this.getbyId, request, this.onAPIResponse, this.onUpdateState);
    }
    onAdd = (request) => {
        ;
        this.apiService.onAPICall("add", this.add, request, this.onAPIResponse, this.onUpdateState);
    }
    onUpdate = (request) => {
        this.apiService.onAPICall("update", this.update, request, this.onAPIResponse, this.onUpdateState);
    }
    onDelete = (request) => {
        this.apiService.onAPICall("delete", this.delete, request, this.onAPIResponse, this.onUpdateState);
    }
    onGetSupplierList = (request) => {
        this.apiService.onAPICall("supplierList", this.supplierList, request, this.onAPIResponse, this.onUpdateState);
    }

    //** Service Event */
    onInitService() {
        //This method only call when we use form component and we can add all items which we on on form load 
        let valRule = getValidationRuleList(this.formFields)
        this.validationRules = valRule;
        this.onUpdateState({
            "validationRules": valRule,
            "resetValidationRule": this.resetValidationRule
        })
    }

    onResetData = () => {
        this.dataItem = { ...this.addOperationalModule }
        this.onUpdateState({ addUserDetails: this.dataItem });
    }

    onRedirectoList = () => {
        this.onRedirect("/RPAModules");
    }

    /** Methods     */
    //  When we get response from  API
    onAPIResponse = (key, isSuccess, responseData) => {
        if (!isSuccess) {
            this.onUpdateState({ "setIsLoad": false });
            return;
        }
        switch (key) {
            case "getOperationalList":
                this.onUpdateState({
                    "dataList": responseData.itemList,
                    "TotalCount": responseData.totalCount,
                    "setIsLoad": false
                });
                break;
            case "getLatestRequestById":
                this.onUpdateState({
                    "GetRequestbyId": responseData,
                    requestName: responseData.requestName,
                    supplierName: responseData.supplierName,
                    endDate: responseData.endDate,
                    middlewareRequestId: responseData.middlewareRequestId,
                    requestType: responseData.requestType,
                    statusName: responseData.statusName,
                    "setIsLoad": false
                });
                break;
            case "getByIdMiddleware":
                if (responseData !== null) {
                    this.onUpdateState({
                        productsDetails: responseData,
                        setIsLoad: false
                    });
                }
                break;
            case "getOperationalActiveList":
                this.onUpdateState({
                    "dataList": responseData.itemList,
                    "setIsLoad": false,
                    "TotalCount": responseData.totalCount,
                });
                break;
            case "getbyId":
                let resData = ({ ...responseData });
                this.dataItem = { ...resData };
                this.onUpdateState({
                    addOperationalModule: {
                        moduleName: resData.moduleName,
                        supplierName: resData.supplierName,
                        currencyName: resData.currencyName,
                        isActive: resData.isActive,
                        scrapperVersionId: resData.scrapperVersionId ? resData.scrapperVersionId : '',
                        cleanerVersionId: resData.cleanerVersionId ? resData.cleanerVersionId : '',
                        catalogCleanerVersionId: resData.catalogCleanerVersionId ? resData.catalogCleanerVersionId : '',
                        moduleTypeId: resData.moduleTypeId,
                        isDBUpdates: resData.isDBUpdates,
                        isVPN: resData.isVPN,
                        isScrapping: resData.isScrapping,
                        isCleaner: resData.isCleaner,
                        supplierID: resData.supplierID,
                        currencyId: resData.currencyId,
                        threshold: resData.threshold ? resData.threshold : '1000',
                        supplierDisplayName: resData.supplierDisplayName,
                        isCatalogCleaner: resData.isCatalogCleaner,
                        isTor: resData.isTor,
                        isLinkBuilder: resData.isLinkBuilder,
                        linkBuilderVersionId: resData.linkBuilderVersionId ? resData.linkBuilderVersionId : '',
                        moduleRequestProcessTypeId: resData.moduleRequestProcessTypeId ? resData.moduleRequestProcessTypeId : '',
                    },
                    "setIsLoad": false,
                    setmoduleId: resData.moduleId
                });
                this.onGetScrapperList(resData.supplierID);
                break;
            case "add":
                // Need to check if user is exist or not.
                if (responseData.errorType === 'EXIST' && responseData.hasError === true) {
                    this.apiService.serviceManager.swalServices.Alert(ErrorMessage.Already_Exist.replace("{0}", "Module"));
                }
                else {
                    this.apiService.serviceManager.swalServices.Success(SuccessMessage.Add_Success.replace("{0}", "New module"))
                    this.onRedirectoList();
                    this.onUpdateState({ "setIsLoad": false });
                }
                break;

            case "getScrapperList":
                let scraperList = [];
                let cleanerList = [];
                let catalogCleanerList = [];
                let linkBuilderList = [];
                responseData?.forEach(function (i) {
                    if (i.configureType === 1) {
                        scraperList.push({
                            name: i.scriptName.concat("-", i.currentVersion), id: i.versionId,
                            file: i.fileName, currentVersion: i.currentVersion,
                            script: i.scriptName
                        })
                    }
                    if (i.configureType === 2) {
                        cleanerList.push({
                            name: i.scriptName.concat("-", i.currentVersion), id: i.versionId,
                            file: i.fileName, currentVersion: i.currentVersion,
                            script: i.scriptName
                        })
                    }
                    if (i.configureType === 3) {
                        catalogCleanerList.push({
                            name: i.scriptName.concat("-", i.currentVersion), id: i.versionId,
                            file: i.fileName, currentVersion: i.currentVersion,
                            script: i.scriptName
                        })
                    }
                    if (i.configureType === 4) {
                        linkBuilderList.push({
                            name: i.scriptName.concat("-", i.currentVersion), id: i.versionId,
                            file: i.fileName, currentVersion: i.currentVersion,
                            script: i.scriptName
                        })
                    }
                });
                this.onUpdateState({
                    "ScrapperList": scraperList,
                    "CleanerList": cleanerList,
                    "CatalogCleanerList": catalogCleanerList,
                    "LinkBuilderList": linkBuilderList,
                    "setIsLoad": false
                });
                break;

            case "update":
                if (responseData.errorType === "EXIST" && responseData.hasError === true) {
                    this.apiService.serviceManager.swalServices.Alert(ErrorMessage.Already_Exist.replace("{0}", "Module"));
                }
                else {
                    this.apiService.serviceManager.swalServices.Success(SuccessMessage.Update_Success.replace("{0}", "New module"));
                    this.onRedirectoList();
                    this.onUpdateState({ "setIsLoad": false });
                }
                break;
            case "delete":
                this.apiService.serviceManager.swalServices.Success(SuccessMessage.Delete_Success.replace("{0}", "Module"));
                this.onUpdateState({ "setIsLoad": false });
                this.onGetActiveList(this.pagination);
                break;
            case "supplierList":
                let supplierData = responseData;
                let newDataList = supplierData?.map((data) => ({
                    id: data.supplierID,
                    name: data.supplierName
                }));
                this.onUpdateState({
                    "setOperationalList": newDataList,
                    "setIsLoad": false
                });
                break;
            case "getAllCurrencies":
                let CurrencyData = responseData;
                let newCurrencyList = CurrencyData?.map((data) => ({
                    id: data.currencyId,
                    name: data.currencyName
                }));
                this.onUpdateState({
                    "CurrencyList": newCurrencyList,
                    "setIsLoad": false
                });
                break;
            case "getAllModuleRequestProcessType":
                let typeData = responseData;
                let newTypeList = typeData?.map((data) => ({
                    id: data.moduleRequestProcessTypeId,
                    name: data.moduleRequestProcessTypeName
                }));
                this.onUpdateState({
                    "TypeProcess": newTypeList,
                    "setIsLoad": false
                });
                break;
                default:
                    // Handle default case here
                    break;
        }
    }
}

