//** Component  */
import React from "react";
import { Navigate } from "react-router";

//** Services */
import RequestDomainServices from "../../services/domainServices/requestServices";

//*** Lib */
import Button from "../../components/common/Button";
import { ErrorMessage } from "../../utils/Messages";
import BaseComponent from "../../shared/baseComponent";
import ButtonLoader from "../../components/common/buttonLoader/ButtonLoader";
import FormFieldGroup from "../../components/UI Element/FormGrop/FormFieldGroup";
 
export default class AddPartialRequest extends BaseComponent {
  constructor(props) {
    super(props);
    this.requestServices = new RequestDomainServices();
    this.requestServices.onUpdateState = this.updateState.bind(this);
    this.state = {
      ...this.state, // Need to use parent state
      addRequestDetails: this.requestServices.dataItem,
      getRequestType: this.requestServices.requestItem,
      userReset: this.requestServices.userResetItem,
      pagination: this.requestServices.pagination,
      getoperationalList: [],
      setModuleList: [],
      setServerList: [],
      setModuleListData: [],
      setServerListIsVPN: [],
      setIsLoad: false,
      isLoad: false,
      serverOptionList: []
    };
    this.handleChange = this.handleChange.bind(this);
  }

  //** Constructor  and Component method */
  componentDidMount() {
    this.state.addRequestDetails.requestType = "3";
    this.requestServices.onInitService();
    this.requestServices.onGetModuleList();
    this.requestServices.onGetServerList();
    this.requestServices.onGetAllServerList(this.state.pagination);
    this.getoperationalModuleList();
  }
// *** Event function **//
  handleChange(name, value) {
    let detail = { ...this.state.addRequestDetails };
    detail[name] = value;
    if (name === "moduleId") {
      let found = this.state.getoperationalList.find((x) => x.moduleId === parseInt(value));
      if (found) {
        detail.supplierID = found.supplierID;
        detail.isCleaner = found.isCleaner ? found.isCleaner : false;
        detail.isDBUpdates = found.isDBUpdates ? found.isDBUpdates : false;
        detail.isScrapping = found.isScrapping ? found.isScrapping : false;
        detail.supplierDisplayName = found.supplierDisplayName ? found.supplierDisplayName : "";
        detail.isCatalogCleaner = found.isCatalogCleaner ? found.isCatalogCleaner : false;
        detail.requestName = found.moduleName?found.moduleName:"";
      }
    }
    if (name === "serverId") {
      this.requestServices.onGetServerConfigurationsById(value);
    }
    if (name === "stagePartialId" && value === "3") {
      detail.isCleaner = true;
      detail.isDBUpdates = true;
      detail.inputFile = ""
    }
    if (name === "stagePartialId" && value === "4") {
      detail.isCleaner = false;
      detail.isDBUpdates = true;
      detail.inputFile = ""
    }
    this.setState({ addRequestDetails: { ...detail } });
  }

  handleFileChange = (e) => {
    if (this.state.addRequestDetails.stagePartialId === "") {
      this.requestServices.apiService.serviceManager.swalServices.Toaster(
        ErrorMessage.SelectRequired.replace("{0}", "StageName")
      );
    }
    else {

      const file = e.target.files[0];
      if (this.state.addRequestDetails.stagePartialId === "3") {
        if (file && (file.type === "text/csv" || file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
        ) {
          if (file.size > 0) {
            let Importfile = this.state.addRequestDetails;
            Importfile.inputFile = file.name;
            Importfile["inputFile"] = file;
            this.setState({ addRequestDetails: { ...Importfile } });
          } else {
            this.requestServices.apiService.serviceManager.swalServices.Toaster(
              ErrorMessage.FileSize
            );
          }
        } else {
          this.requestServices.apiService.serviceManager.swalServices.Toaster(
            ErrorMessage.selectFile.replace("{0}", ".csv or .xlsx")
          );
        }
      }
      else {
        if (file && (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file.type === "application/vnd.ms-excel")
        ) {
          if (file.size > 0) {
            let Importfile = this.state.addRequestDetails;
            Importfile.inputFile = file.name;
            Importfile["inputFile"] = file;
            this.setState({ addRequestDetails: { ...Importfile } });
          } else {
            this.requestServices.apiService.serviceManager.swalServices.Toaster(
              ErrorMessage.FileSize
            );
          }
        } else {
          this.requestServices.apiService.serviceManager.swalServices.Toaster(
            ErrorMessage.selectFile.replace("{0}", ".xlsx")
          );
        }
      }
    }

  }


  handleChangeCheckBoxStatus = (name, value) => {
    let detail = this.state.addRequestDetails;
    detail[name] = value;
    if (name === "isDBUpdates" && value === true && detail.requestType === "1") {
      if (
        detail.isCleaner === false ||
        detail.isScrapping === false ||
        detail.isCleaner === undefined ||
        detail.isScrapping === undefined
      ) {
        this.requestServices.apiService.serviceManager.swalServices.Alert(
          ErrorMessage.validationCleaner.replace("{0}", "DBUpdate")
        );
        detail.isDBUpdates = false;
      }
    }
    if (
      (name === "isCleaner" && value === false) ||
      (name === "isScrapping" && value === false)
    ) {
      if (name === "isDBUpdates" && value === true) {
        detail.isCleaner = true;
      } else {
        detail.isDBUpdates = false;
        detail.isCleaner = false;
      }
    }

    //** Catalog Update  */
    if (name === "isDBUpdates" && value === true && detail.requestType === "2") {
      detail.isCatalogCleaner = true;
    }

    if (name === "isCleaner" && value === true) {
      detail.isScrapping = true;
    }
    if (name === "isCatalogCleaner" && value === false) {
      detail.isDBUpdates = false;
    }
    this.setState({ addRequestDetails: { ...detail } });
  };

  handleInputChange = (name, value) => {
    let detail = { ...this.state.addRequestDetails };
    detail[name] = value;
    this.setState({ addRequestDetails: detail });
  };


  addRequest = () => {
    this.setState({ isLoad: true }, () => {
      if (this.state.addRequestDetails.stagePartialId === "4") {
        if (this.requestServices.isValidSubmit(this.state.addRequestDetails, this.state.validationRules, this.state.validState)
          && this.requestServices.isValidSubmit(this.state.addRequestDetails, this.state.validationRulesForType, this.state.validState)) {
          if (this.state.addRequestDetails.stageId === 3) {
          }
          let request = {
            moduleId: this.state.addRequestDetails.moduleId,
            supplierID: this.state.addRequestDetails.supplierID,
            statusId: 1,
            requestName: this.state.addRequestDetails.requestName,
            isActive: this.state.addRequestDetails.isActive,
            inputFile: "",
            outputFile: "",
            startDate: "",
            endDate: "",
            file: this.state.addRequestDetails.inputFile,
            isCleaner: this.state.addRequestDetails.isCleaner ? this.state.addRequestDetails.isCleaner : false,
            createdBy: this.requestServices.authUser.adminUserId,
            stageId: this.state.addRequestDetails.stagePartialId,
            serverId: this.state.addRequestDetails.serverId,
            serverName: this.state.addRequestDetails.serverId,
            isError: this.state.addRequestDetails.isError,
            isDone: this.state.addRequestDetails.isDone,
            isScrapping: false,
            isDBUpdates: this.state.addRequestDetails.isDBUpdates ? this.state.addRequestDetails.isDBUpdates : false,
            isCatalogCleaner: false,
            requestType: 3,
            subType: this.state.addRequestDetails.subTypeId === "0" ? "" : this.state.addRequestDetails.subTypeId,
            isTor: false,
            hostServerId: this.state.addRequestDetails.hostServerId,
          };
          this.requestServices.onAdd(request);
        }
      }
      else {
        if (this.requestServices.isValidSubmit(this.state.addRequestDetails, this.state.validationRules, this.state.validState)) {
          let request = {
            moduleId: this.state.addRequestDetails.moduleId,
            supplierID: this.state.addRequestDetails.supplierID,
            statusId: 1,
            requestName: this.state.addRequestDetails.requestName,
            isActive: this.state.addRequestDetails.isActive,
            inputFile: "",
            outputFile: "",
            startDate: "",
            endDate: "",
            file: this.state.addRequestDetails.inputFile,
            isCleaner: this.state.addRequestDetails.isCleaner ? this.state.addRequestDetails.isCleaner : false,
            createdBy: this.requestServices.authUser.adminUserId,
            stageId: this.state.addRequestDetails.stagePartialId,
            serverId: this.state.addRequestDetails.serverId,
            serverName: this.state.addRequestDetails.serverId,
            isError: this.state.addRequestDetails.isError,
            isDone: this.state.addRequestDetails.isDone,
            isScrapping: false,
            isDBUpdates: this.state.addRequestDetails.isDBUpdates ? this.state.addRequestDetails.isDBUpdates : false,
            isCatalogCleaner: false,
            requestType: 3,
            subType: this.state.addRequestDetails.subTypeId === "0" ? "" : this.state.addRequestDetails.subTypeId,
            isTor: false,
            hostServerId: this.state.addRequestDetails.hostServerId,
          };
          this.requestServices.onAdd(request);
        }
      }
      this.setState({ isLoad: false });
    })
  };

  getoperationalModuleList = (value) => {
    this.requestServices.apiService.manageOperationalServices.getAllOperationalModuleList().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({ getoperationalList: response.responseItem.responseContent });
      } else {
        this.requestServices.apiService.serviceManager.swalServices.Toaster(response.message);
      }
    });
  };

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <>
        <div className="mb-4">

          <div className="accordion" id="accordionExample">
            <div className="card accordion-item active">
              <div
                id="accordionOne"
                className="accordion-collapse collapse show"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div className="card-body">
                    <div className="row">
                      {this.requestServices.formFields.map((Item, index) => {
                        return (
                          <>
                            {this.state.addRequestDetails.requestType ===
                              "3" ? (
                              <>
                                {(Item.subRequest === "1") && Item.partialrequest !== true ? (

                                  <FormFieldGroup
                                    column={Item}
                                    options={
                                      Item.options &&
                                        Item.fieldName === "moduleId"
                                        ? this.state.setModuleList
                                        : Item.options &&
                                          Item.fieldName === "serverId"
                                          ? this.state.setServerList
                                          :
                                          null
                                    }
                                    value={
                                      this.state.addRequestDetails[
                                      Item.fieldName
                                      ]
                                    }
                                    error={
                                      this.state.validState.error[
                                      Item.fieldName
                                      ]
                                    }
                                    onChange={
                                      Item.inputType === "checkbox"
                                        ? (event) =>
                                          this.handleChangeCheckBoxStatus(
                                            Item.fieldName,
                                            event.target.checked
                                          )
                                        : Item.inputType === "text"
                                          ? (event) =>
                                            this.handleInputChange(
                                              Item.fieldName,
                                              event.target.value
                                            )
                                          : Item.inputType === "select"
                                            ? (event) =>
                                              this.handleChange(
                                                Item.fieldName,
                                                event.target.value
                                              )
                                            : Item.inputType === "file"
                                              ? (event) =>
                                                this.handleFileChange(event)
                                              : null
                                    }
                                    checked={
                                      Item.inputType === "checkbox"
                                        ? this.state.addRequestDetails[
                                        Item.fieldName
                                        ]
                                        : null
                                    }
                                    disabled={
                                      Item.fieldName === "supplierID" &&
                                        Item.visible === false
                                        ? true
                                        : Item.fieldName ===
                                          "supplierDisplayName" &&
                                          Item.visible === false
                                          ? true
                                          : false
                                    }
                                    hidden={
                                      !this.state.addRequestDetails.moduleId && Item.fieldName === "supplierID" && Item.hidden === true ? true
                                        : !this.state.addRequestDetails.moduleId && Item.fieldName === "supplierDisplayName" && Item.hidden === true ? true
                                          : false
                                    }
                                    onBlur={
                                      Item.inputType !== "checkbox"
                                        ? () =>
                                          this.requestServices.validateField(
                                            Item.fieldName,
                                            this.state.addRequestDetails,
                                            this.state.validationRules,
                                            this.state.validState
                                          )
                                        : null
                                    }
                                    onKeyDown={
                                      Item.onkeydown
                                        ? Item.onkeydown.bind(this)
                                        : null
                                    }
                                    key={index} />) : null}
                              </>
                            ) :
                              null}
                          </>
                        );
                      })}
                    </div>
                    <div className="row">

                      {this.requestServices.PartialRequestformFields.map((Item, index) => {
                        return (
                          <>
                            <FormFieldGroup
                              column={Item}
                              options={
                                Item.options &&
                                  Item.fieldName === "stagePartialId"
                                  ? Item.options
                                  : Item.options && Item.fieldName === "subTypeId"
                                    ? Item.options
                                    : Item.fieldName === 'hostServerId' ? this.state.serverOptionList :
                                      null
                              }
                              value={
                                this.state.addRequestDetails[
                                Item.fieldName
                                ]
                              }
                              hidden={
                                (this.state.addRequestDetails.stagePartialId === "3"
                                  || this.state.addRequestDetails.stagePartialId === "")
                                  && Item.fieldName === "subTypeId"
                                  && Item.hidden === true ? true :
                                  false
                              }
                              error={
                                this.state.validState.error[
                                Item.fieldName
                                ]
                              }
                              onChange={
                                Item.inputType === "select"
                                  ? (event) =>
                                    this.handleChange(
                                      Item.fieldName,
                                      event.target.value
                                    )
                                  :
                                  Item.inputType === "file"
                                    ? (event) =>
                                      this.handleFileChange(event)
                                    : null
                              }

                              onBlur={
                                Item.inputType !== "checkbox"
                                  ? () =>
                                    this.requestServices.validateField(
                                      Item.fieldName,
                                      this.state.addRequestDetails,
                                      this.state.validationRules,
                                      this.state.validState
                                    )
                                  : null
                              }
                              onKeyDown={
                                Item.onkeydown
                                  ? Item.onkeydown.bind(this)
                                  : null
                              }
                              key={index} />

                          </>
                        );

                      })}
                      <div className="col-md-12 addnewuser-button">
                        <button className="tab-title tab-title--active  btn-hover  color-3 active" onClick={() => this.addRequest()}>
                          {this.state.isLoad === true ? <ButtonLoader /> : "Save"}
                        </button>
                        <Button type='cancel' title="Cancel" className="btn-hover color-3 tab-title ml-2" onClick={() => this.requestServices.onRedirectoList()} />
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
