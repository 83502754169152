
import { SuccessMessage } from "../../utils/Messages";
import { getValidationRuleList } from "../../utils/validation/generateValidationRule";
import BaseDomainService from "./baseDomainServices";
import { DataFormat } from "../../utils/DateFormat";

const operationalFormField = {
  RequestName: "",
  CreatedBy: 1,
  FileName: "",
  EmailTypeId: "",
  EmailAddress: "",
  File: null,
};

export default class emailValidationServices extends BaseDomainService {
  add = this.apiService.emailValidationServices.addEmailValidation;
  getEmailValidationList =
    this.apiService.emailValidationServices.getEmailValidationList;
  getLogsByProductDataId = this.apiService.emailValidationServices.getLogsById;
  getById = this.apiService.emailValidationServices.getEmailValidationById;

  // define the form fields
  formFields = [
    {
      inputType: "text",
      tittle: "Request Name",
      placeholder: "Request Name",
      fieldName: "RequestName",
      className: "col-md-6",
      maxLength: 200,
      validation: [{ type: "require" }],
    },
    {
      inputType: "select",
      tittle: "Validation Type",
      placeholder: "Select Dropdown",
      fieldName: "EmailTypeId",
      Options: [
        { name: "Single", id: "1" },
        { name: "Bulk", id: "2" },
      ],
      className: "col-md-6 mb-3",
      validation: [{ type: "require" }],
    },
    {
      inputType: "text",
      tittle: "Email Address",
      placeholder: "Email Address",
      fieldName: "EmailAddress",
      className: "col-md-6",
      maxLength: 200,
      // validation: [{ type: "email" } , {type : "require"}]
    },
    {
      inputType: "file",
      tittle: "Input File (only .txt)",
      placeholder: "Input File",
      fieldName: "File",
      subRequest: "1",
      className: "col-md-6 PartielFile",
      // validation: [{ type: "require" }]
    },
  ];

  gridColmns = [
    { name: "RequestName", title: "Request Name" },
    { name: "Name", title: "Type" },
    { name: "EmailAddress", title: "Email Address" },
    { name: "StatusName", title: "Status Name" },
    { name: "CreatedDate", title: "Created Date" },
    { name: "EmailValidationId", title: "Action" },
  ];

  dataItem;

  constructor() {
    super();
    this.actionKey = "EmailValidationId";
    this.dataItem = { ...operationalFormField };
    this.onPaginationUpdate = this.onGetEmailValidationList.bind(this);
  }

  onAdd = (request) => {
    this.apiService.onAPICall(
      "addEmailValidation",
      this.add,
      request,
      this.onAPIResponse,
      this.onUpdateState
    );
  };

  onGetEmailValidationList = (requst) => {
    this.apiService.onAPICall(
      "getEmailValidationList",
      this.getEmailValidationList,
      requst,
      this.onAPIResponse,
      this.onUpdateState
    );
  };

  onGetLogsByProductDataId = (request) => {
    this.apiService.onAPICall(
      "getLogsByProductDataId",
      this.getLogsByProductDataId,
      request,
      this.onAPIResponse,
      this.onUpdateState
    );
  };

  onGetById = (request) => {
    this.apiService.onAPICall(
      "onGetById",
      this.getById,
      request,
      this.onAPIResponse,
      this.onUpdateState
    );
  };

  onInitService() {
    //This method only call when we use form component and we can add all items which we on on form load
    let valRule = getValidationRuleList(this.formFields);
    this.validationRules = valRule;
    this.onUpdateState({
      validationRules: valRule,
      resetValidationRule: this.resetValidationRule,
    });
  }

  onRedirectoList = () => {
    this.onRedirect("/EmailValidation");
  };

  onAPIResponse = (key, isSuccess, responseData) => {
    if (!isSuccess) {
      this.onUpdateState({ setIsLoad: false });
      return;
    }
    switch (key) {
      case "addEmailValidation":
        let data = responseData;
        if (data.errorType === "EXIST") {
          this.apiService.serviceManager.swalServices.Error(
            SuccessMessage.Already_Exist.replace("{0}", data.errorColumn)
          );
        } else {
          this.apiService.serviceManager.swalServices.Success(
            SuccessMessage.Add_Success.replace("{0}", "Email")
          );
          this.onRedirectoList();
        }
        this.onUpdateState({ setIsLoad: false });
        break;
      case "getEmailValidationList":
        let itemList = responseData.itemList;
        itemList.forEach(function (i) {
          i.createdDate = DataFormat(
            i.createdDate
          );
        });
        if (responseData !== null) {
          this.onUpdateState({
            getList: itemList,
            setIsLoad: false,
            TotalCount: responseData.totalCount,
          });
        }
        break;
      case "getLogsByProductDataId":
        if (responseData !== null) {
          this.onUpdateState({
            logsList: responseData,
          });
        }
        this.onUpdateState({
          setIsLoad: false,
        });
        break;
      case "onGetById":
        if (responseData != null) {
          responseData.emailValidationId = responseData.emailValidationId
            ? responseData.emailValidationId
            : "NA";
          responseData.requestName = responseData.requestName
            ? responseData.requestName
            : "NA";
          responseData.name = responseData.name ? responseData.name : "NA";
          responseData.validCounts = responseData.validCounts
            ? responseData.validCounts
            : "0";
          responseData.invalidCounts = responseData.invalidCounts
            ? responseData.invalidCounts
            : "0";
          responseData.invalidCounts = responseData.invalidCounts
            ? responseData.invalidCounts
            : "0";
          responseData.emailTypeId = responseData.emailTypeId
            ? responseData.emailTypeId
            : "NA";
          responseData.emailAddress = responseData.emailAddress
            ? responseData.emailAddress
            : "NA";
          responseData.statusName = responseData.statusName
            ? responseData.statusName
            : "NA";
          responseData.emailStatus = responseData.emailStatus
            ? responseData.emailStatus
            : "NA";
          responseData.mxRecord = responseData.mxRecord
            ? responseData.mxRecord
            : "NA";
          responseData.userName = responseData.userName
            ? responseData.userName
            : "NA";
          responseData.domainName = responseData.domainName
            ? responseData.domainName
            : "NA";
          responseData.error = responseData.error ? responseData.error : "NA";
          responseData.createdDate = responseData.createdDate
            ? DataFormat(
              responseData.createdDate
            )
            : "NA";
          this.onUpdateState({
            EmailData: responseData,
          });
        }
        this.onUpdateState({
          setIsLoad: false,
        });
        break;
        default:
          // Handle default case here
          break;
    }
  };
}
