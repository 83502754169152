//** Lib's */
import BaseComponent from "../../../shared/baseComponent";
import { decryptionData } from "../../../utils/Encryption";

//** Service's */
import { DownloadInputFile } from "../../../services/axiosServices/ApiEndPoints";
import LinkupDomainService from "../../../services/domainServices/linkupDomainService";
import axios from 'axios';
import FileViewer from 'react-file-viewer';
import { Modal } from "react-bootstrap";

export default class LinkupRequest extends BaseComponent {

    constructor(props) {
        super(props);
        this.domainServices = new LinkupDomainService();
        this.state = {
            setIsLoad: false,
            outPutFileData: [],
            showModal: false,
            fileToView: null,
            fileContent: null,
        };
        this.domainServices.onUpdateState = this.updateState.bind(this);
    }

    //** Constructor  and Component method */
    componentDidMount() {
        let params = new URLSearchParams(window.location.search);
        let id = params.get("");
        const decryptKey = decryptionData(id, false);
        if (decryptKey > 0) {
            this.domainServices.onGetOutputFileById(decryptKey);
        }
    }

    handleCloseFile = () => {
        this.setState({ showModal: false })
        this.setState({ fileToView: null });
    }

    previewFile = (data) => {
        const downloadUrl = `${DownloadInputFile}?FileName=${encodeURIComponent(data)}&ModuleType=RegulationLinkupOutput`;
        this.setState({ setIsLoad: true });
        axios.get(downloadUrl, { responseType: 'blob' })
            .then(response => {
                this.setState({ fileToView: response.data });
                this.setState({ showModal: true });
            })
            .catch(error => {
                console.error('Error downloading the file:', error);
            });
    };

    render() {
        const { showModal } = this.state
        return (
            <>
            <div className="card mt-2">
                <div className="card-title-main px-3 py-2">
                    <h6 className="titletexts font-size-16">Output Files</h6>
                </div>
                <div className="card-body custom-card-body">
                    <div className="row">
                        {this.state.outPutFileData.length > 0 && this.state.outPutFileData !== undefined && this.state.outPutFileData !== null ?
                            <>
                                {this.state.outPutFileData.map((data, index) => {
                                    return (
                                        <div className="col-md-6 mb-3">
                                            <div className="inner-detail-sec" key={index}>
                                                <>
                                                    <span className="detail-title">
                                                        {data.linkupOutputFileTypeId === 1 ? 'Linkup File' : ''}
                                                        {data.linkupOutputFileTypeId === 2 ? 'Non Linkup File' : ''}
                                                    </span> &nbsp;
                                                    <span className="detail-desc">
                                                        {data.fileName !== null && data.fileName !== undefined ?
                                                            <>
                                                                {data.linkupOutputFileTypeId === 1 ?
                                                                    <span for="requestName" className="detail-desc ml-3">
                                                                        {this.props.detailData.linkupCount} &nbsp;&nbsp;&nbsp;
                                                                        <a href={`${DownloadInputFile + `?FileName=${data.fileName}&ModuleType=RegulationLinkupOutput`}`}
                                                                            target="_top" >
                                                                            {" "} <i className="fa fa-download"></i>
                                                                        </a>
                                                                        <img src="/csv.png" className="csv-file ml-2 cursor-pointer" title="Preview" alt="Text icon" onClick={() => this.previewFile(data.fileName)} />
                                                                    </span>
                                                                    : ''}
                                                                {data.linkupOutputFileTypeId === 2 ?
                                                                    <span for="requestName" className="detail-desc ml-3">
                                                                        {this.props.detailData.nonLinkupCount} &nbsp;&nbsp;&nbsp;
                                                                        <a href={`${DownloadInputFile + `?FileName=${data.fileName}&ModuleType=RegulationLinkupOutput`}`}
                                                                            target="_top" >
                                                                            {" "} <i className="fa fa-download"></i>
                                                                        </a>
                                                                        <img src="/csv.png" className="csv-file ml-2 cursor-pointer" title="Preview" alt="Text icon" onClick={() => this.previewFile(data.fileName)} />
                                                                    </span>
                                                                    : ''}
                                                            </>
                                                            :
                                                            <span className='detail-desc'>
                                                                File Not Found
                                                            </span>
                                                        }
                                                    </span>
                                                </>
                                            </div>
                                        </div>
                                    )
                                })}
                            </>
                            :
                            <span className='detail-desc'>
                                No Record found
                            </span>
                        }
                    </div>
                </div>
            </div>
            <Modal className="scrolling-model" show={showModal} onHide={this.handleCloseFile}>
          <Modal.Header closeButton >
            <Modal.Title>File Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body >
            <div className="RequestDetails-modal-file">
              {this.state.fileToView && (
                <>
                  <div>
                    <FileViewer
                      fileType="csv" // Set the file type to csv
                      filePath={URL.createObjectURL(this.state.fileToView)}
                      onError={error => console.error('Error:', error)}
                    />
                  </div>
                </>
              )
              }
            </div>
          </Modal.Body>
        </Modal>
                </>
        )
    }
}
