
import React from 'react'
import { encryptionData } from '../../utils/Encryption';
import { Badge } from 'react-bootstrap';

function CleaningReq(props) {

  const onActionCall = (type, data) => {
    switch (type) {
      case "module":
        window.open(`/RequestDetails/id?=${data}`, '_blank');
        // this.setState({ redirect: "/RequestDetails/id?=" + data });
        break;
      default:
        // Handle default case here
        break;
    }
  }

  const getStatusColorClass = (statusName) => {
    let textColorClass = "";
    switch (statusName) {
      case "Done":
        textColorClass = "success";
        break;
      case "Open":
        textColorClass = "warning";
        break;
      case "Start":
        textColorClass = "primary";
        break;
      case "InProgress":
        textColorClass = "info";
        break;
      case "Error":
        textColorClass = "danger";
        break;
      case "OnHold":
        textColorClass = "secondary";
        break;
      default:
        textColorClass = "primary";
        break;
    }
    return textColorClass;
  }

  return (
    <>
      {/* <div className='card mb-3'>
        <div className="row p-3">
          <div className="col-md-4">
            <div className="inner-detail-sec">
              <span className="detail-title">Total Request</span>
              <span className="font-size-16"> ff</span>
            </div>
          </div>
          <div className="col-md-4">
            <div className="inner-detail-sec">
              <span className="detail-title">Total Error </span>
              <span className="font-size-16">ss</span>
            </div>
          </div>
          <div className="col-md-4">
            <div className="inner-detail-sec">
              <span className="detail-title">Total Done </span>
              <span className="font-size-16">ss</span>
            </div>
          </div>
        </div>
      </div> */}
      <div className='group-table'>
        <table className="table table-hover table-shadow table-striped table-borderless">
          <thead>
            <tr className='text-left'>
              <th>Request Name</th>
              <th>Supplier Name</th>
              <th>Module Name</th>
              <th>Status Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className="table-border-bottom-0 text-left">
            {props.cleannerDataUpload && props.cleannerDataUpload.length > 0 ? (
              props.cleannerDataUpload.map((data, idx) => (
                <React.Fragment key={idx}>
                  <tr>
                    <td className="cursor-pointer" onClick={() => onActionCall('module', encryptionData(data.middlewareRequestId))}>
                      {/* <a className='url-links-tag' href={data.middlewareRequestId}> */}
                      <strong className=' up_RPA_module cursor-pointer'>{data.requestName}</strong>
                      {/* </a> */}
                    </td>
                    <td>{data.supplierName}</td>
                    <td>{data.moduleName}</td>
                    <td>
                      <Badge pill bg={getStatusColorClass(data.statusName)}>{data.statusName}</Badge>
                    </td>
                    <td className="">
                      <i className="fa fa-eye cursor-pointer actions-icon" onClick={() => onActionCall('module', encryptionData(data.middlewareRequestId))}></i>
                    </td>
                  </tr>
                </React.Fragment>
              ))
            ) : (
                <td colSpan="5" className="d-flex justify-content-center p-3 font-size-16">No Data Found</td>
            )}
          </tbody>
        </table>
      </div>
    </>
    
  )
}

export default CleaningReq