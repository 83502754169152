//** Component  */
import React from 'react'

//** Services */
import { DownloadInputFile } from '../../../services/axiosServices/ApiEndPoints';

//*** Lib */
import BaseComponent from '../../../shared/baseComponent';
import axios from 'axios';
import FileViewer from 'react-file-viewer';
import { Badge, Modal } from 'react-bootstrap';
import { encryptionData } from '../../../utils/Encryption';
export default class ProductImportInitiate extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            setIsLoad: false,
            showModal: false,
            fileToView: null,
            fileContent: null,
        };
    }

    handleCloseFile = () => {
        this.setState({ showModal: false })
        this.setState({ fileToView: null });
    }

    previewFile = () => {
        const downloadUrl = `${DownloadInputFile}?FileName=${encodeURIComponent(this.props.productDataDetail.fileName)}&ModuleType=ProductImportInput`;
        this.setState({ setIsLoad: true });
        axios.get(downloadUrl, { responseType: 'blob' })
            .then(response => {
                this.setState({ fileToView: response.data });
                this.setState({ showModal: true });
            })
            .catch(error => {
                console.error('Error downloading the file:', error);
            });
    };



    render() {
        const { showModal } = this.state
        return (
            <>
                <div className="card">
                    <div className='card-title-main px-3 py-2'>
                        <h6 className='titletexts font-size-16'>Intiate File</h6>
                    </div>
                    <div className='card-body custom-card-body'>
                        <div className='inner-detail-sec'>
                            <span className='detail-title'> Intiate File </span>
                            &nbsp;&nbsp;&nbsp;
                            {this.props.productDataDetail.intiateCount}
                            {this.props.productDataDetail.fileName !== "" ?
                                <>
                                    {this.props.productDataDetail.intiateCount > 0 ? (
                                        <span htmlFor="requestName" className="detail-desc ml-3">
                                            <a href={`${DownloadInputFile + `?FileName=${this.props.productDataDetail.fileName}&ModuleType=ProductImportInput`}`} target="_top">
                                                <i className="fa fa-download"></i></a>
                                            <img src="/xlsx.png" className="xlsx-file ml-2 cursor-pointer" title="Preview" alt="Text icon" onClick={this.previewFile} />
                                        </span>
                                    ) : null}
                                </>
                                :
                                <span className='detail-desc'>
                                    No Record found
                                </span>
                            }
                        </div>
                    </div>
                </div>
                <Modal className="scrolling-model" show={showModal} onHide={this.handleCloseFile}>
                    <Modal.Header closeButton >
                        <Modal.Title>File Preview</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <div className="RequestDetails-modal-file">
                            {this.state.fileToView && (
                                <>
                                    <div>
                                        <FileViewer
                                            fileType="xlsx" // Set the file type to csv
                                            filePath={URL.createObjectURL(this.state.fileToView)}
                                            onError={error => console.error('Error:', error)}
                                        />
                                    </div>
                                </>
                            )
                            }
                        </div>
                    </Modal.Body>
                </Modal>
             
            </>
        )
    }
}

