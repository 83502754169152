
import { axiosGet, axiosPost, axiosPostAuthorize } from '../AxiosRequests';
//*** Lib */
import {
    AddEmailValidation, GetEmailValidationList, GetLogsById, GetEmailValidationById
} from '../ApiEndPoints';

export default class EmailValidationServices {

    async addEmailValidation(request) {
        let formData = new FormData();
        formData.append("RequestName", request.RequestName);
        formData.append("FileName", request.FileName);
        formData.append("File", request.File);
        formData.append("EmailTypeId", request.EmailTypeId);
        formData.append("EmailAddress", request.EmailAddress);
        formData.append("CreatedBy", request.CreatedBy);
        return axiosPostAuthorize(AddEmailValidation, formData, true, request);
    }

    async getEmailValidationList(request) {
        return axiosPost(GetEmailValidationList, request);
    }
    async getLogsById(request) {
        return axiosGet(GetLogsById, request);
    }
    async getEmailValidationById(request) {
        return axiosGet(GetEmailValidationById, request);
    }
    // async getEmailValidationOutputFileById(request) {
    //     return axiosGet(GetEmailValidationOutputFileById, request);
    // }

}

