//** Component  */
import React, { useEffect , useState} from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import { Outlet } from 'react-router';
import { useLocation } from 'react-router-dom';
import AuthorizationWrap from '../hoc/AuthorizeWrap';
import { getAuthProps } from "./../utils/AuthenticationLibrary";

const Layout = (props) => {
  const location = useLocation()
  const newStr = location.pathname.replace('/', '');
  const breadCrumbs = props.componentRoutes.filter(x => x.path === newStr);
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  // const [isSideBarClose, setIsSideBarClose] = useState(true);
  useEffect(() => {
    const authDetails = getAuthProps();
    let params = window.location.pathname;
    if (params === "/UnAuthorized") {
      window.location.href = "/UnAuthorizedg";
    }
    else if(!authDetails)
    {
      window.location.href = "/login";
    }
  });

 
  return (

    <div>
      {/* <div className={`layout-wrapper layout-content-navbar Drawer-Sidebar-highlighted `}> */}
      <div className={`layout-wrapper layout-content-navbar ${isSideBarOpen === true ? "sidebar-display-part" :"" }`}>
        <Sidebar componentRoutes={props.componentRoutes} handleIconClick = {()=>setIsSideBarOpen(!isSideBarOpen)}/>
        <div className="layout-container ">
          <div className="content-wrapper containt-margin-200 laptop-width">
            <AuthorizationWrap authorizeUserType={breadCrumbs[0].allowrole}>
              {/* <Header componentRoutes={props.componentRoutes} handleIconClick={props.handleIconClick} isHighlighted={props.isHighlighted}/> */}
              <Header componentRoutes={props.componentRoutes}
                handleIconClick = {()=>setIsSideBarOpen(!isSideBarOpen)}
                // handleIconClickk = {()=>isSideBarOpen(false)}
              />
              {/* <div className='container-xxl flex-grow-1 container-p-y  center-sec-main pb-0'> */}
              <div className='container-xxl flex-grow-1 container-p-y pb-0'>
                <Outlet />
              </div>
            </AuthorizationWrap>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Layout;
