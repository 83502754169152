import React from "react";

const CheckboxField = (request) => {
    let props = request.request;
    let showIcon = props.column && props.column.isShow;
    const isChecked = props.value || props.checked;
    return (<>
        {showIcon === true ?
            <>
                {isChecked ? (
                    <i className="fas fa-check text-success font-size-20"></i>
                ) : (
                    <i className="fas fa-times text-danger font-size-20"></i>
                )}
            </>
            :
            <input
                className="form-check-input custom-checkboxs"
                type={props.column.inputType}
                onChange={props.onChange}
                id={props.column.fieldName}
                checked={isChecked}
                disabled={props.disabled}
                defaultChecked={props.defaultChecked}
            />
        }
    </>)
}


export default CheckboxField;
