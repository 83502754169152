//*** Lib */
import { createCookie, getCookie, removeAllCookies, isCookieExist, setCookie } from "./cookies/CookieHandler";

const authCookieName = 'RMAuthUser';
const tokenCookieName = 'RMToken';

export const setAuthProps = (data) => {
    const authProps = {
        cookieName: authCookieName,
        cookieValue: data,
        expirationTime: data.sessionTimeout
    }
    createCookie(authProps);
    setTokenProps(data.token);
}

export const getAuthProps = () => {
    return getCookie(authCookieName);
}

export const isAuthorized = () => {
    return isCookieExist(authCookieName);
}

export const setTokenProps = (data) => {
    const tokenProps = {
        cookieName: tokenCookieName,
        cookieValue: data
    }
    setCookie(tokenProps);
}

export const getTokenProps = () => {
    return getCookie(tokenCookieName);
}

export const signOut = () => {
    removeAllCookies();
    window.location.href = "/login";
}

export const IsAuthorizeUser = (allowUserType) => {
    let cookie = getAuthProps();
    if (cookie) {
        let loginRole = cookie.roles;
        let loginUserRoleList = loginRole.roleName.toLowerCase();
        if (allowUserType !== undefined) {
            return allowUserType.find(x => x.toLowerCase() === loginUserRoleList)
        }
    }
    return ''
}
