import { Bars } from "react-loader-spinner";
import Table from "../../components/table/Table";
import BaseComponent from "../../shared/baseComponent";
import linkBuilderDomainServices from "../../services/domainServices/linkBuilderServices";
import { encryptionData } from "../../utils/Encryption";
import { Link } from "react-router-dom";

export default class ManageLinkBuilderList extends BaseComponent {
  constructor(props) {
    super(props);
    this.linkBuilderServices = new linkBuilderDomainServices();
    this.state = {
      pagination: this.linkBuilderServices.pagination,
      getList: [],
      setIsLoad: false,
    };
    this.linkBuilderServices.onUpdateState = this.updateState.bind(this);
    this.linkBuilderServices.onActionCall = this.onActionCall.bind(this);
  }

  componentDidMount() {
    this.linkBuilderServices.onGetLinkBuilderList(this.state.pagination);
  }

  onActionCall = (type, data) => {
    // *** getByID encryption ***//
    const encryptKey = encryptionData(data, 1);
    switch (type) {
      case "View":
        window.open(`/LinkBuilderDetail/id?=${encryptKey}`, "_blank");
        break;
        default:
          // Handle default case here
          break;
    }
  };

  render() {
    return (
      <div>
        <div className="top-search-add-button-sec mb-3">
          <div className="row left-searchbar-sec"></div>
          <div className="right-button-sec">
            <Link
              to="/AddLinkBuilder"
              className="tab-title btn-hover color-3 active"
            >
              Add Link Builder <span>+</span>
            </Link>
          </div>
        </div>
        <div className="Link-Buider-list">
        {this.state.setIsLoad ? (
          <Bars
            className="row"
            wrapperStyle={{ marginTop: "150px" }}
            color="#3c4b64"
            height={55}
            width="100%"
            visible={this.state.setIsLoad}
          />
        ) : (
          <Table
            columns={this.linkBuilderServices.gridColmns}
            rows={this.state.getList}
            sortingColumns={[]}
            pagination={this.state.pagination}
            totalCount={this.state.TotalCount}
            totalResultes={this.state.TotalCount}
            setPagination={this.linkBuilderServices.onPageChange.bind(this)}
            customScope={[
              {
                column: "linkBuilderId",
                renderTableData:
                  this.linkBuilderServices.gridActionsViewCol.bind(this),
              },
            ]}
          />
        )}
        </div>
      </div>
    );
  }
}
