//** Component  */
import React from "react";
import ValidationText from "../../../utils/validation/ValidationText";
import InputField from "./InputField";
import CheckboxField from "./CheckboxField";
import SelectField from "./SelectField";
import FileField from "./FileField";
import SearchField from "./SearchField";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const FormFieldGroup = (props) => {
  return (
    <>
      {props.column.inputType === "text" || props.column.inputType === "number" || props.column.inputType === "password" ? (
        <div className={`form-group ${props.column.className} ${props.className}`}>
          <label className="form-control-label" for={props.column.fieldName} hidden={props.hidden}>
            {props.column.tittle}
          </label>
          <InputField request={props} />
          <ValidationText error={props.error} />
        </div>
      ) : null}

      {props.column.inputType === "search" ? (
        <div className={`form-group`}>

          <SearchField request={props} />
          <ValidationText error={props.error} />
        </div>
      ) : null}

      {props.column.inputType === "file" ? (
        <div className={`form-group ${props.column.className} ${props.hide}`}>
          <label className="form-control-label" for={props.column.fieldName}>
            {props.column.tittle}
          </label>
          <FileField request={props} />
          <ValidationText error={props.error} />
        </div>
      ) : null}

      {props.column.inputType === "checkbox" ? (
        <div className={`form-group ${props.column.className}`}>
          <CheckboxField request={props} />
          <label
            className={`form-control-label mb-0 ml-2 ${props.column.checkLabelClass}`}
            for={props.column.fieldName}>
            {props.column.tittle}
          </label>
          <ValidationText error={props.error} />
        </div>
      ) : null}

      {props.column.inputType === "select" ? (
        <div className={`${props.column.className}`}>
          <label hidden={props.hidden}>{props.column.tittle} </label>
          <SelectField request={props} />
          <ValidationText error={props.error} />
        </div>
      ) : null}

      {props.column.inputType === "label" ?
        <div className={`inner-detail-sec mb-3 ${props.column.className}`}>
          <label className="fw-bold detail-title" style={{ fontSize: '16px' }} hidden={props.column.tittle === ""}>{props.column.tittle}</label>
          {props.column.Action === true ?
            props.value ?
              <label className="detail-desc ml-3 cursor-pointer text-decoration" style={{ fontSize: '16px' }} hidden={props.column.tittle === ""} onClick={() => props.onActionCall(props.column.fieldName, props.column.Data)}><span className="undeline">{props.value}</span> </label>
              : <span className="detail-desc ml-3" style={{ fontSize: '16px' }}>NA</span>
            :
            <label className="detail-desc ml-3" style={{ fontSize: '16px' }} hidden={props.column.tittle === ""}>{props.value ? props.value : "NA"} </label>
          }
        </div>
        : null}

      {props.column.inputType === "tabs" ?
        <li className={"step " + ((props.state >= props.value) !== 0 ? '' : `step active ${props.column.className}`)} >
          <div className='main-timeline-sec'>
            <div className='icon-round'>
              <i className={props.column.iconClass}></i>
            </div>
            <label>{props.column.tittle}</label>
          </div>
        </li>
        : null}

      {props.column.inputType === "datepicker" ?
        <div className={`form-group ${props.column.className} ${props.className}`}>
          <label className="form-control-label" for={props.column.fieldName} hidden={props.hidden}>
            {props.column.tittle}
          </label>
          <div className="date-picker-react">
            <DatePicker showIcon toggleCalendarOnIconClick
              selected={props.selectedDate} onChange={props.onChange}
              onBlur={props.onBlur} dateFormat="yyyy/MM/dd" />
            <ValidationText error={props.error} />
          </div>
        </div>
        : null}


    </>
  );
};

export default FormFieldGroup;
