//** Component  */
import React, { useState } from "react";


//*** Lib */
import PageLoader from "../common/pageLoader/PageLoader";
import { Badge } from "react-bootstrap";

const defaultScopeAction = (element, value) => {
  return value ? (
    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div
        className={`inline-flex font-medium rounded-full text-center bg-emerald-500 px-2.5 py-0.5 `}
      >
        Active
      </div>
    </td>
  ) : (
    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
      <div
        className={`inline-flex font-medium rounded-full text-center  bg-red-500 px-2.5 py-0.5 `}
      >
        In Active
      </div>
    </td>
  );
};

const availabilityAction = (element, value) => {
  return (
    <>
      {(() => {
        switch (value) {
          case 1:
            return (
              <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div
                  className={`inline-flex font-medium rounded-full text-center bg-emerald-500 px-2.5 py-0.5 `}
                >
                  <i className=" icon-sm fa fa-check-circle fw-bold mt-1 mr-1"></i>{" "}
                  In Stock
                </div>
              </td>
            );
          case 2:
            return (
              <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div
                  className={`inline-flex font-medium rounded-full text-center bg-emerald-500 px-2.5 py-0.5 `}
                >
                  <i className=" icon-sm fal fa-history fw-bold mt-1 mr-1"></i>{" "}
                  Backordered
                </div>
              </td>
            );
          case 3:
            return (
              <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div
                  className={`inline-flex font-medium rounded-full text-center bg-emerald-500 px-2.5 py-0.5 `}
                >
                  <i className="icon-sm fal fa-ban fw-bold mt-1 mr-1"></i>{" "}
                  Suspended
                </div>
              </td>
            );
          case 4:
            return (
              <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div
                  className={`inline-flex font-medium rounded-full text-center bg-emerald-500 px-2.5 py-0.5 `}
                >
                  <i className=" icon-sm fal fa-conveyor-belt-alt fw-bold mt-1 mr-1"></i>{" "}
                  In Production
                </div>
              </td>
            );
          default:
            return null;
        }
      })()}
    </>
  );
};

const defaultScope = [
  {
    column: "isActive",
    renderTableData: defaultScopeAction.bind(this),
  },
  {
    column: "availabilityId",
    renderTableData: availabilityAction.bind(this),
  },
];

export function Rows(props) {
  return (
    props &&
    props.columns &&
    props.columns.map((element) => {
      const columnName = element.name.toLowerCase();
      const columnValue =
        props.tableData[
        Object.keys(props.tableData).find(
          (objKey) => objKey.toLowerCase() === columnName
        )
        ];
      let textColorClass = "";
      let isStatusIDRow = false;
      switch (columnValue) {
        case "Done":
          textColorClass = "success";
          isStatusIDRow = true;
          break;
        case "Open":
          textColorClass = "warning";
          isStatusIDRow = true;
          break;
        case "Start":
          textColorClass = "primary";
          isStatusIDRow = true;
          break;
        case "InProgress":
          textColorClass = "info";
          isStatusIDRow = true;
          break;
        case "Error":
          textColorClass = "danger";
          isStatusIDRow = true;
          break;
        case "OnHold":
          textColorClass = "secondary";
          isStatusIDRow = true;
          break;
        case "PartiallyDone":
          textColorClass = "orange";
          isStatusIDRow = true;
          break;
        case "Wait":
          textColorClass = "yellow";
          isStatusIDRow = true;
          break;
        // Add more cases as needed
        default:
          // Handle other cases here if needed
          textColorClass = "primary";
          isStatusIDRow = false;
          break;
      }
      return props.customScope &&
        props.customScope.filter((c) => c.column.toLowerCase() === element.name.toLowerCase()).length > 0 ?
        (props.customScope.filter((c) => c.column.toLowerCase() === element.name.toLowerCase())[0].renderTableData(element.name, props.index, props.tableData[Object.keys(props.tableData).find((objKey) => objKey.toLowerCase() === element.name.toLowerCase())],
          props.customScope.filter((c) => c.column.toLowerCase() === element.name.toLowerCase())[0].isDeleteRequired ? props.customScope.filter((c) => c.column.toLowerCase() === element.name.toLowerCase())[0].isDeleteRequired : false,
          props.customScope.filter((c) => c.column.toLowerCase() === element.name.toLowerCase())[0].regulationData ? props.customScope.filter((c) => c.column.toLowerCase() === element.name.toLowerCase())[0].regulationData : false)
        ) :
        props.defaultScope && props.defaultScope.filter((c) => c.column.toLowerCase() === element.name.toLowerCase()).length > 0 ?
          (props.defaultScope.filter((c) => c.column.toLowerCase() === element.name.toLowerCase())[0].renderTableData(element.name, props.tableData[Object.keys(props.tableData).find((objKey) => objKey.toLowerCase() === element.name.toLowerCase())])
          ) : (
            <td className={`${props.isCollapsedSection ? "text-start" : "text-left"}`} onClick={() => props.isCollapsedSection ? props.setCollapse(props.index, props.isCollapsed) : null}>
              {isStatusIDRow ? <Badge pill bg={textColorClass}>{columnValue}</Badge> : columnValue}
            </td>
          );
    })
  );
}

const Table = (props) => {
  const [basePageNo, setBasePageNo] = useState(2);
  const [inputValue, setInputValue] = React.useState(props.pagination.pageNo);

  const handleSorting = (columnName, value) => {
    props.pagination.pageNo = 1;
    props.pagination.orderByColumn = columnName;
    props.pagination.orderFlag = value ? 1 : 0;
    props.setPagination(props.pagination);
  };

  const handlePageChange = (pageNumber, isButton = false) => {
    if (
      props.pagination.pageNo !== pageNumber &&
      pageNumber > 0 &&
      pageNumber <= Math.ceil(props.totalCount / props.pagination.pageSize)
    ) {
      if (pageNumber > props.pagination.pageNo && pageNumber - basePageNo > 1) {
        setBasePageNo(pageNumber + 1);
      } else if (
        pageNumber < props.pagination.pageNo &&
        pageNumber > 1 &&
        basePageNo - pageNumber === 0
      ) {
        setBasePageNo(pageNumber - 1);
      } else if (isButton && pageNumber > 1) {
        setBasePageNo(pageNumber - 1);
      }
      props.pagination.pageNo = pageNumber;
      props.setPagination(props.pagination);
    }
  };
  const onChangeHandler = (event) => {
    const re = /^[0-9\b]+$/;
    if (inputValue === "" || re.test(inputValue)) {
      setInputValue(inputValue);
      if (event.key === "Enter") {
        handlePageChange(Number(inputValue), true);
        // alert(`You must have pressed Enter - ${event.target.value} `);
      }
      // handlePageChange(Number(5), true);
      // handlePageChange(event.target.value + inputValue, true);
    }
  };
  return (
    <div>
      {props.isLoading ? (
        <PageLoader />
      ) : (
        <div className="table-container">
          <div
            className={`table-responsive text-nowrap text-shadow-pd ${props.resClassName}`}
          >
            <table className="table table-shadow  rpa_table main-table">
              <thead>
                <tr>
                  {props &&
                    props.columns.map((value, key) => {
                      return (
                        <th key={key}>
                          <div className="font-semibold text-left">
                            {value.title}
                            {props.sortingColumns.includes(value.name) ? (
                              props.pagination.orderFlag === 1 &&
                                props.pagination.orderByColumn.toLowerCase() ===
                                value.name.toLowerCase() ? (
                                <i
                                  className="fa fa-angle-double-down p-2"
                                  onClick={() =>
                                    handleSorting(
                                      value.name,
                                      !props.pagination.orderFlag
                                    )
                                  }
                                ></i>
                              ) : (
                                <i
                                  className="fa fa-angle-double-up p-2"
                                  onClick={() =>
                                    handleSorting(
                                      value.name,
                                      !props.pagination.orderFlag
                                    )
                                  }
                                ></i>
                              )
                            ) : null}
                          </div>
                        </th>
                      );
                    })}
                </tr>
              </thead>
              <tbody className="table-border-bottom-0 ">
                {props && props.rows && props.rows.length > 0 ? (
                  props.rows.map((value, key) => {
                    return (
                      <>
                        <tr className="bg-white">
                          <Rows
                            key={key}
                            columns={props.columns}
                            tableData={value}
                            customScope={props.customScope}
                            defaultScope={defaultScope}
                            index={key}
                            setCollapse={props.setCollapse}
                            isCollapsedSection={props.isCollapsedSection}
                            isCollapsed={value.isCollapsed}
                          />
                        </tr>
                        {value.isCollapsed
                          ? props.collapsibleSection(value)
                          : null}
                      </>
                    );
                  })
                ) : (
                  <div className="d-flex justify-content-center p-3 font-size-16"> No Records Found</div>
                )}
              </tbody>
            </table>
            {props && props.rows && props.rows.length > 0 ? (
              <div className="d-flex justify-content-center">
                <div className="table-pagination">
                  <div className="table-title ps-4 ">
                    <p className="ps-2">
                      Showing
                      <span className="font-medium text-slate-600">
                        {props.pagination.pageNo * props.pagination.pageSize -
                          props.pagination.pageSize +
                          1}
                      </span>
                      to
                      <span className="font-medium text-slate-600">
                        {props.totalResultes <
                          props.pagination.pageNo * props.pagination.pageSize
                          ? props.totalResultes
                          : props.pagination.pageNo * props.pagination.pageSize}
                      </span>
                      of
                      <span className="font-medium text-slate-600">
                        {props.totalResultes}
                      </span>
                      results
                    </p>
                  </div>
                  <div className="flex gap-2 pagination ">
                    <button
                      className={`${props.pagination.pageNo > 1
                        ? "active-button table_paginations"
                        : "inactive-button table_paginations"
                        }`}
                      onClick={() =>
                        handlePageChange(props.pagination.pageNo - 1, true)
                      }
                      disabled={props.pagination.pageNo > 1 ? false : true}
                    >
                      <button
                        type="button"
                        className="page-link page-link--with-arrow pagination_svg"
                        aria-label="Previous"
                      >
                        <svg
                          className="page-link__arrow page-link__arrow--left"
                          aria-hidden="true"
                          width="8"
                          height="13"
                        >
                          <path d="M7.7 12.7c-.4.4-.9.4-1.3 0L0 6.5 6.4.3c.4-.4.9-.3 1.3 0 .4.4.4 1 0 1.3l-5 4.9 5 4.9c.4.4.4 1 0 1.3z"></path>
                        </svg>
                      </button>{" "}
                    </button>

                    <div className="">
                      <ul className="flex items-center text-sm font-medium  table_pagination">
                        <li className="flex item-center">
                          <input
                            className="pagination-input form-control"
                            type="text"
                            name="name"
                            onChange={(event) =>
                              setInputValue(event.target.value)
                            }
                            onKeyPress={(e) => onChangeHandler(e)}
                            value={inputValue}
                          />
                        </li>

                        <li className="flex item-center mt-2">
                          <span className="inline-flex items-center justify-center leading-5 mt-4  text-xl mx-4 theme-color">
                            Of
                          </span>
                        </li>
                        <li className="flex item-center ">
                          <input
                            className="pagination-input form-control"
                            disabled
                            value={Math.ceil(
                              props.totalCount / props.pagination.pageSize
                            )}
                          />
                        </li>
                      </ul>
                    </div>
                    <button
                      className={`${Math.ceil(
                        props.totalCount / props.pagination.pageSize
                      ) > props.pagination.pageNo
                        ? "active-button ms-4"
                        : "inactive-button ms-4"
                        }`}
                      onClick={() =>
                        handlePageChange(props.pagination.pageNo + 1, true)
                      }
                    >
                      <button
                        type="button"
                        className="page-link pagination_svg"
                        aria-label="Next"
                      >
                        <svg
                          className="page-link__arrow page-link__arrow--right"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="13"
                        >
                          <path d="m.3 11.4 5-4.9-5-4.9C-.1 1.2-.1.7.3.3s.9-.4 1.3 0L8 6.5l-6.4 6.2c-.4.4-.9.3-1.3 0s-.4-.9 0-1.3z"></path>
                        </svg>
                      </button>
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
            {/* <div className='mt-1'>
                            <Pagination
                                className="pagination"
                                currentPage={currentPage}
                                totalCount={props.columns.length}
                                pageSize={props.pagination.pageSize}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default Table;
