//*** Lib */
import { axiosPost, axiosGet } from '../AxiosRequests';
import { UpdateUserDetails, GetUserDetailsByUserId, UpdateUserpassword, GetUsersByUserName, AddUsers } from '../ApiEndPoints';

export default class UserServices {
    // API function

    async updateUserDetails(request) {
        return axiosPost(UpdateUserDetails, request);
    }
    async getUserDetailsByUserId(request) {
        return axiosGet(GetUserDetailsByUserId, request);
    }
    async updateUserpassword(request) {
        return axiosPost(UpdateUserpassword, request);
    }
    async getUsersByUserName(request) {
        return axiosGet(GetUsersByUserName, request);
    }
    async addUsers(request) {
        return await axiosPost(AddUsers, request);
    }

}