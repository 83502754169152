
import { axiosGet, axiosPost } from '../AxiosRequests';
//*** Lib */
import {
    AddServer,
    GetAllServerListing,
    GetServerById,
    UpdateServer,
    DeleteServerById
} from '../ApiEndPoints';

export default class ServersServices {
    // API function
    async addServer(request) {
        return axiosPost(AddServer, request);
    }
    async getAllServerListing(request) {
        return axiosPost(GetAllServerListing, request);
    }
    async getServerById(request) {
        return axiosGet(GetServerById, request);
    }
    async updateServer(request) {
        return axiosPost(UpdateServer, request);
    }
    async deleteServerById(request) {
        return axiosPost(DeleteServerById, request);
    }
}

