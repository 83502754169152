//** Component  */
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

//*** pages */
import Login from '../pages/authentication/Login';
import UnAuthorized from '../pages/authentication/UnAuthorized';
import Layout from '../shared/Layout';
const PageNotFound = React.lazy(() => import('../components/common/error/PageNotFound'));

const LayoutNavigation = (props) => {

    return (
        <Router >
            <React.Suspense fallback={null}>
                <Routes>
                    <Route name="Layout" path="/" element={<Layout componentRoutes={props.componentRoutes} />}>
                        {props.componentRoutes && props.componentRoutes.map((route, idx) => {
                            return route.component && (

                                <Route
                                    key={idx}
                                    path={route.path}
                                    element={
                                        <route.component authDetails={route.authDetails} authorizeUserType={route.allowrole} />
                                    } />
                            )
                        })}
                    </Route>
                    <Route exact name="Login" path="/login" element={<Login authDetails={props.authDetails} />} />
                    <Route exact path="*" name="Page Not Found" element={<PageNotFound />} />
                    <Route exact name="*" path="/UnAuthorized" element={<UnAuthorized />} />
                    <Route exact name="UnAuthorized" path="/UnAuthorized" element={<UnAuthorized />} />


                </Routes>
            </React.Suspense>
        </Router>
    )
}

export default LayoutNavigation;