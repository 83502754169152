//** Component  */
import React from 'react';
import BaseComponent from '../../shared/baseComponent';
import RequestProgressBar from '../../components/addNewOperational/RequestProgressBar'
export default class RequestDetails extends BaseComponent {

  render() {
    return (
      <>
        <RequestProgressBar />
      </>
    );
  }
}
