import { Bars } from "react-loader-spinner";
import BaseComponent from "../../shared/baseComponent";
import fileManagerDomainServices from '../../services/domainServices/fileManagerServices';
import FormFieldGroup from "../../components/UI Element/FormGrop/FormFieldGroup";
import moment from "moment";
import Button from "../../components/common/Button";

export default class FileManager extends BaseComponent {
  constructor(props) {
    super(props);
    this.fileManagerServices = new fileManagerDomainServices();
    this.state = {
      ...this.state,
      loading: true,
      setIsLoad: false,
      selectedRows: [],
      operationalFormField: this.fileManagerServices.dataItem,
      searchList: this.fileManagerServices.searchData,
      setModuleList: [],
      getList: [],
      currentPage: 1,
      itemsPerPage: 15,
      isSearchChanged: false,
      moduleListData: [],
      noRecord: false,
      searchValidation: false
    };
    this.fileManagerServices.onUpdateState = this.updateState.bind(this);
  }

  componentDidMount() {
    this.fileManagerServices.onInitService();
    this.fileManagerServices.onGetAllModuleNameList();
  }

  // Function to handle checkbox changes for multiple select
  handleCheckboxChange = (index) => {
    const { selectedRows } = this.state;
    const selectedIndex = selectedRows.indexOf(index);

    if (selectedIndex === -1) {
      this.setState({
        selectedRows: [...selectedRows, index],
      });
    } else {
      const updatedSelectedRows = [...selectedRows];
      updatedSelectedRows.splice(selectedIndex, 1);
      this.setState({
        selectedRows: updatedSelectedRows,
      });
    }
  };

  handleSelectAll = () => {
    const { getList, selectedRows } = this.state;
    if (selectedRows.length === getList.length) {
      this.setState({ selectedRows: [] });
    } else {
      // Select all rows
      const allRowsIndexes = getList.map((_, index) => index);
      this.setState({ selectedRows: allRowsIndexes });
    }
  };

  handleChange(name, value) {
    this.onResetData();
    let detail = this.state.operationalFormField;
    detail[name] = value;
    this.setState({
      operationalFormField: { ...detail },
      currentPage: 1,
      selectedRows: ""
    }, () => {
      let request = {
        moduleType: value
      }
      this.fileManagerServices.onGetModuleFileNameList(request)
    });
  }

  handlePaginationButtonClick = (increment) => {
    const { currentPage } = this.state;
    const newPage = currentPage + increment;

    if (newPage >= 1 && newPage <= Math.ceil(this.state.getList.length / this.state.itemsPerPage)) {
      // Update the current page only if it's within the valid range
      this.setState({
        currentPage: newPage,
      });
    }
  };

  handleDelete = (selectedItems) => {
    this.fileManagerServices.apiService.serviceManager.swalServices
      .Confirm("Are you sure, you want to delete the selected Files?", " ", "Yes", "No")
      .then(async (confirm) => {
        if (confirm) {
          // Loop through selected items and delete each one
          selectedItems.forEach(({ fileName }) => {
            let request = [{
              fileName: fileName,
              moduleType: this.state.operationalFormField.moduleName,
            }];
            this.fileManagerServices.onDeleteFileByModuleType(request, this.state.operationalFormField.moduleName);
            this.setState({
              selectedRows: "",
            });
          });
        }
      });

  }

  getFileIcon(fileName) {
    const extension = fileName.split('.').pop().toLowerCase();
    switch (extension) {
      case 'xlsx':
        return <img src="/xlsx.png" className="xlsx-file" alt="Excel icon" />; // Replace with your actual Excel icon
      case 'txt':
        return <img src="/txt.png" className="txt-file" alt="Text icon" />; // Replace with your actual Text icon
      case 'csv':
        return <img src="/csv.png" className="csv-file" alt="CSV icon" />; // Replace with your actual CSV icon
      default:
        // Handle default case here
        break;
    }
  }

  searchHandleChange = (name, value) => {
    let detail = this.state.searchList;
    detail[name] = value;
    this.setState({ searchList: detail });
  }
  handlePickerChange = (name, value) => {
    let detail = this.state.searchList;
    detail[name] = value;
    this.setState({ searchList: detail });
  }

  searchFilterValidation = (listData) => {
    let isValid = true;
    if (listData.endDate !== '') {
      if (listData.startDate === '') {
        isValid = false;
        this.fileManagerServices.apiService.serviceManager.swalServices.WarningToaster("Please Select Start Date ");
      }
    }
    if (listData.startDate !== '') {
      if (listData.endDate === '') {
        isValid = false;
        this.fileManagerServices.apiService.serviceManager.swalServices.WarningToaster("Please Select End Date ");
      }
    }
    return isValid;
  }

  searchFilter = () => {
    let listData = this.state.searchList;
    let isValid = true;

    if (listData.endDate !== '') {
      if (listData.startDate === '') {
        isValid = false;
        this.fileManagerServices.apiService.serviceManager.swalServices.WarningToaster("Please Select Start Date ");
      }
    }
    if (listData.startDate !== '') {
      if (listData.endDate === '') {
        isValid = false;
        this.fileManagerServices.apiService.serviceManager.swalServices.WarningToaster("Please Select End Date ");
      }
    }

    if (isValid) {
      this.setState({ loading: true });
      let filteredDateItems;
      let itemListop;

      if (listData.startDate !== '' && listData.endDate !== '') {
        listData.startDate = this.convertDate(listData.startDate);
        listData.endDate = this.convertDate(listData.endDate);
        itemListop = this.state.moduleListData.map(item => ({
          fileName: item.fileName,
          createdDate: moment(item.createdDate, 'DD-MM-YYYY').toDate()
        }));
        if (listData.startDate !== '' && listData.startDate !== 'Invalid Date' && listData.endDate !== '' && listData.endDate !== 'Invalid Date') {
          filteredDateItems = itemListop.filter(item => {
            return item.createdDate >= listData.startDate && item.createdDate <= listData.endDate;
          });
          filteredDateItems = filteredDateItems.map(item => ({
            fileName: item.fileName,
            createdDate: moment(item.createdDate).format('DD-MM-YYYY')
          }));
          if (filteredDateItems.length > 0) {
            filteredDateItems.sort((a, b) => {
              const dateA = new Date(a.createdDate);
              const dateB = new Date(b.createdDate);
              return dateB - dateA;
            });
          }
        }
      }

      if (listData.searchText !== '') {
        let data;
        if (filteredDateItems !== undefined) {
          data = this.filterValue(listData.searchText, filteredDateItems);
        } else {
          data = this.filterValue(listData.searchText, this.state.moduleListData);
        }

        if (data.length === 0) {
          this.setState({
            getList: data,
            isSearchChanged: true,
            noRecord: true
          });
        } else {
          this.setState({
            getList: data,
            isSearchChanged: true,
            noRecord: false
          });
        }

      }
      else {
        if (listData.startDate === '' && listData.endDate === '' && listData.searchText === '') {
          this.setState({
            getList: this.state.moduleListData,
            isSearchChanged: true,
            noRecord: true
          });
        } else {
          if (filteredDateItems.length === 0) {
            this.setState({
              getList: filteredDateItems,
              isSearchChanged: true,
              noRecord: true
            });
          } else {
            this.setState({
              getList: filteredDateItems,
              isSearchChanged: true,
              noRecord: false
            });
          }
        }

      }
      this.setState({ loading: false });
    }
  }

  filterValue = (value, filteredDate) => {
    return filteredDate.filter((item) => {
      return item.fileName.toLowerCase().includes(value.toLowerCase());
    });
  }
  convertDate = (date) => {
    return moment(date, 'DD-MM-YYYY').toDate();
  }

  onResetData = () => {
    let request = { ...this.fileManagerServices.searchData }
    this.setState({
      searchList: request,
      getList: this.state.moduleListData,
    });
  }

  render() {
    const { getList, currentPage, itemsPerPage, selectedRows } = this.state;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = getList.slice(indexOfFirstItem, indexOfLastItem);

    return (
      <>
        <div className="top-search-add-button-sec d-flex align-items-center">
          <div className="table-header d-flex align-items-center check-new-box">
            {this.state.getList.length > 0 && (
              <>
                <input
                  type="checkbox"
                  checked={this.state.selectedRows.length === this.state.getList.length}
                  onChange={this.handleSelectAll}
                />
                <b className="ml-2">Select All</b>
              </>
            )}
            {this.state.selectedRows.length > 0 && (
              <div className="">
                {this.state.selectedRows.length === 1 && (
                  <>
                    {/* Delete */}
                    <i className="fa-2x fa fa-trash ml-3 cursor-pointer" onClick={() => {
                      const selectedIndex = this.state.selectedRows[0];
                      const selectedFile = getList[indexOfFirstItem + selectedIndex];
                      this.handleDelete([selectedFile]);
                    }}>

                    </i>
                  </>
                )}
                {this.state.selectedRows.length > 1 && (

                  <i className="fa-2x fa fa-trash ml-3 cursor-pointer" onClick={() => {
                    const selectedItems = this.state.selectedRows.map((selectedIndex) => {
                      return getList[indexOfFirstItem + selectedIndex];
                    });
                    this.handleDelete(selectedItems);
                  }}></i>

                )}
              </div>
            )}
          </div>
          <div className="row left-searchbar-sec justify-content-end">
            {this.fileManagerServices.formFields.map((Item, index) => {
              return (
                <>
                  <FormFieldGroup
                    className="col-md-6"
                    column={Item}
                    value={this.state.operationalFormField[Item.fieldName]}
                    options={Item.Options ? Item.Options : this.state.setModuleList}
                    onChange={(event) =>
                      this.handleChange(Item.fieldName, event.target.value)
                    }
                    key={index}
                  />
                </>
              )
            })}
          </div>
        </div>
        <div>
          {getList.length > 0 || this.state.noRecord === true ?
            <>
              <div className="wrapper filemanager">
                <div className="mb-5">
                  <div className="accordion" id="accordionExample">
                    <div className="card accordion-item active">
                      <div id="accordionOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          <div className="card-body">
                            <div className="row">
                              {this.fileManagerServices.SearchformFields.map((Item, index) => {
                                return (
                                  <>
                                    <FormFieldGroup
                                      className="ms-auto"
                                      column={Item}
                                      value={this.state.searchList[Item.fieldName]}
                                      onChange={
                                        Item.inputType === 'datepicker' ? (e) => this.handlePickerChange(Item.fieldName, e) :
                                          (event) => this.searchHandleChange(Item.fieldName, event.target.value)
                                      }
                                      selectedDate={Item.inputType === 'datepicker' ? this.state.searchList[Item.fieldName] : ''}
                                      onBlur={() => this.fileManagerServices.validateField(Item.fieldName, this.state.searchList, this.state.validationRules, this.state.validState)}
                                      key={index}
                                      error={this.state.validState.error[Item.fieldName]}
                                    />
                                  </>
                                );
                              })}
                            </div>
                            <div className="addnewuser-button mb-4">
                              <button className="tab-title tab-title--active  btn-hover  color-3 active" onClick={() => { this.searchFilter() }}>Search</button>
                              <Button type='cancel' title="Clear" className="ml-2" onClick={() => this.onResetData()} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
            : ''
          }
          {(!this.state.operationalFormField.moduleName ? this.state.loading : this.state.setIsLoad) ? (
            <Bars
              className="row"
              wrapperStyle={{ marginTop: "150px" }}
              color="#3c4b64"
              height={55}
              width="100%"
              visible={this.state.setIsLoad}
            />
          ) : (
            <div className="file-manager-table">
            <div className="table-responsive text-nowrap text-shadow-pd table-container">
              <table className="table table-shadow rpa_table bg-white table-fixed">
                <thead>
                  <tr>
                    <th className="font-semibold text-left">Select</th> {/* Add a new column for checkboxes */}
                    <th className="font-semibold text-left">File Name</th>
                    <th className="font-semibold text-left">Created Date</th>
                  </tr>
                </thead>

                {this.state.getList.length === 0 ?
                  <div className="d-flex justify-content-end no-record-width mt-5 mb-5 border-top-none">
                    No Records Found
                  </div>
                  :
                  <>
                    <tbody className="table-border-bottom-0">
                      {currentItems.map((item, index) => (
                        <tr key={index} className={`bg-white ${selectedRows.includes(indexOfFirstItem + index) ? 'selected-row' : ''}`}>
                          <td className="text-left check-new-box">
                            <input
                              type="checkbox"
                              checked={selectedRows.includes(indexOfFirstItem + index)}
                              onChange={() => this.handleCheckboxChange(indexOfFirstItem + index)} />
                          </td>
                          <td className="text-left">{this.getFileIcon(item.fileName)} <span className="ml-2">{item.fileName}</span> </td>
                          {/* <td className="text-left">{item.createdDate ? moment(item.createdDate).format('DD-MM-YYYY') : 'Invalid date'}</td> */}
                          <td className="text-left">{item.createdDate ? item.createdDate : ''}</td>
                        </tr>
                      ))}
                    </tbody>
                  </>
                }

              </table>
            </div>
            </div>
          )}
          <div className="d-flex justify-content-center mt-2 align-items-center mb-5">
            <button
              onClick={() => this.handlePaginationButtonClick(-1)}
              disabled={currentPage === 1}
              type="button"
              className="page-link page-link--with-arrow pagination_svg"
              aria-label="Previous"
            >
              <svg
                className="page-link__arrow page-link__arrow--left"
                aria-hidden="true"
                width="8"
                height="13"
              >
                <path d="M7.7 12.7c-.4.4-.9.4-1.3 0L0 6.5 6.4.3c.4-.4.9-.3 1.3 0 .4.4.4 1 0 1.3l-5 4.9 5 4.9c.4.4.4 1 0 1.3z"></path>
              </svg>
            </button>
            <div className="d-flex align-items-center ml-5 mr-5">
              <input
                className="pagination-input form-control"
                type="text"
                name="currentPage"
                onChange={(event) => this.setState({ currentPage: event.target.value })}
                value={currentPage}
              />
              <span className="inline-flex items-center justify-center leading-5 text-xl mx-4 theme-color">
                Of
              </span>
              <input
                className="pagination-input form-control"
                disabled
                value={Math.ceil(getList.length / itemsPerPage)}
              />
            </div>
            <button
              type="button"
              className="page-link pagination_svg"
              aria-label="Next"
              onClick={() => this.handlePaginationButtonClick(1)}
              disabled={currentPage === Math.ceil(getList.length / itemsPerPage)}
            >
              <svg
                className="page-link__arrow page-link__arrow--right"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="13"
              >
                <path d="m.3 11.4 5-4.9-5-4.9C-.1 1.2-.1.7.3.3s.9-.4 1.3 0L8 6.5l-6.4 6.2c-.4.4-.9.3-1.3 0s-.4-.9 0-1.3z"></path>
              </svg>
            </button>
          </div>
        </div>
      </>
    );
  }
}
