import React from "react";
import BaseComponent from "../../../shared/baseComponent";
import { decryptionData } from "../../../utils/Encryption";
import RegulationDomainService from "../../../services/domainServices/regulationDomainService";
import ProgressBarStyle from "../../../styles/ProgressBarStyle";
import { Constants } from "../../../utils/Constants";
import RegulationInitiate from "./progress-flow/RegulationInitiate";
import RegulationRequest from "./progress-flow/RegulationRequest";
import RegulationLogs from "./progress-flow/RegulationLogs";
import LinkupDomainService from "../../../services/domainServices/linkupDomainService";
import { DataFormat } from "../../../utils/DateFormat";

export default class RegulationRequestDetails extends BaseComponent {
  constructor(props) {
    super(props);
    this.domainServices = new RegulationDomainService();
    this.domainServicesForRegName = new LinkupDomainService();
    this.state = {
      detailData: [],
      activeTab: Constants.RegulationRequest.RequestInitiate,
    };
    this.domainServices.onUpdateState = this.updateState.bind(this);
    this.domainServicesForRegName.onUpdateState = this.updateState.bind(this);
  }

  componentDidMount() {
    let params = new URLSearchParams(window.location.search);
    let piId = params.get("");
    this.setState({ productImportId: piId });

    const decryptKey = decryptionData(piId, false);
    // *** getByID decryption ***//
    if (decryptKey > 0) {
      this.domainServices.onGetRegulationRequestById(decryptKey);
      this.domainServicesForRegName.onGetRegulationNameList();
    }
  }

  setTab = (currentStage, currentProcess) => {
    if (currentProcess <= currentStage) {
      if (currentProcess === 1) {
        this.setState({
          activeTab: Constants.RegulationRequest.RequestInitiate,
        });
      } else if (currentProcess === 2) {
        this.setState({
          activeTab: Constants.RegulationRequest.RegulationRequest,
        });
      } else if (currentProcess === 3) {
        this.setState({ activeTab: Constants.RegulationRequest.RequestLogs });
      }
    }
  };

  render() {
    const idToFind = this.state?.detailData?.regulationId;
    const regulationName = this.state?.regulationNameList?.find(
      (item) => item.id === idToFind
    );
    return (
      <>
        <div className="card">
          <div className="card-title-main px-3 py-2">
            <div className="row align-items-center">
              <div className="col-6">
                <h5 className="titletexts font-size-16">Request : {this.state.detailData.requestName}</h5>
              </div>
            </div>
          </div>

          <div className="card-body custom-card-body">
            <div className="row">
              <div className="col-md-4 mb-3">
                <div className="inner-detail-sec">
                  <span className="detail-title">Status </span>
                  &nbsp;&nbsp;&nbsp; <span className="font-size-16">{this.state.detailData.statusName}</span>
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <div className="inner-detail-sec">
                  <span className="detail-title">Created Date </span>
                  &nbsp;&nbsp;&nbsp;
                  <span className="font-size-16"> {DataFormat(
                    this.state.detailData.createdDate,
                    "MM-DD-YYYY h:mm:ss"
                  )}</span>
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <div className="inner-detail-sec">
                  <span className="detail-title">Regulation </span>
                  &nbsp;&nbsp;&nbsp; <span className="font-size-16">{regulationName?.name ? regulationName?.name : "NA"}</span>
                </div>
              </div>
              {/* <div className="col-md-4 mb-3">
                                <div className="inner-detail-sec">
                                    <span className="detail-title">Total Counts :</span>
                                    &nbsp;&nbsp;&nbsp;{this.state.detailData.totalCounts}
                                </div>
                            </div> */}
              {/* <div className="col-md-3 mb-3">
                                <div className="inner-detail-sec">
                                    <span className="detail-title">Finish Counts :</span>
                                    &nbsp;&nbsp;&nbsp;{this.state.detailData.emptyCounts}
                                </div>
                            </div> */}
              <div className="col-md-4">
                <div className="inner-detail-sec">
                  <span className="detail-title">Error </span>
                  &nbsp;&nbsp;&nbsp; <span className="font-size-16">{this.state.detailData.error}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ProgressBarStyle />

       <div className="mt-2 pt-2">
          <div id="progress" className="mb-0">
            <div id="progress-bar"></div>
            <ul className="scrapping-timeline" id="progress-check">
              <li
                className={
                  "step " +
                  (this.state.activeTab === Constants.RegulationRequest.RequestInitiate
                    ? "step active red-color"
                    : "")
                }
                onClick={() =>
                  this.setTab(1, Constants.RegulationRequest.RequestInitiate)
                }
              >
                <div className="main-timeline-sec">
                  <div className="icon-round">
                    <i className="fa fa-hourglass-start "></i>
                  </div>
                  <label>Initiate</label>
                </div>
              </li>

              <li
                className={
                  "step " +
                  (this.state.activeTab === Constants.RegulationRequest.RegulationRequest
                    ? "step active green-color"
                    : "")
                }
                onClick={() =>
                  this.setTab(2, Constants.RegulationRequest.RegulationRequest)
                }
              >
                <div className="main-timeline-sec">
                  <div className="icon-round">
                    <i className="fa-brands fa-dropbox"></i>
                  </div>
                  <label>Regulation Request File</label>
                </div>
              </li>

              <li
                className={
                  "step " +
                  (this.state.activeTab === Constants.RegulationRequest.RequestLogs
                    ? "step active yellow-color"
                    : "")
                }
                onClick={() =>
                  this.setTab(3, Constants.RegulationRequest.RequestLogs)
                }
              >
                <div className="main-timeline-sec">
                  <div className="icon-round">
                    <i className="fa fa-link"></i>
                  </div>
                  <label>Logs</label>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div>
          {this.state.activeTab ===
            Constants.RegulationRequest.RequestInitiate ? (
            <RegulationInitiate detailData={this.state.detailData} />
          ) : this.state.activeTab ===
            Constants.RegulationRequest.RegulationRequest ? (
            <RegulationRequest detailData={this.state.detailData} />
          ) : this.state.activeTab ===
            Constants.RegulationRequest.RequestLogs ? (
            <RegulationLogs />
          ) : null}
        </div>
      </>
    );
  }
}
