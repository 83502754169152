//** Component  */
import React from "react";
import { Navigate } from "react-router";
import ButtonLoader from "../../components/common/buttonLoader/ButtonLoader";

//*** Lib */
import BaseComponent from "../../shared/baseComponent";
import FormFieldGroup from "../../components/UI Element/FormGrop/FormFieldGroup";
import AuthenticationServices from "../../services/domainServices/authenticationServices";
import { getCookie } from "../../utils/cookies/CookieHandler";

export default class Login extends BaseComponent {
  constructor(props) {
    super(props);
    this.authenticationServices = new AuthenticationServices();
    this.state = {
      ...this.state,
      redirect: "",
      setIsLoad: false,
      userDetail: [],
      centralizeLogin: false
    };
    this.authenticationServices.onUpdateState = this.updateState.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  // *** Event function **//
  handleChange = (key, value) => {
    let newUserDetails = { ...this.state.userDetail };
    newUserDetails[key] = value;
    this.setState({ userDetail: newUserDetails });
  };

  componentDidMount() {
    this.authenticationServices.onInitService();
    document.title = "Login";
    const cookieDetail = getCookie("__ssoAuth");
    if (cookieDetail && cookieDetail.token) {
      // this.setState({ centralizeLogin: true });
      this.loginViaSSO()
    }
  }
  handleKeyDown = (event) => {
    if (event.key === "Enter") {
      this.login();
    }
  };

  login = () => {
    if (this.authenticationServices.isValidSubmit(this.state.userDetail, this.state.validationRules, this.state.validState)) {
      let request = {
        userName: this.state.userDetail.userName,
        password: this.state.userDetail.password,
      };
      this.authenticationServices.onLogin(request);
    }
  };

  loginViaSSO = () => {
    const cookieDetail = getCookie("__ssoAuth");
    if (cookieDetail && cookieDetail.token) {
      let request = {
        token: cookieDetail.token
      }
      this.authenticationServices.onValidateToken(request);
    } else { }
  };

  render() {
    if (this.props.authDetails) {
      return <Navigate to={"/Dashboard"} />;
    }
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <div className="loginbody d-flex align-items-center">
        <div className="container">
          <div className="login-box-sec">
            <div className="login-box p-4">
              <div className="row">
                <div className="col-lg-12 login-key">
                  <img
                    alt=""
                    className="logo img-fluid p-1"
                    src="./2886365.png"
                  />
                </div>
                <div className="col-lg-12 login-title mt-4">
                  Connecting to RPA Web
                </div>
                <div className="col-lg-12 login-form">
                  <div className="col-lg-12 login-form">
                    {!this.state.centralizeLogin ?
                      this.authenticationServices.formFields.map(
                        (Item, index) => {
                          return (
                            <>
                              <FormFieldGroup
                                column={Item}
                                value={this.state.userDetail[Item.fieldName]}
                                error={
                                  this.state.validState.error[Item.fieldName]
                                }
                                onChange={(event) =>
                                  this.handleChange(
                                    Item.fieldName,
                                    event.target.value
                                  )
                                }
                                onBlur={() =>
                                  this.authenticationServices.validateField(
                                    Item.fieldName,
                                    this.state.userDetail,
                                    this.state.validationRules,
                                    this.state.validState
                                  )
                                }
                                onKeyDown={(e) => this.handleKeyDown(e)}
                                key={index}
                                onKeyPress={
                                  this.authenticationServices.loginOnEnter
                                }
                              />
                            </>
                          );
                        }
                      )
                      : null}
                    <div className="col-lg-12">
                      <div className=" login-btm login-button mx-auto">
                        {this.state.centralizeLogin ?
                          <button type="submit" className="btn-hover color-3 active" onClick={() => this.loginViaSSO()}>
                            {" "}{this.state.setIsLoad ? (<ButtonLoader />) : (<>Login Via CS</>)}{" "}
                          </button> :
                          <button type="submit" className="btn-hover color-3 active" onClick={() => this.login()}>
                            {" "}{this.state.setIsLoad ? (<ButtonLoader />) : (<>Login</>)}{" "}
                          </button>
                        }
                      </div>
                    </div>
                    <div className="col-lg-12 login-btm login-text d-flex justify-content-center mt-5 mb-0">
                      Copyright &#64; {new Date().getFullYear()}&nbsp;
                      <span> Echem Portal</span>
                      &nbsp;All Right Reserved.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
