import APISererviceManager from "../../libs/apiSererviceManager";
import { getAuthProps } from "../../utils/AuthenticationLibrary";
import { isValidForm, validate } from "../../utils/validation/CommonValidator";
export default class BaseDomainService {
    pagination = {
        pageNo: 1,
        pageSize: 25,
        orderByColumn: "CreatedDate",
        orderFlag: 1,
        searchText: "",
    };

    apiService;
    serviceManager;
    authUser;
    onUpdateState;
    onActionCall;
    onPaginationUpdate;
    actionKey;
    regulationActionKey
    activeCheck;

    constructor() {
        this.apiService = new APISererviceManager();
        this.authUser = getAuthProps();
    }

    // Validation
    validateField = (key, data, rules, currentState) => {
        const isValidData = validate(key, data, rules, currentState);
        this.onUpdateState({ validState: isValidData });
    }
    isValidSubmit = (data, rules, currentState) => {
        const isValidData = isValidForm(data, rules, currentState);
        if (data.moduleName === '') {
            this.onUpdateState({ validState: isValidData });
            return isValidData.isValid = false;
        } else if (data.catalogCleanerVersionId === "" || data.cleanerVersionId === "") {
            this.onUpdateState({ validState: isValidData });
            return isValidData.isValid = true;
        } else {
            this.onUpdateState({ validState: isValidData });
            return isValidData.isValid;
        }
    }
    //Redirect to other page
    onRedirect = (redirectUrl) => {
        this.onUpdateState({ redirect: redirectUrl });
    }
    // Pagination Metods
    onPageChange = (newPagination) => {
        this.pagination = newPagination;
        this.onUpdateState({ "pagination": newPagination }, () => {
            this.onPaginationUpdate(this.pagination);
        });
    }
    // Reset the validateion
    resetValid = () => {
        let resetValidation = {
            ...this.state.validState,
            isValid: false,
            error: {}
        }
        this.updateState({ validState: resetValidation })
    }

    gridActionsCol = (element, index, value, isDeleteRequired = false) => {
        return (element !== this.actionKey) ? null
            :
            <td className="" >
                <i className="fa fa-pencil cursor-pointer actions-icon" onClick={() => this.onActionCall("Edit", value)}></i>
                {isDeleteRequired === true ?
                    <i class="fa fa-trash cursor-pointer ms-3 actions-icon" aria-hidden="true"
                        onClick={() => this.onActionCall("Delete", value)}>
                    </i>
                    : null}
            </td>
    }

    gridSyncDataActionsCol = (element, index, value, isDeleteRequired = false) => {
        return (element !== this.actionKey) ? null
            :
            <td className="" >
               <i className="fa fa-eye cursor-pointer actions-icon" onClick={() => this.onActionCall("View", value)}></i>
                {isDeleteRequired === true ?
                    <i class="fa fa-trash cursor-pointer ms-3 actions-icon" aria-hidden="true"
                        onClick={() => this.onActionCall("Delete", value)}>
                    </i>
                    : null}
            </td>
    }

    gridActionsViewCol = (element, index, value, isDeleteRequired = false) => {
        return (element !== this.actionKey) ? null
            :
            <td className="">
                <i className="fa fa-eye cursor-pointer actions-icon" onClick={() => this.onActionCall("View", value)}></i>
            </td>
    }
    gridRegulationActionsViewCol = (element, index, value, isDeleteRequired = false) => {
        return (element !== this.regulationActionKey) ? null
            :
            <td className="" >
                <i className="fa fa-eye cursor-pointer actions-icon" onClick={() => this.onActionCall("View", value)}></i>
            </td>
    }

    gridCheckBoxCol = (element, index, value) => {
        return (element !== this.actionActive) ? null
            : <td className="text-left">
                <div className="form-check">
                    <input disabled className="form-check-input " style={{ position: 'relative' }} type="checkbox" id="inlineFormCheck" checked={value} />
                </div>
            </td>
    }
    gridActionErrorCols = (element, index, value) => {
        return element !== "errorLogId" ? null : (
            <td className="">
                <i className="fa fa-eye cursor-pointer actions-icon" onClick={() => this.onActionCall("View", value)}></i>
            </td>
        );
    };
    gridRequestTypeCol = (element, index, value) => {
        return (element !== this.actionRequestType) ? null
            : <td>
                <div className="dropdown">
                    <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                        <i className="bx bx-dots-vertical-rounded actions-icon"></i>
                    </button>
                    <div className="dropdown-menu">
                        <div className="dropdown-item" onClick={() => this.onActionCall("View", value)}> View </div>
                    </div>
                </div>
            </td>
    }

    gridActionRequestStatusCol = (element, index, value) => {
        return element !== this.actionKey ? null : (
            <td className="">
                <div className="d-flex align-items-center gap-3">
                    <i className="fa fa-pencil cursor-pointer actions-icon" onClick={() => this.onActionCall("Edit", value)}></i>
                    <i className="fa fa-chain-broken cursor-pointer actions-icon" onClick={() => this.onActionCall("Update", value)}></i>
                </div>
            </td>
        );
    };

    gridDownloadCol = (element, index, value, isDeleteRequired = false, tableData) => {
        // let active = tableData.find(x => x.isActive == true);
        return (element !== this.actionId) ? null
            : <td className="text-left ml d-flex ">

                <div className="form-check">
                    <i className="fa fa-download actions-icon" style={{ cursor: 'pointer' }} aria-hidden="true"
                        onClick={() => this.onActionCall("download", value)}></i>
                </div>
                <div className="form-check">
                    <i class="fa fa-trash actions-icon" aria-hidden="true"
                        onClick={() => this.onActionCall("Delete", value)}></i>
                </div>
                <div className="form-check">
                    <input className="form-check-input ml-0" style={{ position: 'relative' }}
                        // checked={active}
                        onChange={(event) => this.onActionCall("active", event.target.checked, index, value)}
                        type="checkbox" id="inlineFormCheck" />
                </div>
            </td>
    }

    gridCountsAndDownloadCol = (element, index, value, isDeleteRequired = false, regulationData) => {
        let pdata = regulationData.map((item) => ({
            productDetailCounts: item.productDetailCounts
        }))
        return (element !== this.actionCounts) ? null : (
            <td className="text-left d-flex align-items-center" style={{ cursor: 'pointer' }}>
                <span> {pdata[index].productDetailCounts}</span>
                {pdata[index].productDetailCounts > 0 ?
                    <div className="form-check">
                        <i className="fa fa-download actions-icon" style={{ cursor: 'pointer' }} aria-hidden="true" onClick={() => this.onActionCall("download", value)}></i>
                        <i className="fa fa-eye actions-icon ml-5" style={{ cursor: 'pointer' }} aria-hidden="true" onClick={() => this.onActionCall("viewfile", value)}></i>
                    </div>
                    : null
                }
            </td>
        );
    }

}
