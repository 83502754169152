import { axiosGet, axiosGetMultiParams, axiosPost } from "../AxiosRequests";
import {AddMiddlewareGroupRequest, GetMiddlewareGroupRequestById, GetMiddlewareGroupRequestList , GetLogsById} from '../ApiEndPoints';

export default class RPARequestGroupServices {
    async getMiddlewareGroupRequestList(request) {
        return axiosPost(GetMiddlewareGroupRequestList, request);
    }
    async getMiddlewareGroupRequestById(request) {
        return axiosGet(GetMiddlewareGroupRequestById, request);
    }
    async addMiddlewareGroupRequest(request) {
        return axiosPost(AddMiddlewareGroupRequest, request);
    }
    async getLogsById(request) {
        return axiosGetMultiParams(GetLogsById, request);
    }
}