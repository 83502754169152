//** Component  */
import React from 'react';
import { Bars } from 'react-loader-spinner';

//*** Lib */
import BaseComponent from '../../../shared/baseComponent';
import { decryptionData } from '../../../utils/Encryption';
import TabProgressBarStyle from '../../../styles/TabProgressBarStyle';

//** Services */
import LinkupDomainService from '../../../services/domainServices/linkupDomainService'
import { DataFormat } from '../../../utils/DateFormat';


export default class LinkupRequestLogs extends BaseComponent {

    constructor(props) {
        super(props)
        this.domainServices = new LinkupDomainService();

        this.domainServices.onUpdateState = this.updateState.bind(this);
        this.state = {
            logsList: [],
            dataId: 0,
            setIsLoad: false,
        }
    }

    //** Constructor  and Component method */
    componentDidMount() {
        let params = new URLSearchParams(window.location.search);
        let id = params.get("");
        this.setState({ dataId: id })
        const decryptKey = decryptionData(id, false);
        if (decryptKey > 0) {
            let req = [
                decryptKey,
                'RegulationLinkupId'
            ]
            this.domainServices.onGetLogsById(req);
        }
    }

    getlog() {
        if (this.state.dataId > 0) {
            let req = [
                this.state.dataId,
                'RegulationLinkupId'
            ]
            this.domainServices.onGetLogsById(req);
        }
    }

    render() {
        return (
            <>
                <TabProgressBarStyle />
                <div className="mb-4">
                    <div className="card-body custom-card-body-section main-card-sections pb-2">
                        <div className='card-title-main px-3 py-2 d-flex justify-content-between'>
                            <h6 className='titletexts mb-0'> Logs </h6>
                            <p className="text-white me-2 mb-0">
                                <i className="fa fa-refresh" title="Refresh Logs" onClick={() => this.getlog()}></i>
                            </p>
                        </div>
                        <div className='scroll_content position_inherit'>
                            <div className='mb-4 px-3'>
                                {
                                    (this.state.setIsLoad)
                                        ?
                                        <Bars
                                            className="row"
                                            wrapperStyle={{ margin: '100px 0' }}
                                            color="#3c4b64"
                                            height={55}
                                            width="100%"
                                            visible={this.state.setIsLoad}
                                        />
                                        :
                                        <div className="timeline-sec">
                                            {this.state.logsList ? this.state.logsList.map((add, index) => (
                                                <div className="accordion fixed_width_content" id="accordionExample" key={index}>
                                                    <div>
                                                        {add.logType === 'Info' || add.logType === 'INFO' ?
                                                            <>
                                                                <div className="marker main-big-icon">
                                                                    <i className="fa fa-info-circle timecolor icon-margin-log" aria-hidden="true"></i>
                                                                </div>
                                                                <div className="card accordion-item log-sec active p-3 card-margin-log">
                                                                    <div className="d-flex align-items-start">
                                                                        <div className='d-flex align-items-center moment-title'>
                                                                            <h5 className='d-flex'><span><strong>{add.componentName} </strong></span><span>&#8658;</span> </h5>
                                                                            <label className='d-flex'><strong className="align-items-end-date-time"> {DataFormat(
                                                                                add.logDateTime
                                                                            )}</strong></label>
                                                                        </div>
                                                                        <div className="moment-log d-flex align-items-start">
                                                                            :&nbsp;&nbsp;<p>{add.logs} </p>
                                                                        </div>
                                                                    </div>
                                                                    {add.otherDetails ? <div className='others-desc w-100'>{add.otherDetails}</div> : null}
                                                                </div>
                                                            </>
                                                            : null}

                                                        {add.logType === 'Error' || add.logType === 'ERROR' ?
                                                            <>
                                                                <div className="marker main-big-icon">
                                                                    <i className="fa fa-times-circle timecolor1 icon-margin-log" aria-hidden="true"></i>

                                                                </div>
                                                                <div className="card accordion-item log-sec active p-3 card-margin-log">

                                                                    <div className="d-flex align-items-start">
                                                                        <div className='d-flex align-items-center moment-title'>
                                                                            <h5 className='d-flex'><span><strong>{add.componentName} </strong></span><span>&#8658;</span> </h5>
                                                                            <label className='d-flex'><strong className="align-items-end-date-time"> {DataFormat(
                                                                                add.logDateTime
                                                                            )}</strong></label>
                                                                        </div>
                                                                        <div className="moment-log d-flex align-items-start">
                                                                            :&nbsp;&nbsp;<p>{add.logs} </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='others-desc w-100'>
                                                                        <span>{add.exception} </span>
                                                                    </div>
                                                                    {add.otherDetails ?
                                                                        <div className='others-desc w-100'>
                                                                            {add.otherDetails}
                                                                        </div>
                                                                        : null
                                                                    }
                                                                </div>
                                                            </>
                                                            : null}

                                                        {add.logType === 'Result' || add.logType === 'RESULT' ?
                                                            <>
                                                                <div className="marker main-big-icon">
                                                                    <i className="fa fa-check-circle fa-circle-icon timecolor2 icon-margin-log" aria-hidden="true"></i>

                                                                </div>
                                                                <div className="card accordion-item log-sec active p-3 card-margin-log">

                                                                    <div className="d-flex align-items-start">
                                                                        <div className='d-flex align-items-center moment-title'>
                                                                            <h5 className='d-flex'><span><strong>{add.componentName} </strong></span><span>&#8658;</span> </h5>
                                                                            <label className='d-flex'><strong className="align-items-end-date-time"> {DataFormat(
                                                                                add.logDateTime
                                                                            )}</strong></label>
                                                                        </div>
                                                                        <div className="moment-log d-flex align-items-start">
                                                                            :&nbsp;&nbsp;<p>{add.logs} </p>
                                                                        </div>
                                                                    </div>
                                                                    {add.otherDetails ?
                                                                        <div className='others-desc w-100'>
                                                                            {add.otherDetails}
                                                                        </div>
                                                                        : null
                                                                    }
                                                                </div>
                                                            </>
                                                            : null}
                                                    </div>
                                                </div>
                                            )) : null}
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}

