import { DataFormat } from "../../utils/DateFormat";
import { ErrorMessage, SuccessMessage } from "../../utils/Messages";
import { getValidationRuleList } from "../../utils/validation/generateValidationRule";
import BaseDomainService from "./baseDomainServices";

const requestDetail = {
  moduleId: "",
  requestName: "",
  supplierID: "",
  isActive: true,
  status: "",
  inputFile: "",
  isCleaner: false,
  stageId: 2,
  serverId: "",
  serverName: "",
  isError: false,
  isDone: false,
  isScrapping: false,
  isDBUpdates: false,
  isCatalogCleaner: false,
  requestType: "0",
  supplierDisplayName: "",
  search: "",
  stagePartialId: "",
  subTypeId: "",
  isTor: false,
  hostServerId: ""
};

export default class RequestDomainServices extends BaseDomainService {

  constructor() {
    super();
    this.onPaginationUpdate = this.onGetRequests.bind(this);
    this.actionKey = "middlewareRequestId"
    this.dataItem = { ...requestDetail }
  }

  // API Get
  getList = this.apiService.manageOperationalServices.getAllRunningRequestList;
  updateStatus =
    this.apiService.manageOperationalServices.updateRequestStatusOnHold;
  getModuleList =
    this.apiService.manageOperationalServices.getAllOperationalModuleList;
  getAllOperationalModuleListById =
    this.apiService.manageOperationalServices.getAllOperationalModuleListById;
  add = this.apiService.middlewareRequest.addMiddlewareRequest;
  getServerList = this.apiService.manageOperationalServices.getAllServerList;
  getServerConfigurationsById =
    this.apiService.serverConfigurationsServices.getServerConfigurationsById;
  getById = this.apiService.manageOperationalServices.getMiddlewareRequestById;
  getCountById = this.apiService.manageOperationalServices.getAllFileCountsByMiddlewareId;
  getLogById = this.apiService.logServices.getAllLogsById;
  // getById = this.apiService.manageOperationalServices.detMiddlewareRequestById;
  getDataImportingById =
    this.apiService.manageOperationalServices.getRequestDetailByMiddlewareId;
  getAllServerList = this.apiService.serverConfigurationsServices.getAllServerListing;
  getPartialRequestPriceOutput = this.apiService.manageOperationalServices.getPartialPriceOutput;

  //Table Grid columns
  gridColmns = [
    { name: "requestName", title: "Request Name" },
    { name: "moduleName", title: "Module Name" },
    { name: "importType", title: "Sub Type" },
    { name: "createdDate", title: "Created Date" },
    { name: "endDate", title: "End Date" },
    { name: "statusName", title: "Status" },
    { name: "requestType", title: "Request Type " },
    { name: "stageName", title: "Stages" },
    { name: "middlewareRequestId", title: "Actions" },
  ];

  // Define the state variables
  dataItem;
  moduleList;
  serverList = [];

  // define the form fields

  PartialRequestformFields = [
    {
      inputType: "select",
      tittle: "Server Name",
      placeholder: "Select Server Name",
      fieldName: "hostServerId",
      options: [],
      optionFieldName: "hostServerId",
      className: 'col-md-6',
      subRequest: "1",
      validation: [{ type: "require" }]
    },
    {
      inputType: "select",
      tittle: "Stage Name",
      placeholder: "Stage Name",
      fieldName: "stagePartialId",
      className: 'col-md-6 PartielStage',
      options: [
        { name: "Cleaner", id: "3" },
        { name: "Data Upload", id: "4" },
      ],
      validation: [{ type: "require" }]
    },
    {
      inputType: "file",
      tittle: "Input File",
      placeholder: "Input File",
      fieldName: "inputFile",
      subRequest: "1",
      className: 'col-md-6 PartielFile mt-3',
      validation: [{ type: "require" }]
    },
    {
      inputType: "select",
      tittle: "Sub Type ",
      placeholder: "Sub Type",
      fieldName: "subTypeId",
      className: 'col-md-6 PartielSubtype mt-3',
      hidden: true,
      validation: [{ type: "require" }],
      options: [
        // { name: "All", id: "0" },
        { name: "Mol-Product", id: "4" },
        { name: "Product", id: "1" },
        { name: "Price", id: "3" },
        { name: "Stock", id: "2" },

      ],
    },


  ]
  formFields = [
    {
      inputType: "text",
      tittle: "Request Name",
      placeholder: "Request Name",
      fieldName: "requestName",
      subRequest: "1",
      className: 'col-md-6',
      validation: [{ type: "require" }]
    },
    {
      inputType: "select",
      tittle: "Module Name",
      placeholder: "Module Name",
      fieldName: "moduleId",
      options: [],
      optionFieldName: "moduleName",
      subRequest: "1",
      className: 'col-md-6',
      validation: [{ type: "require" }]
    },
    {
      inputType: "file",
      tittle: "Input File",
      placeholder: "Input File",
      fieldName: "inputFile",
      subRequest: "1",
      partialrequest: true,
      className: 'col-md-6',
      validation: [{ type: "require" }]
    },
    {
      inputType: "select",
      tittle: "Server Name",
      placeholder: "Select Server Name",
      fieldName: "hostServerId",
      options: [],
      optionFieldName: "hostServerId",
      className: 'col-md-6',
      subRequest: "1",
      partialrequest: true,
      validation: [{ type: "require" }]
    },

    {
      inputType: "text",
      tittle: "Supplier ID ",
      placeholder: "SupplierID",
      fieldName: "supplierID",
      visible: false,
      hidden: true,
      subRequest: "1",
      className: 'col-md-6'
    },
    {
      inputType: "text",
      tittle: "Supplier Name ",
      placeholder: "SupplierName",
      fieldName: "supplierDisplayName",
      visible: false,
      hidden: true,
      subRequest: "1",
      className: 'col-md-6 '
    },

    {
      inputType: "checkbox",
      tittle: "Has Scrapping Configure?",
      fieldName: "isScrapping",
      checked: false,
      disabled: true,
      defaultChecked: false,
      subRequest: "1",
      partialrequest: true,
      className: 'col-md-4 mt-2'
    },
    {
      inputType: "checkbox",
      tittle: "Has Cleaner Configure?",
      fieldName: "isCleaner",
      checked: false,
      disabled: true,
      defaultChecked: false,
      subRequest: "1",
      partialrequest: true,
      className: 'col-md-4  mt-2'
    },
    {
      inputType: "checkbox",
      tittle: "Has CatalogCleaner Configure?",
      fieldName: "isCatalogCleaner",
      checked: false,
      defaultChecked: true,
      subRequest: "2",
      className: 'col-md-4 '
    },
    {
      inputType: "checkbox",
      tittle: " Has DBUpdates Configure?",
      fieldName: "isDBUpdates",
      checked: false,
      subRequest: "1",
      partialrequest: true,
      className: 'col-md-4 '
    },
    {
      inputType: "checkbox",
      tittle: " Enable Tor Request?",
      fieldName: "isTor",
      checked: false,
      subRequest: "1",
      tor: true,
      partialrequest: true,
      className: 'col-md-4'
    },
    {
      inputType: "select",
      tittle: "",
      placeholder: "",
      fieldName: "requestType",
      isDefault: true,
      Options: [
        { name: "Link Update", id: "1" },
        { name: "Catalog Update", id: "2" },
      ],
      Option: [
        { name: "All", id: "0" },
        { name: "Link Update", id: "1" },
        { name: "Catalog Update", id: "2" },
        { name: "Partial Update", id: "3" },

      ],
      dropDown: "1",
      className: 'col-md-4 link-update'
    },
    {
      inputType: "text",
      tittle: "",
      placeholder: "Search ...",
      fieldName: "searchText",
      mode: 1,
      className: 'col-md-4 mb-0 link-update'
    },
  ];
  requestDetailForm = [
    // {
    //   inputType: "label",
    //   tittle: "Name",
    //   fieldName: "requestName",
    //   className: 'col-md-4 detail-desc',
    // },
    {
      inputType: "label",
      tittle: "Supplier",
      fieldName: "supplierDisplayName",
      className: 'col-md-4 detail-desc ',
    },

    {
      inputType: "label",
      tittle: "Module",
      fieldName: "moduleName",
      className: 'col-md-4 detail-desc',
    },
    {
      inputType: "checkbox",
      tittle: "Has Cleaner Configure?",
      fieldName: "isCleaner",
      checked: true,
      className: 'col-md-4 detail-desc d-flex align-items-center font-size-16',
      isShow: true,
    },
    {
      inputType: "label",
      tittle: "Server",
      fieldName: "hostServerName",
      className: 'col-md-4 detail-desc',
    },
    {
      inputType: "label",
      tittle: "Client",
      fieldName: "serverName",
      className: 'col-md-4 detail-desc',
    },
    {
      inputType: "checkbox",
      tittle: "Has Scrapping Configure?",
      fieldName: "isScrapping",
      checked: true,
      className: 'col-md-4 detail-desc d-flex align-items-center font-size-16',
      isShow: true,
    },
    {
      inputType: "label",
      tittle: "IP Address",
      fieldName: "serverIP",
      className: 'col-md-4 detail-desc',
    },
    {
      inputType: "label",
      tittle: "Status",
      fieldName: "statusName",
      className: 'col-md-4 detail-desc',
    },
    {
      inputType: "checkbox",
      tittle: "Has DBUpdates Configure?",
      fieldName: "isDBUpdates",
      checked: true,
      className: 'col-md-4 detail-desc d-flex align-items-center font-size-16',
      isShow: true,
    },
    {
      inputType: "label",
      tittle: "Group Name",
      fieldName: "requestGroupName",
      className: 'col-md-4 detail-desc cursor-pointer',
      Action: true,
      Data: ""
    },
    {
      inputType: "checkbox",
      tittle: "Has Enable Tor?",
      fieldName: "isTor",
      checked: true,
      className: 'col-md-4 detail-desc d-flex align-items-center font-size-16',
      isShow: true,
    },
    {
      inputType: "checkbox",
      tittle: "Has CatalogCleaner Configure?",
      fieldName: "isCatalogCleaner",
      checked: true,
      className: 'col-md-4 detail-desc d-flex align-items-center font-size-16',
      isShow: true,
    },


  ]

  // Validation

  validationRules = {};
  validationRulesForType = {};
  resetValidationRule = {
    stagePartialId: [
      {
        type: "require",
        message: ErrorMessage.FieldRequired.replace("{0}", "add option"),
      },
    ],
    subTypeId: [
      {
        type: "require",
        message: ErrorMessage.FieldRequired.replace("{0}", "add option"),
      },
    ],
  };


  //** API Calls

  onGetRequests = (requst) => {
    this.apiService.onAPICall(
      "getRequest",
      this.getList,
      requst,
      this.onAPIResponse, this.onUpdateState
    );
  };

  onAdd = (request) => {

    this.apiService.onAPICall(
      "addRequestDetails",
      this.add,
      request,
      this.onAPIResponse, this.onUpdateState
    );
  };

  onUpdateStatus = (requst) => {
    this.apiService.onAPICall(
      "updateStatus",
      this.updateStatus,
      requst,
      this.onAPIResponse, this.onUpdateState
    );
  };

  onGetModuleList = (requst) => {
    this.apiService.onAPICall(
      "getModuleList",
      this.getModuleList,
      requst,
      this.onAPIResponse, this.onUpdateState
    );
  };
  onGetModuleById = (requst) => {
    this.apiService.onAPICall(
      "getAllOperationalModuleListById",
      this.getAllOperationalModuleListById,
      requst,
      this.onAPIResponse, this.onUpdateState
    );
  };
  onGetServerList = (requst) => {
    this.apiService.onAPICall(
      "getServerList",
      this.getServerList,
      requst,
      this.onAPIResponse, this.onUpdateState
    );
  };
  onGetServerConfigurationsById = (requst) => {
    this.apiService.onAPICall(
      "getServerConfigurationsById",
      this.getServerConfigurationsById,
      requst,
      this.onAPIResponse, this.onUpdateState
    );
  };
  onGetById = (requst) => {
    this.apiService.onAPICall("getById", this.getById, requst, this.onAPIResponse, this.onUpdateState);
  };
  onGettingCountById = (requst) => {
    this.apiService.onAPICall("getCountById", this.getCountById, requst, this.onAPIResponse, this.onUpdateState)
  };
  onGetlogById = (requst) => {
    this.apiService.onAPICall("getlogById", this.getLogById, requst, this.onAPIResponse, this.onUpdateState)
  };
  onGetDataImportingById = (requst) => {
    this.apiService.onAPICall("getDataImportingById", this.getDataImportingById, requst, this.onAPIResponse, this.onUpdateState)
  };
  onGetAllServerList = (requst) => {
    this.apiService.onAPICall("getAllServerList", this.getAllServerList, requst, this.onAPIResponse, this.onUpdateState);
  }
  onGetPartialPriceOutput = (requst) => {
    this.apiService.onAPICall("getPartialPriceOutput", this.getPartialRequestPriceOutput, requst, this.onAPIResponse, this.onUpdateState);
  }

  //** Service Event */

  onInitService() {
    //This method only call when we use form component and we can add all items which we on on form load

    let valRule = getValidationRuleList(this.formFields);
    let valRules = getValidationRuleList(this.PartialRequestformFields);
    this.validationRules = valRule;
    this.validationRulesForType = valRule

    this.onUpdateState({
      "validationRules": valRule,
      "validationRulesForType": valRules,
      "resetValidationRule": this.resetValidationRule,
    });
  }

  // onInitServicePartial() {
  //   //This method only call when we use form component and we can add all items which we on on form load

  //   let valRule = getValidationRuleList(this.PartialRequestformFields);
  //   this.validationRules = valRule;

  //   this.onUpdateState({
  //     validationRules: valRule,
  //     resetValidationRule: this.resetValidationRule,
  //   });
  // }

  onResetData = () => {
    this.dataItem = { ...requestDetail };
    this.onUpdateState({ addRequestDetails: this.dataItem });
  };
  //** Methods     */
  //  When we get response from  API

  onRedirectoList = () => {
    this.onRedirect('/RPARequest');
  };

  onAPIResponse = (key, isSuccess, responseData) => {
    this.onUpdateState({ setIsLoad: false });
    if (!isSuccess) {
      this.onUpdateState({ setIsLoad: false });
      return;
    }
    switch (key) {
      case "getRequest":
        if (responseData.itemList !== null) {
          let itemList = responseData.itemList;
          itemList.forEach(function (i) {
            i.createdDate = DataFormat(
              i.createdDate
            );
            i.importType = i.importType ? i.importType : "-";
            i.endDate = i.endDate ? DataFormat(i.endDate) : "-";
            i.requestType =
              i.requestType === 1 ? "LinkUpdate" :
                i.requestType === 2 ? "CatalogUpdate" :
                  i.requestType === 3 ? "PartialUpdate" : null

            return { ...itemList, subType: itemList.subType }
          });

        }
        this.onUpdateState({
          dataList: responseData.itemList,
          TotalCount: responseData.totalCount,
          setIsLoad: false,
        });
        break;

      case "updateStatus":
        this.apiService.serviceManager.swalServices.Toaster("Updated Status");
        break;

      case "getModuleList":
        let moduleList = responseData;
        let moduleListData = moduleList.filter(a => a.isCatalogCleaner === true);
        let moduleListByLinkUpdate = moduleList.filter(a => a.isCleaner || a.isScrapping === true);
        let moduleDataList = moduleListByLinkUpdate?.map((data) => ({
          id: data.moduleId,
          name: data.moduleName,
        }));
        let moduleDataLinkUpdate = moduleListData?.map((data) => ({
          id: data.moduleId,
          name: data.moduleName,
        }));
        let moduleDataUpdate = moduleList?.map((data) => ({
          id: data.moduleId,
          name: data.moduleName,
        }));
        this.onUpdateState({
          setModuleList: moduleDataList,
          setModuleListData: moduleDataLinkUpdate,
          moduleLists: moduleDataUpdate
        });
        break;

      case "getServerList":
        let serverList = responseData;
        let serverDataVPN = serverList.filter(a => a.isVPN === true);
        let serverDataList = serverList?.map((data) => ({
          id: data.serverId,
          name: data.serverName,
        }));
        let serverListIsVPN = serverDataVPN?.map((data) => ({
          id: data.serverId,
          name: data.serverName,
        }));
        this.onUpdateState({
          setServerList: serverDataList,
          setServerListIsVPN: serverListIsVPN,

        });
        break;

      case "addRequestDetails":
        if (responseData.errorType === "EXIST") {
          this.apiService.serviceManager.swalServices.Alert(ErrorMessage.Already_Exist.replace("{0}", "Request"));

        } else {
          if (responseData.errorType === "File Not Save" && responseData.hasError === true
          ) {
            this.apiService.serviceManager.swalServices.Alert(ErrorMessage.NoRecordFound.replace("{0}", "for request"));
          } else {
            if (responseData.errorType === "File Already Exist") {
              this.apiService.serviceManager.swalServices.Alert(ErrorMessage.Already_Exist.replace("{0}", "File"));
            }
            if (responseData.errorType === "EXIST") {
              this.apiService.serviceManager.swalServices.Alert(ErrorMessage.Already_Exist.replace("{0}", "This Component Request"));
            } else {
              this.onUpdateState({ "setIsLoad": false });
              this.apiService.serviceManager.swalServices.Success(SuccessMessage.Add_Success.replace("{0}", "This Component Request"));
              this.onRedirectoList();
            }
          }
        }
        break;

      case "getServerConfigurationsById":
        if (responseData !== null) {
          this.onUpdateState({ dataList: responseData.serverId });
        }
        break;

      case "getById":
        if (responseData !== null) {
          responseData.serverName = responseData.serverName ? responseData.serverName : "NA"
          responseData.hostServerName = responseData.hostServerName ? responseData.hostServerName : "NA"
          responseData.serverIP = responseData.serverIP ? responseData.serverIP : "NA"
          responseData.moduleName = responseData.moduleName ? responseData.moduleName : "NA"
          responseData.supplierDisplayName = responseData.supplierDisplayName ? responseData.supplierDisplayName : "NA"
          responseData.requestName = responseData.requestName ? responseData.requestName : "NA"
          responseData.isGroupConfigure = responseData.isGroupConfigure

          let det = this.requestDetailForm
          let x1 = det.find(field => field.fieldName === "requestGroupName");
          if (x1) {
            x1.Data = responseData.middlewareRequestGroupId || "";
          }
          this.onUpdateState({
            productsDetails: responseData,
            setIsLoad: false,
          });
          if (responseData?.subType === 3) {
            this.onGetPartialPriceOutput(responseData?.middlewareRequestId);
          }
        }

        break;
      case "getPartialPriceOutput":
        if (responseData !== null) {
          this.onUpdateState({
            priceOutputFileList: responseData
          });
        }
        this.onUpdateState({
          setIsLoad: false
        });
        break;
      case "getCountById":
        if (responseData !== null) {
          this.onUpdateState({
            productsCounts: responseData,
            setIsLoad: false
          });
        }
        break;
      case "getlogById":
        this.onUpdateState({
          logsList: responseData,
          setIsLoad: false,
        });
        break;

      case "getDataImportingById":
        this.onUpdateState({
          "dataImportCount": responseData,
          "setIsLoad": false
        });
        break;
      case "getAllServerList":
        if (responseData !== null) {
          let serverList = responseData.itemList;
          let serverData = serverList?.map((data) => ({
            id: data.hostServerId,
            name: `${data.serverName}  -  ${data.serverIp}`,
            serverIp: data.serverIp,
          }));
          this.onUpdateState({
            "serverOptionList": serverData,
            "setIsLoad": false
          });
        }
        break;
      default:
        // Handle default case here
        break;
    }
  };
}
