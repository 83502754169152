//** Component  */
import React from "react";

//** Services */
import BaseComponent from "../../shared/baseComponent";

//*** Lib */
import { decryptionData } from "../../utils/Encryption";
import RequestGroupDomainServices from "../../services/domainServices/requestGroupServices";
import { DownloadInputFile } from "../../services/axiosServices/ApiEndPoints";
import { DataFormat } from "../../utils/DateFormat";
import { Navigate } from 'react-router';
import ScrappingReq from "../../pages/requestOPM/ScrappingReq"
import CleaningReq from "../../pages/requestOPM/CleaningReq"
import RequestOperationalModuleListTab from "../../styles/RequestOperationalModuleListTab";
import { Constants } from "../../utils/Constants";
import axios from "axios";
import { Bars } from "react-loader-spinner";
import { Modal } from "react-bootstrap";

export default class RPARequestGroupDetails extends BaseComponent {
    constructor(props) {
        super(props);
        this.requestGroupServices = new RequestGroupDomainServices();
        this.state = {
            setIsLoad: false,
            activeTab: Constants.RequestDetailsGroup.Scrapping,
            dataList: [],
            OutPutFile: [],
            scrapperData: [],
            cleannerDataUpload: [],
            middlewareRequestGroupId: 0,
            errorCount: [],
            scarpCount: [],
            activeIndex: null,
            logsList: [],
            showModal: false,
            fileToView: null,
            fileContent: null,
            setIsLoad: false
        };
        this.requestGroupServices.onUpdateState = this.updateState.bind(this);
    }

    componentDidMount() {
        let params = new URLSearchParams(window.location.search);
        let piId = params.get("");
        this.setState({ middlewareRequestGroupId: piId });

        const decryptKey = decryptionData(piId, false);

        if (decryptKey > 0) {
            this.requestGroupServices.onGetById(decryptKey);
        }

    }


    setTab = (currentStage, currentProcess) => {
        if (currentProcess <= currentStage) {
            if (currentProcess === 1) {
                this.setState({ activeTab: Constants.RequestDetailsGroup.Scrapping })
            }
            else if (currentProcess === 2) {
                this.setState({ activeTab: Constants.RequestDetailsGroup.Cleanner })
            }
        }
    }

    toggleTab = (tab) => {
        if (this.state.activeTab === tab) {
            // Close the tab if it's already open
            this.setState({ activeTab: tab });
        } else {
            // Open the tab if it's closed
            this.setState({ activeTab: tab });
        }
    }

    // getlog() {
    //     if (this.state.middlewareRequestGroupId > 0) {
    //         let req = [
    //             this.state.middlewareRequestGroupId,
    //             'MiddlewareRequestGroupId'
    //         ]
    //         this.requestGroupServices.onGetLogsByProductDataId(req);
    //     }
    // }

    handleClose = () => {
        this.setState({ showModal: false })
        this.setState({ fileContent: null, })
    }


    previewFile = () => {
        const downloadUrl = `${DownloadInputFile}?FileName=${encodeURIComponent(this.state.dataList.inputFile)}&ModuleType=Input`;
        this.setState({ setIsLoad: true });
        axios.get(downloadUrl)
            .then(response => {
                const fileContent = response.data;
                this.setState({ fileContent, showModal: true, setIsLoad: false });
            })
            .catch(error => {
                console.error('Error downloading the file:', error);
                this.setState({ setIsLoad: false });
            });
    };

    render() {
        const {fileContent , setIsLoad , showModal} = this.state
        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} />
        }

        return (
            <>
                <RequestOperationalModuleListTab />
                {/* {this.state.dataList?.map((add, index) => ( */}
                <>
                    <div className="card my-4">
                        <div className="card-title-main px-3 py-2">
                            <div className="row align-items-center">
                                <div className="col-6">
                                    <h6 className="titletexts font-size-16">Name : {this.state.dataList.requestGroupName}</h6>
                                </div>
                            </div>
                        </div>

                        <div className="row p-3">
                            <div className="col-md-4 mb-3">
                                <div className="inner-detail-sec">
                                    <span className="detail-title">Module </span>
                                    <span className="font-size-16"> {this.state.dataList.moduleName}</span>
                                </div>
                            </div>

                            <div className="col-md-4 mb-3">
                                <div className="inner-detail-sec">
                                    <span className="detail-title">Server </span>
                                    <span className="font-size-16">{this.state.dataList.serverName}</span>
                                </div>
                            </div>

                            <div className="col-md-4 mb-3">
                                <div className=" d-flex align-items-center">
                                    {this.state.dataList.isCleaner ?
                                        <i className="fas fa-check text-success font-size-30"></i> :
                                        <i className="fas fa-times text-danger font-size-30"></i>}
                                    <span className="detail-title font-size-16">Is Cleaner ?</span>
                                </div>
                            </div>

                            {/* <div className="col-md-4">
                                <div className="inner-detail-sec">
                                    <span className="detail-title">Stage Name :</span>
                                    <span className="font-size-16">{this.state.dataList.stageName}</span>
                                </div>
                            </div> */}

                            <div className="col-md-4 mb-3">
                                <div className="inner-detail-sec">
                                    <span className="detail-title">Created Date </span>
                                    <span className="font-size-16"> {DataFormat(this.state.dataList.createdDate)}</span>
                                </div>
                            </div>

                            <div className="col-md-4 mb-3">
                                <div className="inner-detail-sec">
                                    <span className="detail-title">Status </span>
                                    <span className="font-size-16">{this.state.dataList.requestGroupStatusName}</span>
                                </div>
                            </div>


                            <div className="col-md-4">
                                <div className=" d-flex align-items-center">
                                    {this.state.dataList.isScrapping ?
                                        <i className="fas fa-check text-success font-size-30"></i> :
                                        <i className="fas fa-times text-danger font-size-30"></i>}
                                    <span className="detail-title font-size-16">Is Scrapping ?</span>
                                </div>
                            </div>
                            {/* 
                            <div className="col-md-4">
                            </div>

                            <div className="col-md-4">
                            </div> */}
                            <div className="col-md-8">
                                <div className="inner-detail-sec">
                                    <span className="detail-title">Files to be Scrape </span>
                                    <>
                                        {this.state.dataList.inputFile ? (
                                            <>
                                                <span className="font-size-16"> {this.state.dataList.fileLength}</span>
                                                <span for="requestName" className="detail-desc ml-1">
                                                    <a
                                                        href={`${DownloadInputFile +
                                                            `?FileName=${this.state.dataList.inputFile}&ModuleType=Input`
                                                            }`}
                                                        target="_top"
                                                    >
                                                        {" "}
                                                        <i className="fa fa-download cursor-pointer"></i>
                                                    </a>
                                                </span>
                                                <img src="/txt.png" className="txt-file ml-5 cursor-pointer" title="Preview" alt="Text icon" onClick={this.previewFile} />
                                            </>
                                        ) : "No Record"}
                                    </>
                                </div>
                            </div>


                            <div className="col-md-4">
                                <div className=" d-flex align-items-center">
                                    {this.state.dataList.isDBUpdates ?
                                        <i className="fas fa-check text-success font-size-30"></i> :
                                        <i className="fas fa-times text-danger font-size-30"></i>}
                                    <span className="detail-title font-size-16">Is DBUpdates ?</span>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="tabs new-btn-width">
                        <ul className="tabs-titles justify-content-left new-border pl-0 cursor-pointer mb-2">
                            <li
                                onClick={() => this.toggleTab(Constants.RequestDetailsGroup.Scrapping)}
                                className={this.state.activeTab === Constants.RequestDetailsGroup.Scrapping ? "tab-title tab-title--active  btn-hover-1 color-3 active cursor-pointer" : "btn-hover-1 color-3 tab-title"}
                            >
                                Scrapping
                            </li>
                            <li
                                onClick={() => this.toggleTab(Constants.RequestDetailsGroup.Cleanner)}
                                className={this.state.activeTab === Constants.RequestDetailsGroup.Cleanner ? "tab-title tab-title--active  btn-hover-1 color-3 active" : "btn-hover-1 color-3 tab-title"}
                            >
                                Cleaning / Data Upload
                            </li>
                        </ul>
                        <div className="tab-content">
                            {this.state.activeTab === Constants.RequestDetailsGroup.Scrapping && <ScrappingReq scrapperData={this.state.scrapperData} scarpCount={this.state.scarpCount} errorCount={this.state.errorCount} dataList={this.state.dataList} logsList={this.state.logsList} />}
                            {this.state.activeTab === Constants.RequestDetailsGroup.Cleanner && <CleaningReq cleannerDataUpload={this.state.cleannerDataUpload} />}
                        </div>

                    </div>

                    <Modal className="scrolling-model" show={showModal} onHide={this.handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>File Preview</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="RequestDetails-modal-file">
                                {setIsLoad ? (
                                    <div className="text-center">
                                        <Bars
                                            color="#3c4b64"
                                            height={30}
                                            visible={setIsLoad}
                                        />
                                    </div>
                                ) : (
                                    <ul className="file-content">
                                        {fileContent ? (
                                            fileContent.split('\n').map((line, index) => (
                                                <li key={index}>{line}</li>
                                            ))
                                        ) : (
                                            <li>No content available</li>
                                        )}
                                    </ul>
                                )}
                            </div>
                        </Modal.Body>
                    </Modal>
                </>
                {/* ))} */}
            </>
        );
    }
}
