import React from 'react';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { Navigate } from "react-router";
import Modal from 'react-bootstrap/Modal';

import { ErrorMessage } from "../../utils/Messages";
import Button from '../../components/common/Button';
import BaseComponent from '../../shared/baseComponent';
import ButtonLoader from '../../components/common/buttonLoader/ButtonLoader';
import FormFieldGroup from '../../components/UI Element/FormGrop/FormFieldGroup';
import CodeManagementServices from '../../services/domainServices/codeManagementServices';
import CodeManagementService from '../../services/axiosServices/apiServices/CodeManagementService';
import { decryptionData } from '../../../src/utils/Encryption';

export default class AddScript extends BaseComponent {
    constructor(props) {
        super(props)
        this.codeManagementServices = new CodeManagementServices();
        this.cmService = new CodeManagementService();
        this.codeManagementServices.onUpdateState = this.updateState.bind(this);
        this.userId = this.codeManagementServices.authUser.adminUserId;
        this.codeManagementId = 0
        this.state = {
            ...this.state, // Need to use parent state
            updateUser: "",
            show: false,
            pagination: {
                ...this.codeManagementServices.pagination,
            },
            addScriptDetails: this.codeManagementServices.addScriptDetails,
            addVersionDetails: this.codeManagementServices.addVersionDetails,
            resetValidationRule: {},
            setIsLoad: false,
            dataItems: [],
            setOperationalList: [],
            isActiveVestion: false,
            activeVersion: false,
            IsUpdatedMode: false,
            importFile: {
                importTypeId: "",
                importTitle: "",
                importFileName: "",
                file: ""
            },

        }
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.show !== this.state.show) {
            if (this.state.show === true) {
                this.codeManagementServices.onInitAddVersionScriptService();
            } else {
                this.codeManagementServices.onInitService();
            }
        }

    }

    componentDidMount() {
        this.codeManagementServices.onInitService();
        this.codeManagementServices.onGetSupplierList();
        let params = new URLSearchParams(window.location.search);
        let cmId = params.get("");
        if (cmId !== null && cmId !== undefined) {
            const decryptKey = decryptionData(cmId, false); // *** getByID encryption ***//
            this.setState({ codeManagementId: decryptKey });
            if (decryptKey > 0) {
                let request = {
                    ...this.state.pagination,
                    codeManagementId: decryptKey
                }
                this.codeManagementServices.onGetCodeManagementById(decryptKey);
                this.codeManagementServices.onGetAllVersionByCodeManagementId(request);
            }
        }
    }
    handleChange(name, value) {
        let detail = this.state.addScriptDetails;
        if (name === 'currentVersion' && value === '') {
            detail[name] = 'V_' + value;
        } else if (name === 'currentVersion' && value !== '') {
            detail[name] = value;
        } else if (name !== 'currentVersion') {
            detail[name] = value;
        }
        this.setState({ addScriptDetails: { ...detail } });
    }

    handleVersionChange(name, value) {
        let detail = this.state.addVersionDetails;
        if (name === 'currentVersion' && value === '') {
            detail[name] = 'V_' + value;
        } else if (value !== '') {
            detail[name] = value;
        }
        this.setState({ addVersionDetails: { ...detail } });
    }


    handleFileChange = (event, val) => {
        let name = event.target.name;
        let value = event.target.value;
        const File = event.target.files[0];
        if (File && (File.type === "application/x-zip-compressed" || File.type === 'application/zip')) {
            if (File.size > 0) {
                let request = {};
                request.File = File;
                if (val === true) {
                    let Importfile = this.state.addVersionDetails;
                    Importfile[name] = value;
                    Importfile.inputFile = File.name;
                    Importfile["inputScriptFile"] = File;
                    this.setState({ addVersionDetails: { ...Importfile } });
                } else {
                    let Importfile = this.state.addScriptDetails;
                    Importfile[name] = value;
                    Importfile.inputFile = File.name;
                    Importfile["inputScriptFile"] = File;
                    this.setState({ addScriptDetails: { ...Importfile } });
                }
            }
        }
        else {
            this.codeManagementServices.apiService.serviceManager.swalServices.Toaster(ErrorMessage.InvalidFileType);
        }
    }

    onAddScript() {
        if (this.codeManagementServices.isValidSubmit(this.state.addScriptDetails, this.state.validationRules, this.state.validState)) {
            let request = {
                fileName: this.state.addScriptDetails.inputFile,
                currentVersion: this.state.addScriptDetails.currentVersion,
                configureType: this.state.addScriptDetails.configureType,
                active: this.state.addScriptDetails.active,
                createdBy: this.userId,
                file: this.state.addScriptDetails.inputScriptFile,
                scriptName: this.state.addScriptDetails.scriptTitle,
                supplierID: this.state.addScriptDetails.supplierID,

            }
            this.codeManagementServices.onAddCodeManagement(request);
        }
    }
    onUpdate() {
        if (this.codeManagementServices.isValidSubmit(this.state.addScriptDetails, this.state.validationRules, this.state.validState)) {
            let request = {
                codeManagementId: this.state.codeManagementId,
                scriptName: this.state.addScriptDetails.scriptTitle,
                fileName: this.state.addScriptDetails.inputFile,
                supplierID: this.state.addScriptDetails.supplierID,
                configureType: this.state.addScriptDetails.configureType,
                file: this.state.addScriptDetails.inputScriptFile,
                updatedBy: this.userId
            }
            this.codeManagementServices.onUpdateScript(request);
        }
    }

    onAddVersion() {

        if (this.codeManagementServices.isValidSubmit(this.state.addVersionDetails, this.state.validationRules, this.state.validState)) {
            let request = {
                fileName: this.state.addVersionDetails.inputFile,
                currentVersion: this.state.addVersionDetails.currentVersion,
                configureType: this.state.addScriptDetails.configureType,
                file: this.state.addVersionDetails.inputScriptFile,
                codeManagementId: this.state.addScriptDetails.codeManagementId,
                scriptName: this.state.addScriptDetails.scriptTitle,
            }

            let getRequest = {
                ...this.state.pagination,
                codeManagementId: this.state.addScriptDetails.codeManagementId
            }

            let found = this.state.dataItems.find(x => x.currentVersion === this.state.addVersionDetails.currentVersion)
            if (found) {
                this.codeManagementServices.apiService.serviceManager.swalServices.Alert(ErrorMessage.Already_Exist.replace("{0}", "Version"));
            }
            else {
                this.codeManagementServices.onAddVersion(request, getRequest);
            }
        }
    }

    handleShow = () => this.setState({ show: true })

    handleClose = () => this.codeManagementServices.onResetVersionData();

    delete = (id) => {
        this.codeManagementServices.apiService.serviceManager.swalServices.Confirm("Are you sure you want to delete Module?", "", "Yes", "No")
            .then(async confirm => {
                if (confirm) {
                    let data = {
                        id: id,
                        deletedBy: this.userId,
                    };
                    this.codeManagementServices.onDelete(data);
                }
            });
    }

    download = (id) => {
        this.cmService.downloadScriptFile(id).then((res) => {
            var formattedDate = moment(new Date()).format("YYMMDD")
            var filename = 'Version' + formattedDate;
            var blob = new Blob([res.data])
            saveAs(blob, filename + ".zip");
        })
    }

    activeVersion = (name, value, index, data) => {
        let request = {
            versionId: data.versionId,
            codeManagementId: this.state.codeManagementId,
            active: value
        };
        this.codeManagementServices.onIsActiveVersion(request, this.state.codeManagementId);
    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} />;
        }
        return (
            <div>
                <div className="mb-4">
                    <div className="accordion" id="accordionExample">
                        <div className="card accordion-item active">
                            <div
                                id="accordionOne"
                                className="accordion-collapse collapse show"
                                data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className="card-body">
                                        <div>
                                            <div className="row">
                                                {this.codeManagementServices.formFields.map((Item, index) => {
                                                    if (this.state.updateUser) {
                                                        if (Item.fieldName !== "fileName" || Item.inputType !== "file")
                                                            return (
                                                                <>
                                                                    <FormFieldGroup
                                                                        column={Item}
                                                                        value={this.state.addScriptDetails[Item.fieldName]}
                                                                        error={this.state.validState.error[Item.fieldName]}
                                                                        checked={Item.checked === true ? this.state.addScriptDetails[Item.fieldName] : null}
                                                                        onChange={Item.inputType === 'checkbox' ? (event) => this.handleChange(Item.fieldName, event.target.checked)
                                                                            : Item.inputType === "file" ? (event) => this.handleFileChange(event, false) :
                                                                                Item.inputType === "select" || Item.inputType === "text" ? (event) => this.handleChange(Item.fieldName, event.target.value)
                                                                                    : null
                                                                        }
                                                                        options={Item.fieldName === "supplierID" ? this.state.setOperationalList : Item.Options ? Item.Options : null}
                                                                        disabled={(this.state.updateUser && Item.fieldName) === 'fileName' ? true :
                                                                            (this.state.updateUser && Item.fieldName) === 'currentVersion' ? true : false
                                                                        }
                                                                        onBlur={() => this.codeManagementServices.validateField(Item.fieldName, this.state.addScriptDetails, this.state.validationRules, this.state.validState)}
                                                                    />
                                                                </>)

                                                    } else {
                                                        if (Item.fieldName !== "fileName" || Item.inputType !== "text")
                                                            return (
                                                                <>
                                                                    <FormFieldGroup
                                                                        column={Item}
                                                                        value={this.state.addScriptDetails[Item.fieldName]}
                                                                        error={this.state.validState.error[Item.fieldName]}
                                                                        checked={Item.checked === true ? this.state.addScriptDetails[Item.fieldName] : null}

                                                                        onChange={Item.inputType === 'checkbox' ? (event) => this.handleChange(Item.fieldName, event.target.checked)
                                                                            : Item.inputType === "file" ? (event) => this.handleFileChange(event, false) :
                                                                                Item.inputType === "select" || Item.inputType === "text" ? (event) => this.handleChange(Item.fieldName, event.target.value)
                                                                                    : null
                                                                        }
                                                                        options={Item.fieldName === "supplierID" ? this.state.setOperationalList : Item.Options ? Item.Options : null}
                                                                        onBlur={() => this.codeManagementServices.validateField(Item.fieldName, this.state.addScriptDetails, this.state.validationRules, this.state.validState)}
                                                                    />
                                                                </>)
                                                    }
                                                })}
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-12 addnewuser-button">
                                        {this.state.updateUser ?
                                            <button className="tab-title tab-title--active ml-2 btn-hover color-3 active" onClick={() => this.onUpdate()}>
                                                {this.state.setIsLoad ? <ButtonLoader /> : "Update "}
                                            </button>
                                            :
                                            <>
                                                <button className="tab-title tab-title--active ml-2 btn-hover color-3 active" onClick={() => this.onAddScript()}>
                                                    {this.state.setIsLoad ? <ButtonLoader /> : "Add "}
                                                </button>
                                            </>
                                        }
                                        <Button type='cancel' title="Cancel" className="btn-hover color-3 tab-title ml-2" onClick={() => this.codeManagementServices.onRedirectoList()} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.updateUser ?
                    <div className="mt-5">
                        <div className="accordion" id="accordionExample">
                            <div className="card accordion-item active">
                                <div id="accordionOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <div className='Add-Script-table-btn'>
                                            <h4 className="card-header ">Version List</h4>
                                            <button className="version-list tab-title tab-title--active btn-hover color-3 active" onClick={() => this.handleShow()}>
                                                Add Version
                                            </button>
                                        </div>
                                        <div className='card-body'>
                                            {(this.state.setIsLoad) ?
                                                <div className="loader-inner line-scale-pulse-out-rapid" style={{ textAlign: 'center' }}>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                </div>
                                                :
                                                <table className="table table-hover table-shadow table-striped table-borderless">
                                                    <thead>
                                                        <tr className='text-left'>
                                                            <th>File Name</th>
                                                            <th> Current Version </th>
                                                            <th> Active </th>
                                                            <th> Action </th>
                                                        </tr>
                                                    </thead>

                                                    <tbody className="table-border-bottom-0 text-left">
                                                        {this.state.dataItems?.map((item, index) => (
                                                            <tr key={index}>
                                                                <td> {item.fileName} </td>
                                                                <td> {item.currentVersion} </td>
                                                                <td>
                                                                    <input className="form-check-input ml-0" style={{ position: 'relative' }}
                                                                        checked={item.active}
                                                                        disabled={item.active === true}
                                                                        onChange={(event) => this.activeVersion("active", event.target.checked, index, item)}
                                                                        type="checkbox" id="inlineFormCheck" />
                                                                </td>
                                                                <td>
                                                                    <i className="fa fa-download me-3" style={{ cursor: 'pointer' }} aria-hidden="true"
                                                                        onClick={() => this.download(item.versionId)}></i>
                                                                    {item.active === false ?
                                                                        <i class="fa fa-trash" aria-hidden="true" onClick={() => this.delete(item.versionId)}></i> : null
                                                                    }

                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null}
                <Modal show={this.state.show} onHide={this.handleClose} >
                    <Modal.Header closeButton>
                        <Modal.Title>Add Version  <span>&times;</span></Modal.Title>
                    </Modal.Header>
                    <hr />
                    <Modal.Body>
                        <div className="row">
                            {this.codeManagementServices.addversionFormFields.map((Item, index) => {

                                return (
                                    <>
                                        <FormFieldGroup
                                            column={Item}
                                            value={this.state.addVersionDetails[Item.fieldName]}
                                            error={this.state.validState.error[Item.fieldName]}
                                            checked={Item.checked === true ? this.state.addVersionDetails[Item.fieldName] : null}

                                            onChange={Item.inputType === "file" ? (event) => this.handleFileChange(event, true)
                                                : (event) => this.handleVersionChange(Item.fieldName, event.target.value)}
                                            options={Item.Options ? Item.Options : null}
                                            onBlur={() => this.codeManagementServices.validateField(Item.fieldName, this.state.addVersionDetails, this.state.validationRules, this.state.validState)}
                                        />
                                    </>)
                            })}
                        </div>
                    </Modal.Body>
                    <hr />
                    <Modal.Footer>
                        <button className='tab-title tab-title--active btn-hover color-3 active' type='submit' onClick={() => this.onAddVersion()}>
                            Add
                        </button>
                        <button className='btn-hover color-3 tab-title' type='button' onClick={this.handleClose}>
                            close
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}
