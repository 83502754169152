//** Component  */
import BaseComponent from "../../shared/baseComponent";

//** Services */
import productDataSyncDomainServices from "../../services/domainServices/productDataSyncServices";

//*** Lib */
import { Constants } from "../../utils/Constants";
import ProgressBarStyle from "../../styles/ProgressBarStyle";
import ProductDataLogs from "./progress-flow/ProductDataLogs";
import ProductDataRequest from "./progress-flow/ProductDataRequest";
import ProductDataInitiate from "./progress-flow/ProductDataInitiate";
import { decryptionData, encryptionData } from "../../../src/utils/Encryption";
import { DataFormat } from "../../utils/DateFormat";
import FormFieldGroup from "../../components/UI Element/FormGrop/FormFieldGroup";

export default class ProductDataSyncDetail extends BaseComponent {
  constructor(props) {
    super(props);
    this.productDataSyncServices = new productDataSyncDomainServices();
    this.state = {
      activeTab: Constants.RequestSections.RequestInitiate,
      pagination: this.productDataSyncServices.pagination,
      getList: [],
      logList: [],
      inputFile: "",
      setIsLoad: false,
      productDataSyncDetail: [],
    };
    this.productDataSyncServices.onUpdateState = this.updateState.bind(this);
  }

  componentDidMount() {
    let params = new URLSearchParams(window.location.search);
    let pdsId = params.get("");
    // *** getByID decryption ***//
    const decryptKey = decryptionData(pdsId, false);
    this.setState({ productDataSyncId: decryptKey });

    if (decryptKey > 0) {
      this.productDataSyncServices.onGetById(decryptKey);
    }
  }

  setTab = (currentStage, currentProcess) => {
    if (currentProcess <= currentStage) {
      if (currentProcess === 1) {
        this.setState({
          activeTab: Constants.ProductDataSection.RequestInitiate,
        });
      } else if (currentProcess === 2) {
        this.setState({
          activeTab: Constants.ProductDataSection.RequestProductData,
        });
      } else if (currentProcess === 3) {
        this.setState({ activeTab: Constants.ProductDataSection.RequestLogs });
      }
    }
  };
  onActionCall = (data) => {

        const encryptKey = encryptionData(data, 1);
        window.open(`/ProductImportDetail/id?=${encryptKey}`, '_blank');
    

  }


  render() {
    return (
      <>
        <div className="card">
          <div className="card-title-main px-3 py-2">
            <div className="row align-items-center">
              <div className="col-6">
                <h5 className="titletexts font-size-16">Request : {this.state.productDataSyncDetail.name}</h5>
              </div>
            </div>
          </div>
          <div className="card-body custom-card-body">
            <div className="row">
              <div className="col-md-4 mb-3">
                <div className="inner-detail-sec">
                  <span className="detail-title">Mode </span>
                  &nbsp;&nbsp;&nbsp;<span className="font-size-16">{this.state.productDataSyncDetail.mode}</span>
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <div className="inner-detail-sec">
                  <span className="detail-title">Import Type </span>
                  &nbsp;&nbsp;&nbsp;<span className="font-size-16">{this.state.productDataSyncDetail.productDataSyncImportTypeName}</span>
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <div className="inner-detail-sec">
                  <span className="detail-title">Status </span>
                  &nbsp;&nbsp;&nbsp;<span className="font-size-16">{this.state.productDataSyncDetail.statusName}</span>
                </div>
              </div>
              <div className="col-md-4">
                <div className="inner-detail-sec">
                  <span className="detail-title">Created Date </span>
                  &nbsp;&nbsp;&nbsp;
                  <span className="font-size-16">{
                    this.state.productDataSyncDetail.createdDate
                   
                  }</span>
                </div>
              </div>
              <div className="col-md-4">
                <div className="inner-detail-sec">
                  <span className="detail-title">Product Data Sync Id </span>
                  &nbsp;&nbsp;&nbsp;<span className="font-size-16">{this.state.productDataSyncDetail.productDataSyncId}</span>
                </div>
              </div>
              <div className="col-md-4">
                <div className="inner-detail-sec">
                  <span className="detail-title">Product Import Name </span>
                  &nbsp;&nbsp;&nbsp;<span className="font-size-16 undeline cursor-pointer" onClick={() => this.onActionCall(this.state.productDataSyncDetail.productImportId)}>{this.state.productDataSyncDetail.productImportName}</span>
                </div>
              
              </div>
            </div>
          </div>
        </div>
        <ProgressBarStyle />
        <div className="mt-2 pt-2">
          <div id="progress" className="mb-0">
            <div id="progress-bar"></div>
            <ul className="scrapping-timeline" id="progress-check">
              <li
                className={
                  "step " +
                  (this.state.activeTab === Constants.ProductDataSection.RequestInitiate
                    ? "active red-color"
                    : "")
                }
                onClick={() =>
                  this.setTab(1, Constants.ProductDataSection.RequestInitiate)
                }
              >
                <div className="main-timeline-sec">
                  <div className="icon-round">
                    <i className="fa fa-hourglass-start "></i>
                  </div>
                  <label>Initiate</label>
                </div>
              </li>

              <li
                className={
                  "step " +
                  (this.state.activeTab === Constants.ProductDataSection.RequestProductData
                    ? "active green-color"
                    : "")
                }
                onClick={() =>
                  this.setTab(2, Constants.ProductDataSection.RequestProductData)
                }
              >
                <div className="main-timeline-sec">
                  <div className="icon-round">
                    <i className="fa-brands fa-dropbox"></i>
                  </div>
                  <label>Product Data Sync Files</label>
                </div>
              </li>

              <li
                className={
                  "step " +
                  (this.state.activeTab === Constants.ProductDataSection.RequestLogs
                    ? "active yellow-color"
                    : "")
                }
                onClick={() =>
                  this.setTab(3, Constants.ProductDataSection.RequestLogs)
                }
              >
                <div className="main-timeline-sec">
                  <div className="icon-round">
                    <i className="fa fa-link"></i>
                  </div>
                  <label>Logs</label>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div>
          {this.state.activeTab ===
            Constants.ProductDataSection.RequestInitiate ? (
            <ProductDataInitiate
              productDataSyncDetail={this.state.productDataSyncDetail}
            />
          ) : this.state.activeTab ===
            Constants.ProductDataSection.RequestProductData ? (
            <ProductDataRequest
              productDataSyncDetail={this.state.productDataSyncDetail}
            />
          ) : this.state.activeTab ===
            Constants.ProductDataSection.RequestLogs ? (
            <ProductDataLogs />
          ) : null}
        </div>
      </>
    );
  }
}
