import React from 'react';
import LayoutNavigation from './navigation/LayoutNavigation';
import { getAuthProps } from './utils/AuthenticationLibrary';
import ComponentNavigation from '../src/navigation/ComponentNavigation';
import './index.css';

const App = () => {
  const authDetails = getAuthProps();
  if (window.location.pathname !== "/login") {
    if (authDetails) {
     if(window.location.pathname === "/"){
      window.location.href = "/Dashboard"
     }
      ComponentNavigation.forEach(element => {
        element.authDetails = authDetails;
      });
    }
    else {
      if(window.location.pathname === "/"){
        window.location.href = "/login";
      }
      // window.location.href = "/login";
    }
  }

  return (
    <>
      <LayoutNavigation componentRoutes={ComponentNavigation} authDetails={authDetails} /></>
  );
}

export default App;
