//** Lib's */
import { Constants } from "../../utils/Constants";
import BaseComponent from "../../shared/baseComponent";
import { decryptionData } from "../../utils/Encryption";
import LinkupRequest from "./progress-flow/LinkupRequest";
import LinkupRequestLogs from "./progress-flow/LinkupRequestLogs";

//** Service's */
import LinkupDomainService from "../../services/domainServices/linkupDomainService";
import LinkBuilderStyle from "../../styles/LinkBuilderStyle";
import { DataFormat } from "../../utils/DateFormat";

export default class LinkupRequestDetail extends BaseComponent {
  constructor(props) {
    super(props);
    this.domainServices = new LinkupDomainService();
    this.state = {
      detailData: [],
      regulationNameList: [],
      activeTab: Constants.LinkupRequest.LinkupRequestData,
      regulationName: "",
    };
    this.domainServices.onUpdateState = this.updateState.bind(this);
  }

  componentDidMount() {
    let params = new URLSearchParams(window.location.search);
    let id = params.get("");

    const decryptKey = decryptionData(id, false);
    // *** getByID decryption ***//
    if (decryptKey > 0) {
      this.domainServices.onGetById(decryptKey);
      this.domainServices.onGetRegulationNameList();
    }
  }

  setTab = (currentStage, currentProcess) => {
    if (currentProcess <= currentStage) {
      if (currentProcess === 1) {
        this.setState({
          activeTab: Constants.LinkupRequest.LinkupRequestData,
        });
      } else if (currentProcess === 2) {
        this.setState({ activeTab: Constants.LinkupRequest.RequestLogs });
      }
    }
  };

  render() {
    const idToFind = this.state?.detailData?.regulationId;
    const regulationName = this.state?.regulationNameList?.find(
      (item) => item.id === idToFind
    );
    return (
      <>
        <div className="card">
          <div className="card-title-main px-3 py-2">
            <div className="row align-items-center">
              <div className="col-6">
                <h5 className="titletexts font-size-16">Request : {this.state.detailData.linkupRequestName}</h5>
              </div>
            </div>
          </div>

          <div className="card-body custom-card-body">
            <div className="row">
              <div className="col-md-4">
                <div className="inner-detail-sec">
                  <span className="detail-title">Status </span>
                  &nbsp;&nbsp;&nbsp; <span className="font-size-16">{this.state.detailData.statusName}</span>
                </div>
              </div>
              <div className="col-md-4">
                <div className="inner-detail-sec">
                  <span className="detail-title">Created Date </span>
                  &nbsp;&nbsp;&nbsp;{" "}
                 <span className="font-size-16"> {DataFormat(
                    this.state.detailData.createdDate,
                    "MM-DD-YYYY h:mm:ss"
                  )}</span>
                </div>
              </div>
              <div className="col-md-4">
                <div className="inner-detail-sec">
                  <span className="detail-title">Regulation </span>
                  &nbsp;&nbsp;&nbsp; <span className="font-size-16">{regulationName?.name ? regulationName?.name : "NA"}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <LinkBuilderStyle />
        <div className="mt-2 pt-2">
          <div id="progress" className="mb-0">
            <div id="progress-bar"></div>
            <ul className="scrapping-timeline" id="progress-check">
              <li
                className={
                  "step " +
                  ((this.state.activeTab ===
                    Constants.LinkupRequest.LinkupRequestData) 
                    ? "step active green-color"
                    : "")
                }
                onClick={() =>
                  this.setTab(1, Constants.LinkupRequest.LinkupRequestData)
                }
              >
                <div className="main-timeline-sec">
                  <div className="icon-round">
                    <i className="fa-brands fa-dropbox"></i>
                  </div>
                  <label>Linkup Request File</label>
                </div>
              </li>

              <li
                className={
                  "step " +
                  ((this.state.activeTab ===
                    Constants.LinkupRequest.RequestLogs) 
                    ? "step active yellow-color"
                    : "")
                }
                onClick={() =>
                  this.setTab(2, Constants.LinkupRequest.RequestLogs)
                }
              >
                <div className="main-timeline-sec">
                  <div className="icon-round">
                    <i className="fa fa-link"></i>
                  </div>
                  <label>Logs</label>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div>
          {this.state.activeTab ===
          Constants.LinkupRequest.LinkupRequestData ? (
            <LinkupRequest detailData={this.state.detailData} />
          ) : this.state.activeTab === Constants.LinkupRequest.RequestLogs ? (
            <LinkupRequestLogs />
          ) : null}
        </div>
      </>
    );
  }
}
