//** Component  */
import React from "react";
import Modal from "react-bootstrap/Modal";
import FormFieldGroup from "../../../components/UI Element/FormGrop/FormFieldGroup";
//** Services */
import productImportServices from "../../../services/domainServices/productImportServices";

//*** Lib */
import BaseComponent from "../../../shared/baseComponent";
import { DownloadInputFile } from "../../../services/axiosServices/ApiEndPoints";
import productDataSyncDomainServices from "../../../services/domainServices/productDataSyncServices";
import { decryptionData } from "../../../utils/Encryption";
import ButtonLoader from "../../../components/common/buttonLoader/ButtonLoader";
import axios from 'axios';
import FileViewer from 'react-file-viewer';
export default class ProductImportRequest extends BaseComponent {
  constructor(props) {
    super(props);
    this.productDataSyncServices = new productDataSyncDomainServices();
    this.productImportServices = new productImportServices();

    this.state = {
      setIsLoad: false,
      show: false,
      outputFileList: [],
      error: "",
      operationalFormField: {
        moduleType: "ProductImportOutput",
        name: "",
        fileName: "",
        productImportId: 0,
        mode: {
          sync: "Sync",
          manual: "Manual",
        },
        productDataSyncImportTypeId: {
          TutomerImport: 1,
          ChemPropertiesImport: 2,
          PubChemSync: 3,
          ProductUpdate: 4,
          Regulation:5
          
        },
        createdBy: 1,
        file: null,
        showModal: false,
        fileToView: null,
        fileContent: null,
      },
      showTooltip: false,
      addScriptDetails: this.productImportServices.addScriptDetails,
    };
    this.productImportServices.onUpdateState = this.updateState.bind(this);
    this.productDataSyncServices.onUpdateState = this.updateState.bind(this);
  }

  handleMouseEnter = () => {
    this.setState({ showTooltip: true });
  };

  handleMouseLeave = () => {
    this.setState({ showTooltip: false });
  };

  //** Constructor  and Component method */
  componentDidMount() {
    this.productDataSyncServices.onInitService();
    this.productDataSyncServices.onProductDataSyncImportType();
    let params = new URLSearchParams(window.location.search);
    let pdsId = params.get("");
    const decryptKey = decryptionData(pdsId, false);
    if (decryptKey > 0) {
      this.productImportServices.onGetProductDataOutputFileById(decryptKey);
    }
  }

  handleError = (TutomerImport, ChemPropertiesImport, PubChemSync, ProductUpdate,Regulation) => {
    if (!TutomerImport && !ChemPropertiesImport && !PubChemSync && !ProductUpdate && !Regulation) {
      this.setState({ error: "Please select at least one option." });
      return false;
    } else {
      return true;
    }
  };
  NewDataCount = () => {
    const { TutomerImport, ChemPropertiesImport, PubChemSync, ProductUpdate,Regulation } = this.state.addScriptDetails;
    let isValid = this.handleError(TutomerImport, ChemPropertiesImport, PubChemSync, ProductUpdate,Regulation);
    if (isValid) {
      const requests = [];
      const dataListObject = { DataList: [] };
      const commonRequestData = {
        Name: this.props.productDataDetail.name,
        Mode: this.state.operationalFormField.mode.manual,
        FileName: this.state.outputFileList.find(
          (item) => item.outputFileTypeId === 1
        ).fileName,
        // FileName: "test.py",
        CreatedBy: this.state.operationalFormField.createdBy,
        ModuleType: this.state.operationalFormField.moduleType,
        ProductImportId : this.props.productDataDetail.productImportId

      };
  
        
        if (TutomerImport) {
            requests.push({
                ...commonRequestData,
                ProductDataSyncImportTypeId: 1,
            });
        }
        if (ChemPropertiesImport) {
            requests.push({
                ...commonRequestData,
                ProductDataSyncImportTypeId: 2,
            });
        }
        if (PubChemSync) {
            requests.push({
                ...commonRequestData,
                ProductDataSyncImportTypeId: 3,
            });
        }
        if (ProductUpdate) {
            requests.push({
                ...commonRequestData,
                ProductDataSyncImportTypeId: 4,
            });
        }
        if (Regulation) {
            requests.push({
                ...commonRequestData,
                ProductDataSyncImportTypeId: 5,
            });
        }
    
      requests.forEach((request) => {
        dataListObject.DataList.push({ ...request });
      });
      this.productDataSyncServices.onAddSync(dataListObject);
      this.setState({ show: false })
    }  
  }
  handleShoww = () => this.setState({ show: true });

  handleClose = () => this.productImportServices.onResetVersionData();

  handleChange(name, value) {
    let detail = this.state.addScriptDetails;
    detail[name] = value;
    if (name === "TutomerImport" || name === "ChemPropertiesImport" || name === "PubChemSync" || name === "ProductUpdate" || name==="Regulation") {
      this.setState({ error: "" });
    }
    this.setState({ addScriptDetails: { ...detail } });
  }


  handleCloseFile = () => {
    this.setState({ showModal: false })
    this.setState({ fileToView: null });
  }

  previewFile = (data) => {
    const downloadUrl = `${DownloadInputFile}?FileName=${encodeURIComponent(data)}&ModuleType=ProductImportOutput`;
    this.setState({ setIsLoad: true });
    axios.get(downloadUrl, { responseType: 'blob' })
      .then(response => {
        this.setState({ fileToView: response.data });
        this.setState({ showModal: true });
      })
      .catch(error => {
        console.error('Error downloading the file:', error);
      });
  };

  render() {
    const { showModal, showTooltip } = this.state

    return (
      <>
        <div className="card">
          <div className="card-title-main px-3 py-2 d-flex justify-content-between align-items-center">
            <h6 className="titletexts font-size-16">Output Files</h6>
            <div>
              {this.props.productDataDetail.newDataCounts ===
                0 ? null : (
                <p
                  className="product-import-details mb-0"
                  onClick={() => this.handleShoww()}
                  title="Add Request"
                >
                  New Count Sync<span className="fas fa-sync pl-2"></span>
                </p>
              )}
            </div>
          </div>
          <div className="card-body custom-card-body">
            <div className="row align-items-center">
              <div className="col-md-6 mb-3">
                <div className="inner-detail-sec tooltipp">
                  <span className="detail-title">New Data Counts
                    <i className="fa fa-info-circle ml-2 cursor-pointer"
                      onMouseEnter={this.handleMouseEnter}
                      onMouseLeave={this.handleMouseLeave}
                    //  title="" aria-hidden="true"
                    ></i>
                    {showTooltip && (
                      <span className="tooltipptext">
                        The count will be for those SMILES that are not in ChemProduct
                      </span>
                    )}
                  </span>
                  <span className="detail-desc font-size-16">
                    &nbsp;&nbsp;&nbsp;
                    <div className="d-flex">
                      <div className="cursor-pointer">
                        {this.props.productDataDetail.newDataCounts}
                      </div>
                      <div>
                        {this.state.outputFileList.map((filesDetail, index) => (
                          <>
                            {filesDetail.outputFileTypeId === 1 ? (
                              <span
                                htmlFor="requestName"
                                className="detail-desc ml-3"
                              >
                                <a
                                  href={`${DownloadInputFile +
                                    `?FileName=${filesDetail.fileName}&ModuleType=ProductImportOutput`
                                    }`}
                                  target="_top"
                                >
                                  {" "}
                                  <i className="fa fa-download"></i>
                                </a>
                                <img src="/csv.png" className="csv-file ml-2 cursor-pointer" title="Preview" alt="Text icon" onClick={() => this.previewFile(filesDetail.fileName)} />
                              </span>
                            ) : null}
                          </>
                        ))}
                      </div>
                    </div>
                  </span>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="inner-detail-sec tooltipp">
                  <span className="detail-title">Total Counts
                    <i className="fa fa-info-circle ml-2 cursor-pointer"
                      onMouseEnter={this.handleMouseEnter}
                      onMouseLeave={this.handleMouseLeave}
                    //  title="" aria-hidden="true"
                    ></i>
                    {showTooltip && (
                      <span className="tooltipptext">
                        Total Count of Smile
                      </span>
                    )}
                  </span>
                  <span className="detail-desc">
                    &nbsp;&nbsp;&nbsp;
                    {this.props.productDataDetail.totalCounts}
                    {this.state.outputFileList.map((filesDetail, index) => (
                      <>
                        {filesDetail.outputFileTypeId === 3 ? (
                          <span for="requestName" className="detail-desc ml-3">
                            <a
                              href={`${DownloadInputFile +
                                `?FileName=${filesDetail.fileName}&ModuleType=ProductImportOutput`
                                }`}
                              target="_top"
                            >
                              {" "}
                              <i className="fa fa-download"></i>
                            </a>
                            <img src="/csv.png" className="csv-file ml-2 cursor-pointer" title="Preview" alt="Text icon" onClick={() => this.previewFile(filesDetail.fileName)} />
                          </span>
                        ) : null}
                      </>
                    ))}
                  </span>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="inner-detail-sec tooltipp">
                  <span className="detail-title">Existing Data Counts   <i className="fa fa-info-circle ml-2 cursor-pointer"
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                  //  title="" aria-hidden="true"
                  ></i>
                    {showTooltip && (
                      <span className="tooltipptext">
                        The count will be for those SMILES that already exist
                      </span>
                    )}</span>
                  <span className="detail-desc">
                    &nbsp;&nbsp;&nbsp;
                    {this.props.productDataDetail.existingDataCounts}
                    {this.state.outputFileList.map((filesDetail, index) => (
                      <>
                        {filesDetail.outputFileTypeId === 2 ? (
                          <span for="requestName" className="detail-desc ml-3">
                            <a
                              href={`${DownloadInputFile +
                                `?FileName=${filesDetail.fileName}&ModuleType=ProductImportOutput`
                                }`}
                              target="_top"
                            >
                              {" "}
                              <i className="fa fa-download"></i>
                            </a>
                            <img src="/csv.png" className="csv-file ml-2 cursor-pointer" title="Preview" alt="Text icon" onClick={() => this.previewFile(filesDetail.fileName)} />
                          </span>
                        ) : null}
                      </>
                    ))}
                  </span>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="inner-detail-sec tooltipp">
                  <span className="detail-title">Error Count   <i className="fa fa-info-circle ml-2 cursor-pointer"
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                  //  title="" aria-hidden="true"
                  ></i>
                    {showTooltip && (
                      <span className="tooltipptext">
                        The count will be for those SMILES that have some error.
                      </span>
                    )}</span>
                  <span className="detail-desc">
                    &nbsp;&nbsp;&nbsp;
                    {this.props.productDataDetail.errorCount}

                    {this.state.outputFileList.map((filesDetail, index) => (
                      <>
                        {filesDetail.outputFileTypeId === 4 ? (
                          <span for="requestName" className="detail-desc ml-3">
                            <a
                              href={`${DownloadInputFile +
                                `?FileName=${filesDetail.fileName}&ModuleType=ProductImportOutput`
                                }`}
                              target="_top"
                            >
                              {" "}
                              <i className="fa fa-download"></i>
                            </a>
                            <img src="/csv.png" className="csv-file ml-2 cursor-pointer" title="Preview" alt="Text icon" onClick={() => this.previewFile(filesDetail.fileName)} />
                          </span>
                        ) : null}
                      </>
                    ))}
                  </span>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="inner-detail-sec">
                <div className="inner-detail-sec tooltipp">
                  <span className="detail-title">Association Count   <i className="fa fa-info-circle ml-2 cursor-pointer"
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                  //  title="" aria-hidden="true"
                  ></i>
                    {showTooltip && (
                      <span className="tooltipptext">
                        The count will be for SMILES that match with CanSmile or InChIKey
                      </span>
                    )}
                  </span>
                  <span className="detail-desc">
                    &nbsp;&nbsp;&nbsp;
                    {this.props.productDataDetail.associationCounts}
                    {this.state.outputFileList.map((filesDetail, index) => (
                      <>
                        {filesDetail.outputFileTypeId === 5 ? (
                          <span for="requestName" className="detail-desc ml-3">
                            <a
                              href={`${DownloadInputFile +
                                `?FileName=${filesDetail.fileName}&ModuleType=ProductImportOutput`
                                }`}
                              target="_top"
                            >
                              {" "}
                              <i className="fa fa-download"></i>
                            </a>
                          </span>
                        ) : null}
                      </>
                    ))}
                  </span>
                </div>
              </div>
             
            </div>
            <div className="col-md-6 mb-3">
                <div className="inner-detail-sec">
                  <span className="detail-title">LinkUP Count <i className="fa fa-info-circle ml-2 cursor-pointer" title="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum." aria-hidden="true"></i>
                    </span>
                  <span className="detail-desc">
                    &nbsp;&nbsp;&nbsp;
                    {this.props.productDataDetail.linkUpCounts}
                    {this.state.outputFileList.map((filesDetail, index) => (
                      <>
                        {filesDetail.outputFileTypeId === 6 ? (
                          <span for="requestName" className="detail-desc ml-3">
                            <a
                              href={`${DownloadInputFile +
                                `?FileName=${filesDetail.fileName}&ModuleType=ProductImportOutput`
                                }`}
                              target="_top"
                            >
                              {" "}
                              <i className="fa fa-download"></i>
                            </a>
                            <img src="/csv.png" className="csv-file ml-2 cursor-pointer" title="Preview" alt="Text icon" onClick={() => this.previewFile(filesDetail.fileName)} />
                          </span>
                        ) : null}
                      </>
                    ))}
                  </span>
                </div>
              </div>
          </div>
          </div>
          <>
            <Modal show={this.state.show} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Auto Sync</Modal.Title>
              </Modal.Header>
              <hr />
              <Modal.Body>
                <div className="row">
                  {this.productImportServices.addversionFormFields.map(
                    (Item, index) => {
                      return (
                        <>
                          <FormFieldGroup
                            className=""
                            column={Item}
                            value={this.state.addScriptDetails[Item.fieldName]}
                            onChange={(event) =>
                              this.handleChange(
                                Item.fieldName,
                                event.target.checked
                              )
                            }
                            onKeyPress={(e) =>
                              this.handleChange(Item.fieldName, e.target.value)
                            }
                            key={index}
                          />
                        </>
                      );
                    }
                  )}
                </div>
              </Modal.Body>

              <span className="text-xs mt-1 text-danger ml-3">
                {" "}
                {this.state.error}
              </span>
              <hr className="mb-2 mt-2" />
              <Modal.Footer>
                <button
                  className="tab-title tab-title--active btn-hover color-3 active"
                  type="button"
                  onClick={() => this.NewDataCount()}
                >
                  {this.state.setIsLoad ? <ButtonLoader /> : "Add"}
                </button>
                <button
                  className="btn-hover color-3 tab-title"
                  type="button"
                  onClick={this.handleClose}
                >
                  Close
                </button>
              </Modal.Footer>
            </Modal>
          </>
        </div>
        <Modal className="scrolling-model" show={showModal} onHide={this.handleCloseFile}>
          <Modal.Header closeButton >
            <Modal.Title>File Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body >
            <div className="RequestDetails-modal-file">
              {this.state.fileToView && (
                <>
                  <div>
                    <FileViewer
                      fileType="csv" // Set the file type to csv
                      filePath={URL.createObjectURL(this.state.fileToView)}
                      onError={error => console.error('Error:', error)}
                    />
                  </div>
                </>
              )
              }
            </div>
          </Modal.Body>
        </Modal>
   
      </>
    );
  }
}
