//*** Lib */
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal)
class SwalServices {

  /** Confirm  */
  async Confirm(title, text, saveBtnText, cancelBtnText) {
    return (MySwal.fire({
      title: title,
      text: text,
      icon: 'question',
      iconHtml: '<span className="warning-body"><span className="warning-dot">!</span>',
      showCancelButton: true,
      confirmButtonText: saveBtnText,
      cancelButtonText: cancelBtnText,
      showCloseButton: false,
      allowOutsideClick: false,
      allowEscapeKey: true,
    }).then((result) => {
      if (result.value) {
        return true;
      } else if (result.dismiss === MySwal.DismissReason.cancel) {
        return false;
      }
    })
    )
  }

  async Warning(text) {
    MySwal.fire({
      title: 'warning',
      text: text,
      icon: "warning",
      iconHtml: '<span className="warning-body"></span><span className="warning-dot"></span>',
      showCancelButton: true,
      allowOutsideClick: false,
      allowEscapeKey: true,
    });
  }

  async Error(text) {
    MySwal.fire({
      title: 'Oops!',
      text: text,
      icon: "warning",
      iconHtml: '<span className="warning-body"><span className="warning-dot">!</span>',
      showCancelButton: false,
      allowOutsideClick: false,
      allowEscapeKey: true
    });
  }
  

  async Alert(text) {
    MySwal.fire({
      text: text,
      icon: "warning",
      iconHtml: '<span className="warning-body"><i class="fa fa-check" aria-hidden="true"></i></span><span className="warning-dot"></span>',
      showCancelButton: false,
      allowOutsideClick: false,
      allowEscapeKey: true
    });
  }

  async showLoginAlert(text) {
    MySwal.fire({
      text: text,
      icon: "warning",
      iconHtml: '<span className="warning-body"></span><span className="warning-dot"></span>',
      showCancelButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonText: "Login"
    }).then((result) => {
      if (result.value) {
        window.location = `/#/login?redirect=${window.location.hash}`;
      }
    });
  }

  async Success(text) {
    await MySwal.fire({
      title: "Success",
      text: text,
      icon: "success",
      iconHtml: '<span class="sa-line success-tip"><i class="fa fa-check" aria-hidden="true"></i></span><span class="sa-line success-long"></span>',
      showCancelButton: false,
      allowOutsideClick: false,
      allowEscapeKey: true
    });
  }


  async Toaster(text) {
    MySwal.fire({
      text: text,
      toast: true,
      icon: "success",
      iconHtml: '<i class="fas fa-check"></i>',
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      },
    });
  }

  async WarningToaster(text) {
    MySwal.fire({
      text: text,
      toast: true,
      icon: "warning",
      iconHtml: '<span className="warning-body"></span><span className="warning-dot"></span>',
      position: 'top-end',
      showConfirmButton: false,
      timer: 4000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      },
    });
  }


  async ThankYou(title, text) {
    MySwal.fire({
      title: title,
      text: text,
      icon: "info",
      iconHtml: '<i className="fas fa-check-circle fa-3x"></i>',
      showCancelButton: false,
      allowOutsideClick: false,
      allowEscapeKey: true
    });
  }

  async SuccessWithAction(text, saveBtnText) {
    return (MySwal.fire({
      title: "Success",
      text: text,
      icon: 'success',
      iconHtml: '<span className="sa-line success-tip"></span><span className="sa-line success-long"></span>',
      showCancelButton: false,
      confirmButtonText: saveBtnText,
      showCloseButton: false,
      allowOutsideClick: false,
      allowEscapeKey: true,
    }).then((result) => {
      if (result.value) {
        return true;
      } else if (result.dismiss === MySwal.DismissReason.cancel) {
        return false;
      }
    })
    )
  }
}

export default SwalServices;
