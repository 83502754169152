import { Component } from "react";
import { isValidForm, validate } from "../utils/validation/CommonValidator";
export default class BaseComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validState: {
        isValid: true,
        error: {},
      },
      validationRules: {},
    };
  }
  // This use to update the state
  updateState = (data, onStateUpdated) => {
    let stateObj = data;
    this.setState(stateObj, () => {
      if (onStateUpdated) onStateUpdated();
    });
  };
  validateField = (key, data, rules) => {
    const isValidData = validate(key, data, rules, this.state.validState);
    this.updateState({ validState: isValidData });
  };
  isValidSubmit = (data, rules) => {
    const isValidData = isValidForm(data, rules, this.state.validState);
    this.updateState({ validState: isValidData });
    return isValidData.isValid;
  };
  // Reset the validateion
  resetValid = () => {
    let resetValidation = {
      ...this.state.validState,
      isValid: false,
      error: {},
    };
    this.updateState({ validState: resetValidation });
  };
}
