//*** Lib */
import { axiosPost, axiosGet, axiosGetMultiParams } from '../AxiosRequests';
import { GetAllOperationalModuleList, GetAllFileCountsByMiddlewareId, UpdateRequestStatusOnHold, 
    GetAllOperationalModuleListActiveInactive, GetAllOperationalModuleListById, GetRequestDetailByMiddlewareId, 
    GetMiddlewareDataUPloadFileById, UpdateOptaionalModule, AddOperationalModule, GetAllRunningRequestList, 
    GetAllRequestList, GetAllRequestHistoryList, GetMiddlewareRequestById, GetAllUserList, GetModuleStatusById, 
    GetDashboardCounts, GetAllServerList, GetMiddlewareRequestListById,GetAllCurrencies, GetAllModuleRequestProcessType, GetPartialPriceOutput } from '../ApiEndPoints';

export default class ManageOperationalServices {

    // API function
    async getAllOperationalModuleList(request) {
        return axiosGet(GetAllOperationalModuleList, request);
    }
    async getAllOperationalModuleListActiveInactive(request) {
        return axiosPost(GetAllOperationalModuleListActiveInactive, request);
    }
    async updateOptaionalModule(request) {
        return axiosPost(UpdateOptaionalModule, request);
    }
    async addOperationalModule(request) {
        return axiosPost(AddOperationalModule, request);
    }
    async getAllRunningRequestList(request) {
        return axiosPost(GetAllRunningRequestList, request);
    }
    async getAllRequestList(request) {
        return axiosPost(GetAllRequestList, request)
    }
    async getAllRequestHistoryList(request) {
        return axiosPost(GetAllRequestHistoryList, request)
    }
    async getMiddlewareRequestById(request) {
        return axiosGetMultiParams(GetMiddlewareRequestById, request)
        // return axiosGet(GetMiddlewareRequestById, request)
    }
    async getAllUserList(request) {
        return axiosPost(GetAllUserList, request)
    }
    async getModuleStatusById(request) {
        return axiosGet(GetModuleStatusById, request)
    }
    async getDashboardCounts(request) {
        return axiosGet(GetDashboardCounts, request)
    }
    async getAllCurrencies(request) {
        return axiosGet(GetAllCurrencies, request)
    }
    async getAllModuleRequestProcessType(request) {
        return axiosGet(GetAllModuleRequestProcessType, request)
    }
    async getAllServerList(request) {
        return axiosGet(GetAllServerList, request);
    }
    async getAllOperationalModuleListById(request) {
        return axiosGet(GetAllOperationalModuleListById, request);
    }
    async getMiddlewareRequestListById(request) {
        return axiosGet(GetMiddlewareRequestListById, request)
    }
    async getMiddlewareDataUPloadFileById(request) {
        return axiosGet(GetMiddlewareDataUPloadFileById, request)
    }
    async getRequestDetailByMiddlewareId(request) {
        return axiosGet(GetRequestDetailByMiddlewareId, request)
    }
    async getAllFileCountsByMiddlewareId(request) {
        return axiosGet(GetAllFileCountsByMiddlewareId, request)
    }
    async updateRequestStatusOnHold(request) {
        return axiosGet(UpdateRequestStatusOnHold, request)
    }
    async getPartialPriceOutput(request) {
        return axiosGet(GetPartialPriceOutput, request)
    }
}

