//** Component  */
import React, { useState } from 'react'
import DashboardServices from '../../services/domainServices/dashboardServices';

//** Services */
import DashboardProgressBarStyle from '../../styles/DashboardProgressBarStyle';

//*** Lib */
import { Constants } from '../../utils/Constants';
import FormFieldGroup from '../UI Element/FormGrop/FormFieldGroup';

const DashboardProgressbar = (props) => {

    const dashboardService = new DashboardServices();

    const [activeTab] = useState(Constants.RequestSections.RequestInitiate);

    return (
        <>
        <DashboardProgressBarStyle />
        <div id="progress">
            <div id="progress-bar"></div>
            <ul id="progress-check">
                {dashboardService.tabsFields.map((Item, index) => {
                    return (
                        <FormFieldGroup
                            state={activeTab}
                            column={Item}
                            value={props.dataParentToChild[Item.fieldName]} />)
                })}
            </ul>
        </div>
        </>
    )
}

export default DashboardProgressbar
