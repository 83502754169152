/* Component  */
import { axiosGet, axiosPost } from '../AxiosRequests';
import { GetErrorTraceList, GetErrorTraceById } from '../ApiEndPoints';

export default class ExceptionServices {
    // API function
    async getErrorTraceList(request) {
        return axiosPost(GetErrorTraceList, request);
    }
    async getErrorTraceById(request) {
        return axiosGet(GetErrorTraceById, request);
    }

}
