
import BaseDomainService from "./baseDomainServices";
export default class DashboardServices extends BaseDomainService {

    // API Get
    getRunningRequestdata = this.apiService.middlewareRequest.getAllDashboardRunningRequest;
    getActiveServerListdata = this.apiService.serverConfigurationsServices.getAllActiveServerConfigurationsList;
    getOprationById = this.apiService.manageOperationalServices.getAllOperationalModuleListById;
    getServerById = this.apiService.serverConfigurationsServices.getServerConfigurationsById;
    getDashboardCounts = this.apiService.manageOperationalServices.getDashboardCounts;
    getActiveServertdata = this.apiService.serverConfigurationsServices.getAllActiveServerList;


    onGetRunningRequest = (requst) => {
        this.apiService.onAPICall("getRunningRequest", this.getRunningRequestdata, requst, this.onAPIResponse, this.onUpdateState);
    }
    onGetActiveServerList = (requst) => {
        this.apiService.onAPICall("getActiveServerListdata", this.getActiveServerListdata, requst, this.onAPIResponse, this.onUpdateState);
    }
    onGetActiveServer = (requst) => {
        this.apiService.onAPICall("getActiveServertdata", this.getActiveServertdata, requst, this.onAPIResponse, this.onUpdateState);
    }
    onGetOperationalById = (request) => {
        this.apiService.onAPICall("getOperationalById", this.getOprationById, request, this.onAPIResponse, this.onUpdateState);
    }
    onGetServerById = (request) => {
        this.apiService.onAPICall("getServerById", this.getServerById, request, this.onAPIResponse, this.onUpdateState);
    }
    onGetDashboardCounts = (request) => {
        this.apiService.onAPICall("getDashboardCounts", this.getDashboardCounts, request, this.onAPIResponse, this.onUpdateState);
    }

    // Define the state variables
    dataImportList;

    getAllCardList = [
        {
            cardName: 'Active Module',
            cardImages: '../assets/img/icons/unicons/RPAModules.png',
            cardBtn: 'View More',
            href: 'RPAModules'
        },
        {
            cardName: 'Client / Server Configure',
            cardImages: '../assets/img/icons/unicons/Server-registrer.png',
            cardBtn: 'View More',
            href: '/ServerClientConfigurations'
        },
        {
            cardName: 'Currently Running Request',
            cardImages: '../assets/img/icons/unicons/inprogress-request.png',
            cardBtn: 'View More',
            href: '/RPARequest'
        },
    ]

    formFields = [
        {
            inputType: "checkbox",
            tittle: "inlineFormCheck",
            fieldName: "inlineFormCheck",
            checked: true,
            className: 'col-md-4'
        }
    ]
    tabsFields = [
        {
            inputType: "tabs",
            tittle: "Scrapping",
            fieldName: "scraper",
            className: "green-color",
            iconClass: "fa-brands fa-dropbox"
        },
        {
            inputType: "tabs",
            tittle: "Cleaning",
            fieldName: "cleaner",
            className: "yellow-color",
            iconClass: "fa-solid fa-broom"
        },
        {
            inputType: "tabs",
            tittle: "DataUpdate",
            fieldName: "import",
            className: "red-color",
            iconClass: "fa fa-cloud-upload"
        }
    ]

    //** API Calls 
    onAPIResponse = (key, isSuccess, responseData) => {
        if (!isSuccess) {
            this.onUpdateState({ "setIsLoad": false });
            return;
        }
        switch (key) {
            case "getRunningRequest":
                this.onUpdateState({
                    "dataList": responseData,
                    "setIsLoad": false
                });
                break;

            case "getActiveServertdata":
                let itemList = responseData;
                itemList.forEach(function (i) {
                    i.serverLocationId =
                        i.serverLocationId === 1 ? "USA" :
                            i.serverLocationId === 2 ? "INDIA" :
                                null
                });
                if (responseData !== null) {
                    this.onUpdateState({
                        "ActiveClientsList": itemList,
                        "setIsLoad": false
                    });
                }
                break;
            case "getActiveServerListdata":
                this.onUpdateState({
                    "ActiveClientsdataList": responseData,
                    "setIsLoad": false
                });
                break;

            case "getOperationalById":
                this.onUpdateState({
                    "redirectId": responseData,
                    "setIsLoad": false
                });
                break;
            case "getServerById":
                this.onUpdateState({
                    "ServerIdd": responseData,
                    "setIsLoad": false
                });
                break;

            case "getDashboardCounts":
                this.onUpdateState({
                    dataImportList: responseData,
                    "setIsLoad": false
                });
                break;
                default:
                    // Handle default case here
                    break;
        }
    }

}
