import React from "react";
import BaseComponent from "../../../shared/baseComponent";
import linkBuilderDomainServices from "../../../services/domainServices/linkBuilderServices";
import { DownloadInputFile } from "../../../services/axiosServices/ApiEndPoints";
import { decryptionData } from "../../../utils/Encryption";
import Modal from "react-bootstrap/Modal";
import FormFieldGroup from "../../../components/UI Element/FormGrop/FormFieldGroup";
import ButtonLoader from "../../../components/common/buttonLoader/ButtonLoader";
import { ErrorMessage } from "../../../utils/Messages";
import { getAuthProps, isAuthorized } from "../../../utils/AuthenticationLibrary";
import axios from 'axios';
import FileViewer from 'react-file-viewer';
export default class LinkBuilderRequest extends BaseComponent {
  constructor(props) {
    super(props);
    this.linkBuilderServices = new linkBuilderDomainServices();
    this.state = {
      userId: (isAuthorized()) ? getAuthProps().userId : 0,
      setIsLoad: false,
      autoSyncFields: this.linkBuilderServices.dataItems,
      pagination: this.linkBuilderServices.pagination,
      outputFileList: [],
      show: false,
      serverOptionList: [],
      moduleLists: [],
      staticValue: {
        stageId: 1,
        fileLength: "",
        moduleType: "LinkBuilder"
      },
      showModal: false,
      fileToView: null,
      fileContent: null,
    };
    this.linkBuilderServices.onUpdateState = this.updateState.bind(this);
  }


  componentDidMount() {
    let params = new URLSearchParams(window.location.search);
    let pdsId = params.get("");
    const decryptKey = decryptionData(pdsId, false);
    if (decryptKey > 0) {
      this.linkBuilderServices.onGetLinkBuilderOutputFileById(decryptKey);
      this.linkBuilderServices.onGetAllServerList(this.state.pagination);
      this.linkBuilderServices.onGetModuleList();
    }
  }

  handleChange = (name, value) => {
    let detail = { ...this.state.autoSyncFields };
    detail[name] = value;
    this.setState({ autoSyncFields: detail });
  }

  handleChangeCheckBoxStatus = (name, value) => {
    let detail = this.state.autoSyncFields;
    detail[name] = value;
    if (name === "isDBUpdates" && value === true) {
      if (
        detail.isCleaner === false ||
        detail.isScrapping === false ||
        detail.isCleaner === undefined ||
        detail.isScrapping === undefined
      ) {
        this.linkBuilderServices.apiService.serviceManager.swalServices.Toaster(
          ErrorMessage.validationCleaner.replace("{0}", "DBUpdate")
        );
        detail.isDBUpdates = false;
      }
    }
    if (
      (name === "isCleaner" && value === false) ||
      (name === "isScrapping" && value === false)
    ) {
      if (name === "isDBUpdates" && value === true) {
        detail.isCleaner = true;
      } else {
        detail.isDBUpdates = false;
        detail.isCleaner = false;
      }
    }

    //** Catalog Update  */
    if (name === "isDBUpdates" && value === true) {
      detail.isCatalogCleaner = true;
    }

    if (name === "isCleaner" && value === true) {
      detail.isScrapping = true;
    }

    if (name === "isCatalogCleaner" && value === false) {
      detail.isDBUpdates = false;
    }
    this.setState({ autoSyncFields: { ...detail } });
  };

  handleCloseFile = () => {
    this.setState({ showModal: false })
    this.setState({ fileToView: null });
  }

  handleShow = () => this.setState({ show: true });

  handleClose = () => this.linkBuilderServices.onResetAutoSyncData();

  AddSyncReqest = () => {
    this.setState({ setIsLoad: true }); // Set loading state to true before the operation

    let filterValue = this.state.moduleLists.filter((x) => x.moduleId === this.props.productDataDetail.moduleId);
    let syncValue = filterValue[0];

    if (this.state.autoSyncFields.hostServerId === "" || this.state.autoSyncFields.isScrapping === false) {
      // Display error message if required fields are not filled
      this.linkBuilderServices.apiService.serviceManager.swalServices.Alert(
        ErrorMessage.SelectRequired.replace("{0}", "CheckBox Or Dropdown")
      );
      this.setState({ setIsLoad: false }); // Set loading state back to false
    } else {
      // Proceed with adding sync request
      let req = {
        moduleId: syncValue.moduleId,
        requestGroupName: this.props.productDataDetail.requestName,
        isActive: syncValue.isActive,
        isCleaner: this.state.autoSyncFields.isCleaner,
        isScrapping: this.state.autoSyncFields.isScrapping,
        isDBUpdates: this.state.autoSyncFields.isDBUpdates,
        createdBy: this.state.userId,
        stageId: this.state.staticValue.stageId,
        serverId: this.state.autoSyncFields.hostServerId,
        fileLength: this.state.staticValue.fileLength,
        inputFile: this.state.outputFileList.find(
          (item) => item.linkBuilderOutputFileTypeId === 1
        ).fileName,
        moduleType: this.state.staticValue.moduleType,
        middlewareRequestGroupId: this.props.productDataDetail.middlewareRequestGroupId,
        linkBuilderId: this.props.productDataDetail.linkBuilderId,
      };
      // Call the API to add sync request
      this.linkBuilderServices.onAddSync(req).then(() => {
        this.setState({ setIsLoad: false });
      }).catch((error) => {
        this.linkBuilderServices.apiService.serviceManager.swalServices.Alert(error.message);
        this.setState({ setIsLoad: false });
      });
    }
  };

  previewFile = (data) => {
    const downloadUrl = `${DownloadInputFile}?FileName=${encodeURIComponent(data)}&ModuleType=LinkBuilder`;
    this.setState({ setIsLoad: true });
    axios.get(downloadUrl, { responseType: 'blob' })
      .then(response => {
        this.setState({ fileToView: response.data });
        this.setState({ showModal: true });
      })
      .catch(error => {
        console.error('Error downloading the file:', error);
      });
  };

  handleCloseFile = () => {
    this.setState({ showModal: false })
    this.setState({ fileToView: null });
}

  render() {
    const { showModal } = this.state
    let fileItem = this.state.outputFileList.find(item => item.linkBuilderOutputFileTypeId === 1);
    return (
      <>
        <div className="card mt-2">
          <div className="card-title-main px-3 py-2">
            <h6 className="titletexts font-size-16">Link Builder Files</h6>
          </div>
          <div className="card-body custom-card-body">
            <div className="row">
              <div className="col-md-6 d-flex align-items-center">
                <div className="inner-detail-sec">
                  <span className="detail-title font-size-16">Scrapped Counts </span>
                  <span className="detail-desc">
                    &nbsp;&nbsp;&nbsp;
                    {this.props.productDataDetail.scrappedCounts}
                    {this.state.outputFileList.map((filesDetail, index) => (
                      <>
                        {filesDetail.linkBuilderOutputFileTypeId === 1 ? (
                          <span for="requestName" className="detail-desc ml-3 font-size-16">
                            <a
                              href={`${DownloadInputFile +
                                `?FileName=${filesDetail.fileName}&ModuleType=LinkBuilder`
                                }`}
                              target="_top"
                            >
                              {" "}
                              <i className="fa fa-download"></i>
                            </a>
                            <img src="/csv.png" className="csv-file ml-2 cursor-pointer" title="Preview" alt="Text icon" onClick={() => this.previewFile(filesDetail.fileName)} />
                          </span>
                        ) : null}
                      </>
                    ))}
                  </span>
                </div>
                {/* <button
                    className="tab-title tab-title--active ml-2 btn-hover color-3 active"
                    onClick={() => this.handleShow()}
                  >
                    Sync Group Request<span className="fas fa-sync pl-2"></span>
                  </button> */}
                <div className="cursor-poniter">
                  {this.props.productDataDetail.statusName === "Done" &&
                    this.props.productDataDetail.scrappedCounts !== "0" &&
                    fileItem && fileItem.fileName !== "" ?
                    <button
                      className="tab-title tab-title--active ml-2 btn-hover req-group-btn color-3 active"
                      title="Add Group Name"
                      onClick={() => this.handleShow()}
                    >
                      <i className="fa fa-plus cursor-poniter" aria-hidden="true" title="Add Group Name" ></i>
                    </button>

                    : null}
                </div>
              </div>
              <div className="col-md-6  mt-2">
                <div className="inner-detail-sec">
                  <span className="detail-title">Error Count </span>
                  <span className="detail-desc">
                    &nbsp;&nbsp;&nbsp;
                    {this.props.productDataDetail.errorCounts}
                    {this.state.outputFileList.map((filesDetail, index) => (
                      <>
                        {filesDetail.linkBuilderOutputFileTypeId === 2 ? (
                          <span for="requestName" className="detail-desc ml-3">
                            <a
                              href={`${DownloadInputFile +
                                `?FileName=${filesDetail.fileName}&ModuleType=LinkBuilder`
                                }`}
                              target="_top"
                            >
                              {" "}
                              <i className="fa fa-download"></i>
                            </a>
                            <img src="/csv.png" className="csv-file ml-2 cursor-pointer" title="Preview" alt="Text icon" onClick={() => this.previewFile(filesDetail.fileName)} />
                          </span>
                        ) : null}
                      </>
                    ))}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <>
            <Modal show={this.state.show} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Add Group Name</Modal.Title>
              </Modal.Header>
              <hr />
              <Modal.Body>
                <div className="row">
                  {this.linkBuilderServices.autoSyncFields.map(
                    (Item, index) => {
                      return (
                        <>
                          <FormFieldGroup
                            className=""
                            column={Item}
                            value={this.state.autoSyncFields[Item.fieldName]}
                            options={this.state.serverOptionList}
                            onChange={
                              Item.inputType === "checkbox"
                                ? (event) =>
                                  this.handleChangeCheckBoxStatus(
                                    Item.fieldName,
                                    event.target.checked
                                  ) : Item.inputType === "select"
                                  ? (event) =>
                                    this.handleChange(
                                      Item.fieldName,
                                      event.target.value
                                    )
                                  : null
                            }
                            checked={
                              Item.inputType === "checkbox"
                                ? this.state.autoSyncFields[
                                Item.fieldName
                                ]
                                : null
                            }
                            // onKeyPress={(e) =>
                            //   this.handleChange(Item.fieldName, e.target.value)
                            // }
                            key={index}
                          />
                        </>
                      );
                    }
                  )}
                </div>
              </Modal.Body>

              <span className="text-xs mt-1 text-danger ml-3">
                {" "}
                {this.state.error}
              </span>
              <hr className="mb-2 mt-2" />
              <Modal.Footer>
                <button
                  className="tab-title tab-title--active btn-hover color-3 active"
                  type="button"
                  onClick={() => this.AddSyncReqest()}
                >
                  {this.state.setIsLoad ? <ButtonLoader /> : "Add"}
                </button>
                <button
                  className="btn-hover color-3 tab-title"
                  type="button"
                  onClick={this.handleClose}
                >
                  Close
                </button>
              </Modal.Footer>
            </Modal>
          </>
        </div>
        <Modal className="scrolling-model" show={showModal} onHide={this.handleCloseFile}>
          <Modal.Header closeButton >
            <Modal.Title>File Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body >
            <div className="RequestDetails-modal-file">
              {this.state.fileToView && (
                <>
                  <div>
                    <FileViewer
                      fileType="csv" // Set the file type to csv
                      filePath={URL.createObjectURL(this.state.fileToView)}
                      onError={error => console.error('Error:', error)}
                    />
                  </div>
                </>
              )
              }
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
