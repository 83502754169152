//** Services */
import productImportServices from "../../services/domainServices/productImportServices";

//** Component  */
import { Constants } from "../../utils/Constants";
import BaseComponent from "../../shared/baseComponent";
import ProgressBarStyle from "../../styles/ProgressBarStyle";
import ProductImportLogs from "./progress-flow/ProductImportLogs";
import ProductImportRequest from "./progress-flow/ProductImportRequest";
import ProductImportInitiate from "./progress-flow/ProductImportInitiate";
import { decryptionData } from "../../../src/utils/Encryption";
import { DataFormat } from "../../utils/DateFormat";
import { Badge } from "react-bootstrap";
import React from "react";
import axios from "axios";
import { encryptionData } from '../../utils/Encryption'
export default class ProductImportDetail extends BaseComponent {
  constructor(props) {
    super(props);
    this.productImportServices = new productImportServices();
    this.state = {
      activeTab: Constants.RequestSections.RequestInitiate,
      pagination: this.productImportServices.pagination,
      getList: [],
      productImportId: 0,
      logList: [],
      inputFile: "",
      setIsLoad: false,
      productDataDetail: [],
      mapData: []
    };
    this.productImportServices.onUpdateState = this.updateState.bind(this);
  }


  componentDidMount() {
    let params = new URLSearchParams(window.location.search);
    let piId = params.get("");
    this.setState({ productImportId: piId });

    const decryptKey = decryptionData(piId, false);
    // *** getByID decryption ***//
    if (decryptKey > 0) {
      this.productImportServices.onGetById(decryptKey);
      this.productImportServices.onGetProductDataSyncListByProductId(decryptKey);
    }
  }

  setTab = (currentStage, currentProcess) => {
    if (currentProcess <= currentStage) {
      if (currentProcess === 1) {
        this.setState({
          activeTab: Constants.ProductDataSection.RequestInitiate,
        });
      } else if (currentProcess === 2) {
        this.setState({
          activeTab: Constants.ProductDataSection.RequestProductData,
        });
      } else if (currentProcess === 3) {
        this.setState({ activeTab: Constants.ProductDataSection.RequestLogs });
      }
    }
  };


  
  getStatusColorClass(statusName) {
    let textColorClass = "";
    switch (statusName) {
        case "Done":
            textColorClass = "success";
            break;
        case "Open":
            textColorClass = "warning";
            break;
        case "Start":
            textColorClass = "primary";
            break;
        case "InProgress":
            textColorClass = "info";
            break;
        case "Error":
            textColorClass = "danger";
            break;
        case "OnHold":
            textColorClass = "secondary";
            break;
        default:
            textColorClass = "primary";
            break;
    }
    return textColorClass;
}

onActionCall(type, data) {
    switch (type) {
      case "module":
        window.open(`/ProductDataSyncDetail/id?=${data}`, '_blank');
        break;
      default:
        break;
    }
  }

  render() {
    return (
      <>
        <div className="card">
          <div className="card-title-main px-3 py-2">
            <div className="row align-items-center">
              <div className="col-6">
                <h5 className="titletexts font-size-16">Request : {this.state.productDataDetail.name}</h5>
              </div>
            </div>
          </div>

          <div className="card-body custom-card-body">
            <div className="row">
              <div className="col-md-4">
                <div className="inner-detail-sec">
                  <span className="detail-title">Product Import Id </span>
                  &nbsp;
                  <span className="detail-desc font-size-16">
                    &nbsp;&nbsp;&nbsp;
                    {this.state.productDataDetail.productImportId}
                  </span>
                </div>
              </div>

              <div className="col-md-4 mb-2">
                <div className="inner-detail-sec">
                  <span className="detail-title">Created Date </span>
                  &nbsp;
                  <span className="detail-desc font-size-16">
                    &nbsp;&nbsp;&nbsp;
                    {DataFormat(
                      this.state.productDataDetail.createdDate,
                      "MM-DD-YYYY h:mm:ss"
                    )}
                  </span>
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <div className=" d-flex align-items-center">
                  {this.state.productDataDetail.isTutomerImport ?
                    <i className="fas fa-check text-success font-size-30"></i> :
                    <i className="fas fa-times text-danger font-size-30"></i>}
                  <span className="detail-title font-size-16">Is TutomerImport ?</span>
                </div>
              </div>
              <div className="col-md-4">
                <div className="inner-detail-sec">
                  <span className="detail-title">Process Time</span>
                  &nbsp;
                  <span className="detail-desc font-size-16">
                    &nbsp;&nbsp;&nbsp;
                    {this.state.productDataDetail.processTime}
                  </span>
                </div>
              </div>

              <div className="col-md-4">
                <div className="inner-detail-sec">
                  <span className="detail-title">Status </span>
                  &nbsp;
                  <span className="detail-desc font-size-16">
                    &nbsp;&nbsp;&nbsp;
                    {this.state.productDataDetail.statusName}
                  </span>
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <div className=" d-flex align-items-center">
                  {this.state.productDataDetail.isChemPropertiesImport ?
                    <i className="fas fa-check text-success font-size-30"></i> :
                    <i className="fas fa-times text-danger font-size-30"></i>}
                  <span className="detail-title font-size-16">Is ChemPropertiesImport ?</span>
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <div className=" d-flex align-items-center">
                  {this.state.productDataDetail.isPubChemSync ?
                    <i className="fas fa-check text-success font-size-30"></i> :
                    <i className="fas fa-times text-danger font-size-30"></i>}
                  <span className="detail-title font-size-16">Is PubChemSync ?</span>
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <div className=" d-flex align-items-center">
                  {this.state.productDataDetail.isProductUpdate ?
                    <i className="fas fa-check text-success font-size-30"></i> :
                    <i className="fas fa-times text-danger font-size-30"></i>}
                  <span className="detail-title font-size-16">Is ProductUpdate ?</span>
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <div className=" d-flex align-items-center">
                  {this.state.productDataDetail.isRegulation ?
                    <i className="fas fa-check text-success font-size-30"></i> :
                    <i className="fas fa-times text-danger font-size-30"></i>}
                  <span className="detail-title font-size-16">Is Regualtion ?</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ProgressBarStyle />
        <div className="mt-2 pt-2">
          <div id="progress" className="mb-0">
            <div id="progress-bar"></div>
            <ul className="scrapping-timeline" id="progress-check">
              <li
                className={
                  "step " +
                  (this.state.activeTab === Constants.ProductDataSection.RequestInitiate
                    ? "step active red-color"
                    : "")
                }
                onClick={() =>
                  this.setTab(1, Constants.ProductDataSection.RequestInitiate)
                }
              >
                <div className="main-timeline-sec">
                  <div className="icon-round">
                    <i className="fa fa-hourglass-start "></i>
                  </div>
                  <label>Initiate</label>
                </div>
              </li>

              <li
                className={
                  "step " +
                  (this.state.activeTab === Constants.ProductDataSection.RequestProductData
                    ? "step active green-color"
                    : "")
                }
                onClick={() =>
                  this.setTab(
                    2,
                    Constants.ProductDataSection.RequestProductData
                  )
                }
              >
                <div className="main-timeline-sec">
                  <div className="icon-round">
                    <i className="fa-brands fa-dropbox"></i>
                  </div>
                  <label>Product Import Files</label>
                </div>
              </li>

              <li
                className={
                  "step " +
                  (this.state.activeTab >= Constants.ProductDataSection.RequestLogs
                    ? "step active yellow-color"
                    : "")
                }
                onClick={() =>
                  this.setTab(3, Constants.ProductDataSection.RequestLogs)
                }
              >
                <div className="main-timeline-sec">
                  <div className="icon-round">
                    <i className="fa fa-link"></i>
                  </div>
                  <label>Logs</label>
                </div>
              </li>
            </ul>
          </div>
        </div>

          <div>
            {this.state.activeTab ===
              Constants.ProductDataSection.RequestInitiate ? (
              <ProductImportInitiate
                productDataDetail={this.state.productDataDetail}
                mapData={this.state.mapData}
              />

            ) : this.state.activeTab ===
              Constants.ProductDataSection.RequestProductData ? (
              <ProductImportRequest
                productDataDetail={this.state.productDataDetail}
              />
            ) : this.state.activeTab ===
              Constants.ProductDataSection.RequestLogs ? (
              <ProductImportLogs />
            ) : null} 
          </div>

{this.state.activeTab === Constants.ProductDataSection.RequestInitiate ||
 this.state.activeTab === Constants.ProductDataSection.RequestProductData ? (
    <div className='product-details-grid mt-3'>
        <table className="table table-shadow rpa_table bg-white table-fixed">
            <thead>
                <tr className='text-left'>
                    <th>mode</th>
                    <th>name</th>
                    <th>status</th>
                    <th>product data sync type</th>
                    <th>productDataSyncId</th>
                </tr>
            </thead>
            <tbody className="table-border-bottom-0 text-left">
                {this.state.mapData && this.state.mapData.length > 0 ? (
                    this.state.mapData.map((data, idx) => (
                        <React.Fragment key={idx}>
                            <tr>
                                <td>{data.mode}</td>
                                <td>{data.name}</td>
                                <td>
                                    <Badge pill bg={this.getStatusColorClass(data.statusName)}>{data.statusName}</Badge>
                                </td>
                                <td>{data.productDataSyncImportTypeName}</td>
                                <td>
                                <i className="fa fa-eye cursor-pointer actions-icon" title='view' onClick={() => this.onActionCall('module', encryptionData(data.productDataSyncId))}></i>
                                </td>
                            </tr>
                        </React.Fragment>
                    ))
                ) : (
                    <tr>
                        <td colSpan="5" className="d-flex justify-content-center p-3 font-size-16">No Data Found</td>
                    </tr>
                )}
            </tbody>
        </table>
    </div>
) : null}

         
                  
      </>
    );
  }
}
