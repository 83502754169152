//*** Lib */
// import { AESIVKey } from './AppSetting';

var AESKeyValue = process.env.REACT_APP_AESKEY;
var AESIVKey = process.env.REACT_APP_AESIVKEY;
var CryptoJS = require("crypto-js");
const Key = CryptoJS.enc.Base64.parse(AESKeyValue);
const IV = CryptoJS.enc.Base64.parse(AESIVKey);

// encryption for api request
export const encryptionAPI = (data, string) => {
    if (string === 1)
        data = JSON.stringify(data);
    var encprtArray = CryptoJS.enc.Utf8.parse(data);
    var decryptedText = CryptoJS.AES.encrypt(encprtArray, Key,
        {
            iv: IV,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
    return decryptedText.ciphertext.toString(CryptoJS.enc.based64);
}

// decryption for api response 
export const decryptionAPI = (res) => {
    var response = { ...res };
    if (response.isEnType !== undefined && response.isEnType !== "undefined" && response.isEnType !== "null" && response.isEnType !== null) {
        if (response.isEnType === true) {
            var appkey = Key;
            var appkeyvalue = IV;
            var cipherParams = CryptoJS.lib.CipherParams.create({
                ciphertext: CryptoJS.enc.Base64.parse(response.responseData)
            });
            var decryptedText = CryptoJS.AES.decrypt(cipherParams, appkey, {
                iv: appkeyvalue,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });
            var data = decryptedText.toString(CryptoJS.enc.Utf8)
            if (res.responseType === 0) {
                return { ...JSON.parse(data) };
            }
            else {
                return data.toString();
            }
        }
        else {
            return { ...JSON.parse(res.typeData) };
        }
    }
    else {
        return response;
    }
}

// encryption for query params
export const encryptionData = (data, string) => {

    if (string === 1)
        data = JSON.stringify(data);
    let encryptedData = CryptoJS.AES.encrypt(data.toString(), AESKeyValue).toString();
    encryptedData = encodeURIComponent(encryptedData); 
    encryptedData = encryptedData.replaceAll('/', 's2e0s21');
    return encryptedData;
}

// decryption for query params
export const decryptionData = (res, isObject) => {
    res = res.replaceAll('s2e0s21', '/');
    res = decodeURIComponent(res); 
    // res = res.replaceAll(' ', '+');
    const data = CryptoJS.AES.decrypt(res, AESKeyValue).toString(CryptoJS.enc.Utf8);
    if (isObject === true) {
        return { ...JSON.parse(data) };
    }
    else {
        return data.toString();
    }
}

