import React, { useState } from 'react';
import { DateRangePicker } from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';

const DateRangePickerComponent = ({ onChange }) => { // Receive onChange prop
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const handleDateRangeChange = (event, picker) => {
        setStartDate(picker.startDate);
        setEndDate(picker.endDate);

        // Call the onChange prop with the selected date range
        onChange({
            startDate: picker.startDate,
            endDate: picker.endDate
        });
    };

    const startDateFormatted = startDate ? moment(startDate).format('MM-DD-YYYY') : 'MM/DD/YYYY';
    const endDateFormatted = endDate ? moment(endDate).format('MM-DD-YYYY') : 'MM/DD/YYYY';

    return (
        <div>
            <DateRangePicker
                initialSettings={{ startDate: { startDate }, endDate: { endDate } }}
                onApply={handleDateRangeChange}
            >
                <input
                    type="text"
                    className="form-control"
                    value={`${startDateFormatted} - ${endDateFormatted}`}
                />
            </DateRangePicker>
        </div>
    );
};

export default DateRangePickerComponent;
