//** Component  */
import React from 'react';
import BaseComponent from '../../shared/baseComponent';
import DataAnalyzerProgress from './DataAnalyzerProgress';
import { DownloadInputFile } from '../../services/axiosServices/ApiEndPoints';
import FormFieldGroup from '../../components/UI Element/FormGrop/FormFieldGroup';
import DatabaseAnalyzerServices from '../../services/domainServices/databaseAnalyzerServices';
import { decryptionData } from '../../../src/utils/Encryption';


export default class DatabaseAnalyzerDetails extends BaseComponent {
    constructor(props) {
        super(props)
        this.analyzerServices = new DatabaseAnalyzerServices();

        this.analyzerServices.onUpdateState = this.updateState.bind(this);
        this.state = {
            analyzerDetails: [],
            totalProceedCount: 0,
            setIsLoad: true
        }
    }

    //** Constructor  and Component method */
    componentDidMount() {
        let params = new URLSearchParams(window.location.search);
        let reqId = params.get("");

        // *** getByID decryption ***//
        const decryptKey =  decryptionData(reqId,false); 
        if (decryptKey > 0) {
            this.analyzerServices.onGetAnalyzerDataId(decryptKey);
        }
    }


    render() {
        return (
            <>
                <div className="card">
                    <div className='card-title-main px-3 py-2'>
                        <div className='row align-items-center'>
                            <div className='col-6'>
                                <h5 className='titletexts font-size-16'>Request : {this.state.analyzerDetails.sourceDb}</h5>
                            </div>
                            <div className='col-md-6 text-end'>
                                <h6 className='titletexts fw-bold' style={{ border: '1px solid #fff', fontSize: '16px', padding: '10px', display: 'inline-block', borderRadius: '5px' }}>
                                    {this.state.analyzerDetails.dataAnalyzerTypeId}
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div className="card-body custom-card-body">
                        {this.state.setIsLoad ?
                            <div className="loader-inner line-scale-pulse-out-rapid" style={{ display: 'flex', justifyContent: 'center' }}>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div> :
                            <>
                                <div className="row">
                                    {this.analyzerServices.analyzerDetailForm.map((Item, index) => {
                                        return (
                                            <FormFieldGroup
                                                column={Item}
                                                value={this.state.analyzerDetails[Item.fieldName]}
                                                checked={this.state.analyzerDetails[Item.fieldName]} />)
                                    })}
                                </div>
                                <div className='inner-detail-sec'>
                                    <span className='detail-title' style={{ minWidth: 'auto' }}>Files :</span>
                                    {this.state.analyzerDetails.inputFile ?
                                        <>
                                            {this.state.analyzerDetails.inputFile.length > 0 ?
                                                <span for="requestName" className="detail-desc ml-3">
                                                    <a href={`${DownloadInputFile + `?FileName=${this.state.analyzerDetails.inputFile}&ModuleType=DataAnalyzer`}`} target="_top">
                                                        <i className="fa fa-download"></i></a>
                                                </span>
                                                :
                                                <span className='detail-desc'>
                                                    No Record found
                                                </span>
                                            }
                                        </>
                                        : <span className='detail-desc'>No Record found</span>
                                    }
                                </div>
                            </>
                        }
                    </div>
                </div>
                <DataAnalyzerProgress />
            </>
        );
    }
}
