
import { axiosGet, axiosGetFileAuthorizeblob, axiosPost, axiosPostAuthorize } from '../AxiosRequests';
//*** Lib */
import {
    AddRegulation, UpdateRegulation, GetRegulationById, GetAllRegulation, DeleteRegulation,ExportRegulationDetails,
    AddRegulationRequest, GetAllRegulationRequest, GetAllRegulationNameList, GetRegulationRequestById, GetRegulationOutputFileById
} from '../ApiEndPoints';

export default class RegulationService {

    async addRegulation(request) {
        return axiosPost(AddRegulation, request);
    }
    async updateRegulation(request) {
        return axiosPost(UpdateRegulation, request);
    }
    async getRegulationById(request) {
        return axiosGet(GetRegulationById, request);
    }
    async getAllRegulation(request) {
        return axiosPost(GetAllRegulation, request);
    }
    async deleteRegulation(request) {
        return axiosPost(DeleteRegulation, request);
    }

    async addRegulationRequest(request) {
        let formData = new FormData();
        formData.append("RequestName", request.requestName);
        formData.append("RegulationId", request.regulationId);
        formData.append("RegulationFile", request.regulationFile);
        formData.append("RegulationFileName", request.regulationFileName);
        formData.append("CreatedBy", request.createdBy);

        return axiosPostAuthorize(AddRegulationRequest, formData, true, request);
    }
    async getAllRegulationRequest(request) {
        return axiosPost(GetAllRegulationRequest, request);
    }
    async getAllRegulationNameList(request) {
        return axiosGet(GetAllRegulationNameList, request);
    }
    async getRegulationRequestById(request) {
        return axiosGet(GetRegulationRequestById, request);
    }
    async getRegulationOutputFileById(request) {
        return axiosGet(GetRegulationOutputFileById, request);
    }
    async exportRegulationDetails(request) {
        return axiosGetFileAuthorizeblob(ExportRegulationDetails, request);
    }
}