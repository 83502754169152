//** Component  */
import Table from "../../components/table/Table";
import BaseComponent from "../../shared/baseComponent";

//** Services */
import productDataSyncDomainServices from "../../services/domainServices/productDataSyncServices";
//*** Lib */
import { Bars } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { encryptionData } from '../../../src/utils/Encryption';
import FormFieldGroup from "../../components/UI Element/FormGrop/FormFieldGroup";
import ButtonLoader from "../../components/common/buttonLoader/ButtonLoader";
import moment from "moment";
import { saveAs } from 'file-saver';
import ProductDataSyncServices from "../../services/axiosServices/apiServices/ProductDataSyncServices";

export default class SyncData extends BaseComponent {
  constructor(props) {
    super(props);
    this.productDataSyncServices = new productDataSyncDomainServices();
   // this.domainServices=new productDataSyncDomainServices();
    this.state = {
      pagination: this.productDataSyncServices.pagination,
      getList: [],
      operationalFormField: this.productDataSyncServices.dataItem,
      setIsLoad: false,
      setModulelists: [],
      setStatuslists:[],
      isLoad: false,
    };
    this.productDataSyncServices.onUpdateState = this.updateState.bind(this);
    this.productDataSyncServices.onActionCall = this.onActionCall.bind(this);
    //this.domainServices.onActionCall=this.onActionCall.bind(this);
  }

  componentDidMount() {
    let detail = this.state.pagination;
    detail.productDataSyncImportTypeId = 0
    detail.statusId=0
    this.productDataSyncServices.onGetAllProductDataSync(detail);
    this.productDataSyncServices.onProductDataSyncImportType();
    this.productDataSyncServices.onGetAllStatus();

  }

  onActionCall = (type, data) => {
    // *** getByID encryption ***//
    const encryptKey = encryptionData(data, 1);
console.log(data);
    switch (type) {
      case "View":
        window.open(`/ProductDataSyncDetail/id?=${encryptKey}`, "_blank");
        break;
        case "Delete":
          this.productDataSyncServices.apiService.serviceManager.swalServices
              .Confirm("Are you sure, you want delete?", "","Yes", "No")
              .then(async (confirm) => {
                  if (confirm) {
                      let request = {
                        productDataSyncId:data,
                        deletedBy: 1,
                      };
                      this.productDataSyncServices.onDelete(request);
                  }
              });
          break;
      default:
        // Handle default case here
        break;
    }
  };

  exportReport = new ProductDataSyncServices();
  
  ExportReport = () => {
    
    this.setState({ isLoad : true})
    this.exportReport.exportProductSyncAnalyzer().then((res) => {
      var formattedDate = moment(new Date()).format("YYMMDD")
      var filename = 'Export report' + formattedDate;
      var blob = new Blob([res.data])
      saveAs(blob, filename + ".xlsx");
    })
    this.setState({ isLoad : false})
  }

  handleChange(name, value) {
    let detail = this.state.pagination;
    detail[name] = value;
    if (name === "productDataSyncImportTypeId") {
      this.productDataSyncServices.onGetAllProductDataSync(detail);
    }
    if (name === "statusId") {
      this.productDataSyncServices.onGetAllProductDataSync(detail);
    }
    if (name === "searchText") {
      detail[name] = value.trim();
      let data = { ...this.state.pagination };
      data.pageNo = 1;

      this.productDataSyncServices.onGetAllProductDataSync(data);
      this.setState({ pagination: { ...data } });
    }
    this.setState({ operationalFormField: { ...detail } });
  }

  render() {
    return (
      <div>
        <div className="top-search-add-button-sec mb-3">
          <div className="row left-searchbar-sec">
            {this.productDataSyncServices.formFieldsSearch.map((Item, index) => {
              return (
                <>
                  {Item.fieldName === "productDataSyncImportTypeId" ||
                    Item.fieldName === "searchText" ? (
                    <FormFieldGroup
                      className="col-md-4"
                      column={Item}
                      value={this.state.operationalFormField[Item.fieldName]}
                      options={Item.Options ? Item.Options : this.state.setModulelists}
                      onChange={(event) =>
                        this.handleChange(Item.fieldName, event.target.value)
                      }
                      key={index}
                    />
                  ) : null}
                   { Item.fieldName === "statusId"  ? 
                   (
                    <FormFieldGroup
                      className="col-md-6"
                      column={Item}
                     // value={this.state.operationalFormField[Item.fieldName]}
                      options={Item.Options ? Item.Options : this.state.setStatuslists}
                      onChange={(event) =>
                        this.handleChange(Item.fieldName, event.target.value)
                      }
                      key={index}
                    />
                  ) : null}
                </>
              );
            })}
          </div>
          <div className="right-button-sec">
          <button
              className="tab-title mt-0 btn-hover color-3 active mr-0 pro-import-btn"
              onClick={() => this.ExportReport()}
            >{this.state.isLoad ? <ButtonLoader /> : "Export Report" }
              <i className="fas fa-file ml-2"></i>
            </button>
            <Link
              to="/AddNewProductData"
              className="tab-title btn-hover color-3 active"
            >
              Add New Product Data<span>+</span>
            </Link>
          </div>
        </div>
        <div className="product-sync-list">
        {this.state.setIsLoad ? (
          <Bars
            className="row"
            wrapperStyle={{ marginTop: "150px" }}
            color="#3c4b64"
            height={55}
            width="100%"
            visible={this.state.setIsLoad}
          />
        ) : (
          <Table
            columns={this.productDataSyncServices.gridColmns}
            rows={this.state.getList}
            sortingColumns={[]}
            pagination={this.state.pagination}
            totalCount={this.state.TotalCount}
            totalResultes={this.state.TotalCount}
            setPagination={this.productDataSyncServices.onPageChange.bind(this)}
            customScope={[
              {
                column: "productDataSyncId",
                isDeleteRequired: true,
                renderTableData:
                  this.productDataSyncServices.gridSyncDataActionsCol.bind(this),
              },
            ]}
          />
        )}
        </div>
      </div>
    );
  }
}
