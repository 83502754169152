//*** Lib */
import { axiosPost,axiosGet, axiosPostAuthorize } from '../AxiosRequests';
import { AddMiddlewareRequest,GetOutputFile,GetAllRequestStatusById,UpdateRequestStatusAndEndTime , GetAllDashboardRunningRequest} from '../ApiEndPoints';

export default class MiddlewareRequestServices {

    async addMiddlewareRequest(request) {
        
        let formData = new FormData();
        formData.append("moduleId", request.moduleId);
        formData.append("statusId", request.statusId);
        formData.append("requestName", request.requestName);
        formData.append("isActive", request.isActive);
        formData.append("inputFile", request.inputFile);
        formData.append("outputFile", request.outputFile);
        formData.append("startDate", request.startDate);
        formData.append("endDate", request.endDate);
        formData.append("file", request.file);  
        formData.append("isCleaner", request.isCleaner);
        formData.append("createdBy", request.createdBy);
         formData.append("stageId", request.stageId);
        formData.append("isError", request.isError);
        formData.append("isDone", request.isDone);
        formData.append("supplierID", request.supplierID);
        formData.append("isScrapping", request.isScrapping);
        formData.append("isDBUpdates", request.isDBUpdates);
        formData.append("isCatalogCleaner", request.isCatalogCleaner);
        formData.append("requestType", request.requestType);
        formData.append("subType", request.subType);
        formData.append("isTor", request.isTor);
        formData.append("hostServerId", request.hostServerId);

        
        return axiosPostAuthorize(AddMiddlewareRequest, formData,true);
    }
    async GetOutputFile(request) {
        return axiosGet(GetOutputFile, request)
    }
    async getAllRequestStatusById(request) {
        return axiosGet(GetAllRequestStatusById, request)
    }
    async updateRequestStatusAndEndTime(request) {
        return axiosPost(UpdateRequestStatusAndEndTime, request)
    }
    async getAllDashboardRunningRequest(request) {
        return axiosGet(GetAllDashboardRunningRequest, request)
    }
}