
import { axiosGet, axiosGetFileAuthorizeblob, axiosPost, axiosPostAuthorize } from '../AxiosRequests';
//*** Lib */
import {
    AddProductImport, GetAllProductImport,GetAllStatus,DeleteProductImportByProductImportId, GetProductImportById, GetProductImportOutputFileById, GetLogsById , ExportChemindexAnalyzer, GetProductDataSyncListByProductId
} from '../ApiEndPoints';

export default class ProductImportServices {

    async getAllProductImport(request) {
        return axiosPost(GetAllProductImport, request);
    }

    async getProductImportById(request) {
        return axiosGet(GetProductImportById, request);
    }

    async exportChemindexAnalyzer(request) {
        return axiosGetFileAuthorizeblob(ExportChemindexAnalyzer, request);
    }

    async getProductImportOutputFileById(request) {
        return axiosGet(GetProductImportOutputFileById, request);
    }

    async getProductDataSyncListByProductId(request) {
        return axiosGet(GetProductDataSyncListByProductId, request);
    }

    async getAllStatus(request) {
        return axiosGet(GetAllStatus, request);
    }
    async getLogsById(request) {
        return axiosGet(GetLogsById, request);
    }
    async deleteProductImportByProductImportId(request) {
        return axiosPost(DeleteProductImportByProductImportId, request);
    }
    async addProductImport(request) {
        let formData = new FormData();
        formData.append("FileName", request.FileName);
        formData.append("File", request.File);
        formData.append("Name", request.Name);
        formData.append("CreatedBy", request.CreatedBy);
        formData.append("isTutomerImport", request.isTutomerImport);
        formData.append("isPubChemSync", request.isPubChemSync);
        formData.append("isChemPropertiesImport", request.isChemPropertiesImport);
        formData.append("isProductUpdate", request.isProductUpdate);
        formData.append("isRegulation", request.isRegulation);

        return axiosPostAuthorize(AddProductImport, formData, true, request);
    }
}

