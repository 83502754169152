//** Component  */
import Table from "../../components/table/Table";
import BaseComponent from "../../shared/baseComponent";

//** Services */
import serverDomainServices from "../../services/domainServices/serverServices";

//*** Lib */
import { Navigate } from "react-router";
import { Bars } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { encryptionData } from '../../../src/utils/Encryption';

export default class ServerClientConfigurations extends BaseComponent {
  constructor(props) {
    super(props);
    this.serverServices = new serverDomainServices();
    this.state = {
      pagination: this.serverServices.pagination,
      setIsLoad: false,
      dataList: [],
    };
    this.serverServices.onUpdateState = this.updateState.bind(this);
    this.serverServices.onActionCall = this.onActionCall.bind(this);
  }
  //** Constructor  and Component method */
  componentDidMount() {
    this.serverServices.onGetServer(this.state.pagination);
  }

  // *** Other function **//

  onActionCall = (type, data) => {
    
    // *** getByID encryption ***//
    const encryptKey = encryptionData(data, 1);

    switch (type) {
      case "Edit":
        window.open(`/AddServerConfigurations/id?=${encryptKey}`, "_blank");
        break;
      case "View":
        this.setState({ redirect: "/GetConfiguration" });
        break;
      case "Delete":
        this.serverServices.apiService.serviceManager.swalServices
          .Confirm(
            "Are you sure, you want delete Module?",
            + "",
            "Yes",
            "No"
          )
          .then(async (confirm) => {
            if (confirm) {
              let datas = {
                id: data,
              };
              this.serverServices.onDelete(datas);
            }
          });
        break;
        default:
          // Handle default case here
          break;
    }
  };

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <>
        <div className="">
          <div className="top-search-add-button-sec mb-3">
            <div className="row left-searchbar-sec"></div>
            <div className="right-button-sec">
              <Link
                to="/AddServerConfigurations"
                className="tab-title btn-hover color-3 active"
              >
                Add New Server Client <span>+</span>
              </Link>
            </div>
          </div>
          <div className="server-client-list">
          {this.state.setIsLoad ? (
            <Bars
              className="row"
              wrapperStyle={{ marginTop: "150px" }}
              color="#3c4b64"
              height={55}
              width="100%"
              visible={this.state.setIsLoad}
            />
          ) : (
            <Table
              columns={this.serverServices.gridColmns}
              rows={this.state.dataList}
              sortingColumns={[]}
              pagination={this.state.pagination}
              totalCount={this.state.TotalCount}
              totalResultes={this.state.TotalCount}
              setPagination={this.serverServices.onPageChange.bind(this)}
              customScope={[
                {
                  column: "isActive",
                  renderTableData:
                    this.serverServices.gridCheckBoxCol.bind(this),
                },
                {
                  column: "serverId",
                  isDeleteRequired: true,
                  renderTableData:
                    this.serverServices.gridActionsCol.bind(this),
                },
                {
                  column: "requestType",
                  renderTableData:
                    this.serverServices.gridRequestTypeCol.bind(this),
                },
              ]}
            />
          )}
          </div>
        </div>
      </>
    );
  }
}
