//*** Lib */
import { Validate, ValidateAll } from './Validation';

// validation function 
export const isValidForm = (formDetails, validationRule, validState) => {
    const validation = ValidateAll(formDetails, validationRule);
    validState = validation;
    return validState;
}

export const validate = (key, object, validationRule, validState) => {
    const validation = Validate(object, validationRule, key);
    const newErr = { ...validState.error };
    if (!validation.isValid) {
        newErr[key] = validation.error[key];
        validState = {
            isValid: false,
            error: newErr
        };
    } else {
        delete newErr[key]
        validState = {
            isValid: true,
            error: newErr
        };
    }
    return validState;
}


// ********** It's used for IP validation **********

// export const ValidateIPaddress = (key, object, validationRule, validState) => {
//     
//     const newErr = { ...validState.error };
//     if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(object.serverIP)) {
//         validState = {
//             isValid: true,
//             error: newErr
//         };
//     } else {
//         alert("You have entered an invalid IP address!");
//         validState = {
//             isValid: false,
//             error: newErr
//         };
//     }
//     return validState;
// }
