//*** Lib */
import { axiosGet, axiosPost, axiosPostAuthorize} from '../AxiosRequests';
import { AddSupplierComparison, GetSupplierComparisonById, GetSupplierComparisonList} from '../ApiEndPoints';

export default class SupplierComparisonServices {

    // API function
    async addSupplierComparison(request) {
        let formData = new FormData();
        formData.append("RequestName", request.RequestName);
        formData.append("ComparisonType", request.ComparisonType);
        formData.append("SourceInputFileName", request.SourceInputFileName);
        formData.append("DestinationInputFileName", request.DestinationInputFileName);
        formData.append("SourceInputFile", request.SourceInputFile);
        formData.append("CreatedBy", request.CreatedBy);
        formData.append("DestinationInputFile", request.DestinationInputFile);

        return axiosPostAuthorize(AddSupplierComparison, formData, true, request);
    }

    async getSupplierComparisonList(request) {
        return axiosPost(GetSupplierComparisonList, request);
    }

    async getSupplierComparisonById(request) {
        return axiosGet(GetSupplierComparisonById, request);
    }
}

