import React from "react";
import emailValidationDomainServices from "../../services/domainServices/emailValidationServices";
import BaseComponent from "../../shared/baseComponent";
import { Constants } from "../../utils/Constants";
import ProgressBarStyle from "../../styles/ProgressBarStyle";
import { decryptionData } from "../../utils/Encryption";
import EmailDataInitiate from "./progress-flow/EmailDataInitiate";
import EmailDataRequest from "./progress-flow/EmailDataRequest";
import EmailDataLogs from "./progress-flow/EmailDataLogs";
import { DataFormat } from "../../utils/DateFormat";

export default class EmailValidationDetail extends BaseComponent {
  constructor(props) {
    super(props);
    this.emailValidationServices = new emailValidationDomainServices();
    this.state = {
      activeTab: Constants.RequestSections.RequestInitiate,
      pagination: this.emailValidationServices.pagination,
      getList: [],
      logList: [],
      inputFile: "",
      setIsLoad: false,
      EmailData: [],
    };
    this.emailValidationServices.onUpdateState = this.updateState.bind(this);
  }

  setTab = (currentStage, currentProcess) => {
    if (currentProcess <= currentStage) {
      if (currentProcess === 1) {
        this.setState({
          activeTab: Constants.ProductDataSection.RequestInitiate,
        });
      } else if (currentProcess === 2) {
        this.setState({
          activeTab: Constants.ProductDataSection.RequestProductData,
        });
      } else if (currentProcess === 3) {
        this.setState({ activeTab: Constants.ProductDataSection.RequestLogs });
      }
    }
  };

  componentDidMount() {
    let params = new URLSearchParams(window.location.search);
    let pdsId = params.get("");
    // *** getByID decryption ***//
    const decryptKey = decryptionData(pdsId, false);
    this.setState({ productValidationId: decryptKey });

    if (decryptKey > 0) {
      this.emailValidationServices.onGetById(decryptKey);
    }
  }

  render() {
    return (
      <>
        <div className="card">
          <div className="card-title-main px-3 py-2">
            <div className="row align-items-center">
              <div className="col-6">
                <h5 className="titletexts font-size-16">Request : {this.state.EmailData.requestName}</h5>
              </div>
            </div>
          </div>

          {/* <div className="inner-detail-sec">
                  <span className="detail-title">Request Name</span>
                  &nbsp;
                  <span className="detail-desc">
                    :&nbsp;&nbsp;&nbsp;
                    {this.state.productDataDetail.name}
                  </span>
                </div> */}

          <div className="card-body custom-card-body">
            <div className="row">
              <div className="col-md-4 mb-3">
                <div className="inner-detail-sec">
                  <span className="detail-title">Type  </span>
                  &nbsp;&nbsp;&nbsp;<span className="font-size-16">{this.state.EmailData.name}</span>
                </div>
              </div>
              <div className="col-md-4">
                <div className="inner-detail-sec">
                  <span className="detail-title">Status  </span>
                  &nbsp;&nbsp;&nbsp;<span className="font-size-16">{this.state.EmailData.statusName}</span>
                </div>
              </div>
              {this.state.EmailData.name === "Single" && (
                <div className="col-md-4">
                  <div className="inner-detail-sec">
                    <span className="detail-title">Email Status  </span>
                    &nbsp;&nbsp;&nbsp;<span className="font-size-16">{this.state.EmailData.emailStatus}</span>
                  </div>
                </div>
              )}
              <div className="col-md-4">
                <div className="inner-detail-sec">
                  <span className="detail-title">Created Date </span>
                  &nbsp;&nbsp;&nbsp;
                  <span className="font-size-16">{DataFormat(
                    this.state.EmailData.createdDate,
                    "MM-DD-YYYY h:mm:ss"
                  )}</span>
                </div>
              </div>

              {this.state.EmailData.name === "Single" ? (
                <>
                  <div className="col-md-4 mb-3">
                    <div className="inner-detail-sec">
                      <span className="detail-title">Email Address </span>
                      &nbsp;&nbsp;&nbsp;<span className="font-size-16">{this.state.EmailData.emailAddress}</span>
                    </div>
                  </div>
                  <div className="col-md-4 mb-3">
                    <div className="inner-detail-sec">
                      <span className="detail-title">User </span>
                      &nbsp;&nbsp;&nbsp;<span className="font-size-16">{this.state.EmailData.userName}</span>
                    </div>
                  </div>
                  <div className="col-md-4 mb-3">
                    <div className="inner-detail-sec">
                      <span className="detail-title">Domain </span>
                      &nbsp;&nbsp;&nbsp;<span className="font-size-16">{this.state.EmailData.domainName}</span>
                    </div>
                  </div>
                  <div className="col-md-4 mb-3">
                    <div className="inner-detail-sec">
                      <span className="detail-title">MX Record </span>
                      &nbsp;&nbsp;&nbsp;<span className="font-size-16">{this.state.EmailData.mxRecord}</span>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>

        {this.state.EmailData.name === "Bulk" ? (
          <>
            <ProgressBarStyle />

            <div className="mt-2 pt-2">
              <div id="progress" className="mb-0">
                <div id="progress-bar"></div>
                <ul className="scrapping-timeline" id="progress-check">
                  <li
                    className={
                      "step " +
                      ((this.state.activeTab ===
                        Constants.ProductDataSection.RequestInitiate) 
                        ? "step active red-color"
                        : "")
                    }
                    onClick={() =>
                      this.setTab(
                        1,
                        Constants.ProductDataSection.RequestInitiate
                      )
                    }
                  >
                    <div className="main-timeline-sec">
                      <div className="icon-round">
                        <i className="fa fa-hourglass-start "></i>
                      </div>
                      <label>Initiate</label>
                    </div>
                  </li>

                  <li
                    className={
                      "step " +
                      ((this.state.activeTab ===
                        Constants.ProductDataSection.RequestProductData) 
                        ? "step active green-color"
                        : "")
                    }
                    onClick={() =>
                      this.setTab(
                        2,
                        Constants.ProductDataSection.RequestProductData
                      )
                    }
                  >
                    <div className="main-timeline-sec">
                      <div className="icon-round">
                        <i className="fa-brands fa-dropbox"></i>
                      </div>
                      <label>Email Validation Files</label>
                    </div>
                  </li>

                  <li
                    className={
                      "step " +
                      (this.state.activeTab >=
                      Constants.ProductDataSection.RequestLogs
                        ? "step active yellow-color"
                        : "")
                    }
                    onClick={() =>
                      this.setTab(3, Constants.ProductDataSection.RequestLogs)
                    }
                  >
                    <div className="main-timeline-sec">
                      <div className="icon-round">
                        <i className="fa fa-link"></i>
                      </div>
                      <label>Logs</label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div>
              {this.state.activeTab ===
              Constants.ProductDataSection.RequestInitiate ? (
                <EmailDataInitiate EmailData={this.state.EmailData} />
              ) : this.state.activeTab ===
                Constants.ProductDataSection.RequestProductData ? (
                <EmailDataRequest EmailData={this.state.EmailData} />
              ) : this.state.activeTab ===
                Constants.ProductDataSection.RequestLogs ? (
                <EmailDataLogs />
              ) : null}
            </div>
          </>
        ) : null}

        {this.state.EmailData.name === "Single" ? (
          <>
            <ProgressBarStyle />
            <div className="mt-5">
              <EmailDataLogs />
            </div>
          </>
        ) : null}
      </>
    );
  }
}
