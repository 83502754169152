import { Link } from "react-router-dom";
import { Bars } from "react-loader-spinner";

//** Lib's */
import Table from "../../../components/table/Table";
import BaseComponent from "../../../shared/baseComponent";
import { encryptionData } from "../../../utils/Encryption";
import RegulationService from '../../../services/axiosServices/apiServices/RegulationService'
import { saveAs } from 'file-saver';
import FileViewer from 'react-file-viewer';

//** Service */
import RegulationDomainService from "../../../services/domainServices/regulationDomainService";
import moment from "moment";

export default class ManageRegulation extends BaseComponent {

    constructor(props) {
        super(props);
        this.domainServices = new RegulationDomainService();
        this.RegulationService = new RegulationService();
        this.state = {
            getList: [],
            setIsLoad: false,
            pagination: this.domainServices.pagination,
            parsedData: [], // Initialize parsedData here,
            fileToView: null,
            showModal: false,

        };
        this.domainServices.onUpdateState = this.updateState.bind(this);
        this.domainServices.onActionCall = this.onActionCall.bind(this);
    }

    componentDidMount() {
        this.domainServices.onGetList(this.state.pagination);
    }

    handleclose = () => {
        this.setState({ showModal: false });
        this.setState({ fileToView: null });
    }

    //** Action Method's */
    onActionCall = (type, data) => {
        const encryptKey = encryptionData(data, 1);
        switch (type) {
            case "Edit":
                window.open(`/AddRegulation/id?=${encryptKey}`, "_blank");
                break;
            case "Delete":
                this.domainServices.apiService.serviceManager.swalServices
                    .Confirm("Are you sure, you want delete regulation?", "","Yes", "No")
                    .then(async (confirm) => {
                        if (confirm) {
                            let request = {
                                Id: data,
                            };
                            this.domainServices.onDelete(request);
                        }
                    });
                break;
            case "download":
                let request = this.state.getList.find(
                    (item) => item.regulationID
                ).regulationID
                this.RegulationService.exportRegulationDetails(request).then((res) => {
                    var formattedDate = moment(new Date()).format("YYMMDD")
                    var filename = 'Counts' + formattedDate;
                    var blob = new Blob([res.data])
                    saveAs(blob, filename + ".xlsx");
                })
                break;
            case 'viewfile':
                let request1 = this.state.getList.find(item => item.regulationID).regulationID;
                this.RegulationService.exportRegulationDetails(request1).then(res => {
                    var blob = new Blob([res.data]);
                    this.setState({ fileToView: blob });
                    this.setState({ showModal: true });
                });
                break;
            default:
                break;
        }
    };

    render() {
        return (
            <div>
                <div className="top-search-add-button-sec mb-3">
                    <div className="row left-searchbar-sec"></div>
                    <div className="right-button-sec">
                        <Link to="/AddRegulation" className="tab-title btn-hover color-3 active" >
                            Add <span>+</span>
                        </Link>
                    </div>
                </div>
                <div className="regulations-list">
                    {this.state.setIsLoad ? (
                        <Bars
                            className="row"
                            wrapperStyle={{ marginTop: "150px" }}
                            color="#3c4b64"
                            height={55}
                            width="100%"
                            visible={this.state.setIsLoad}
                        />
                    ) : (
                        <Table
                            columns={this.domainServices.gridColmns}
                            rows={this.state.getList}
                            sortingColumns={[]}
                            pagination={this.state.pagination}
                            totalCount={this.state.TotalCount}
                            totalResultes={this.state.TotalCount}
                            setPagination={this.domainServices.onPageChange.bind(this)}
                            customScope={[
                                {
                                    column: "regulationID",
                                    isDeleteRequired: true,
                                    renderTableData: this.domainServices.gridActionsCol.bind(this),
                                },
                                {
                                    column: "isEnable",
                                    renderTableData: this.domainServices.gridCheckBoxCol.bind(this),
                                },
                                {
                                    column: "productDetailCounts",
                                    regulationData: this.state.getList,
                                    renderTableData: this.domainServices.gridCountsAndDownloadCol.bind(this)
                                },
                            ]}
                        />
                    )}
                </div>
                <div className="regulation-card">
                {this.state.showModal &&
                    <div className="card-regulation-popup">
                        {this.state.fileToView && (
                            <>
                            <i className="fa fa-close cursor-pointer regulation-close" onClick={this.handleclose}></i>
                            <div>
                                <FileViewer
                                    fileType="xlsx"
                                    filePath={URL.createObjectURL(this.state.fileToView)}
                                    onError={error => console.error('Error:', error)}
                                />
                            </div>
                            </>
                        )
                        }
                    </div>
                }
                </div>
            </div>
        );
    }
}
