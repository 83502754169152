
import { axiosGet, axiosPost } from '../AxiosRequests';
//*** Lib */
import {
    GetAllModuleNameList , GetModuleFileNameList , DeleteFileByModuleType 
} from '../ApiEndPoints';

export default class FileManagerServices {

    async getAllModuleNameList(request) {
        return axiosGet(GetAllModuleNameList, request);
    }

    async getModuleFileNameList(request) {
        return axiosPost(GetModuleFileNameList, request);
    }

    async deleteFileByModuleType (request) {
        return axiosPost(DeleteFileByModuleType , request);
    }

}

