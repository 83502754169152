import React from 'react';
import { Navigate } from 'react-router-dom';
import { Bars } from 'react-loader-spinner';

//** Lib's */
import Button from '../../../components/common/Button';
import { ErrorMessage } from '../../../utils/Messages';
import BaseComponent from "../../../shared/baseComponent";
import FormFieldGroup from '../../../components/UI Element/FormGrop/FormFieldGroup';

//** Service's */
import RegulationDomainService from '../../../services/domainServices/regulationDomainService';

export default class AddRegulationRequest extends BaseComponent {

    constructor(props) {
        super(props)
        this.domainServices = new RegulationDomainService();
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            ...this.state,
            setIsLoad: false,
            regulationNameList: [],
            formField: this.domainServices.regulationDataItem
        }
        this.domainServices.onUpdateState = this.updateState.bind(this);
    }

    componentDidMount() {
        this.domainServices.onGetRegulationNameList();
        this.domainServices.onRegulationInitService();
    }

    //** Handle Changes */
    handleChange(name, value) {
        let detail = this.state.formField;
        detail[name] = value;
        this.setState({ formField: detail });
    }

    handleFileChange(event) {
        const { name } = event.target;
        const File = event.target.files[0];
        if (File && (File.type === "text/csv" || File.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
            if (File.size > 0) {
                let updatedState = { ...this.state.formField };

                if (name === 'regulationFile') {
                    updatedState = {
                        ...updatedState,
                        regulationFile: File,
                        regulationFileName: File.name,
                        FileName: File.name,
                    };
                    this.setState({ formField: updatedState });
                }
            }
        } else {
            this.domainServices.apiService.serviceManager.swalServices.Toaster(ErrorMessage.InvalidFileTypeCSV);
        }
    }

    //** API's Call */
    add = () => {
        if (this.domainServices.isValidSubmit(this.state.formField, this.state.validationRules, this.state.validState)) {
            let request = {
                requestName: this.state.formField.requestName,
                regulationId: this.state.formField.regulationId,
                regulationFile: this.state.formField.regulationFile,
                regulationFileName: this.state.formField.regulationFileName,
                createdBy: this.state.formField.createdBy,
            }
            this.domainServices.onAddRegulationRequest(request);
        }
    }


    render() {
        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} />
        }
        return (
            <div className="mb-4">
                <div className="accordion" id="accordionExample">
                    <div className="card accordion-item active">
                        <div id="accordionOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <div className="card-body">
                                    {this.state.setIsLoad ?
                                        <Bars
                                            className="row"
                                            wrapperStyle={{ marginTop: "150px" }}
                                            color="#3c4b64"
                                            height={55}
                                            width="100%"
                                            visible={this.state.setIsLoad}
                                        /> :
                                        <div className="row mb-4">
                                            {this.domainServices.regulationFormFields.map((Item, index) => {
                                                return (
                                                    <>
                                                        <FormFieldGroup
                                                            column={Item}
                                                            value={this.state.formField[Item.fieldName]}
                                                            error={this.state.validState.error[Item.fieldName]}
                                                            options={Item.options ? this.state.regulationNameList : null}
                                                            onChange={Item.inputType === "file" ? (event) => this.handleFileChange(event)
                                                                : (event) => this.handleChange(Item.fieldName, event.target.value)}
                                                            key={index}
                                                            onBlur={() => this.domainServices.validateField(Item.fieldName, this.state.formField, this.state.validationRules, this.state.validState)}
                                                        />
                                                    </>
                                                );

                                            })}
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="addnewuser-button mb-4">
                                <button className="tab-title tab-title--active  btn-hover  color-3 active" onClick={() => this.add()}> Save </button>
                                <Button type='cancel' title="Cancel" className="ml-2" onClick={() => this.domainServices.onRedirectoRegulationRequestList()} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}