import React from 'react'
import BaseComponent from '../../shared/baseComponent'
import SupplierComparisonServices from '../../services/domainServices/supplierComparisonServices';
import { ErrorMessage } from '../../utils/Messages';
import { Navigate } from 'react-router-dom';
import ButtonLoader from '../../components/common/buttonLoader/ButtonLoader';
import Button from '../../components/common/Button';
import FormFieldGroup from '../../components/UI Element/FormGrop/FormFieldGroup';

export default class AddSupplierComparison extends BaseComponent {
  constructor(props) {
    super(props);
    this.supplierServices = new SupplierComparisonServices();
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      ...this.state,
      supplierDetail: this.supplierServices.dataItem,
      setIsLoad: false
    };
    this.supplierServices.onUpdateState = this.updateState.bind(this);
  }

  handleChange(name, value) {
    let detail = this.state.supplierDetail;
    detail[name] = value;
    this.setState({ supplierDetail: { ...detail } });
  }

  handleFileChange = event => {
    const { name, files } = event.target;
    const File = files[0];
    if (File && (File.type === "text/csv" || File.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
      if (File.size > 0) {
        this.setState(prevState => ({
          supplierDetail: {
            ...prevState.supplierDetail,
            [name]: event.target.value,
            inputFile: File.name,
            FileName: File.name, // Add FileName property for PDF
            [name === "SourceInputFile" ? "inputScriptFile" : "inputScriptFileNew"]: File
          }
        }));
      }
    } else {
      this.supplierServices.apiService.serviceManager.swalServices.Toaster(ErrorMessage.InvalidFileTypeCSV);
    }
  };

  componentDidMount() {
    this.supplierServices.onInitService();
  }

  add = () => {
    if (this.supplierServices.isValidSubmit(this.state.supplierDetail, this.state.validationRules, this.state.validState)) {
      let request = {
        RequestName: this.state.supplierDetail.RequestName,
        ComparisonType: this.state.supplierDetail.ComparisonType,
        SourceInputFileName: this.state.supplierDetail.SourceInputFileName,
        DestinationInputFileName: this.state.supplierDetail.DestinationInputFileName,
        SourceInputFile: this.state.supplierDetail.inputScriptFile,
        DestinationInputFile: this.state.supplierDetail.inputScriptFileNew,
        CreatedBy: this.state.supplierDetail.CreatedBy,
      }
      this.supplierServices.onAdd(request);
    }
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />
    }
    return (
      <div className="accordion" id="accordionExample">
        <div className="card accordion-item active">
          <div id="accordionOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
            <div className="accordion-body">
              <div className="card-body">
                <div className="row mb-3 align-items-center">
                  <div className='col-md-12'>
                    <div className='row'>
                      {this.supplierServices.formFields.map((Item, index) => {
                        return (
                          <>
                            {/* Conditionally render based on serverLocationId */}
                            <FormFieldGroup
                              column={Item}
                              value={this.state.supplierDetail[Item.fieldName]}
                              error={this.state.validState.error[Item.fieldName]}
                              options={Item.Options ? Item.Options : null}
                              onKeyDown={Item.onkeydown ? Item.onkeydown.bind(this) : null}
                              onChange={Item.inputType === 'checkbox' ? (event) => this.handleChange(Item.fieldName, event.target.checked)
                                : Item.inputType === "file" ? (event) => this.handleFileChange(event) :
                                  Item.inputType === "select" || Item.inputType === "text" ? (event) => this.handleChange(Item.fieldName, event.target.value)
                                    : (event) => this.handleChange(Item.fieldName, event.target.value) }
                            // onBlur={() => this.supplierServices.validateField(Item.fieldName, this.state.supplierDetail, this.state.validationRules, this.state.validState)}
                            />
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className="addnewuser-button mb-4">
              <button
                className="tab-title tab-title--active  btn-hover  color-3 active"
                onClick={() => this.add()}
              >{this.state.setIsLoad ? <ButtonLoader /> : "Save"}</button>
              <Button type='cancel' title="Cancel" className="ml-2" onClick={() => this.supplierServices.onRedirectoList()} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
