//** Component  */
import React from "react";
import BaseComponent from "../../shared/baseComponent";

//** Services */
import UserDomainServices from "../../services/domainServices/userServices";

//*** Lib */
import { Navigate } from "react-router";
import Button from "../../components/common/Button";
import FormFieldGroup from "../../components/UI Element/FormGrop/FormFieldGroup";
import ButtonLoader from "../../components/common/buttonLoader/ButtonLoader";
import { decryptionData } from "../../../src/utils/Encryption";

export default class AddNewUsers extends BaseComponent {
  //** Constructor  and Component method */
  constructor(props) {
    super(props);
    this.userdmServices = new UserDomainServices();
    this.userdmServices.onUpdateState = this.updateState.bind(this);
    this.state = {
      ...this.state, // Need to use parent state
      updateUser: "",
      addUserDetails: this.userdmServices.dataItem,
      userReset: this.userdmServices.userResetItem,
      resetValidationRule: {},
      setIsLoad: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    this.userdmServices.onInitService();
    let params = new URLSearchParams(window.location.search);
    let userId = params.get("");

    // *** getByID decryption ***//
    if (userId !== null && userId !== undefined) {
      const decryptKey = decryptionData(userId, false);
      if (decryptKey > 0) {
        this.userdmServices.onGetByid(decryptKey);
      }
    }
  }

  // *** handleChange function **//
  handleChanges(name, value) {
    let detail = this.state.userReset;
    detail[name] = value;
    this.setState({ userReset: { ...detail } });
  }
  handleChange(name, value) {
    let detail = this.state.addUserDetails;
    detail[name] = value;
    this.setState({ addUserDetails: { ...detail } });
  }

  //** API Function  */
  addNewUser = () => {
    if (
      this.userdmServices.isValidSubmit(
        this.state.addUserDetails,
        this.state.validationRules,
        this.state.validState
      )
    ) {
      this.userdmServices.onAdd(this.state.addUserDetails);
    }
  };
  UpdateUserpassword = () => {
    let params = new URLSearchParams(window.location.search);
    let userId = params.get("");
    if (
      this.userdmServices.isValidSubmit(
        this.state.userReset,
        this.state.resetValidationRule,
        this.state.validState
      )
    ) {
      let request = {
        userId: userId,
        password: this.state.userReset.password,
        confirmPassword: this.state.userReset.confirmPassword,
      };
      this.userdmServices.onUpdatePassword(request);
    }
  };
  updateUserDetail = () => {
    let request = {
      userId: this.state.addUserDetails.userId,
      userName: this.state.addUserDetails.userName,
      firstName: this.state.addUserDetails.firstName,
      lastName: this.state.addUserDetails.lastName,
      phoneNo: this.state.addUserDetails.phoneNo,
      designation: this.state.addUserDetails.designation,
      updatedBy: this.userdmServices.authUser.adminUserId,
      isActive: this.state.addUserDetails.isActive,
      hashedPassword: this.state.addUserDetails.hashedPassword,
      userType: this.state.addUserDetails.userType,
    };
    if (
      this.userdmServices.isValidSubmit(
        this.state.addUserDetails,
        this.state.validationRules,
        this.state.validState
      )
    ) {
      this.userdmServices.onUpdate(request);
    }
  };

  //** Render Method  */
  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <>
        <div className="card mb-30">
          <div className="card-body custom-card-body">
            <div className="row">
              {this.userdmServices.formFields.map((Item, index) => {
                return (
                  <>
                    <FormFieldGroup
                      column={Item}
                      value={this.state.addUserDetails[Item.fieldName]}
                      error={this.state.validState.error[Item.fieldName]}
                      onChange={(event) =>
                        Item.checked !== true
                          ? this.handleChange(
                              Item.fieldName,
                              event.target.value
                            )
                          : this.handleChange(
                              Item.fieldName,
                              event.target.checked
                            )
                      }
                      options={Item.Options ? Item.Options : null}
                      checked={
                        Item.checked === true
                          ? this.state.addUserDetails[Item.fieldName]
                          : null
                      }
                      onBlur={() =>
                        this.userdmServices.validateField(
                          Item.fieldName,
                          this.state.addUserDetails,
                          this.state.validationRules,
                          this.state.validState
                        )
                      }
                      onKeyDown={
                        Item.onkeydown ? Item.onkeydown.bind(this) : null
                      }
                      key={index}
                    />
                  </>
                );
              })}
              <div className="col-md-12 addnewuser-button">
                {this.state.updateUser ? (
                  <button
                    className="tab-title tab-title--active ml-2 btn-hover color-3 active"
                    onClick={() => this.updateUserDetail()}
                  >
                    {this.state.setIsLoad ? <ButtonLoader /> : "Update "}{" "}
                  </button>
                ) : (
                  <button
                    className="tab-title tab-title--active ml-2 btn-hover color-3 active"
                    onClick={() => this.addNewUser()}
                  >
                    {this.state.setIsLoad ? <ButtonLoader /> : "Add "}{" "}
                  </button>
                )}
                <Button
                  type="cancel"
                  title="Cancel"
                  className="btn-hover color-3 tab-title ml-2"
                  onClick={() => this.userdmServices.onRedirectoList()}
                />
              </div>
            </div>
          </div>
        </div>
        {this.state.updateUser ? (
          <div className="card">
            <div className="card-title ">
              <h4 className="card-header">Reset Password</h4>
            </div>
            <div className="card-body custom-card-body">
              <div className="row">
                {this.userdmServices.PassformFields.map((Item, index) => {
                  return (
                    <>
                      <FormFieldGroup
                        column={Item}
                        value={this.state.userReset[Item.fieldName]}
                        error={this.state.validState.error[Item.fieldName]}
                        onChange={(event) =>
                          this.handleChanges(Item.fieldName, event.target.value)
                        }
                        onBlur={() =>
                          this.userdmServices.validateField(
                            Item.fieldName,
                            this.state.userReset,
                            this.state.resetValidationRule,
                            this.state.validState
                          )
                        }
                        onKeyDown={
                          Item.onkeydown ? Item.onkeydown.bind(this) : null
                        }
                        key={index}
                      />
                    </>
                  );
                })}
              </div>
              <div className="col-md-12 d-flex justify-content-end new-btn-width">
                <button
                  className="tab-title tab-title--active ml-2 btn-hover color-3 active min-width-250"
                  onClick={() => this.UpdateUserpassword()}
                >
                  {this.state.setIsLoad ? <ButtonLoader /> : "Update Password"}{" "}
                </button>
                <button
                  className="tab-title tab-title--active ml-2 btn-hover color-3 active"
                  onClick={() => this.userdmServices.onRedirectoList()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}
