//** Component  */
import React from "react";

//** Services */
import productDataSyncDomainServices from "../../../services/domainServices/productDataSyncServices";

//*** Lib */
import BaseComponent from "../../../shared/baseComponent";
import { DownloadInputFile } from "../../../services/axiosServices/ApiEndPoints";
import { decryptionData } from "../../../utils/Encryption";
import axios from 'axios';
import FileViewer from 'react-file-viewer';
import { Modal } from "react-bootstrap";
export default class ProductDataRequest extends BaseComponent {
  constructor(props) {
   
    super(props);
    this.productDataSyncServices = new productDataSyncDomainServices();
    this.state = {
      setIsLoad: false,
      outputFileList: [],
      // productDataSyncDetail: []
      showModal: false,
      fileToView: null,
      fileContent: null,
    };
    this.productDataSyncServices.onUpdateState = this.updateState.bind(this);
  }

  //** Constructor  and Component method */
  componentDidMount() {
    let params = new URLSearchParams(window.location.search);
    let pdsId = params.get("");
    const decryptKey = decryptionData(pdsId, false);
    if (decryptKey > 0) {
      this.productDataSyncServices.onGetProductDataOutputFileById(decryptKey);
    }
  }

  handleCloseFile = () => {
    this.setState({ showModal: false })
    this.setState({ fileToView: null });
  }

  previewFile = (data) => {
    const downloadUrl = `${DownloadInputFile}?FileName=${encodeURIComponent(data)}&ModuleType=ProductSyncOutput`;
    this.setState({ setIsLoad: true });
    axios.get(downloadUrl, { responseType: 'blob' })
      .then(response => {
        this.setState({ fileToView: response.data });
        this.setState({ showModal: true });
      })
      .catch(error => {
        console.error('Error downloading the file:', error);
      });
  };

  render() {
    const { showModal } = this.state
    return (
      <>
        <div className="card">
          <div className="card-title-main px-3 py-2">
            <h6 className="titletexts font-size-16">Output Files</h6>
          </div>
          <div className="card-body custom-card-body">

            <div className="row">
              <div className="col-md-6 mb-3">
                <div className="inner-detail-sec">
                  <span className="detail-title">Total Counts </span>
                  <span className="detail-desc">
                    &nbsp;&nbsp;&nbsp;
                    {this.props.productDataSyncDetail.totalCounts}
                    {this.state.outputFileList.map((filesDetail, index) => (
                      <>
                        {filesDetail.outputFileTypeId === 3 &&
                          this.props.productDataSyncDetail.totalCounts > 0 ? (
                          <span for="requestName" className="detail-desc ml-3 font-size-16">
                            <a
                              href={`${DownloadInputFile +
                                `?FileName=${filesDetail.fileName}&ModuleType=ProductSyncOutput`
                                }`}
                              target="_top"
                            >
                              {" "}
                              <i className="fa fa-download"></i>
                            </a>
                            <img src="/csv.png" className="csv-file ml-2 cursor-pointer" title="Preview" alt="Text icon" onClick={() => this.previewFile(filesDetail.fileName)} />
                          </span>
                        ) : null}
                      </>
                    ))}
                  </span>
                </div>
              </div>
              {this.props.productDataSyncDetail.productDataSyncImportTypeId === 5 ?
                <>
                  <div className="col-md-6 mb-3">
                    <div className="inner-detail-sec">
                      <span className="detail-title">LinkUp Counts </span>
                      <span className="detail-desc">
                        &nbsp;&nbsp;&nbsp;
                        {this.props.productDataSyncDetail.linkUpCounts}
                        {this.state.outputFileList.map((filesDetail, index) => (
                          <>
                            {filesDetail.outputFileTypeId === 6 &&
                              this.props.productDataSyncDetail.linkUpCounts > 0 ? (
                              <span for="requestName" className="detail-desc ml-3 font-size-16">
                                <a
                                  href={`${DownloadInputFile +
                                    `?FileName=${filesDetail.fileName}&ModuleType=ProductSyncOutput`
                                    }`}
                                  target="_top"
                                >
                                  {" "}
                                  <i className="fa fa-download"></i>
                                </a>
                                <img src="/csv.png" className="csv-file ml-2 cursor-pointer" title="Preview" alt="Text icon" onClick={() => this.previewFile(filesDetail.fileName)} />
                              </span>
                            ) : null}
                          </>
                        ))}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="inner-detail-sec">
                      <span className="detail-title">Non LinkUp Counts </span>
                      <span className="detail-desc">
                        &nbsp;&nbsp;&nbsp;
                        {this.props.productDataSyncDetail.nonLinkUpCounts}
                        {this.state.outputFileList.map((filesDetail, index) => (
                          <>
                            {filesDetail.outputFileTypeId === 7 &&
                              this.props.productDataSyncDetail.nonLinkUpCounts > 0 ? (
                              <span for="requestName" className="detail-desc ml-3 font-size-16">
                                <a
                                  href={`${DownloadInputFile +
                                    `?FileName=${filesDetail.fileName}&ModuleType=ProductSyncOutput`
                                    }`}
                                  target="_top"
                                >
                                  {" "}
                                  <i className="fa fa-download"></i>
                                </a>
                                <img src="/csv.png" className="csv-file ml-2 cursor-pointer" title="Preview" alt="Text icon" onClick={() => this.previewFile(filesDetail.fileName)} />
                              </span>
                            ) : null}
                          </>
                        ))}
                      </span>
                    </div>
                  </div>
                </>
                :
                <>
                  <div className="col-md-6">
                    <div className="inner-detail-sec">
                      <span className="detail-title">Non Existing Counts </span>
                      <span className="detail-desc">
                        &nbsp;&nbsp;&nbsp;
                        {this.props.productDataSyncDetail.nonExistingCounts}
                        {this.state.outputFileList.map((filesDetail, index) => (
                          <>
                            {filesDetail.outputFileTypeId === 1 ? (
                              <span for="requestName" className="detail-desc font-size-16">
                                <a
                                  href={`${DownloadInputFile +
                                    `?FileName=${filesDetail.fileName}&ModuleType=ProductSyncOutput`
                                    }`}
                                  target="_top"
                                >
                                  {" "}
                                  <i className="fa fa-download"></i>
                                </a>
                                <img src="/csv.png" className="csv-file ml-2 cursor-pointer" title="Preview" alt="Text icon" onClick={() => this.previewFile(filesDetail.fileName)} />
                              </span>
                            ) : null}
                          </>
                        ))}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-6 mb-3">
                    <div className="inner-detail-sec">
                      <span className="detail-title">Existing Data Counts </span>
                      <span className="detail-desc">
                        &nbsp;&nbsp;&nbsp;
                        {this.props.productDataSyncDetail.existingDataCounts}
                        {this.state.outputFileList.map((filesDetail, index) => (
                          <>
                            {filesDetail.outputFileTypeId === 2 ? (
                              <span for="requestName" className="detail-desc ml-3 font-size-16">
                                <a
                                  href={`${DownloadInputFile +
                                    `?FileName=${filesDetail.fileName}&ModuleType=ProductSyncOutput`
                                    }`}
                                  target="_top"
                                >
                                  {" "}
                                  <i className="fa fa-download"></i>
                                </a>
                                <img src="/csv.png" className="csv-file ml-2 cursor-pointer" title="Preview" alt="Text icon" onClick={() => this.previewFile(filesDetail.fileName)} />
                              </span>
                            ) : null}
                          </>
                        ))}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 ">
                    <div className="inner-detail-sec">
                      <span className="detail-title">Error Count </span>
                      <span className="detail-desc">
                        &nbsp;&nbsp;&nbsp;
                        {this.props.productDataSyncDetail.errorCount}
                        {this.state.outputFileList.map((filesDetail, index) => (
                          <>
                            {filesDetail.outputFileTypeId === 4 ? (
                              <span for="requestName" className="detail-desc ml-3 font-size-16">
                                <a
                                  href={`${DownloadInputFile +
                                    `?FileName=${filesDetail.fileName}&ModuleType=ProductSyncOutput`
                                    }`}
                                  target="_top"
                                > 
                                  {" "}
                                  <i className="fa fa-download"></i>
                                </a>
                                <img src="/csv.png" className="csv-file ml-2 cursor-pointer" title="Preview" alt="Text icon" onClick={() => this.previewFile(filesDetail.fileName)} />
                              </span>
                            ) : null}
                          </>
                        ))}
                      </span>
                    </div>
                  </div>
                </>
              }
            </div>
          </div>
        </div>
        <Modal className="scrolling-model" show={showModal} onHide={this.handleCloseFile}>
          <Modal.Header closeButton >
            <Modal.Title>File Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body >
            <div className="RequestDetails-modal-file">
              {this.state.fileToView && (
                <>
                  <div>
                    <FileViewer
                      fileType="csv" // Set the file type to csv
                      filePath={URL.createObjectURL(this.state.fileToView)}
                      onError={error => console.error('Error:', error)}
                    />
                  </div>
                </>
              )
              }
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
