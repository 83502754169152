import React from 'react'
import FormFieldGroup from '../../components/UI Element/FormGrop/FormFieldGroup'
import ButtonLoader from '../../components/common/buttonLoader/ButtonLoader'
import Button from '../../components/common/Button';
import BaseComponent from '../../shared/baseComponent';
import productImportDomainServices from '../../services/domainServices/productImportServices';
import { Navigate } from 'react-router-dom';
import { ErrorMessage } from '../../utils/Messages';
import { DownloadInputFile } from '../../services/axiosServices/ApiEndPoints';

export default class AddProductImport extends BaseComponent {

    constructor(props) {
        super(props)
        this.productImportServices = new productImportDomainServices();
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            ...this.state,
            setIsLoad: false,
            operationalFormField: this.productImportServices.dataItem,
        }
        this.productImportServices.onUpdateState = this.updateState.bind(this);
    }

    handleChange(name, value) {
        let detail = this.state.operationalFormField;
        detail[name] = value;
        this.setState({ operationalFormField: { ...detail } });
    }
    handleFileChange(event) {
        const { name, value } = event.target;
        const File = event.target.files[0];

        if (File && (File.type === "text/csv" || File.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
            if (File.size > 0) {
                const importFile = {
                    ...this.state.operationalFormField,
                    [name]: value,
                    inputFile: File.name,
                    inputScriptFile: File,
                    FileName: File.name, // Add FileName property for PDF
                };

                this.setState({ operationalFormField: importFile });
            }
        } else {
            this.productImportServices.apiService.serviceManager.swalServices.Toaster(ErrorMessage.InvalidFileTypeCSV);
        }
    }

    add = () => {
        const { operationalFormField } = this.state;
        if (this.productImportServices.isValidSubmit(this.state.operationalFormField, this.state.validationRules, this.state.validState)) {
            let request = {
                Name: this.state.operationalFormField.Name,
                File: this.state.operationalFormField.inputScriptFile,
                FileName: this.state.operationalFormField.FileName,
                CreatedBy: this.state.operationalFormField.CreatedBy,
                isTutomerImport: this.state.operationalFormField.TutomerImport,
                isChemPropertiesImport: this.state.operationalFormField.ChemPropertiesImport,
                isPubChemSync: this.state.operationalFormField.PubChemSync,
                isProductUpdate: this.state.operationalFormField.ProductUpdate,
                isRegulation: this.state.operationalFormField.Regualtion,

            }
            this.productImportServices.onAdd(request);
        }
    }

    componentDidMount() {
        this.productImportServices.onInitService();
    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} />
        }
        return (
            <div>
                <div className="mb-4">
                    <div className='d-flex justify-content-end align-items-center mb-3 mr-2'>
                        <b>
                            DownLoad Template :
                        </b>
                        <span for="requestName" className="ml-3">
                            <a href={`${DownloadInputFile + `?FileName=ProductImport.csv&ModuleType=Templates`}`} target="_top"> <i className="fa-2x fa fa-download"></i></a>
                        </span>
                    </div>
                    <div className="accordion" id="accordionExample">
                        <div className="card accordion-item active">
                            <div id="accordionOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className="card-body">
                                        <div className="row mb-3 align-items-center">
                                            <div className='col-md-12'>
                                                <div className='row'>
                                                    {this.productImportServices.formFields.map((Item, index) => {
                                                        return (
                                                            <>
                                                                {/* Conditionally render based on serverLocationId */}
                                                                <FormFieldGroup
                                                                    column={Item}
                                                                    value={this.state.operationalFormField[Item.fieldName]}
                                                                    error={this.state.validState.error[Item.fieldName]}
                                                                    options={Item.Options ? Item.Options : null}
                                                                    onKeyDown={Item.onkeydown ? Item.onkeydown.bind(this) : null}
                                                                    onChange={Item.inputType === 'checkbox' ? (event) => this.handleChange(Item.fieldName, event.target.checked)
                                                                        : Item.inputType === "file" ? (event) => this.handleFileChange(event) :
                                                                            Item.inputType === "select" || Item.inputType === "text" ? (event) => this.handleChange(Item.fieldName, event.target.value)
                                                                                : (event) => this.handleChange(Item.fieldName, event.target.value)}
                                                                // onBlur={() => this.productImportServices.validateField(Item.fieldName, this.state.operationalFormField, this.state.validationRules, this.state.validState)}
                                                                />
                                                            </>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="addnewuser-button mb-4">
                                    <button
                                        className="tab-title tab-title--active  btn-hover  color-3 active"
                                        onClick={() => this.add()}
                                    >{this.state.setIsLoad ? <ButtonLoader /> : "Save"}</button>
                                    <Button type='cancel' title="Cancel" className="ml-2" onClick={() => this.productImportServices.onRedirectoList()} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
