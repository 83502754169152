//** Component  */
import React from "react";
//*** Lib */
import { decryptionData } from "../../../../utils/Encryption";
import BaseComponent from "../../../../shared/baseComponent";

//** Services */
import { DownloadInputFile } from "../../../../services/axiosServices/ApiEndPoints";
import RegulationDomainService from "../../../../services/domainServices/regulationDomainService";
import axios from 'axios';
import FileViewer from 'react-file-viewer';
import { Modal } from "react-bootstrap";

export default class RegulationRequest extends BaseComponent {

    constructor(props) {
        super(props);
        this.domainServices = new RegulationDomainService();
        this.state = {
            setIsLoad: false,
            outPutFileData: [],
            showModal: false,
            fileToView: null,
            fileContent: null,
        };
        this.domainServices.onUpdateState = this.updateState.bind(this);
    }

    //** Constructor  and Component method */
    componentDidMount() {
        let params = new URLSearchParams(window.location.search);
        let id = params.get("");
        const decryptKey = decryptionData(id, false);
        if (decryptKey > 0) {
            this.domainServices.onGetRegulationOutPutById(decryptKey);
        }
    }

    handleCloseFile = () => {
        this.setState({ showModal: false })
        this.setState({ fileToView: null });
    }

    previewFile = (data) => {
        const downloadUrl = `${DownloadInputFile}?FileName=${encodeURIComponent(data)}&ModuleType=ProductImportInput`;
        this.setState({ setIsLoad: true });
        axios.get(downloadUrl, { responseType: 'blob' })
            .then(response => {
                this.setState({ fileToView: response.data });
                this.setState({ showModal: true });
            })
            .catch(error => {
                console.error('Error downloading the file:', error);
            });
    };

    render() {
        const { showModal } = this.state
        return (
            <>
                <div className="card">
                    <div className="card-title-main px-3 py-2">
                        <h6 className="titletexts font-size-16">Output Files</h6>
                    </div>
                    <div className="card-body custom-card-body">
                        <div className="row">
                            {this.state.outPutFileData.length > 0 && this.state.outPutFileData !== undefined && this.state.outPutFileData !== null ?
                                <>
                                    {this.state.outPutFileData.map((data, index) => {
                                        return (
                                            <div className="col-md-6 mb-3">
                                                <div className="inner-detail-sec" key={index}>
                                                    <>
                                                        <span className="detail-title">
                                                            {data.regulationRequestOutputFileTypeId === 1 ? 'Empty File' : ''}
                                                            {data.regulationRequestOutputFileTypeId === 2 ? 'Non Empty File' : ''}
                                                        </span> &nbsp;
                                                        <span className="detail-desc">
                                                            {data.regulationRequestOutputFileTypeName !== null && data.regulationRequestOutputFileTypeName !== undefined ?
                                                                <>
                                                                    {data.regulationRequestOutputFileTypeId === 1 ?
                                                                        <span for="requestName" className="detail-desc ml-3">
                                                                            {this.props.detailData.emptyCounts} &nbsp;&nbsp;&nbsp;
                                                                            <a href={`${DownloadInputFile + `?FileName=${data.regulationRequestOutputFileTypeName}&ModuleType=RegulationRequestOutput`}`}
                                                                                target="_top" >
                                                                                {" "} <i className="fa fa-download"></i>
                                                                            </a>
                                                                            <img src="/csv.png" className="csv-file ml-2 cursor-pointer" title="Preview" alt="Text icon" onClick={() => this.previewFile(data.regulationRequestOutputFileTypeName)} />
                                                                        </span>
                                                                        : ''}
                                                                    {data.regulationRequestOutputFileTypeId === 2 ?
                                                                        <span for="requestName" className="detail-desc ml-3">
                                                                            {this.props.detailData.nonEmptyCounts} &nbsp;&nbsp;&nbsp;
                                                                            <a href={`${DownloadInputFile + `?FileName=${data.regulationRequestOutputFileTypeName}&ModuleType=RegulationRequestOutput`}`}
                                                                                target="_top" >
                                                                                {" "} <i className="fa fa-download"></i>
                                                                            </a>
                                                                            <img src="/csv.png" className="csv-file ml-2 cursor-pointer" title="Preview" alt="Text icon" onClick={() => this.previewFile(data.regulationRequestOutputFileTypeName)} />
                                                                        </span>
                                                                        : ''}
                                                                </>
                                                                :
                                                                <span className='detail-desc'>
                                                                    File Not Found
                                                                </span>
                                                            }
                                                        </span>
                                                    </>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </>
                                :
                                <span className='detail-desc'>
                                    No Record found
                                </span>
                            }
                        </div>
                    </div>
                </div>
                <Modal className="scrolling-model" show={showModal} onHide={this.handleCloseFile}>
                    <Modal.Header closeButton >
                        <Modal.Title>File Preview</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <div className="RequestDetails-modal-file">
                            {this.state.fileToView && (
                                <>
                                    <div>
                                        <FileViewer
                                            fileType="csv" // Set the file type to csv
                                            filePath={URL.createObjectURL(this.state.fileToView)}
                                            onError={error => console.error('Error:', error)}
                                        />
                                    </div>
                                </>
                            )
                            }
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}
