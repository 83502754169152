
import { axiosPost, axiosGet, axiosGetMultiParams } from '../AxiosRequests';
//*** Lib */
import { GetAllLogsList, GetAllLogsById, AddLogs, GetAllLogsDetails, GetRunningRequestDetails, GetAllLogsByLastId } from '../ApiEndPoints';

export default class LogServices {
    // API function
    async getAllLogsList(request) {
        return axiosGet(GetAllLogsList, request);
    }
    async getAllLogsById(request) {
        return axiosGetMultiParams(GetAllLogsById, request)
    }
    async addLogs(request) {
        return axiosPost(AddLogs, request);
    }
    async getAllLogsDetails(request) {
        return axiosPost(GetAllLogsDetails, request);
    }
    async getRunningRequestDetails(request) {
        return axiosPost(GetRunningRequestDetails, request);
    }
    async getAllLogsByLastId(request) {
        return axiosPost(GetAllLogsByLastId, request)
    }
}

