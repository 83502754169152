import React from "react";
import codeManagementServices from "../../services/domainServices/codeManagementServices";
import { Bars } from "react-loader-spinner";
import Table from "../../components/table/Table";
import FormFieldGroup from "../../components/UI Element/FormGrop/FormFieldGroup";
import { Link, Navigate } from "react-router-dom";
import BaseComponent from "../../shared/baseComponent";
import { encryptionData } from '../../../src/utils/Encryption';

export default class CodeManagement extends BaseComponent {
  constructor(props) {
    super(props);
    this.codeManagementServices = new codeManagementServices();
    this.state = {
      pagination: this.codeManagementServices.pagination,
      searchList: this.codeManagementServices.dataItem,
      setIsLoad: false,
      getAllCodeManagementList: [],
    };
    this.codeManagementServices.onUpdateState = this.updateState.bind(this);
    this.codeManagementServices.onActionCall = this.onActionCall.bind(this);
  }
  componentDidMount() {
    this.codeManagementServices.onGetCodeManagementList(this.state.pagination);
  }

  handleChange = (name, value) => {
    let detail = this.state.pagination;
    detail[name] = value;
    if (name === "searchText") {
      detail[name] = value.trim();
      let data = { ...this.state.pagination };
      data.pageNo = 1;
      this.codeManagementServices.onGetCodeManagementList(data);
      this.setState({ pagination: { ...data } });
    }
    this.setState({ searchList: { ...detail } });
  };

  handleChangeEvent = (name, value) => {
    let detail = this.state.pagination;
    detail[name] = value;
    this.setState({ searchList: { ...detail } });
  };

  onActionCall = (type, data) => {

    // *** getByID encryption ***//
    const encryptKey = encryptionData(data, 1);

    switch (type) {
      case "Edit":
        window.open(`/AddScript/id?=${encryptKey}`, "_blank");
        break;
      case "Delete":
        this.codeManagementServices.apiService.serviceManager.swalServices
          .Confirm(
            "Are you sure, you want delete Script?",
            "",
            "Yes",
            "No"
          )
          .then(async (confirm) => {
            if (confirm) {
              let datas = {
                id: data,
              };
              this.codeManagementServices.onDeleteCodeManagementScript(datas);
            }
          });
        break;
      default:
        // Handle default case here
        break;
    }
  };
  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <div className="">
        <div className="top-search-add-button-sec mb-3">
          <div className="row left-searchbar-sec">
            {this.codeManagementServices.SearchformFields.map((Item, index) => {
              return (
                <>
                  {Item.inputType === "search" ? (
                    <FormFieldGroup
                      className="ms-auto"
                      column={Item}
                      value={this.state.searchList[Item.fieldName]}
                      onChange={(event) =>
                        this.handleChangeEvent(
                          Item.fieldName,
                          event.target.value
                        )
                      }
                      onBlur={Item.onBlur ? Item.onBlur : null}
                      onKeyPress={(e) =>
                        this.handleChange(Item.fieldName, e.target.value)
                      }
                      key={index}
                    />
                  ) : null}
                </>
              );
            })}
          </div>
          <div className="right-button-sec">
            <Link
              to="/AddScript"
              className="tab-title btn-hover color-3 active"
            >
              Add Script <span>+</span>
            </Link>
          </div>
        </div>
        <div className="code-managment-list">
          {this.state.setIsLoad ? (
            <Bars
              className="row"
              wrapperStyle={{ marginTop: "150px" }}
              color="#3c4b64"
              height={55}
              width="100%"
              visible={this.state.setIsLoad}
            />
          ) : (
            <Table
              columns={this.codeManagementServices.gridColmns}
              rows={this.state.getAllCodeManagementList}
              sortingColumns={[]}
              pagination={this.state.pagination}
              totalCount={this.state.TotalCount}
              totalResultes={this.state.TotalCount}
              setPagination={this.codeManagementServices.onPageChange.bind(this)}
              customScope={[
                {
                  column: "codeManagementId",
                  isDeleteRequired: true,
                  renderTableData:
                    this.codeManagementServices.gridActionsCol.bind(this),
                },
                // {
                //   column: "active",
                //   renderTableData:
                //     this.codeManagementServices.gridCheckBoxCol.bind(this),
                // },
              ]}
            />
          )}
        </div>
      </div>
    );
  }
}
