
import { axiosGet, axiosPost } from '../AxiosRequests';
//*** Lib */
import {
    AddLinkupRequest, GetAllLinkupRequest, GetLinkupRequestById, GetLinkupRequestOutputFileById
} from '../ApiEndPoints';

export default class LinkupServices {

    async addLinkupRequest(request) {
        return axiosPost(AddLinkupRequest, request);
    }
    async getAllLinkupRequest(request) {
        return axiosPost(GetAllLinkupRequest, request);
    }
    async getLinkupRequestById(request) {
        return axiosGet(GetLinkupRequestById, request);
    }
    async getLinkupRequestOutputFileById(request) {
        return axiosGet(GetLinkupRequestOutputFileById, request);
    }

}
