import React from 'react'
import BaseComponent from '../../../shared/baseComponent'
import emailValidationDomainServices from '../../../services/domainServices/emailValidationServices';
import { DownloadInputFile } from '../../../services/axiosServices/ApiEndPoints';
import axios from 'axios';
import FileViewer from 'react-file-viewer';
import { Modal } from 'react-bootstrap';
export default class EmailDataRequest extends BaseComponent {

  constructor(props) {
    super(props);
    this.emailValidationServices = new emailValidationDomainServices();
    this.state = {
      setIsLoad: false,
      // outputFileList: [],
      showModal: false,
      fileToView: null,
      fileContent: null,
    };
    this.emailValidationServices.onUpdateState = this.updateState.bind(this);
  }

  handleCloseFile = () => {
    this.setState({ showModal: false })
    this.setState({ fileToView: null });
  }

  previewFile = (data) => {
    const downloadUrl = `${DownloadInputFile}?FileName=${encodeURIComponent(data)}&ModuleType=EmailValidationOutput`;
    this.setState({ setIsLoad: true });
    axios.get(downloadUrl, { responseType: 'blob' })
      .then(response => {
        this.setState({ fileToView: response.data });
        this.setState({ showModal: true });
      })
      .catch(error => {
        console.error('Error downloading the file:', error);
      });
  };

  render() {
    const { showModal } = this.state
    return (
      <>
        <div className="card">
          <div className="card-title-main px-3 py-2">
            <h6 className="titletexts font-size-16">Output Files</h6>
          </div>
          <div className="card-body custom-card-body">
            <div className="row">
              <div className="col-md-6 mb-3">
                <div className="inner-detail-sec">
                  <span className="detail-title">Valid Counts </span>
                  &nbsp;<span className="detail-desc">
                    &nbsp;&nbsp;&nbsp;
                    {this.props.EmailData.validCounts}
                    {this.props.EmailData.emailOuntputList.map((filesDetail, index) => (
                      <>
                        {filesDetail.outputFIleTypeId === 1 &&
                          this.props.EmailData.validCounts > 0 ? (
                          <span htmlFor="requestName" className="detail-desc ml-3">
                            <a
                              href={`${DownloadInputFile +
                                `?FileName=${filesDetail.outputFileName}&ModuleType=EmailValidationOutput`
                                }`}
                              target="_top"
                            >
                              {" "}
                              <i className="fa fa-download"></i>
                            </a>
                            <img src="/csv.png" className="csv-file ml-2 cursor-pointer" title="Preview" alt="Text icon" onClick={() => this.previewFile(filesDetail.outputFileName)} />
                          </span>
                        ) : null}
                      </>
                    ))}
                  </span>
                </div>
              </div>

              <div className="col-md-6 mb-3">
                <div className="inner-detail-sec">
                  <span className="detail-title">Invalid Counts </span>
                  &nbsp;<span className="detail-desc">
                    &nbsp;&nbsp;&nbsp;
                    {this.props.EmailData.invalidCounts}
                    {this.props.EmailData.emailOuntputList.map((filesDetail, index) => (
                      <>
                        {filesDetail.outputFIleTypeId === 2 &&
                          this.props.EmailData.invalidCounts > 0 ? (
                          <span htmlFor="requestName" className="detail-desc ml-3">
                            <a
                              href={`${DownloadInputFile +
                                `?FileName=${filesDetail.outputFileName}&ModuleType=EmailValidationOutput`
                                }`}
                              target="_top"
                            >
                              {" "}
                              <i className="fa fa-download"></i>
                            </a>
                            <img src="/csv.png" className="csv-file ml-2 cursor-pointer" title="Preview" alt="Text icon" onClick={() => this.previewFile(filesDetail.outputFileName)} />
                          </span>
                        ) : null}
                      </>
                    ))}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal className="scrolling-model" show={showModal} onHide={this.handleCloseFile}>
          <Modal.Header closeButton >
            <Modal.Title>File Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body >
            <div className="RequestDetails-modal-file">
              {this.state.fileToView && (
                <>
                  <div>
                    <FileViewer
                      fileType="csv" // Set the file type to csv
                      filePath={URL.createObjectURL(this.state.fileToView)}
                      onError={error => console.error('Error:', error)}
                    />
                  </div>
                </>
              )
              }
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
