//** Component  */
import React from "react";

//** Services */
import BaseComponent from "../../shared/baseComponent";
import ProgressBarStyle from "../../styles/ProgressBarStyle";
import RequestLogs from "../../components/requestFlow/RequestLogs";
import RequestInitiate from "../../components/requestFlow/RequestInitiate";
import RequestCleaning from "../../components/requestFlow/RequestCleaning";
import RequestImporting from "../../components/requestFlow/RequestImporting";
import RequestScrapping from "../../components/requestFlow/RequestScrapping";
import RequestDomainServices from "../../services/domainServices/requestServices";

//*** Lib */
import { Constants } from "../../utils/Constants";
import FormFieldGroup from "../../components/UI Element/FormGrop/FormFieldGroup";
import { decryptionData, encryptionData } from "../../../src/utils/Encryption";

export default class RequestProgressBar extends BaseComponent {
  constructor(props) {
    super(props);
    this.requestServices = new RequestDomainServices();
    this.requestServices.onUpdateState = this.updateState.bind(this);
    this.state = {
      activeTab: Constants.ProductDataSection.RequestInitiate,
      productsDetails: [],
      productsCounts: [],
      activeRequestDetailId: 0,
      newData: [],
      priceOutputFileList: []
    };
  }

  setTab = (currentStage, currentProcess) => {
    if (currentProcess <= currentStage) {
      if (currentProcess === 1) {
        let request = [this.state.activeRequestDetailId, 1];
        if (this.state.activeRequestDetailId > 0) {
          this.requestServices.onGetById(request);
        }
        this.setState({ activeTab: Constants.RequestSections.RequestInitiate });
      } else if (currentProcess === 2) {
        let request = [this.state.activeRequestDetailId, 2];
        if (this.state.activeRequestDetailId > 0) {
          this.requestServices.onGetById(request);
        }
        this.setState({
          activeTab: Constants.RequestSections.RequestScrapping,
        });
      } else if (currentProcess === 3) {
        let request = [this.state.activeRequestDetailId, 3];
        if (this.state.activeRequestDetailId > 0) {
          this.requestServices.onGetById(request);
        }
        this.setState({ activeTab: Constants.RequestSections.RequestCleaning });
      } else if (currentProcess === 4) {
        let request = [this.state.activeRequestDetailId, 4];
        if (this.state.activeRequestDetailId > 0) {
          this.requestServices.onGetById(request);
        }
        this.setState({
          activeTab: Constants.RequestSections.RequestImporting,
        });
      }
    } else if (currentProcess === 5) {
      this.setState({ activeTab: Constants.RequestSections.RequestLogs });
    } else {
      this.setState({ activeTab: currentStage });
    }
  };

  //** Constructor  and Component method */
  componentDidMount() {

    let params = new URLSearchParams(window.location.search);
    let reqId = params.get("");
    // *** getByID encryption ***//
    const decryptKey = decryptionData(reqId, false);

    this.setState({ activeRequestDetailId: decryptKey });
    let rpStateId = 1;
    let request = [decryptKey, rpStateId];
    if (decryptKey > 0) {
      this.requestServices.onGetById(request);
      this.requestServices.onGettingCountById(decryptKey);
    }
  }

  onActionCall = (type, data) => {
    switch (type) {
      case "requestGroupName":
        const encryptKey = encryptionData(data, 1);
        window.open(`/RPARequestGroupDetails/id?=${encryptKey}`, '_blank');
        // this.setState({ redirect: "/RequestDetails/id?=" + encryptKey , '_blank' });
        break;
      default:
        // Handle default case here
        break;
    }
  }

  render() {
    return (
      <>
        <div className="card">
          <div className="card-title-main px-3 py-2">
            <div className="row align-items-center">
              <div className="col-6">
                <h6 className="titletexts font-size-16">Name : {this.state.productsDetails.requestName}</h6>
              </div>
              <div className="col-md-6 text-end">
                <label style={{
                  // border: "1px solid #fff",
                  fontSize: "16px",
                }}
                  className="titletexts">Request Type &nbsp;: &nbsp;</label>
                <h6
                  className="text-white"
                  style={{
                    // border: "1px solid #fff",
                    fontSize: "16px",
                    // padding: "10px",
                    display: "inline-block",
                    borderRadius: "5px",
                    paddingBottom: "0",
                    marginBottom: "0"
                  }}
                >
                  {this.state.productsDetails.requestType === 2
                    ? " CatalogUpdate"
                    : this.state.productsDetails.requestType === 1
                      ? " LinkUpdate"
                      : this.state.productsDetails.requestType === 3
                        ? "PartialUpdate"
                        : null}
                </h6>
              </div>
            </div>
          </div>
          <div className="card-body custom-card-body">
            <div className="row">
              {this.requestServices.requestDetailForm.map((Item, index) => {
                return (
                  <FormFieldGroup
                    column={Item}
                    value={this.state.productsDetails[Item.fieldName]}
                    checked={this.state.productsDetails[Item.fieldName]}
                    onActionCall={this.onActionCall}
                  />
                );
              })}
              {/* <div className="col-md-4">
                <div className="inner-detail-sec">
                  <input className="form-check-input custom-checkboxs" type="checkbox" checked={this.state.productsDetails.isGroupConfigure} />
                  <label className="form-check-label ml-2">Has Group Configure ?</label>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <ProgressBarStyle />
        <div className="mt-2 pt-2">
          <div id="progress" className="mb-0">
            <div id="progress-bar"></div>
            <ul
              className={
                this.state.productsDetails.requestType === 2
                  ? "scrapping-timeline"
                  : ""
              }
              id="progress-check"
            >
              {
                this.state.productsDetails.requestType === 3 ?
                  <li className={"step " + ((this.state.activeTab === Constants.RequestSections.RequestInitiate) ? "step active red-color" : "")}
                    onClick={() => this.setTab(this.state.productsDetails.stageId, Constants.RequestSections.RequestInitiate)}>
                    <div className="main-timeline-sec">
                      <div className="icon-round">
                        <i className="fa fa-hourglass-start "></i>
                      </div>
                      <label className="pt-0">Initial</label>
                    </div>
                  </li>
                  : null
              }
              {this.state.productsCounts.inputLinkCount > 0 &&
                <>
                  {this.state.productsDetails.stageId === 1 ||
                    this.state.productsDetails.stageId <= 5 ? (
                    <li
                      className={
                        "step " +
                        ((this.state.activeTab ===
                          Constants.RequestSections.RequestInitiate)
                          ? "step active red-color"
                          : "")
                      }
                      onClick={() =>
                        this.setTab(
                          this.state.productsDetails.stageId,
                          Constants.RequestSections.RequestInitiate
                        )
                      }
                    >
                      <div className="main-timeline-sec">
                        <div className="icon-round">
                          <i className="fa fa-hourglass-start "></i>
                        </div>
                        <label className="pt-0">Initial</label>
                      </div>
                    </li>
                  ) : (
                    <li
                      className={
                        "step " +
                        ((this.state.activeTab ===
                          this.state.productsDetails.initial) !==
                          0
                          ? ""
                          : "step active red-color")
                      }
                    >
                      <div className="main-timeline-sec">
                        <div className="icon-round">
                          <i className="fa fa-hourglass-start "></i>
                        </div>
                        <label className="pt-0">Initiate</label>
                      </div>
                    </li>
                  )}
                </>
              }

              {this.state.productsDetails.isScrapping === true &&
                <>
                  {this.state.productsDetails.requestType === 2 ||
                    this.state.productsDetails.requestType === 3 ? (
                    ""
                  ) : (
                    <>
                      {this.state.productsDetails.stageId >= 2 ||
                        this.state.productsDetails.stageId <= 5 ? (
                        <li
                          className={
                            "step " +
                            ((this.state.activeTab ===
                              Constants.RequestSections.RequestScrapping)
                              ? "step active green-color"
                              : "")
                          }
                          onClick={() =>
                            this.setTab(
                              this.state.productsDetails.stageId,
                              Constants.RequestSections.RequestScrapping
                            )
                          }
                        >
                          <div className="main-timeline-sec">
                            <div className="icon-round">
                              <i className="fa-brands fa-dropbox"></i>
                            </div>
                            <label className="pt-0">Scraping</label>
                          </div>
                        </li>
                      ) : (
                        <li
                          className={
                            "step " +
                            ((this.state.activeTab >=
                              this.state.productsDetails.scraper) !==
                              0
                              ? ""
                              : "step active green-color")
                          }
                        >
                          <div className="main-timeline-sec">
                            <div className="icon-round">
                              <i className="fa-brands fa-dropbox"></i>
                            </div>
                            <label className="pt-0">Scraping</label>
                          </div>
                        </li>
                      )}
                    </>
                  )}
                </>
              }

              {this.state.productsDetails.isCleaner === true &&
                <>
                  {this.state.productsDetails.requestType === 3 &&
                    this.state.productsDetails.isCleaner === false ? (
                    ""
                  ) : (
                    <>
                      {this.state.productsDetails.stageId >= 3 ||
                        this.state.productsDetails.stageId <= 5 ? (
                        <li
                          className={
                            "step " +
                            ((this.state.activeTab ===
                              Constants.RequestSections.RequestCleaning)
                              ? "step active yellow-color"
                              : "")
                          }
                          onClick={() =>
                            this.setTab(
                              this.state.productsDetails.stageId,
                              Constants.RequestSections.RequestCleaning
                            )
                          }
                        >
                          <div className="main-timeline-sec">
                            <div className="icon-round">
                              <i className="fa-solid fa-broom"></i>
                            </div>
                            <label className="pt-0">Cleaning</label>
                          </div>
                        </li>
                      ) : (
                        <li
                          className={
                            "step " +
                            ((this.state.activeTab >=
                              this.state.productsDetails.cleaner) !==
                              0
                              ? ""
                              : "step active yellow-color")
                          }
                        >
                          <div className="main-timeline-sec">
                            <div className="icon-round">
                              <i className="fa-solid fa-broom"></i>
                            </div>
                            <label className="pt-0">Cleaning</label>
                          </div>
                        </li>
                      )}
                    </>
                  )}
                </>
              }

              {this.state.productsDetails?.isDBUpdates&&
                <>
                  {this.state.productsDetails.stageId === 4 ? (
                    <li
                      className={
                        "step " +
                        ((this.state.activeTab ===
                          Constants.RequestSections.RequestImporting)
                          ? "step active red-color"
                          : "")
                      }
                      onClick={() =>
                        this.setTab(
                          this.state.productsDetails.stageId,
                          Constants.RequestSections.RequestImporting
                        )
                      }
                    >
                      <div className="main-timeline-sec">
                        <div className="icon-round">
                          <i className="fa fa-cloud-upload"></i>
                        </div>
                        <label className="pt-0">DataUpdate</label>
                      </div>
                    </li>
                  ) : (
                    <li
                      className={
                        "step " +
                        ((this.state.activeTab >=
                          this.state.productsDetails.ImportingReq) !==
                          0
                          ? ""
                          : "step active black-color")
                      }
                    >
                      <div className="main-timeline-sec">
                        <div className="icon-round">
                          <i className="fa fa-cloud-upload"></i>
                        </div>
                        <label className="pt-0">DataUpload</label>
                      </div>
                    </li>
                  )}
                </>
              }

              <li
                className={
                  "step " +
                  (this.state.activeTab >= Constants.RequestSections.RequestLogs
                    ? "step active yellow-color"
                    : "")
                }
                onClick={() =>
                  this.setTab(
                    this.state.productsDetails.stageId,
                    Constants.RequestSections.RequestLogs
                  )
                }
              >
                <div className="main-timeline-sec">
                  <div className="icon-round">
                    <i className="fa fa-link"></i>
                  </div>
                  <label className="pt-0">Logs</label>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div>
          {this.state.activeTab ===
            Constants.RequestSections.RequestInitiate ? (
            <RequestInitiate productsDetails={this.state.productsDetails}
              productsCounts={this.state.productsCounts} priceOutputFileList={this.state.priceOutputFileList}
            />
          ) : this.state.activeTab ===
            Constants.RequestSections.RequestScrapping ? (
            <RequestScrapping productsDetails={this.state.productsDetails}
              productsCounts={this.state.productsCounts} />
          ) : this.state.activeTab ===
            Constants.RequestSections.RequestCleaning ? (
            <RequestCleaning productsDetails={this.state.productsDetails}
              productsCounts={this.state.productsCounts} />
          ) : this.state.activeTab ===
            Constants.RequestSections.RequestImporting ? (
            <RequestImporting productsDetails={this.state.productsDetails}
              productsCounts={this.state.productsCounts} />
          ) : this.state.activeTab === Constants.RequestSections.RequestLogs ? (
            <RequestLogs />
          ) : null}
        </div>
      </>
    );
  }
}
