export const ButtonTypes = {
    Primary: 1,
    Secondary: 2,
    Cancel: 3
}
export const AddLogs = {
    dataKeyId: 1,

}

export const Constants = {
    RequestSections: {
        RequestInitiate: 1,
        RequestScrapping: 2,
        RequestCleaning: 3,
        RequestImporting: 4,
        RequestLogs: 5
    },
    ProductDataSection: {
        RequestInitiate: 1,
        RequestProductData: 2,
        RequestLogs: 3
    },
    LinkBuilderSection: {
        RequestProductData: 1,
        RequestLogs: 2
    },
    RegulationRequest: {
        RequestInitiate: 1,
        RegulationRequest: 2,
        RequestLogs: 3
    },
    LinkupRequest: {
        LinkupRequestData: 1,
        RequestLogs: 2
    },
    RequestDetailsGroup : {
        Scrapping : 1,
        Cleanner  : 2,
    }

}
export const Logs = {
    LogSections: {
        Info: 1,
        Error: 2,
        Result: 3,
    }
}