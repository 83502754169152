import { Navigate } from "react-router-dom";
import { Bars } from "react-loader-spinner";

//** Lib's */
import Button from "../../components/common/Button";
import BaseComponent from "../../shared/baseComponent";
import FormFieldGroup from "../../components/UI Element/FormGrop/FormFieldGroup";

//** Service's */
import LinkupDomainService from "../../services/domainServices/linkupDomainService";

export default class AddLinkupRequest extends BaseComponent {
  constructor(props) {
    super(props);
    this.domainServices = new LinkupDomainService();
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      ...this.state,
      regulationId: 0,
      setIsLoad: false,
      selectedDate: null,
      regulationNameList: [],
      formField: this.domainServices.dataItem,
    };
    this.domainServices.onUpdateState = this.updateState.bind(this);
  }

  componentDidMount() {
    this.domainServices.onInitService();
    this.domainServices.onGetRegulationNameList();
  }

  //** Handle Changes */
  handleChange(name, value) {
    let detail = this.state.formField;
    detail[name] = value;
    this.setState({ formField: detail });
  }

  //** API's Call */
  add = () => {
    if (
      this.domainServices.isValidSubmit(
        this.state.formField,
        this.state.validationRules,
        this.state.validState
      )
    ) {
      let request = {
        linkupRequestName: this.state.formField.linkupRequestName,
        regulationId: this.state.formField.regulationId,
        createdBy: this.state.formField.createdBy,
      };
      this.domainServices.onAdd(request);
    }
  };

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }

    return (
      <div className="mb-4">
        <div className="accordion" id="accordionExample">
          <div className="card accordion-item active">
            <div
              id="accordionOne"
              className="accordion-collapse collapse show"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <div className="card-body">
                  {this.state.setIsLoad ? (
                    <Bars
                      className="row"
                      wrapperStyle={{ marginTop: "150px" }}
                      color="#3c4b64"
                      height={55}
                      width="100%"
                      visible={this.state.setIsLoad}
                    />
                  ) : (
                    <div className="row mb-4">
                      {this.domainServices.formFields.map((Item, index) => {
                        return (
                          <>
                            <FormFieldGroup
                              column={Item}
                              value={this.state.formField[Item.fieldName]}
                              error={
                                this.state.validState.error[Item.fieldName]
                              }
                              options={
                                Item.options
                                  ? this.state.regulationNameList
                                  : null
                              }
                              onChange={(event) =>
                                this.handleChange(
                                  Item.fieldName,
                                  event.target.value
                                )
                              }
                              key={index}
                              onBlur={() =>
                                this.domainServices.validateField(
                                  Item.fieldName,
                                  this.state.formField,
                                  this.state.validationRules,
                                  this.state.validState
                                )
                              }
                            />
                          </>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
              <div className="addnewuser-button mb-4">
                <button
                  className="tab-title tab-title--active  btn-hover  color-3 active"
                  onClick={() => this.add()}
                >
                  {" "}
                  Save{" "}
                </button>
                <Button
                  type="cancel"
                  title="Cancel"
                  className="ml-2"
                  onClick={() => this.domainServices.onRedirectoList()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
