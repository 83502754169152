import { DataFormat } from "../../utils/DateFormat";
import BaseDomainService from "./baseDomainServices";
import { SuccessMessage } from "../../utils/Messages";
import { getValidationRuleList } from "../../utils/validation/generateValidationRule";


const intialSearchField = {
    searchText: ""
}

const intialFormFields = {
    importTitle: "",
    appId: "",
    file: "",
    intialFileName: "",
    createdBy: 1,
}

export default class BingoProductImportDomainService extends BaseDomainService {

    //**  API Get */
    add = this.apiService.bingoProductImportServices.addProductDataImport;
    appList = this.apiService.bingoProductImportServices.getApplicationsList;
    getList = this.apiService.bingoProductImportServices.getProductDataImportList;
    getById = this.apiService.bingoProductImportServices.getProductDataImportById;

    //** Table Grid columns  */
    gridColmns = [
        { name: 'importTitle', title: 'Import Title' },
        { name: 'appName', title: 'Application Name' },
        { name: 'intialFileName', title: 'Intial File Name' },
        { name: 'statusName', title: 'Status' },
        { name: 'createdDate', title: 'Created Date' },
        { name: 'dataImportId', title: 'Action' },
    ];

    // Define the state variables
    dataItem;
    formDataItem;

    constructor() {
        super();
        this.actionKey = "dataImportId";
        this.dataItem = { ...intialSearchField };
        this.formDataItem = { ...intialFormFields };
        this.onPaginationUpdate = this.onGetList.bind(this);
    }

    //** Form Fields
    formFields = [
        {
            inputType: "text",
            tittle: "Product Import Title",
            placeholder: "Product Import Title",
            fieldName: "importTitle",
            className: "col-md-6",
            validation: [{ type: "require" }]
        },
        {
            inputType: "select",
            tittle: "Application Name",
            placeholder: "Select Application Name",
            fieldName: "appId",
            options: [],
            optionFieldName: "appId",
            className: 'col-md-6',
            validation: [{ type: "require" }]
        },
        {
            inputType: "file",
            tittle: "Intial File Name",
            placeholder: "Intial File Name",
            fieldName: "intialFileName",
            className: 'col-md-6',
            validation: [{ type: "require" }]
        }
    ];

    searchformFields = [
        {
            inputType: "search",
            placeholder: "Search ...",
            fieldName: "searchText",
            labelStyleName: 'none'
        },
    ]

    //** API Calls
    onAdd = (requst) => {
        this.apiService.onAPICall("add", this.add, requst, this.onAPIResponse, this.onUpdateState);
    }
    onGetList = (requst) => {
        this.apiService.onAPICall("getList", this.getList, requst, this.onAPIResponse, this.onUpdateState);
    }
    onAppList = (requst) => {
        this.apiService.onAPICall("appList", this.appList, requst, this.onAPIResponse, this.onUpdateState);
    }
    onGetById = (requst) => {
        this.apiService.onAPICall("getById", this.getById, requst, this.onAPIResponse, this.onUpdateState);
    }

    //** Init Services For Validation 
    onInitService() {
        let valRule = getValidationRuleList(this.formFields);
        this.validationRules = valRule;
        this.validationRulesForType = valRule
        this.onUpdateState({
            "validationRules": valRule,
            "resetValidationRule": this.resetValidationRule,
        });
    }

    onRedirectoList = () => {
        this.onRedirect('/BingoProductImportList');
    };

    //**  API Responces
    onAPIResponse = (key, isSuccess, responseData, getRequest) => {
        if (!isSuccess) {
            this.onUpdateState({ "setIsLoad": false });
            return;
        }
        switch (key) {
            case "add":
                if (responseData.hasError === true) {
                    this.apiService.serviceManager.swalServices.Success(responseData.errorMessage + " and " + responseData.errorType);
                } else {
                    this.apiService.serviceManager.swalServices.Success(SuccessMessage.Insert_Success.replace("{0}", "New Product Import"));
                    this.onRedirectoList();
                }
                this.onUpdateState({ setIsLoad: false });
                break;
            case "getList":
                if (responseData.itemList !== null) {
                    let itemList = responseData.itemList;
                    itemList.forEach(function (i) {
                        i.createdDate = i.createdDate ? DataFormat(i.createdDate) : "-";
                    });
                }
                this.onUpdateState({
                    dataList: responseData.itemList,
                    TotalCount: responseData.totalCount,
                    setIsLoad: false
                });
                break;
            case "appList":
                if (responseData !== null) {
                    let getList = responseData;
                    let dataList = getList?.map((data) => ({
                        id: data.appId,
                        name: data.appName,
                    }));
                    this.onUpdateState({
                        appNameList: dataList
                    });
                }
                this.onUpdateState({ setIsLoad: false });
                break;
            case "getById":
                if (responseData != null) {
                    responseData.createdDate = DataFormat(responseData.createdDate);
                    this.onUpdateState({
                        detailData: responseData
                    })
                }
                this.onUpdateState({ setIsLoad: false });
                break;
        }
    }
}