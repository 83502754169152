//**
// This utility use to generate the validation rules from the form fields 
//
//**/

import { ErrorMessage } from "../Messages";

export const getValidationRuleList=(fromFields)=>{

    let valRules = {}
    fromFields.forEach(element => {
            if(element.validation && element.validation.length > 0)
            {
                let vallist = element.validation;
                let fieldValRules = [];
                vallist.forEach(valItem=>{
                    let valRule = getValidationRule(element,valItem)
                    fieldValRules.push(valRule);
                });
                valRules = {
                        ...valRules,
                        [element.fieldName]:fieldValRules
                }
            }
    });
    return valRules;
}


// Note: Element is not use as of now
const getValidationRule =(element,validation)=>
{

    let valObj = {};
    switch(validation.type)
    {
        case "require":
        valObj = {
                type:validation.type,
                message: ErrorMessage.FieldRequired.replace('{0}', element.tittle)
        }
        break;
        case "email":
            valObj = {
                    type:validation.type,
                    message: ErrorMessage.EmailAddressNotValid
            }
            break;
            case "password":
                valObj = {
                        type:validation.type,
                        message: ErrorMessage.NOTVALIDPATTERN.replace('{0}', element.tittle)
                }
                break;
                default:
                    // Handle default case here
                    break;

            // Please enter all the case
    }
    return valObj;

}