import React from "react";

//** Lib's */
import BaseComponent from "../../shared/baseComponent";
import { decryptionData } from "../../utils/Encryption";

//** Service's */
import { DownloadInputFile } from "../../services/axiosServices/ApiEndPoints";
import BingoProductImportDomainService from "../../services/domainServices/BingoProductImportDomainService";

export default class BingoProductImportDetail extends BaseComponent {

    //** Constructor  and Component method */
    constructor(props) {
        super(props);
        this.domainServices = new BingoProductImportDomainService();
        this.state = {
            detailData: {}
        }
        this.domainServices.onUpdateState = this.updateState.bind(this);
    }

    componentDidMount() {
        let params = new URLSearchParams(window.location.search);
        let id = params.get("");
        const decryptId = decryptionData(id, false);
        if (decryptId > 0) {
            this.domainServices.onGetById(decryptId);
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="card mb-5">
                    <div className="card-title-main px-3 py-2">
                        <div className="row align-items-center">
                            <div className="col-6">
                                <h5 className="titletexts font-size-16">Product Import Information</h5>
                            </div>
                        </div>
                    </div>

                    <div className="card-body custom-card-body">
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <div className="inner-detail-sec">
                                    <span className="detail-title">Application Name </span>
                                    &nbsp;&nbsp;&nbsp; <span className="font-size-16">{this.state.detailData.appName}</span>
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <div className="inner-detail-sec">
                                    <span className="detail-title">Import Title</span>
                                    &nbsp;&nbsp;&nbsp; <span className="font-size-16">{this.state.detailData.importTitle}</span>
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <div className="inner-detail-sec">
                                    <span className="detail-title">Status </span>
                                    &nbsp;&nbsp;&nbsp; <span className="font-size-16 ">{this.state.detailData.statusName}</span>
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <div className="inner-detail-sec">
                                    <span className="detail-title">Created Date </span>
                                    &nbsp;&nbsp;&nbsp; <span className="font-size-16">{this.state.detailData.createdDate}</span>
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <div className="inner-detail-sec">
                                    <span className="detail-title">Start Date</span>
                                    &nbsp;&nbsp;&nbsp; <span className="font-size-16">{this.state.detailData.dataImportStartDate ? this.state.detailData.dataImportStartDate : "NA"}</span>
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <div className="inner-detail-sec">
                                    <span className="detail-title">End Date</span>
                                    &nbsp;&nbsp;&nbsp; <span className="font-size-16">{this.state.detailData.dataImportFinishDate ? this.state.detailData.dataImportFinishDate : "NA"}</span>
                                </div>
                            </div>
                            <div className="col-md-12 mb-3">
                                <div className="inner-detail-sec">
                                    <span className="detail-title">Error Message </span>
                                    &nbsp;&nbsp;&nbsp; <span className="font-size-16">{this.state.detailData.errorMessage ? this.state.detailData.errorMessage : "NA"}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-title-main px-3 py-2">
                        <div className="row align-items-center">
                            <div className="col-6">
                                <h5 className="titletexts font-size-16">File Information </h5>
                            </div>
                        </div>
                    </div>
                    <div className="card-body custom-card-body">
                        <div className="row">
                            <div className="col-md-4 mb-3">
                                <div className="inner-detail-sec">
                                    <span className="detail-title">Intial File </span>
                                    &nbsp;&nbsp;&nbsp; <span className="font-size-16">
                                        {this.state.detailData.intialCount}
                                        &nbsp;&nbsp;&nbsp;
                                        <a href={`${DownloadInputFile + `?FileName=${this.state.detailData.intialFileName}&ModuleType=MolProductImport`}`} target="_top">
                                            <i className="fa fa-download"></i></a>
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-4 mb-3">
                                <div className="inner-detail-sec">
                                    <span className="detail-title">New Record File </span>
                                    &nbsp;&nbsp;&nbsp; <span className="font-size-16">
                                        {this.state.detailData.newRecordCount}
                                        &nbsp;&nbsp;{this.state.detailData.newRecordFileName ?
                                            <a href={`${DownloadInputFile + `?FileName=${this.state.detailData.newRecordFileName}&ModuleType=MolProductImport`}`} target="_top">
                                                <i className="fa fa-download"></i></a> : "NA"}
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-4 mb-3">
                                <div className="inner-detail-sec">
                                    <span className="detail-title">Error Record File </span>
                                    &nbsp;&nbsp;&nbsp; <span className="font-size-16">
                                        {this.state.detailData.errorCount}
                                        &nbsp;&nbsp;{this.state.detailData.errorFileName ?
                                            <a href={`${DownloadInputFile + `?FileName=${this.state.detailData.errorFileName}&ModuleType=MolProductImport`}`} target="_top">
                                                <i className="fa fa-download"></i></a> : "NA"}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}