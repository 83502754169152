import React from 'react';
import linkBuilderDomainServices from '../../services/domainServices/linkBuilderServices';
import BaseComponent from '../../shared/baseComponent'
import LinkBuilderLogs from './progress-flow/LinkBuilderLogs';
import LinkBuilderRequest from './progress-flow/LinkBuilderRequest';
import { Constants } from '../../utils/Constants';
import LinkBuilderStyle from '../../../src/styles/LinkBuilderStyle';
import { decryptionData } from '../../../src/utils/Encryption';
import { DataFormat } from '../../utils/DateFormat';

export default class LinkBuilderDetail extends BaseComponent {

    constructor(props) {
        super(props)
        this.linkBuilderServices = new linkBuilderDomainServices();
        this.state = {
            activeTab: Constants.LinkBuilderSection.RequestProductData,
            pagination: this.linkBuilderServices.pagination,
            getList: [],
            LinkBuilderRequestId: 0,
            logList: [],
            inputFile: "",
            setIsLoad: false,
            productDataDetail: []
        }
        this.linkBuilderServices.onUpdateState = this.updateState.bind(this);
    }

    componentDidMount() {
        let params = new URLSearchParams(window.location.search);
        let piId = params.get("");
        this.setState({ LinkBuilderRequestId: piId });
        // *** getByID decryption ***//
        const decryptKey = decryptionData(piId, false);

        if (decryptKey > 0) {
            this.linkBuilderServices.onGetById(decryptKey);
        }
    }

    setTab = (currentStage, currentProcess) => {
        if (currentProcess <= currentStage) {
            if (currentProcess === 1) {
                this.setState({ activeTab: Constants.LinkBuilderSection.RequestProductData })
            }
            else if (currentProcess === 2) {
                this.setState({ activeTab: Constants.LinkBuilderSection.RequestLogs })
            }
        }
    }


    render() {
        return (
            <>
                <div className="card">
                    <div className='card-title-main px-3 py-2'>
                        <div className='row align-items-center'>
                            <div className='col-6'>
                                <h5 className='titletexts font-size-16'>Request : {this.state.productDataDetail.requestName}</h5>
                            </div>
                        </div>
                    </div>

                    <div className="card-body custom-card-body">
                        <div className="row">
                            <div className="col-md-4 mb-3">
                                <div className='inner-detail-sec'>
                                    <span className='detail-title '>Link Builder Id  </span>
                                    <span className='font-size-16'> {this.state.productDataDetail.linkBuilderId}</span>
                                </div>
                            </div>
                            <div className="col-md-4 mb-3">
                                <div className='inner-detail-sec'>
                                    <span className='detail-title'>Module  </span>
                                    <span className='font-size-16'> {this.state.productDataDetail.moduleName}</span>
                                </div>
                            </div>
                            <div className="col-md-4 mb-3">
                                <div className='inner-detail-sec'>
                                    <span className='detail-title'>Status </span>
                                    <span className='font-size-16'>{this.state.productDataDetail.statusName}</span>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className='inner-detail-sec'>
                                    <span className='detail-title'>Created Date </span>
                                    <span className='font-size-16'>  {DataFormat(
                                        this.state.productDataDetail.createdDate,
                                        "MM-DD-YYYY h:mm:ss"
                                    )}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <LinkBuilderStyle />
                <div className='mt-2 pt-2'>
                    <div id="progress" className="mb-0">
                        <div id="progress-bar"></div>
                        <ul className="scrapping-timeline" id="progress-check">

                            <li className={"step " + ((this.state.activeTab === Constants.LinkBuilderSection.RequestProductData) ? 'step active red-color' : '')}
                                onClick={() => this.setTab(1, Constants.LinkBuilderSection.RequestProductData)}>
                                <div className='main-timeline-sec'>
                                    <div className='icon-round'>
                                        <i className="fa fa-hourglass-start "></i>
                                    </div>
                                    <label>Link Builder Files </label>
                                </div>
                            </li>

                            <li className={"step " + ((this.state.activeTab === Constants.LinkBuilderSection.RequestLogs) ? 'step active green-color' : '')}
                                onClick={() => this.setTab(2, Constants.LinkBuilderSection.RequestLogs)} >
                                <div className='main-timeline-sec'>
                                    <div className='icon-round'>
                                        <i className="fa-brands fa-dropbox"></i>
                                    </div>
                                    <label>Logs</label>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div>
                    {
                        (this.state.activeTab === Constants.LinkBuilderSection.RequestProductData) ?
                            <LinkBuilderRequest productDataDetail={this.state.productDataDetail} />
                            :
                            (this.state.activeTab === Constants.LinkBuilderSection.RequestLogs) ?
                                <LinkBuilderLogs />
                                :
                                null
                    }
                </div>
            </>
        )
    }
}
