//** Component  */
import React from "react";

//** Services */
import BaseComponent from "../../shared/baseComponent";
import LogDomainServices from "../../services/domainServices/logsServices";

//*** Lib */
import { Bars } from "react-loader-spinner";
import { Accordion, AccordionItem } from "react-sanfona";
import VerticalPogressBarStyle from "../../styles/VerticalPogressBarStyle";
import FormFieldGroup from "../UI Element/FormGrop/FormFieldGroup";
import { DataFormat } from "../../utils/DateFormat";
import DateRangePickerComponent from "../DateRangePicker";
import Button from '../../components/common/Button';

export default class VerticalProgressBarGroup extends BaseComponent {
  constructor(props) {
    super(props);
    this.LogsServices = new LogDomainServices();
    this.state = {
      logsList: [],
      setIsLoad: false,
      searchList: this.LogsServices.SearchdataItem,
      logReq: {
        searchText: "",
        startDate: null,
        endDate: null,
      },
      shouldRerenderFormCreator: false
    };
    this.LogsServices.onUpdateState = this.updateState.bind(this);
  }
  //** Constructor  and Component method */
  componentDidMount() {
    this.LogsServices.onGetReqList(this.state.logReq);
  }

  handleChangeSearch = (name, value) => {
    let detail = this.state.logReq;
    detail[name] = value;
    detail[name] = value.trim();
    this.setState({ searchList: { ...detail } });
  }

  handleDate = (value) => {
    this.setState({ logReq: value });
    let request = {
      ...this.state.searchList,
      startDate: this.state.logReq.startDate,
      endDate: this.state.logReq.endDate,
    };
    this.setState({ logReq: { ...request } })
  }

  handleSearch = () => {
    this.LogsServices.onGetReqList(this.state.logReq);
  };

  onReset = () => {
    let request = {
      searchText: '',
      startDate: null,
      endDate: null,
    };
    this.LogsServices.onGetReqList(request);
    this.setState({
      logsList: [],
      searchList: {  // Clear input fields
        searchText: '',
        startDate: null,
        endDate: null,
      },
      logReq: {  // Clear logReq in state
        searchText: '',
        startDate: null,
        endDate: null,
      },
    });
    this.setState(prevState => ({
      shouldRerenderFormCreator: !prevState.shouldRerenderFormCreator
    }));
  };


  render() {
    return (
      <>
        <div className="d-flex justify-content-end log-filter pl-0 align-items-center" key={this.state.shouldRerenderFormCreator}>
          {this.LogsServices.formFieldsSearch.map((Item, index) => {
            return (
              <>
                <FormFieldGroup
                  className="ml-0 pl-0"
                  column={Item}
                  value={this.state.searchList[Item.fieldName]}
                  onChange={(event) =>
                    this.handleChangeSearch(Item.fieldName, event.target.value)
                  }
                  key={index}
                />
              </>
            );
          })}
          <div>
            <DateRangePickerComponent onChange={this.handleDate} />
          </div>
          <div className="d-flex log-btn-new">
            <button
              className="tab-title tab-title--active btn-hover color-3 active"
              onClick={this.handleSearch}
            >Search</button>
            <Button type='cancel' title="Clear" className="ml-1"
              onClick={this.onReset}
            />
          </div>
        </div>
        <div className="">
          <VerticalPogressBarStyle />
          {this.state.setIsLoad ? (
            <Bars
              className="row"
              wrapperStyle={{ margin: "100px 0" }}
              color="#3c4b64"
              height={55}
              width="100%"
              visible={this.state.setIsLoad}
            />
          ) : (
            <>
              <Accordion>
                {this.state.logsList
                  ? this.state.logsList.map((add, index) => {
                    return (
                      <AccordionItem
                        title={`Request Name :  ${add && add.length > 0 ? add[0].requestName : null
                          } `}
                        expanded={add === 1}
                      >
                        <div className="mt-1">
                          {add && add.length > 0 ? (
                            <div className="card p-4 my-4">
                              {add.map((log, logindex) => (
                                <div className="timeline-sec1">
                                  <div className="timeline-part">
                                    {log.logType === "Info" ||
                                      log.logType === "INFO" ? (
                                      <div className="timeline-block timeline-block-right main-top-title">
                                        <div className="marker main-big-icon log-details-icons">
                                          <i
                                            className="fa fa-info-circle   timecolor"
                                            aria-hidden="true"
                                          ></i>
                                        </div>
                                        <div className="timeline-content fixed_width_content">
                                          <div className="card m-3 shadow">
                                            <div className="card-body p-3">
                                              <div className="d-flex gap-2">
                                                <div className="d-flex moment-title">
                                                  <h5>
                                                    <strong>
                                                      {" "}
                                                      {log.componentName}
                                                    </strong>
                                                  </h5>
                                                  <label className="ml-3">
                                                    <strong className="align-items-end-date-time text-dark">
                                                      {" "}
                                                      {DataFormat(
                                                        log.logDateTime
                                                      )}
                                                    </strong>
                                                  </label>
                                                </div>
                                                <div className=" d-flex ">
                                                  <p className="text-dark rpa_para">
                                                    :&nbsp;&nbsp;&nbsp;
                                                    {log.logs}{" "}
                                                  </p>
                                                </div>
                                              </div>
                                              <span className="text-dark fs-6  ms-3">
                                                {log.otherDetails}{" "}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                    {log.logType === "Error" ||
                                      log.logType === "ERROR" ? (
                                      <div className="timeline-block timeline-block-right main-top-title">
                                        <div className="marker main-big-icon ">
                                          <i
                                            className="fa fa-times-circle   fa-3x timecolor1"
                                            ar
                                            ia-hidden="true"
                                          ></i>
                                        </div>
                                        <div className="timeline-content">
                                          <div className="card m-3 shadow">
                                            <div className="card-body p-3">
                                              <div className="col-12 d-flex gap-2">
                                                <div className="d-flex moment-title">
                                                  <h5>
                                                    <strong>
                                                      {" "}
                                                      {log.componentName}
                                                    </strong>
                                                  </h5>
                                                  <label className="ml-3">
                                                    <strong className="align-items-end-date-time text-dark">
                                                      {" "}
                                                      {DataFormat(
                                                        log.logDateTime
                                                      )}
                                                    </strong>
                                                  </label>
                                                </div>
                                                <div className=" d-flex gap-2">
                                                  <p className="text-dark rpa_para">
                                                    :&nbsp;&nbsp;&nbsp;
                                                    {log.logs}{" "}
                                                  </p>
                                                </div>
                                              </div>
                                              <span className="text-dark fs-6 ms-3 ">
                                                {log.otherDetails}{" "}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                    {log.logType === "Result" ||
                                      log.logType === "RESULT" ? (
                                      <div className="timeline-block timeline-block-right main-top-title">
                                        <div className="marker main-big-icon ">
                                          <i
                                            className="fa fa-check-circle  fa-3x timecolor2"
                                            aria-hidden="true"
                                          ></i>
                                        </div>
                                        <div className="timeline-content ">
                                          <div className="card m-3 shadow">
                                            <div className="card-body p-3">
                                              <div className="col-12 d-flex gap-2">
                                                <div className="d-flex moment-title">
                                                  <h5>
                                                    <strong>
                                                      {" "}
                                                      {log.componentName}
                                                    </strong>
                                                  </h5>
                                                  <label className="ml-3">
                                                    <strong className="align-items-end-date-time text-dark">
                                                      {" "}
                                                      {DataFormat(
                                                        log.logDateTime
                                                      )}
                                                    </strong>
                                                  </label>
                                                </div>
                                                <div className=" d-flex gap-2">
                                                  <p className="text-dark rpa_para">
                                                    :&nbsp;&nbsp;&nbsp;
                                                    {log.logs}{" "}
                                                  </p>
                                                </div>
                                              </div>
                                              <span className="text-dark fs-6 ms-3 ">
                                                {log.otherDetails}{" "}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : null}
                        </div>
                      </AccordionItem>
                    );
                  })
                  : null}
              </Accordion>
            </>
          )}
        </div>
      </>
    );
  }
}
