import React from "react";

const SelectField = (request) => {
    let props = request.request;
    return (
        <>
            <select
                className="select2 form-select"
                value={props.value}
                onChange={props.onChange}
                onBlur={props.onBlur}
                id={props.column.fieldName}
                hidden={props.hidden}
                disabled={props.selectDisabled}
                name={props.column.fieldName}>
                {props.column.placeholder !== "" ?
                    <option value="" disabled>{props.column.placeholder}</option>
                    : null}
                {props.options?.map((operationalList, index) => (
                    <option key={index} value={operationalList.id} name={operationalList.name}>
                        {operationalList.name}
                    </option>
                ))}
            </select>
        </>
    )
}


export default SelectField;
