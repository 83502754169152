import BaseDomainService from "./baseDomainServices";

const searchFilter = {
    searchText: "",
}
export default class LogsServices extends BaseDomainService {

    // API Get
    getLogList = this.apiService.logServices.getAllLogsDetails;
    getList = this.apiService.logServices.getRunningRequestDetails;

    formFieldsSearch = [
        {
            inputType: "text",
            tittle: "",
            placeholder: "Search By Request Name",
            fieldName: "searchText",
            mode: 1,
            className: 'col-md-4 mb-0'
        },
    ]

    //** API Calls 

    constructor() {
        super();
        this.onPaginationUpdate = this.onLogs.bind(this);
        this.SearchdataItem = { ...searchFilter };
    }

    onLogs = (requst) => {
        this.apiService.onAPICall("getLogs", this.getLogList, requst, this.onAPIResponse,this.onUpdateState);
    }
    onGetReqList = (requst) => {
        this.apiService.onAPICall("getReq", this.getList, requst, this.onAPIResponse,this.onUpdateState);
    }
    onAPIResponse = (key, isSuccess, responseData) => {
        if (!isSuccess) {
            this.onUpdateState({ "setIsLoad": false });
            return;
        }
        switch (key) {
            case "getLogs": 
                this.onUpdateState({
                    "logsList": responseData,
                    "TotalCount": responseData.totalCount,
                    "setIsLoad": false
                });
                break;
                case "getReq":
                let resData = responseData
                    let tempMainArr = [];
                    let subArray = [];
                    let tempId = 0;
                    resData.map((log, index) => {
                        if (tempId === 0 && index === 0) {
                            tempId = log.dataKeyId;
                            subArray.push(log);
                        } else if (tempId === log.dataKeyId) {
                            subArray.push(log);
                            if (index === resData.length - 1) {
                                tempMainArr.push(subArray);
                            }
                        } else if ((tempId !== log.dataKeyId)) {
                            tempMainArr.push(subArray);
                            subArray = [];
                            tempId = log.dataKeyId;
                            subArray.push(log);
                        } else if (index === resData.length - 1) {
                            tempMainArr.push(subArray);
                            subArray = [];
                            tempId = log.dataKeyId;
                            subArray.push(log);
                        }
                    })
                    this.onUpdateState({ logsList: tempMainArr ,
                        "setIsLoad": false })
                    break;
                    default:
                    // Handle default case here
                    break;
        }
    }

}
