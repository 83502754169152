import { SuccessMessage } from "../../utils/Messages";
import { getValidationRuleList } from "../../utils/validation/generateValidationRule";
import BaseDomainService from "./baseDomainServices";

const serverDetail = {
    serverName: "",
    serverIp: "",
    serverLocationId: "",
    isActive: false,
}

export default class manageServerServices extends BaseDomainService {

    // API GET
    add = this.apiService.serversServices.addServer;
    getAllServerList = this.apiService.serversServices.getAllServerListing;
    getById = this.apiService.serversServices.getServerById;
    update = this.apiService.serversServices.updateServer;
    deleteServer = this.apiService.serversServices.deleteServerById;


    //Table Grid columns
    gridColmns = [
        { name: 'serverName', title: 'Server Name' },
        // { name: 'serverId', title: 'Server Id' },
        { name: 'serverIp', title: 'Ip Address' },
        { name: 'serverLocationId', title: 'location' },
        { name: 'isActive', title: 'Active' },
        { name: 'hostServerId', title: 'Action' }
    ];

    constructor() {
        super();
        // this.onPaginationUpdate = this.onGetServer.bind(this);
        this.actionKey = "hostServerId";
        this.actionActive = "isActive";
        this.actionRequestType = "requestType";
        this.dataItem = { ...serverDetail };
    }
    onInitService() {
        //This method only call when we use form component and we can add all items which we on on form load 
        let valRule = getValidationRuleList(this.formFields)
        this.validationRules = valRule;
        this.onUpdateState({
            "validationRules": valRule,
            "resetValidationRule": this.resetValidationRule
        })
    }

    // Define the state variables
    dataItem;

    // define the form fields
    formFields = [
        {
            inputType: "text",
            tittle: "Server Name",
            placeholder: "Server Name",
            fieldName: "serverName",
            className: 'col-md-6',
            validation: [{ type: "require" }]
        },
        {
            inputType: "text",
            tittle: "Ip Address",
            placeholder: "Ip Address",
            fieldName: "serverIp",
            className: 'col-md-6',
            onkeydown: (e) => {
                /^[\s\a-zA-Z,\b][0-9,\b]*$/.test(e.key) && e.preventDefault()
            },
            validation: [{ type: "require" }]
        },
        {
            inputType: "select",
            tittle: "Location",
            placeholder: "Select location",
            fieldName: "serverLocationId",
            Options: [
                { name: "USA", id: "1" },
                { name: "INDIA", id: "2" },
            ],
            className: 'col-md-6 mb-3'
        },
        {
            inputType: "checkbox",
            tittle: "IsActive ?",
            fieldName: "isActive",
            checked: true,
            className: 'col-md-3 ServerChekbox mt-2',
        },
    ]

    onAdd = (request) => {
        this.apiService.onAPICall("addServer", this.add, request, this.onAPIResponse, this.onUpdateState);
    }
    onGetAllServerList = (requst) => {
        this.apiService.onAPICall("getAllServerList", this.getAllServerList, requst, this.onAPIResponse, this.onUpdateState);
    }
    onGetServerById = (requst) => {
        this.apiService.onAPICall("getById", this.getById, requst, this.onAPIResponse, this.onUpdateState);
    }
    onUpdate = (requst) => {
        this.apiService.onAPICall("update", this.update, requst, this.onAPIResponse, this.onUpdateState);
    }
    onDeleteServer = (requst) => {
        this.apiService.onAPICall("deleteServer", this.deleteServer, requst, this.onAPIResponse, this.onUpdateState);
    }


    //** Redirect to list */

    onRedirectoList = () => {
        this.onRedirect("/ManageServer");
      };
    onViewRequestDetail = () => {
        this.onRedirect("/ServerClientConfigurations");
    }


    onAPIResponse = (key, isSuccess, responseData) => {
        if (!isSuccess) {
            this.onUpdateState({ "setIsLoad": false });
            return;
        }
        switch (key) {
            case "addServer":
                let data = responseData;
                if (data.errorType === 'EXIST') {
                    this.apiService.serviceManager.swalServices.Error(SuccessMessage.Already_Exist.replace("{0}", data.errorColumn))
                } else {
                    this.apiService.serviceManager.swalServices.Success(SuccessMessage.Add_Success.replace("{0}", "Server"));
                    this.onRedirectoList();
                }
                this.onUpdateState({ "setIsLoad": false });
                break;
            case "getAllServerList":
                let itemList = responseData.itemList;
                itemList.forEach(function (i) {
                    i.serverLocationId =
                        i.serverLocationId === 1 ? "USA" :
                            i.serverLocationId === 2 ? "INDIA" :
                                null
                });
                if (responseData !== null) {
                    this.onUpdateState({
                        "serverList": itemList,
                        "setIsLoad": false
                    });
                }
                break;
            case "getById":
                if (responseData !== null) {
                    let userDet = ({ ...responseData });
                    this.dataItem = { ...userDet };
                    this.onUpdateState({
                        serverDetail: {
                            serverId: userDet.serverId,
                            serverName: userDet.serverName,
                            serverIp: userDet.serverIp,
                            serverLocationId: userDet.serverLocationId,
                            isActive: userDet.isActive
                        },
                        "setIsLoad": false,
                        "IsUpdatedMode": true
                    });
                }
                break;
            case "update":
                if (responseData.hasError === false) {
                    this.apiService.serviceManager.swalServices.Success(SuccessMessage.Update_Success.replace("{0}", "Server"));
                } else if (responseData.errorType === 'EXIST') {
                    this.apiService.serviceManager.swalServices.Error(SuccessMessage.Already_Exist.replace("{0}", responseData.errorColumn))
                }
                this.onUpdateState({ "setIsLoad": false });
                break;
            case "deleteServer":
                if (responseData !== null) {
                    this.onUpdateState({ "setIsLoad": false });
                    this.apiService.serviceManager.swalServices.Success(SuccessMessage.Delete_Success.replace("{0}", "Server"));
                    this.onGetAllServerList(this.pagination);
                }
                break;
                default:
                    // Handle default case here
                    break;
        }
    }
}