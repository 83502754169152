//** Component  */
import React from 'react';
import BaseComponent from '../../shared/baseComponent';

//** Services */
import UserDomainServices from '../../services/domainServices/userServices';

//*** Lib */
import { Navigate } from 'react-router';
import Button from '../../components/common/Button';
import FormFieldGroup from '../../components/UI Element/FormGrop/FormFieldGroup';
import ButtonLoader from '../../components/common/buttonLoader/ButtonLoader';

export default class AddNewUsers extends BaseComponent {
    //** Constructor  and Component method */
    constructor(props) {
        super(props)
        this.userdmServices = new UserDomainServices();
        this.userdmServices.onUpdateState = this.updateState.bind(this);
        this.state = {
            ...this.state, // Need to use parent state
            updateUser: "",
            addUserDetails: this.userdmServices.dataItem,
            userReset: this.userdmServices.userResetItem,
            resetValidationRule: {},
            setIsLoad: false

        }
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount() {
        this.userdmServices.onInitService();
        this.userdmServices.onGetByid(this.userdmServices.authUser.adminUserId);
    }

    //** Other function  */
    handleChanges(name, value) {
        let detail = this.state.userReset;
        detail[name] = value;
        this.setState({ userReset: { ...detail } });
    }

    // *** handleChange function **//
    handleChange(name, value) {
        let detail = this.state.addUserDetails;
        detail[name] = value;
        this.setState({ addUserDetails: { ...detail } });
    }

    //** API Function  */
    UpdateUserpassword = () => {
        if (this.userdmServices.isValidSubmit(this.state.userReset, this.state.resetValidationRule, this.state.validState)) {
            let requests = {
                userId: this.userdmServices.authUser.adminUserId,
                password: this.state.userReset.password,
                confirmPassword: this.state.userReset.confirmPassword
            }
            this.userdmServices.onUpdatePassword(requests);
        }
    }
    updateUserDetail = () => {
        let request = {
            userId: this.state.addUserDetails.userId,
            userName: this.state.addUserDetails.userName,
            firstName: this.state.addUserDetails.firstName,
            lastName: this.state.addUserDetails.lastName,
            phoneNo: this.state.addUserDetails.phoneNo,
            designation: this.state.addUserDetails.designation,
            updatedBy: this.userdmServices.authUser.adminUserId,
            isActive: this.state.addUserDetails.isActive,
            hashedPassword: this.state.addUserDetails.hashedPassword,
            userType: this.state.addUserDetails.userType
        }
        if (this.userdmServices.isValidSubmit(this.state.addUserDetails, this.state.validationRules, this.state.validState)) {
            this.userdmServices.onUpdateUserDetail(request);
        }
    }

    //** Render Method  */
    render() {
        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} />
        }
        return (
            <>
            <div className="card mb-30">
                <div className="card-body custom-card-body">
                    <div className="row">
                        {this.userdmServices.formFields.map((Item, index) => {
                            return (
                                <>
                                <FormFieldGroup
                                    column={Item}
                                    value={this.state.addUserDetails[Item.fieldName]}
                                    error={this.state.validState.error[Item.fieldName]}
                                    onChange={(event) => Item.checked !== true ? this.handleChange(Item.fieldName, event.target.value)
                                        : this.handleChange(Item.fieldName, event.target.checked)}
                                    options={Item.Options ? Item.Options : null}
                                    checked={Item.checked === true ? this.state.addUserDetails[Item.fieldName] : null}
                                    onBlur={() => this.userdmServices.validateField(Item.fieldName, this.state.addUserDetails, this.state.validationRules, this.state.validState)}
                                    onKeyDown={Item.onkeydown ? Item.onkeydown.bind(this) : null}
                                    key={index} />
                                </>)
                        })}
                        <div className="col-md-12 addnewuser-button">
                            <button className="tab-title tab-title--active ml-2 btn-hover color-3 active" onClick={() => this.updateUserDetail()}>
                                {this.state.setIsLoad ? <ButtonLoader /> : "Update "}      </button>
                            <Button type='cancel' title="Cancel" className="btn-hover color-3 tab-title ml-2" onClick={() => this.userdmServices.onRedirectoDashboard()} />
                        </div>
                    </div>

                </div>
            </div>
            <div className="card">
                <div className='card-title '>
                    <h4 className="card-header">Reset Password</h4>
                </div>
                <div className="card-body custom-card-body">
                    <div className="row">
                        {this.userdmServices.PassformFields.map((Item, index) => {
                            return (
                                <>
                                <FormFieldGroup
                                    column={Item}
                                    value={this.state.userReset[Item.fieldName]}
                                    error={this.state.validState.error[Item.fieldName]}
                                    onChange={(event) => this.handleChanges(Item.fieldName, event.target.value)}
                                    onBlur={() => this.userdmServices.validateField(Item.fieldName, this.state.userReset, this.state.resetValidationRule, this.state.validState)}
                                    onKeyDown={Item.onkeydown ? Item.onkeydown.bind(this) : null}
                                    key={index} />
                                </>
                            )
                        })}
                    </div>
                    <div className="col-md-12 d-flex justify-content-end new-btn-width">
                        <button className="tab-title tab-title--active ml-2 btn-hover color-3 active" onClick={() => this.UpdateUserpassword()}>
                            {this.state.setIsLoad ? <ButtonLoader /> : "Update Password"}      </button>
                        <Button type='cancel' title="Cancel" className="btn-hover color-3 tab-title ml-2" onClick={() => this.userdmServices.onRedirectoDashboard()} />
                    </div>
                </div>
            </div>
            </>
        )
    }
}