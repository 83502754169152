//** Component  */
import React from "react";
import BaseComponent from "../../shared/baseComponent";
import Table from "../../components/table/Table";

//** Services */
import operationalDomainService from "../../services/domainServices/operationalService";

//*** Lib */
import { Navigate } from "react-router";
import { Bars } from "react-loader-spinner";
import FormFieldGroup from "../../components/UI Element/FormGrop/FormFieldGroup";
import { Link } from "react-router-dom";
import { encryptionData } from '../../../src/utils/Encryption';

export default class RPAModules extends BaseComponent {
  constructor(props) {
    super(props);
    this.operationalService = new operationalDomainService();
    this.state = {
      pagination: this.operationalService.pagination,
      searchList: this.operationalService.dataItems,
      setIsLoad: false,
      dataList: [],
      TotalCount: [],
    };
    this.userId = this.operationalService.authUser.adminUserId;
    this.operationalService.onUpdateState = this.updateState.bind(this);
    this.operationalService.onActionCall = this.onActionCall.bind(this);
  }

  //** Constructor  and Component method */
  componentDidMount() {
    this.operationalService.onGetActiveList(this.state.pagination);
  }

  // *** Action function **//
  onActionCall = (type, key) => {

    // *** getByID encryption ***//
    const encryptKey = encryptionData(key, 1);

    switch (type) {
      case "Edit":
        window.open(`/AddNewComponent/id?=${encryptKey}`, "_blank");
        break;
      case "Delete":
        this.operationalService.apiService.serviceManager.swalServices
          .Confirm(
            "Are you sure, you want to delete this Module?",
            "",
            "Yes",
            "No"
          )
          .then(async (confirm) => {
            if (confirm) {
              let data = {
                id: key,
                deletedBy: this.userId,
              };
              this.operationalService.onDelete(data);
            }
          });
        break;
      default:
        // Handle default case here
        break;
    }
  };

  handleChangeEvent = (name, value) => {
    let detail = this.state.pagination;
    detail[name] = value;
    this.setState({ searchList: { ...detail } });
  };

  handleChange = (name, value) => {
    let detail = this.state.pagination;
    detail[name] = value;
    if (name === "searchText") {
      detail[name] = value.trim();
      let data = { ...this.state.pagination };
      data.pageNo = 1;
      this.operationalService.onGetActiveList(data);
      this.setState({ pagination: { ...data } });
    }
    this.setState({ searchList: { ...detail } });
  };
  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <div className="ManageoperationalList-search mt-1">
        <div className="top-search-add-button-sec mb-3">
          <div className="row left-searchbar-sec">
            {this.operationalService.SearchformFields.map((Item, index) => {
              return (
                <>
                  <FormFieldGroup
                    className="ms-auto"
                    column={Item}
                    value={this.state.searchList[Item.fieldName]}
                    onChange={(event) =>
                      this.handleChangeEvent(Item.fieldName, event.target.value)
                    }
                    onKeyPress={(e) =>
                      e.key === "Enter" &&
                      this.handleChange(Item.fieldName, e.target.value)
                    }
                    key={index}
                  />
                </>
              );
            })}
          </div>
          <div className="right-button-sec">
            <Link
              to="/AddNewComponent"
              className="tab-title btn-hover color-3 active"
            >
              Add New RPA Modules <span>+</span>
            </Link>
          </div>
        </div>
        <div className="rpa-module-list">
          {this.state.setIsLoad ? (
            <Bars
              className="row"
              wrapperStyle={{ marginTop: "150px" }}
              color="#3c4b64"
              height={55}
              width="100%"
              visible={this.state.setIsLoad}
            />
          ) : (

            <Table
              columns={this.operationalService.gridColmns}
              rows={this.state.dataList}
              sortingColumns={[]}
              pagination={this.state.pagination}
              totalCount={this.state.TotalCount}
              totalResultes={this.state.TotalCount}
              setPagination={this.operationalService.onPageChange.bind(this)}
              customScope={[
                {
                  column: "moduleId",
                  isDeleteRequired: true,
                  renderTableData:
                    this.operationalService.gridActionsCol.bind(this),
                },
                {
                  column: "isActive",
                  renderTableData:
                    this.operationalService.gridCheckBoxCol.bind(this),
                },
              ]}
            />
          )}
        </div>
      </div>
    );
  }
}
