import React from 'react'
import BaseComponent from '../../shared/baseComponent';
import emailValidationDomainServices from '../../services/domainServices/emailValidationServices';
import Button from '../../components/common/Button';
import FormFieldGroup from '../../components/UI Element/FormGrop/FormFieldGroup';
import { Navigate } from 'react-router-dom';
import { ErrorMessage } from '../../utils/Messages';
import { email } from '../../utils/validation/ValidateField';

export default class AddEmail extends BaseComponent {

  constructor(props) {
    super(props)
    this.emailValidationServices = new emailValidationDomainServices();
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      ...this.state,
      setIsLoad: false,
      operationalFormField: this.emailValidationServices.dataItem,
      setOperationalList: []
    }
    this.emailValidationServices.onUpdateState = this.updateState.bind(this);
  }

  handleChange(name, value) {
    let detail = this.state.operationalFormField;
    detail[name] = value;
    this.setState({ operationalFormField: { ...detail } });
  }

  handleFileChange(event) {
    const { name, value } = event.target;
    const File = event.target.files[0];

    if (File && File.type === "text/plain") {
      if (File.size > 0) {
        const importFile = {
          ...this.state.operationalFormField,
          [name]: value,
          inputFile: File.name,
          inputScriptFile: File,
          FileName: File.name, // Add FileName property for PDF
        };

        this.setState({ operationalFormField: importFile });
      }
    } else {
      this.emailValidationServices.apiService.serviceManager.swalServices.Toaster(ErrorMessage.InvalidFileTypeTXT);
    }
  }

  componentDidMount() {
    this.emailValidationServices.onInitService();
  }

  proceed = () => {
    let detail = this.state.operationalFormField;
    let isValid = this.emailValidationServices.isValidSubmit(detail, this.state.validationRules, this.state.validState)
    if (isValid) {
      if (detail.EmailTypeId === "1") {
        if ((detail.EmailAddress)) {
          let validEmail = email(detail.EmailAddress)
          if (validEmail === false) {
            this.emailValidationServices.apiService.serviceManager.swalServices.Toaster(ErrorMessage.NOTVALIDPATTERN.replace("{0}", "Email Address"));
          }
          else {
            this.add();
          }
        }
        else {
          this.emailValidationServices.apiService.serviceManager.swalServices.Error(ErrorMessage.FieldRequired.replace("{0}", "Email Address"));
        }
      }
      else if (detail.EmailTypeId === "2") {
        if ((detail.File === null) && detail.FileName === "") {
          this.emailValidationServices.apiService.serviceManager.swalServices.Error(ErrorMessage.InvalidFileTypeTXT);
        }
        else {
          this.add();
        }

      }
    }
  }

  add = () => {
    const { RequestName, CreatedBy, EmailTypeId, inputScriptFile, FileName, EmailAddress } = this.state.operationalFormField;
    const commonProperties = {
      RequestName,
      CreatedBy,
      EmailTypeId,
    };
    const request = {
      ...commonProperties,
      File: EmailTypeId === "2" ? (inputScriptFile ? inputScriptFile : null) : null,
      FileName: EmailTypeId === "2" ? FileName : "",
      EmailAddress: EmailTypeId === "1" ? EmailAddress : "",
    };

    this.emailValidationServices.onAdd(request);
  };

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />
    }
    return (
      <div>
        <div className="mb-4">
          <div className="accordion" id="accordionExample">
            <div className="card accordion-item active">
              <div id="accordionOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <div className="card-body">

                    <div className="row mb-4">
                      {this.emailValidationServices.formFields.map((Item, index) => {
                        return (
                          <React.Fragment key={index}>
                            {/* Always show Request Name */}
                            {(Item.fieldName === "RequestName" || (Item.fieldName === "EmailTypeId" && Item.inputType === "select")) ? (
                              <FormFieldGroup
                                column={Item}
                                value={this.state.operationalFormField[Item.fieldName]}
                                error={this.state.validState.error[Item.fieldName]}
                                options={Item.Options ? Item.Options : this.state.setModuleList}
                                onKeyDown={Item.onkeydown ? Item.onkeydown.bind(this) : null}
                                onChange={(event) => this.handleChange(Item.fieldName, event.target.value)}
                                onBlur={
                                  Item.inputType !== "checkbox"
                                    ? () =>
                                      this.emailValidationServices.validateField(
                                        Item.fieldName,
                                        this.state.operationalFormField,
                                        this.state.validationRules,
                                        this.state.validState
                                      )
                                    : null
                                }
                              />
                            ) : (
                              // Conditionally render based on the selected mode
                              (this.state.operationalFormField.EmailTypeId === "1" && Item.fieldName === "EmailAddress") ? (
                                <FormFieldGroup
                                  column={Item}
                                  value={this.state.operationalFormField[Item.fieldName]}
                                  error={this.state.validState.error[Item.fieldName]}
                                  options={Item.Options ? Item.Options : this.state.setModuleList}
                                  onKeyDown={Item.onkeydown ? Item.onkeydown.bind(this) : null}
                                  onChange={(event) => this.handleChange(Item.fieldName, event.target.value)}
                                  onBlur={
                                    Item.inputType !== "checkbox"
                                      ? () =>
                                        this.emailValidationServices.validateField(
                                          Item.fieldName,
                                          this.state.operationalFormField,
                                          this.state.validationRules,
                                          this.state.validState
                                        )
                                      : null
                                  }
                                />
                              ) : (this.state.operationalFormField.EmailTypeId === "2" && Item.fieldName === "File") ? (
                                <FormFieldGroup
                                  column={Item}
                                  value={this.state.operationalFormField[Item.fieldName]}
                                  error={this.state.validState.error[Item.fieldName]}
                                  options={Item.Options ? Item.Options : this.state.setModuleList}
                                  onKeyDown={Item.onkeydown ? Item.onkeydown.bind(this) : null}
                                  onChange={(event) => this.handleFileChange(event)}
                                  onBlur={
                                    Item.inputType !== "checkbox"
                                      ? () =>
                                        this.emailValidationServices.validateField(
                                          Item.fieldName,
                                          this.state.operationalFormField,
                                          this.state.validationRules,
                                          this.state.validState
                                        )
                                      : null
                                  }
                                />
                              ) : null
                            )}
                          </React.Fragment>
                        );
                      })}
                    </div>

                  </div>
                </div>
                <div className="addnewuser-button mb-4">
                  <button
                    className="tab-title tab-title--active  btn-hover  color-3 active"
                    onClick={() => this.proceed()}
                  >Save</button>
                  <Button type='cancel' title="Cancel" className="ml-2" onClick={() => this.emailValidationServices.onRedirectoList()} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
