//** Component  */
import React from 'react'

//*** Lib */

//** Services */
import TabProgressBarStyle from '../../styles/TabProgressBarStyle';
import { DownloadInputFile } from '../../services/axiosServices/ApiEndPoints';
import RequestDomainServices from '../../services/domainServices/requestServices';
import BaseComponent from '../../shared/baseComponent';
import { decryptionData } from '../../utils/Encryption';

export default class RequestCleaning extends BaseComponent {
    constructor(props) {
        super(props)
        this.requestServices = new RequestDomainServices();
        this.requestServices.onUpdateState = this.updateState.bind(this);
        this.state = {
            productsCounts: []
        }
    }


    //** Constructor  and Component method */
    componentDidMount() {
        let params = new URLSearchParams(window.location.search);
        let reqId = params.get("");
        const decryptKey = decryptionData(reqId, false);
        if (reqId > 0) {
            this.requestServices.onGettingCountById(decryptKey);
        }
    }

    render() {
        return (
            <>
                <TabProgressBarStyle />
                <div className="card">
                    <div className='card-title-main px-3 py-2'>
                        <h6 className='titletexts font-size-16'>Cleaning Files</h6>
                    </div>
                    <div className="card-body custom-card-body">
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <div className='inner-detail-sec'>
                                    <span className='detail-title'>Cleanner </span>
                                    <span className="detail-desc">{this.props.productsDetails.moduleName}</span>
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <div className='inner-detail-sec'>
                                    <span className='detail-title'>Product File </span>
                                    {this.props.productsCounts ? <>
                                        <span for="requestName" className="detail-desc">
                                            {this.props.productsCounts.cleanerProductCount > 0 && this.props.productsDetails.productFile ? this.props.productsCounts.cleanerProductCount : null}
                                        </span>
                                        {this.props.productsCounts.cleanerProductCount > 0 && this.props.productsDetails.productFile ?
                                            <span for="requestName" className="detail-desc ml-3">
                                                <a href={`${DownloadInputFile + `?FileName=${this.props.productsDetails.productFile}&ModuleType=Cleaning`}`} target="_top"> <i className="fa fa-download"></i></a>
                                            </span>
                                            :
                                            <span className='detail-desc'>
                                                No Record found
                                            </span>
                                        }
                                    </>
                                        : <span className='detail-desc'>
                                            No Record found
                                        </span>}
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <div className='inner-detail-sec'>
                                    <span className='detail-title'>Price File </span>
                                    {this.props.productsCounts ? <>
                                        <span for="requestName" className="detail-desc">
                                            {this.props.productsCounts.cleanerPriceCount > 0 && this.props.productsDetails.priceFile ? this.props.productsCounts.cleanerPriceCount : null}
                                        </span>
                                        {this.props.productsCounts.cleanerPriceCount > 0 && this.props.productsDetails.priceFile ?
                                            <span for="requestName" className="detail-desc ml-3">
                                                <a href={`${DownloadInputFile + `?FileName=${this.props.productsDetails.priceFile}&ModuleType=Cleaning`}`} target="_top"> <i className="fa fa-download"></i></a>
                                            </span>
                                            :
                                            <span className='detail-desc'>
                                                No Record found
                                            </span>
                                        }
                                    </>
                                        : <span className='detail-desc'>
                                            No Record found
                                        </span>}
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <div className='inner-detail-sec'>
                                    <span className='detail-title'>Stock File </span>
                                    {this.props.productsCounts ? <>
                                        <span for="requestName" className="detail-desc">
                                            {this.props.productsCounts.cleanerStockCount > 0 && this.props.productsDetails.stockFile ? this.props.productsCounts.cleanerStockCount : null}
                                        </span>
                                        {this.props.productsCounts.cleanerStockCount > 0 && this.props.productsDetails.stockFile ?
                                            <span for="requestName" className="detail-desc ml-3">
                                                <a href={`${DownloadInputFile + `?FileName=${this.props.productsDetails.stockFile}&ModuleType=Cleaning`}`} target="_top"> <i className="fa fa-download"></i></a>
                                            </span>
                                            :
                                            <span className='detail-desc'>
                                                No Record found
                                            </span>
                                        }
                                    </>
                                        : <span className='detail-desc'>
                                            No Record found
                                        </span>}
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <div className='inner-detail-sec'>
                                    <span className='detail-title'> File With Smile</span>
                                    {this.props.productsCounts ? <>
                                        <span for="requestName" className="detail-desc">
                                            {this.props.productsCounts.cleanerSmileCount > 0 && this.props.productsDetails.smileFile ? this.props.productsCounts.cleanerSmileCount : null}
                                        </span>
                                        {this.props.productsCounts.cleanerSmileCount > 0 && this.props.productsDetails.smileFile ?
                                            <span for="requestName" className="detail-desc ml-3">
                                                <a href={`${DownloadInputFile + `?FileName=${this.props.productsDetails.smileFile}&ModuleType=Cleaning`}`} target="_top"> <i className="fa fa-download"></i></a>
                                            </span>
                                            :
                                            <span className='detail-desc'>
                                                No Record found
                                            </span>
                                        }
                                    </>
                                        : <span className='detail-desc'>
                                            No Record found
                                        </span>}
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <div className='inner-detail-sec'>
                                    <span className='detail-title'> Remaining Product File</span>
                                    {this.props.productsCounts ? <>
                                        <span for="requestName" className="detail-desc">
                                            {this.props.productsCounts.cleanerRemainingProductCount > 0 && this.props.productsDetails.remainingProductFile ? this.props.productsCounts.cleanerRemainingProductCount : null}
                                        </span>
                                        {this.props.productsCounts.cleanerRemainingProductCount > 0 && this.props.productsDetails.remainingProductFile ?
                                            <span for="requestName" className="detail-desc ml-3">
                                                <a href={`${DownloadInputFile + `?FileName=${this.props.productsDetails.remainingProductFile}&ModuleType=Cleaning`}`} target="_top"> <i className="fa fa-download"></i></a>
                                            </span>
                                            :
                                            <span className='detail-desc'>
                                                No Record found
                                            </span>
                                        }
                                    </>
                                        : <span className='detail-desc'>
                                            No Record found
                                        </span>}
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <div className='inner-detail-sec'>
                                    <span className='detail-title'> Remaining Price File</span>
                                    {this.props.productsCounts ? <>
                                        <span for="requestName" className="detail-desc">
                                            {this.props.productsCounts.cleanerRemainingPriceCount > 0 && this.props.productsDetails.remainingPriceFile ? this.props.productsCounts.cleanerRemainingPriceCount : null}
                                        </span>
                                        {this.props.productsCounts.cleanerRemainingPriceCount > 0 && this.props.productsDetails.remainingPriceFile ?
                                            <span for="requestName" className="detail-desc ml-3">
                                                <a href={`${DownloadInputFile + `?FileName=${this.props.productsDetails.remainingPriceFile}&ModuleType=Cleaning`}`} target="_top"> <i className="fa fa-download"></i></a>
                                            </span>
                                            :
                                            <span className='detail-desc'>
                                                No Record found
                                            </span>
                                        }
                                    </>
                                        : <span className='detail-desc'>
                                            No Record found
                                        </span>}
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <div className='inner-detail-sec'>
                                    <span className='detail-title'> Remaining Stock File</span>
                                    {this.props.productsCounts ? <>
                                        <span for="requestName" className="detail-desc">
                                            {this.props.productsCounts.cleanerRemainingStockCount > 0 && this.props.productsDetails.remainingStockFile ? this.props.productsCounts.cleanerRemainingStockCount : null}
                                        </span>
                                        {this.props.productsCounts.cleanerRemainingStockCount > 0 && this.props.productsDetails.remainingStockFile ?
                                            <span for="requestName" className="detail-desc ml-3">
                                                <a href={`${DownloadInputFile + `?FileName=${this.props.productsDetails.remainingStockFile}&ModuleType=Cleaning`}`} target="_top"> <i className="fa fa-download"></i></a>
                                            </span>
                                            :
                                            <span className='detail-desc'>
                                                No Record found
                                            </span>
                                        }
                                    </>
                                        : <span className='detail-desc'>
                                            No Record found
                                        </span>}
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <div className='inner-detail-sec'>
                                    <span className='detail-title'> ErrorPriceFile</span>
                                    {this.props.productsCounts ? <>
                                        <span for="requestName" className="detail-desc">
                                            {this.props.productsCounts.cleanerErrorPriceCount > 0 && this.props.productsDetails.errorPriceFile ? this.props.productsCounts.cleanerErrorPriceCount : null}
                                        </span>
                                        {this.props.productsCounts.cleanerErrorPriceCount > 0 && this.props.productsDetails.errorPriceFile ?
                                            <span for="requestName" className="detail-desc ml-3">
                                                <a href={`${DownloadInputFile + `?FileName=${this.props.productsDetails.errorPriceFile}&ModuleType=Cleaning`}`} target="_top"> <i className="fa fa-download"></i></a>
                                            </span>
                                            :
                                            <span className='detail-desc'>
                                                No Record found
                                            </span>
                                        }
                                    </>
                                        : <span className='detail-desc'>
                                            No Record found
                                        </span>}
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <div className='inner-detail-sec'>
                                    <span className='detail-title'> RemainingErrorPriceFile</span>
                                    {this.props.productsCounts ? <>
                                        <span for="requestName" className="detail-desc">
                                            {this.props.productsCounts.cleanerRemainingErrorPriceCount > 0 && this.props.productsDetails.remainingErrorPriceFile ? this.props.productsCounts.cleanerRemainingErrorPriceCount : null}
                                        </span>
                                        {this.props.productsCounts.cleanerRemainingErrorPriceCount > 0 && this.props.productsDetails.remainingErrorPriceFile ?
                                            <span for="requestName" className="detail-desc ml-3">
                                                <a href={`${DownloadInputFile + `?FileName=${this.props.productsDetails.remainingErrorPriceFile}&ModuleType=Cleaning`}`} target="_top"> <i className="fa fa-download"></i></a>
                                            </span>
                                            :
                                            <span className='detail-desc'>
                                                No Record found
                                            </span>
                                        }
                                    </>
                                        : <span className='detail-desc'>
                                            No Record found
                                        </span>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
