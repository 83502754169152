//*** Lib */
import { axiosGet } from '../AxiosRequests';
import {GetAllSupplierNameList } from '../ApiEndPoints';

export default class ManageSupplierServices {
    // API function

    async getAllSupplierNameList(request) {
        return axiosGet(GetAllSupplierNameList, request);
    }
}