import React from 'react'
import BaseComponent from '../../shared/baseComponent'
import Button from '../../components/common/Button';
import { DataFormat } from '../../utils/DateFormat';
import ExceptionServices from '../../services/domainServices/exceptionService';
import { Navigate } from 'react-router-dom';
import { decryptionData } from '../../utils/Encryption';

export default class ExceptionDetails extends BaseComponent {
    constructor(props) {
        super(props)
        this.exceptionServices = new ExceptionServices();
        let newPagination = { ...this.exceptionServices.pagination }
        newPagination.pageSize = 25
        this.state = {
            ...this.state,
            setIsLoad: false,
            innerSystemErrorMessage:"",
            pagination: newPagination,
            customErrorMessage: "",
            trackTrace: "",
            errorLogId: 0,
            systemErrorMessage: "",
            redirect: null,
            Exceptionlog: 0,
            createdDate: ""
        };
        this.exceptionServices.onUpdateState = this.updateState.bind(this);
    }

    componentDidMount() {
        let params = new URLSearchParams(window.location.search);
        let piId = params.get("");
        this.setState({ errorLogId: piId });
        const decryptKey = decryptionData(piId, false);
        if (decryptKey > 0) {
            this.exceptionServices.onGetErrorTraceById(decryptKey)
        }
       
    }

    render() {
        if (this.state.redirect != null) {
            return <Navigate to={this.state.redirect} />
        }
        return (
            <div>
                {/* <div className="container"> */}
                <div className="row">
                    <div className="col">
                        <div className="sm:px-5 lg:px-5 py-1 w-full max-w-9xl mx-auto">
                            <div className='card'>
                                <div className="card-body custom-card-body activity_details">
                                    <div className='row'>
                                        <div className='col-12 col-md-6 col-lg-12'>
                                            <div className='d-flex p-4 gap-2 useractivitydetailsException'>
                                                <label>Exception Date : </label>
                                                <span>{this.state.createdDate ? DataFormat(this.state.createdDate) : "Data Not Found"}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col-12 col-md-6 col-lg-12'>
                                            <div className='d-flex p-4 gap-2 useractivitydetailsException'>
                                                <label>Error LogId : </label>
                                                <span>{this.state.errorLogId ? this.state.errorLogId : "Data Not Found"}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col-12 col-md-6 col-lg-12'>
                                            <div className='d-flex p-4 gap-2 useractivitydetailsException'>
                                                <label>Custom Error Message : </label>
                                                <span>{this.state.customErrorMessage ? this.state.customErrorMessage : "Data Not Found"}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col-12 col-md-6 col-lg-12'>
                                            <div className='d-flex p-4 gap-2 useractivitydetailsException'>
                                                <label>Inner System Error Message: </label>
                                                <span>{this.state.innerSystemErrorMessage ? this.state.innerSystemErrorMessage : "Data Not Found"}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col-12 col-md-6 col-lg-12'>
                                            <div className='d-flex p-4 gap-2 useractivitydetailsException'>
                                                <label>Track Trace : </label>
                                                <span>{this.state.trackTrace ? this.state.trackTrace : "Data Not Found"}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col-12 col-md-6 col-lg-12'>
                                            <div className='d-flex p-4 gap-2 useractivitydetailsException'>
                                                <label>System Error Message : </label>
                                                <span>{this.state.systemErrorMessage ? this.state.systemErrorMessage : "Data Not Found"}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="addnewuser-button mt-3">
                                        <Button type='cancel' title="Cancel" className="ml-2" 
                                        onClick={() => this.exceptionServices.onRedirectoList()} 
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </div> */}
            </div>
        );

    }
}
