//** Component  */
import Table from "../../components/table/Table";
import BaseComponent from "../../shared/baseComponent";

//** Services */
import RequestDomainServices from "../../services/domainServices/requestServices";

//*** Lib */
import { Navigate } from "react-router";
import { Bars } from "react-loader-spinner";
import FormFieldGroup from "../../components/UI Element/FormGrop/FormFieldGroup";
import { Link } from "react-router-dom";
import { encryptionData } from '../../../src/utils/Encryption'

export default class ManageRequests extends BaseComponent {
  constructor(props) {
    super(props);
    this.requestServices = new RequestDomainServices();
    this.state = {
      pagination: {
        ...this.requestServices.pagination,
        requestTypeList: "0",
        openStatus: props.openStatus,
      },
      setIsLoad: false,
      dataList: [],
      requestTypeList: this.requestServices.dataItem,
    };
    this.requestServices.onUpdateState = this.updateState.bind(this);
    this.requestServices.onActionCall = this.onActionCall.bind(this);
  }
  //** Constructor  and Component method */
  componentDidMount() {
    this.requestServices.onGetRequests(this.state.pagination);
  }

  // *** Other function **//

  onActionCall = (type, data) => {

    // *** getByID encryption ***//
    const encryptKey = encryptionData(data, 1);

    switch (type) {
      case "Edit":
        window.open("/RequestDetails/id?=" + encryptKey, "_blank");
        break;
      case "Update":
        this.requestServices.onUpdateStatus(data);
        this.requestServices.onGetRequests(this.state.pagination);
        break;
        default:
          // Handle default case here
          break;
    }
  };

  handleChange(name, value) {
    let detail = this.state.pagination;
    detail[name] = value;
    if (name === "requestType") {
      this.requestServices.onGetRequests(detail);
    }
    if (name === "searchText") {
      detail[name] = value.trim();
      let data = { ...this.state.pagination };
      data.pageNo = 1;

      this.requestServices.onGetRequests(data);
      this.setState({ pagination: { ...data } });
    }
    this.setState({ requestTypeList: { ...detail } });
  }
  handleRefresh = () => {
    this.requestServices.onGetRequests(this.state.pagination);
  };
  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <>
        <div className="top-search-add-button-sec mb-3">
          <div className="row left-searchbar-sec">
            {this.requestServices.formFields.map((Item, index) => {
              return (
                <>
                  {Item.fieldName === "requestType" ||
                  Item.fieldName === "searchText" ? (
                    <FormFieldGroup
                      className="col-md-6"
                      column={Item}
                      value={this.state.requestTypeList[Item.fieldName]}
                      options={Item.Option ? Item.Option : null}
                      onChange={(event) =>
                        this.handleChange(Item.fieldName, event.target.value)
                      }
                      key={index}
                    />
                  ) : null}
                </>
              );
            })}
          </div>
          <div className="right-button-sec">
          <button onClick={this.handleRefresh} className="tab-title btn-hover color-3 active" style={{ margin: '0px 0px 0px 20px'}}>Refresh</button>             
              <Link to="/AddRequest" className="tab-title btn-hover color-3 active">
                Add RPA Request <span>+</span>
              </Link>
              <Link to="/AddPartialRequest" className="tab-title btn-hover color-3 active">
              Add Partial Request <span>+</span>
              </Link>
          </div>
        </div>
        <div className="requestlist-table">
          {this.state.pagination.openStatus === 1 ? (
            <div className="main-rpa-request">
              {this.state.setIsLoad ? (
                <Bars
                  className="row"
                  wrapperStyle={{ marginTop: "150px" }}
                  color="#3c4b64"
                  height={55}
                  width="100%"
                  visible={this.state.setIsLoad}
                />
              ) : (
                <Table
                  columns={this.requestServices.gridColmns}
                  rows={this.state.dataList}
                  sortingColumns={[]}
                  pagination={this.state.pagination}
                  totalCount={this.state.TotalCount}
                  totalResultes={this.state.TotalCount}
                  setPagination={this.requestServices.onPageChange.bind(this)}
                  resClassName={"responsive_table"}
                  customScope={[
                    {
                      column: "middlewareRequestId",
                      renderTableData:
                        this.requestServices.gridActionRequestStatusCol.bind(
                          this
                        ),
                    },
                  ]}
                />
              )}
            </div>
          ) : (
            <div className="main-rpa-request">
              {this.state.setIsLoad ? (
                <Bars
                  className="row"
                  wrapperStyle={{ marginTop: "150px" }}
                  color="#3c4b64"
                  height={55}
                  width="100%"
                  visible={this.state.setIsLoad}
                />
              ) : (
                <Table
                  columns={this.requestServices.gridColmns}
                  rows={this.state.dataList}
                  sortingColumns={[]}
                  pagination={this.state.pagination}
                  totalCount={this.state.TotalCount}
                  totalResultes={this.state.TotalCount}
                  setPagination={this.requestServices.onPageChange.bind(this)}
                  resClassName={"responsive_table"}
                  customScope={[
                    {
                      column: "middlewareRequestId",
                      renderTableData:
                        this.requestServices.gridActionsCol.bind(this),
                    },
                  ]}
                />
              )}
            </div>
          )}
        </div>
      </>
    );
  }
}
