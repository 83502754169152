//** Component  */
import React from 'react'

//** Services */
import BaseComponent from '../../shared/baseComponent';
import LogDomainServices from "../../services/domainServices/logsServices"

//*** Lib */
import { Bars } from 'react-loader-spinner';
import VerticalPogressBarStyle from '../../styles/VerticalPogressBarStyle';
import FormFieldGroup from '../../components/UI Element/FormGrop/FormFieldGroup';
import { DataFormat } from '../../utils/DateFormat';
import DateRangePickerComponent from '../DateRangePicker';
import Button from '../../components/common/Button';
// import DateRangePickerComponent from '../DateRangePicker';
// import 'bootstrap/dist/css/bootstrap.css';

export default class VerticalProgressBar extends BaseComponent {
    constructor(props) {
        super(props)
        this.LogsServices = new LogDomainServices();
        this.state = {
            logsList: [],
            setIsLoad: false,
            searchList: this.LogsServices.SearchdataItem,
            logReq: {
                searchText: "",
                startDate: null,
                endDate: null,
            },
            shouldRerenderFormCreator: false
        }
        this.LogsServices.onUpdateState = this.updateState.bind(this);
    }

    //** Constructor  and Component method */
    componentDidMount() {
        this.LogsServices.onLogs(this.state.logReq);
    }

    handleChangeSearch = (name, value) => {
        let detail = this.state.logReq;
        detail[name] = value;
        detail[name] = value.trim();
        this.setState({ searchList: { ...detail } });
    }

    handleDate = (value) => {
        this.setState(prevState => ({
            logReq: {
                ...prevState.logReq,
                startDate: value.startDate,
                endDate: value.endDate
            }
        }));
    }

    handleSearch = () => {
        this.LogsServices.onLogs(this.state.logReq);
    };

    onReset = () => {
        let request = {
            searchText: '',
            startDate: null,
            endDate: null,
        };
        this.LogsServices.onLogs(request);
        this.setState({
            searchList: {  // Clear input fields
                searchText: '',
            },
            logReq: {  // Clear input fields
                searchText: '',
                startDate: null,
                endDate: null,
            },
        });
        this.setState(prevState => ({
            shouldRerenderFormCreator: !prevState.shouldRerenderFormCreator
        }));
    };

    render() {
        return (
            <>
                <div className='container'>
                    <VerticalPogressBarStyle />
                    <div className="d-flex justify-content-end log-filter pl-0 align-items-center" key={this.state.shouldRerenderFormCreator}>
                        {this.LogsServices.formFieldsSearch.map((Item, index) => {
                            return (
                                <>
                                    <FormFieldGroup
                                        className="ml-0 pl-0"
                                        column={Item}
                                        value={this.state.searchList[Item.fieldName]}
                                        onChange={(event) =>
                                            this.handleChangeSearch(Item.fieldName, event.target.value)
                                        }
                                        key={index}
                                    />
                                </>
                            );
                        })}
                        <div>
                            <DateRangePickerComponent startDate={this.state.logReq.startDate}
                                endDate={this.state.logReq.endDate} onChange={this.handleDate} />
                        </div>
                        <div className="d-flex log-btn-new">
                            <button
                                className="tab-title tab-title--active btn-hover color-3 active"
                                onClick={this.handleSearch}
                            >Search</button>
                            <Button type='cancel' title="Clear" className="ml-1"
                                onClick={this.onReset}
                            />
                        </div>
                    </div>
                    <div className="card mt-3">
                        <div className="">
                            <div className="colorheader">
                                <h5 className="card-header log-text-size mt-0 text-white">Log Details by Request Name</h5>
                            </div>
                        </div>
                        <div className="container">
                            {
                                (this.state.setIsLoad) ?
                                    <Bars
                                        className="row"
                                        wrapperStyle={{ margin: '100px 0' }}
                                        color="#3c4b64"
                                        height={55}
                                        width="100%"
                                        visible={this.state.setIsLoad}
                                    />
                                    :
                                    <>
                                        {this.state.logsList ? this.state.logsList.map((add, index) => (
                                            <div className="timeline-sec ">
                                                <div className="timeline-part">
                                                    {add.logType === 'Info' || add.logType === 'INFO' ?
                                                        <div className="timeline-block timeline-block-right main-top-title">
                                                            <div className="marker main-big-icon"><i className="fa fa-info-circle  timecolor" aria-hidden="true"></i></div>
                                                            <div className="timeline-content fixed_width_content">
                                                                <div className="card m-3 shadow ">
                                                                    <div className="card-body p-3">
                                                                        <div className="row d-flex align-items-end">
                                                                            <div className="col-xl-12 d-flex">
                                                                                <div className='col-8  d-flex pl-0'>
                                                                                    <h5 className='pl-0'>RequestName : &nbsp;<strong>{add.requestName}</strong></h5>
                                                                                </div>
                                                                                <div className='col-4'>
                                                                                    <h5><strong>{add.componentName}</strong></h5>
                                                                                </div>
                                                                            </div>
                                                                            <div className='row mt-1'>
                                                                                <div className='d-flex mt-2 align-items-cneter strong-min-width'>
                                                                                    <strong className="align-items-end-date-time text-dark"> {DataFormat(
                                                                                        add.logDateTime
                                                                                    )}</strong>
                                                                                    <div>
                                                                                        <p className="text-dark mb-0">:&nbsp;&nbsp;&nbsp;{add.logs}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <span className="text-dark mt-2">{add.otherDetails} </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : null}
                                                    {add.logType === 'Error' || add.logType === 'ERROR' ?
                                                        <div className="timeline-block timeline-block-right main-top-title">
                                                            <div className="marker main-big-icon"><i className="fa fa-times-circle  fa-3x timecolor1" aria-hidden="true"></i></div>
                                                            <div className="timeline-content">
                                                                <div className="card m-3 shadow">
                                                                    <div className="card-body p-3">
                                                                        <div className="row d-flex align-items-end">
                                                                            <div className="col-xl-12 d-flex">
                                                                                <div className='col-8 pl-0'>
                                                                                <h5 className='pl-0'>RequestName : &nbsp;<strong>{add.requestName}</strong></h5>
                                                                                </div>
                                                                                <div className='col-4'>
                                                                                    <h5><strong>{add.componentName}</strong></h5>
                                                                                </div>
                                                                            </div>
                                                                            <div className='row mt-1'>
                                                                                <div className='d-flex mt-2 align-items-cneter strong-min-width'>
                                                                                    <strong className="align-items-end-date-time text-dark"> {DataFormat(
                                                                                        add.logDateTime
                                                                                    )}</strong>
                                                                                    <div>
                                                                                        <p className="text-dark mb-0">:&nbsp;&nbsp;&nbsp;{add.logs}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <span className="text-dark mt-2">{add.exception} </span>
                                                                            <span className="text-dark mt-2">{add.otherDetails} </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : null}
                                                    {add.logType === 'RESULT' || add.logType === 'Result' ?
                                                        <div className="timeline-block timeline-block-right main-top-title">
                                                            <div className="marker main-big-icon"><i className="fa fa-check-circle fa-3x timecolor2" aria-hidden="true"></i></div>
                                                            <div className="timeline-content ">
                                                                <div className="card m-3 shadow">
                                                                    <div className="card-body p-3">
                                                                        <div className="row d-flex align-items-end">
                                                                            <div className="col-xl-12 d-flex">
                                                                                <div className='col-8 pl-0'>
                                                                                <h5 className='pl-0'>RequestName : &nbsp;<strong>{add.requestName}</strong></h5>
                                                                                </div>
                                                                                <div className='col-4'>
                                                                                    <h5><strong>{add.componentName}</strong></h5>
                                                                                </div>
                                                                            </div>
                                                                            <div className='row mt-1'>
                                                                                <div className='d-flex mt-2 align-items-cneter strong-min-width'>
                                                                                    <strong className="align-items-end-date-time text-dark"> {DataFormat(
                                                                                        add.logDateTime
                                                                                    )}</strong>
                                                                                    <div>
                                                                                        <p className="text-dark mb-0">:&nbsp;&nbsp;&nbsp;{add.logs}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <span className="text-dark mt-2">{add.otherDetails} </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        : null}

                                                </div>
                                            </div>
                                        )) : null}
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }

}


