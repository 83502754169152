
import { axiosPost, axiosGet, axiosPostWithoutEncryption, axiosPostAuthorize, axiosGetFileAuthorizeblob } from '../AxiosRequests';
//*** Lib */
import {
    GetAllCodeManagementList,
    AddCodeManagement,
    GetCodeManagementById,
    UploadScriptFile,
    GetAllVersionByCodeManagementId,
    AddVersion,
    GetAllScriptList,
    DeleteScriptVersion,
    DownloadScriptFile,
    DeleteCodeManagementScript,
    UpdateAddVersionIsActive,
    UpdateScript
} from '../ApiEndPoints';

export default class CodeManagementService {
    // API function
    async getAllCodeManagementList(request) {
        return axiosPost(GetAllCodeManagementList, request);
    }
    async addCodeManagement(request) {
        let formData = new FormData();
        formData.append("fileName", request.fileName);
        formData.append("currentVersion", request.currentVersion);
        formData.append("configureType", request.configureType);
        formData.append("active", request.active);
        formData.append("createdBy", request.createdBy);
        formData.append("file", request.file);
        formData.append("scriptName", request.scriptName);
        formData.append("supplierID", request.supplierID);

        return axiosPostAuthorize(AddCodeManagement, formData, true)
    }
    async updateScriptData(request) {
        let formData = new FormData();
        formData.append("codeManagementId", request.codeManagementId);
        formData.append("scriptName", request.scriptName);
        formData.append("fileName", request.fileName);
        formData.append("supplierID", request.supplierID);
        formData.append("configureType", request.configureType);
        formData.append("file", request.file);
        formData.append("updatedBy", request.updatedBy);

        return axiosPostAuthorize(UpdateScript, formData, true);
    }
    async getCodeManagementById(request) {
        return axiosGet(GetCodeManagementById, request);
    }
    async getAllVersionByCodeManagementId(request) {
        return axiosPost(GetAllVersionByCodeManagementId, request);
    }
    async UploadScriptFiles(request) {
        let formData = new FormData();
        formData.append("file", request.File);
        return axiosPostWithoutEncryption(UploadScriptFile, formData, true)
    }
    async addVersion(request) {
        let formData = new FormData();
        formData.append("fileName", request.fileName);
        formData.append("currentVersion", request.currentVersion);
        formData.append("configureType", request.configureType);
        formData.append("file", request.file);
        formData.append("codeManagementId", request.codeManagementId);
        formData.append("scriptName", request.scriptName);

        return axiosPostAuthorize(AddVersion, formData, true)
    }
    async getAllScriptList(request) {
        return axiosGet(GetAllScriptList, request);
    }
    async deleteScriptVersion(request) {
        return axiosPost(DeleteScriptVersion, request);
    }
    async deleteCodeManagementScript(request) {
        return axiosPost(DeleteCodeManagementScript, request);
    }
    async downloadScriptFile(request) {
        return axiosGetFileAuthorizeblob(DownloadScriptFile, request);
    }
    async activeVersion(request) {
        return axiosPost(UpdateAddVersionIsActive, request);
    }
}

