//** Component  */
import React from 'react';
const UnAuthorized = () => {
  return (
    <div className="UnAuthorized d-flex align-items-center justify-content-center flex-column h-100">
      <svg width="400" height="279" viewBox="0 0 400 279" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_501_2)">
          <path d="M79.6974 278.301C123.713 278.301 159.395 275.591 159.395 272.248C159.395 268.905 123.713 266.195 79.6974 266.195C35.6817 266.195 0 268.905 0 272.248C0 275.591 35.6817 278.301 79.6974 278.301Z" fill="#E6E6E6" />
          <path d="M60.9189 61.7501C74.7887 55.8508 92.0637 59.5886 108.935 62.1827C109.092 59.0412 110.994 55.2178 109.001 53.0261C106.579 50.3634 106.802 47.5657 108.26 44.7519C111.986 37.5564 106.647 29.9 101.256 23.5339C100.041 22.1022 98.5055 20.9773 96.7741 20.2506C95.0426 19.5238 93.1642 19.2158 91.2913 19.3514L81.3052 20.0647C78.8751 20.2382 76.5561 21.1498 74.6583 22.6774C72.7605 24.205 71.3745 26.2757 70.6858 28.6125C68.3013 31.8555 67.0251 35.0895 67.8286 38.3066C64.257 40.7474 63.6569 43.6936 65.2619 47.015C66.6228 49.1063 66.6083 51.1419 65.1999 53.1212C63.3504 55.6447 61.9338 58.4585 61.0082 61.4471L60.9189 61.7501Z" fill="#2F2E41" />
          <path d="M390.522 240.587H107.839C105.325 240.587 102.915 239.588 101.137 237.81C99.3595 236.033 98.3608 233.622 98.3608 231.108C200.833 219.199 301.38 219.199 400 231.108C400 233.622 399.002 236.033 397.224 237.81C395.446 239.588 393.036 240.587 390.522 240.587Z" fill="#2F2E41" />
          <path d="M400 231.666L98.3608 231.108L133.32 172.286L133.487 172.007V42.3689C133.487 40.7954 133.797 39.2372 134.399 37.7835C135.001 36.3297 135.884 35.0088 136.996 33.8961C138.109 32.7834 139.43 31.9009 140.884 31.2988C142.337 30.6967 143.895 30.3868 145.469 30.387H351.219C352.793 30.3868 354.351 30.6967 355.805 31.2988C357.259 31.9009 358.579 32.7834 359.692 33.8961C360.805 35.0088 361.687 36.3297 362.289 37.7835C362.892 39.2372 363.201 40.7954 363.201 42.3689V172.899L400 231.666Z" fill="#3F3D56" />
          <path d="M145.195 38.7504C144.161 38.7515 143.169 39.1631 142.437 39.8948C141.705 40.6265 141.294 41.6185 141.292 42.6533V160.856C141.294 161.891 141.705 162.883 142.437 163.614C143.169 164.346 144.161 164.757 145.195 164.759H353.165C354.2 164.757 355.192 164.346 355.923 163.614C356.655 162.882 357.067 161.89 357.068 160.856V42.6533C357.067 41.6185 356.655 40.6265 355.923 39.8948C355.192 39.1632 354.2 38.7516 353.165 38.7504H145.195Z" fill="white" />
          <path d="M146.067 180.37C145.747 180.371 145.434 180.463 145.165 180.635C144.896 180.808 144.681 181.054 144.547 181.344L133.775 204.761C133.658 205.016 133.607 205.296 133.626 205.576C133.645 205.856 133.735 206.127 133.886 206.363C134.038 206.599 134.246 206.793 134.492 206.928C134.738 207.063 135.014 207.133 135.295 207.133H361.823C362.108 207.133 362.388 207.06 362.637 206.921C362.886 206.782 363.096 206.582 363.246 206.34C363.395 206.097 363.481 205.82 363.494 205.535C363.506 205.251 363.446 204.967 363.318 204.712L351.61 181.295C351.472 181.016 351.258 180.782 350.994 180.619C350.73 180.455 350.425 180.369 350.114 180.37H146.067Z" fill="#2F2E41" />
          <path d="M248.344 36.7989C249.73 36.7989 250.853 35.6756 250.853 34.2899C250.853 32.9042 249.73 31.7809 248.344 31.7809C246.958 31.7809 245.835 32.9042 245.835 34.2899C245.835 35.6756 246.958 36.7989 248.344 36.7989Z" fill="white" />
          <path d="M226.078 211.594C225.711 211.594 225.353 211.715 225.061 211.939C224.769 212.163 224.559 212.476 224.463 212.831L221.761 222.867C221.694 223.115 221.686 223.375 221.735 223.627C221.785 223.879 221.892 224.116 222.049 224.32C222.205 224.524 222.406 224.689 222.636 224.802C222.866 224.916 223.119 224.975 223.376 224.975H274.763C275.029 224.975 275.292 224.911 275.529 224.789C275.766 224.667 275.971 224.49 276.125 224.272C276.28 224.055 276.38 223.804 276.418 223.54C276.456 223.276 276.43 223.007 276.343 222.755L272.869 212.719C272.755 212.39 272.542 212.106 272.259 211.904C271.975 211.702 271.636 211.594 271.288 211.594H226.078Z" fill="#2F2E41" />
          <path d="M363.201 170.055V172.285H133.319L133.492 172.007V170.055H363.201Z" fill="#2F2E41" />
          <path d="M356.789 78.0582C378.345 78.0582 395.818 60.5843 395.818 39.0291C395.818 17.4739 378.345 0 356.789 0C335.234 0 317.76 17.4739 317.76 39.0291C317.76 60.5843 335.234 78.0582 356.789 78.0582Z" fill="#6C63FF" />
          <path d="M372.958 56.0347H340.62C340.029 56.0341 339.462 55.7989 339.044 55.3808C338.625 54.9627 338.39 54.3957 338.39 53.8044V34.2899C338.39 33.6986 338.625 33.1317 339.044 32.7135C339.462 32.2954 340.029 32.0603 340.62 32.0596H372.958C373.55 32.0603 374.117 32.2954 374.535 32.7135C374.953 33.1317 375.188 33.6986 375.189 34.2899V53.8044C375.188 54.3957 374.953 54.9627 374.535 55.3808C374.117 55.7989 373.55 56.0341 372.958 56.0347ZM340.62 34.2899V53.8044H372.96L372.958 34.2899L340.62 34.2899Z" fill="white" />
          <path d="M366.825 34.2899H346.753V25.369C346.753 18.9075 350.974 14.2178 356.789 14.2178C362.604 14.2178 366.825 18.9075 366.825 25.369L366.825 34.2899ZM348.983 32.0597H364.595V25.369C364.595 20.1165 361.385 16.448 356.789 16.448C352.193 16.448 348.983 20.1165 348.983 25.369L348.983 32.0597Z" fill="white" />
          <path d="M356.789 45.7198C358.021 45.7198 359.02 44.7213 359.02 43.4896C359.02 42.2578 358.021 41.2593 356.789 41.2593C355.558 41.2593 354.559 42.2578 354.559 43.4896C354.559 44.7213 355.558 45.7198 356.789 45.7198Z" fill="white" />
          <path d="M329.544 124.666H168.817C168.028 124.665 167.272 124.351 166.714 123.793C166.156 123.235 165.842 122.478 165.841 121.689V81.8198C165.842 81.0307 166.156 80.2742 166.714 79.7162C167.272 79.1582 168.028 78.8443 168.817 78.8434H329.544C330.333 78.8443 331.089 79.1582 331.647 79.7162C332.205 80.2742 332.519 81.0307 332.52 81.8198V121.689C332.519 122.478 332.205 123.235 331.647 123.793C331.089 124.351 330.333 124.665 329.544 124.666ZM168.817 80.034C168.344 80.0345 167.89 80.2228 167.555 80.5576C167.22 80.8924 167.032 81.3463 167.031 81.8198V121.689C167.032 122.163 167.22 122.617 167.555 122.951C167.89 123.286 168.344 123.474 168.817 123.475H329.544C330.017 123.474 330.471 123.286 330.806 122.951C331.141 122.617 331.329 122.163 331.329 121.689V81.8198C331.329 81.3463 331.141 80.8924 330.806 80.5576C330.471 80.2228 330.017 80.0345 329.544 80.0339L168.817 80.034Z" fill="#E6E6E6" />
          <path d="M193.791 112.349C200.487 112.349 205.915 106.921 205.915 100.225C205.915 93.5286 200.487 88.1004 193.791 88.1004C187.095 88.1004 181.667 93.5286 181.667 100.225C181.667 106.921 187.095 112.349 193.791 112.349Z" fill="#E6E6E6" />
          <path d="M221.792 92.1418C221.526 92.1414 221.263 92.1934 221.018 92.2947C220.772 92.3961 220.549 92.5449 220.361 92.7326C220.173 92.9203 220.024 93.1432 219.922 93.3885C219.82 93.6339 219.768 93.8969 219.768 94.1626C219.768 94.4282 219.82 94.6912 219.922 94.9366C220.024 95.182 220.173 95.4049 220.361 95.5926C220.549 95.7802 220.772 95.929 221.018 96.0304C221.263 96.1317 221.526 96.1837 221.792 96.1833H317.054C317.59 96.1833 318.104 95.9704 318.483 95.5914C318.862 95.2125 319.075 94.6985 319.075 94.1626C319.075 93.6266 318.862 93.1127 318.483 92.7337C318.104 92.3547 317.59 92.1418 317.054 92.1418H221.792Z" fill="#E6E6E6" />
          <path d="M221.792 104.266C221.256 104.266 220.742 104.479 220.363 104.858C219.984 105.237 219.771 105.751 219.771 106.287C219.771 106.823 219.984 107.337 220.363 107.716C220.742 108.095 221.256 108.308 221.792 108.308H262.784C263.32 108.308 263.834 108.095 264.213 107.716C264.592 107.337 264.805 106.823 264.805 106.287C264.805 105.751 264.592 105.237 264.213 104.858C263.834 104.479 263.32 104.266 262.784 104.266H221.792Z" fill="#E6E6E6" />
          <path d="M132.924 105.084L103.213 120.88L102.836 107.716C112.534 106.097 121.76 103.279 130.291 98.69L133.399 91.0128C133.737 90.1762 134.288 89.4421 134.996 88.8825C135.704 88.3229 136.545 87.957 137.437 87.8207C138.329 87.6845 139.242 87.7826 140.084 88.1054C140.927 88.4282 141.672 88.9647 142.244 89.662C143.083 90.6824 143.501 91.9831 143.416 93.3008C143.331 94.6184 142.749 95.8545 141.786 96.7586L132.924 105.084Z" fill="#FFB8B8" />
          <path d="M60.3267 196.284C60.11 195.489 60.0639 194.657 60.1917 193.843C60.3194 193.029 60.618 192.251 61.0678 191.561L67.5946 181.544C69.1383 179.175 71.5071 177.464 74.2412 176.743C76.9754 176.021 79.88 176.341 82.3916 177.641C79.6486 182.31 80.031 186.406 83.3389 189.965C78.2037 192.472 73.4576 195.708 69.2476 199.572C68.479 200.096 67.5963 200.429 66.6731 200.542C65.7499 200.656 64.8129 200.548 63.9401 200.226C63.0674 199.904 62.2841 199.379 61.6555 198.693C61.027 198.007 60.5714 197.181 60.3267 196.284Z" fill="#2F2E41" />
          <path d="M134.277 183.187C133.29 184.833 131.895 186.198 130.229 187.15C128.562 188.102 126.678 188.61 124.759 188.624L81.399 188.953L79.5186 178.046L98.6994 172.405L82.5273 159.993L100.204 139.308L132.31 169.232C134.186 170.981 135.405 173.321 135.764 175.861C136.122 178.401 135.597 180.988 134.277 183.187Z" fill="#2F2E41" />
          <path d="M74.6293 257.778H64.4747C55.3574 195.282 46.0769 132.612 73.501 109.221L105.845 114.486L101.708 141.941L83.6556 162.626L74.6293 257.778Z" fill="#2F2E41" />
          <path d="M83.5558 271.935C82.7608 272.152 81.929 272.198 81.115 272.07C80.301 271.943 79.5232 271.644 78.8329 271.194L67.8071 269.712C65.4379 268.169 63.7265 265.8 63.005 263.066C62.2835 260.332 62.6033 257.427 63.9024 254.916C68.5717 257.658 72.6677 257.276 76.227 253.968C78.742 259.046 82.8858 258.736 86.8432 263.014C87.3672 263.783 87.7 264.665 87.8138 265.589C87.9276 266.512 87.8192 267.449 87.4976 268.322C87.176 269.194 86.6504 269.978 85.9648 270.606C85.2791 271.235 84.4532 271.691 83.5558 271.935Z" fill="#2F2E41" />
          <path d="M98.6993 67.4741L79.8945 62.9609C83.0177 56.568 83.2749 49.4546 81.775 41.8996L94.5622 41.5235C94.9629 50.9184 96.1751 59.6956 98.6993 67.4741Z" fill="#FFB8B8" />
          <path d="M103.868 125.81C90.1784 135.138 80.506 126.129 73.1249 112.229C74.1506 103.724 72.4898 93.5456 69.4141 82.486C68.0462 77.6079 68.5569 72.3942 70.8453 67.8743C73.1338 63.3544 77.0337 59.8566 81.7751 58.0717L97.9472 64.8414C111.679 76.0346 114.382 88.159 109.23 100.947L103.868 125.81Z" fill="#6C63FF" />
          <path d="M64.475 76.8764L49.4312 84.7744L76.1339 100.57L79.8494 109.735C80.0974 110.347 80.2182 111.003 80.2045 111.663C80.1908 112.323 80.0428 112.973 79.7695 113.574C79.4963 114.175 79.1035 114.714 78.6152 115.158C78.1269 115.602 77.5533 115.942 76.9293 116.157C76.2199 116.402 75.4636 116.479 74.7195 116.382C73.9754 116.286 73.2638 116.018 72.6403 115.601C72.0168 115.183 71.4984 114.627 71.1257 113.976C70.7531 113.325 70.5362 112.596 70.4921 111.847L70.1164 105.46L36.1775 94.2598C35.034 93.8825 33.9914 93.2498 33.1286 92.4099C32.2658 91.5699 31.6055 90.5446 31.1976 89.4117C30.6031 87.7602 30.5728 85.9583 31.1116 84.2878C31.6503 82.6173 32.7276 81.1725 34.175 80.1796L61.4662 61.4565L64.475 76.8764Z" fill="#FFB8B8" />
          <path d="M79.5186 78.3808C73.2444 75.6312 67.5391 78.6181 60.3378 81.0135L59.2095 61.0805C66.3601 57.2693 73.1777 56.2417 79.5186 59.2L79.5186 78.3808Z" fill="#6C63FF" />
          <path d="M87.0242 51.397C93.655 51.397 99.0303 46.0217 99.0303 39.3909C99.0303 32.7601 93.655 27.3848 87.0242 27.3848C80.3934 27.3848 75.0181 32.7601 75.0181 39.3909C75.0181 46.0217 80.3934 51.397 87.0242 51.397Z" fill="#FFB8B8" />
          <path d="M101.387 37.8761C89.5001 39.0403 80.4423 37.0962 74.6533 31.5608V27.1029H100.378L101.387 37.8761Z" fill="#2F2E41" />
        </g>
        <defs>
          <clipPath id="clip0_501_2">
            <rect width="400" height="278.301" fill="white" />
          </clipPath>
        </defs>
      </svg>

      <h2 className='mt-5 text-center font-weight-bold'>Access denied.</h2>
    </div>
  )
}

export default UnAuthorized
