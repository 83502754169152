import BaseDomainService from './baseDomainServices';
import { SuccessMessage } from "../../utils/Messages";
import { getValidationRuleList } from "../../utils/validation/generateValidationRule";
import { DataFormat } from '../../utils/DateFormat';

const operationalFormField = {
    RequestName: "",
    moduleId: "",
    CreatedBy: 1,
}

const autoSyncFields = {
    isCleaner: false,
    isDBUpdates: false,
    isScrapping: false,
    hostServerId: ""
}

let onInitAddSyncDetails = {
    //  active: true
    isCleaner: false,
    isDBUpdates: false,
    isScrapping: false,
    hostServerId: ""
}

export default class linkBuilderServices extends BaseDomainService {

    add = this.apiService.linkBuilderServices.addLinkBuilder;
    addSync = this.apiService.rpaRequestGroupServices.addMiddlewareGroupRequest;
    getModuleTypeForLinkBuilder = this.apiService.linkBuilderServices.getModuleTypeForLinkBuilder;
    getLinkBuilderList = this.apiService.linkBuilderServices.getLinkBuilderList;
    getLinkBuilderById = this.apiService.linkBuilderServices.getLinkBuilderById;
    getLinkBuilderOutputFileById = this.apiService.linkBuilderServices.getLinkBuilderOutputFileById;
    getLogsByProductDataId = this.apiService.linkBuilderServices.getLogsById;
    getAllServerList = this.apiService.serverConfigurationsServices.getAllServerListing;
    getModuleList = this.apiService.manageOperationalServices.getAllOperationalModuleList;

    // define the form fields
    formFields = [
        {
            inputType: "text",
            tittle: "Request Name",
            placeholder: "Request Name",
            fieldName: "RequestName",
            className: 'col-md-6',
            maxLength: 200,
            validation: [{ type: "require" }]
        },
        {
            inputType: "select",
            tittle: "Module Name",
            placeholder: "Module Name",
            fieldName: "moduleId",
            options: [],
            optionFieldName: "moduleName",
            subRequest: "1",
            className: 'col-md-6',
            validation: [{ type: "require" }]
        },
    ]
    dataItem;
    dataItems;

    gridColmns = [
        { name: 'RequestName', title: 'Request Name' },
        { name: 'moduleName', title: 'Module Name' },
        { name: 'statusName', title: 'Status' },
        { name: 'linkBuilderId', title: 'Action' }
    ];

    productDataDetailForm = [
        {
            inputType: "label",
            tittle: "Request Name",
            fieldName: "requestName",
            className: 'col-md-4',
        },
        {
            inputType: "label",
            tittle: "Link Builder Id",
            fieldName: "linkBuilderId",
            className: 'col-md-4',
        },
        {
            inputType: "label",
            tittle: "Module Name",
            fieldName: "moduleName",
            className: 'col-md-4',
        },

        // {
        //     inputType: "label",
        //     tittle: "Association Counts",
        //     fieldName: "associationCounts",
        //     className: 'col-md-4',
        // },
        {
            inputType: "label",
            tittle: "Status",
            fieldName: "statusName",
            className: 'col-md-4',
        },
        {
            inputType: "label",
            tittle: "Scrapped Counts",
            fieldName: "scrappedCounts",
            className: 'col-md-4',
        },
        {
            inputType: "label",
            tittle: "Error Count",
            fieldName: "errorCounts",
            className: 'col-md-4',
        },
        {
            inputType: "label",
            tittle: "Error Message",
            fieldName: "errorMessage",
            className: 'col-md-4',
        },
        {
            inputType: "label",
            tittle: "Created Date",
            fieldName: "createdDate",
            className: 'col-md-4',
        },
        // {
        //     inputType: "label",
        //     tittle: "Status Id",
        //     fieldName: "statusId",
        //     className: 'col-md-4',
        // },
    ]

    autoSyncFields = [
        {
            inputType: "checkbox",
            tittle: "Has Scrapping Configure?",
            fieldName: "isScrapping",
            checked: false,
            partialrequest: true,
            className: 'col-md-6'
        },
        {
            inputType: "checkbox",
            tittle: " Has Cleaner Configure?",
            fieldName: "isCleaner",
            checked: false,
            partialrequest: true,
            className: 'col-md-6'
        },
        {
            inputType: "checkbox",
            tittle: " Has DBUpdates Configure?",
            fieldName: "isDBUpdates",
            checked: false,
            partialrequest: true,
            className: 'col-md-6 mt-2'
        },
        {
            inputType: "select",
            tittle: "Server Name",
            placeholder: "Select Server Name",
            fieldName: "hostServerId",
            options: [],
            optionFieldName: "hostServerId",
            className: 'col-md-11',
            subRequest: "1",
            validation: [{ type: "require" }]
        },
    ];

    constructor() {
        super();
        this.actionKey = "linkBuilderId";
        this.dataItem = { ...operationalFormField };
        this.dataItems = { ...autoSyncFields };
        this.onPaginationUpdate = this.onGetLinkBuilderList.bind(this);
    }

    onAdd = (request) => {
        this.apiService.onAPICall("addLinkBuilder", this.add, request, this.onAPIResponse, this.onUpdateState);
    }

    onAddSync = (request) => {
        this.apiService.onAPICall("addMiddlewareGroupRequest", this.addSync, request, this.onAPIResponse, this.onUpdateState);
    }

    onGetModuleTypeForLinkBuilder = (requst) => {
        this.apiService.onAPICall("getModuleTypeForLinkBuilder", this.getModuleTypeForLinkBuilder, requst, this.onAPIResponse, this.onUpdateState);
    }

    onGetLinkBuilderList = (requst) => {
        this.apiService.onAPICall("getLinkBuilderList", this.getLinkBuilderList, requst, this.onAPIResponse, this.onUpdateState);
    }

    onGetById = (requst) => {
        this.apiService.onAPICall("getLinkBuilderById", this.getLinkBuilderById, requst, this.onAPIResponse, this.onUpdateState);
    }

    onGetLinkBuilderOutputFileById = (request) => {
        this.apiService.onAPICall("getLinkBuilderOutputFileById", this.getLinkBuilderOutputFileById, request, this.onAPIResponse, this.onUpdateState);
    }

    onGetLogsByProductDataId = (request) => {
        this.apiService.onAPICall("getLogsByProductDataId", this.getLogsByProductDataId, request, this.onAPIResponse, this.onUpdateState);
    }

    onGetAllServerList = (requst) => {
        this.apiService.onAPICall("getAllServerList", this.getAllServerList, requst, this.onAPIResponse, this.onUpdateState);
    }

    onGetModuleList = (requst) => {
        this.apiService.onAPICall(
            "getModuleList",
            this.getModuleList,
            requst,
            this.onAPIResponse, this.onUpdateState
        );
    };

    onInitService() {
        //This method only call when we use form component and we can add all items which we on on form load 
        let valRule = getValidationRuleList(this.formFields)
        this.validationRules = valRule;
        this.onUpdateState({
            "validationRules": valRule,
            "resetValidationRule": this.resetValidationRule
        })
    }

    onResetAutoSyncData = () => {
        this.dataItem = { ...onInitAddSyncDetails }
        let resetValidation = {
            isValid: false,
            error: {}
        }
        this.onUpdateState({
            autoSyncFields: this.dataItem,
            show: false,
            validState: resetValidation
        });
    }

    onRedirectoList = () => {
        this.onRedirect("/ManageLinkBuilder");
    }

    onAPIResponse = (key, isSuccess, responseData) => {
        if (!isSuccess) {
            this.onUpdateState({ "setIsLoad": false });
            return;
        }
        switch (key) {
            case "addLinkBuilder":
                let data = responseData;
                if (data.errorType === 'EXIST') {
                    this.apiService.serviceManager.swalServices.Error(SuccessMessage.Already_Exist.replace("{0}", data.errorColumn))
                } else {
                    this.apiService.serviceManager.swalServices.Success(SuccessMessage.Add_Success.replace("{0}", "Link Builder"));
                    this.onRedirectoList();
                }
                this.onUpdateState({ "setIsLoad": false });
                break;
            case "getModuleTypeForLinkBuilder":
                let moduleList = responseData;
                let moduleData = moduleList?.map((data) => ({
                    id: data.moduleId,
                    name: data.moduleName,
                }));
                this.onUpdateState({
                    setOperationalList: moduleData

                });
                break;
            case "getLinkBuilderList":
                let itemList = responseData.itemList;

                if (responseData !== null) {
                    this.onUpdateState({
                        "getList": itemList,
                        "setIsLoad": false,
                        "TotalCount": responseData.totalCount,
                    });
                }
                break;
            case "getLinkBuilderById":
                if (responseData != null) {
                    responseData.name = responseData.name ? responseData.name : "NA"
                    responseData.moduleId = responseData.moduleId ? responseData.moduleId : "NA"
                    // responseData.totalCounts = responseData.totalCounts ? responseData.totalCounts : "0"
                    // responseData.associationCounts = responseData.associationCounts ? responseData.associationCounts : "0"
                    responseData.statusName = responseData.statusName ? responseData.statusName : "NA"
                    // responseData.statusId = responseData.statusId ? responseData.statusId : "NA"
                    responseData.scrappedCounts = responseData.scrappedCounts ? responseData.scrappedCounts : "0"
                    responseData.linkBuilderId = responseData.linkBuilderId ? responseData.linkBuilderId : "0"
                    responseData.errorMessage = responseData.errorMessage ? responseData.errorMessage : "NA"
                    responseData.errorCounts = responseData.errorCounts ? responseData.errorCounts : "0"
                    responseData.createdDate = responseData.createdDate ? DataFormat(
                        responseData.createdDate
                      ) : "NA"
                      responseData.middlewareRequestGroupId=responseData.middlewareRequestGroupId ? responseData.middlewareRequestGroup: 0
                      responseData.linkBuilderId=responseData.linkBuilderId ? responseData.linkBuilderId:0
                    this.onUpdateState({
                        productDataDetail: responseData
                    })
                }
                this.onUpdateState({
                    "setIsLoad": false
                });
                break;
            case "getLogsByProductDataId":
                if (responseData !== null) {
                    this.onUpdateState({
                        logsList: responseData
                    })
                }
                this.onUpdateState({
                    "setIsLoad": false,
                });
                break;
            case "getLinkBuilderOutputFileById":
                if (responseData.length > 0) {
                    this.onUpdateState({
                        outputFileList: responseData
                    })
                }
                this.onUpdateState({
                    "setIsLoad": false,
                });
                break;
            case "getAllServerList":
                if (responseData !== null) {
                    let serverList = responseData.itemList;
                    let serverData = serverList?.map((data) => ({
                        id: data.hostServerId,
                        name: `${data.serverName}  -  ${data.serverIp}`,
                        serverIp: data.serverIp,
                    }));
                    this.onUpdateState({
                        "serverOptionList": serverData,
                        "setIsLoad": false
                    });
                }
                break;
            case "getModuleList":
                let moduleListt = responseData;
                let moduleDataUpdate = moduleListt?.map((data) => ({
                    moduleId: data.moduleId,
                    isActive: data.isActive,
                    isTor: data.isTor,
                    isCatalogCleaner: data.isCatalogCleaner,
                    isDBUpdates: data.isDBUpdates,
                    isScrapping: data.isScrapping,
                    supplierID: data.supplierID,
                    isCleaner: data.isCleaner,
                }));
                this.onUpdateState({
                    moduleLists: moduleDataUpdate,
                });
                break;
            case "addMiddlewareGroupRequest":
                let dataSync = responseData;
                if (dataSync.errorType === 'ProcessRunning') {
                    this.apiService.serviceManager.swalServices.Error(SuccessMessage.Already_Exist.replace("{0}", dataSync.errorMessage))
                    this.onResetAutoSyncData()
                } else {
                    this.apiService.serviceManager.swalServices.Success(SuccessMessage.Add_Success.replace("{0}", "Link Builder"));
                    this.onRedirectoList();
                    this.onResetAutoSyncData()
                }
                this.onUpdateState({ "setIsLoad": false });
                break;
                default:
                    // Handle default case here
                    break;
        }
    }

}
