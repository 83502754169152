//*** Lib */
import { axiosPost, axiosGet } from '../AxiosRequests';
import { AddModuleType, GetAllModuleType,GetLatestRequestById, UpdateModuleType, GetOperationalModuleDetailsById, GetAllScriptList, DeleteModuleType } from '../ApiEndPoints';

export default class OperationalModuleTypeServices {
    // API function

    async addModuleType(request) {
        return axiosPost(AddModuleType, request);
    }
    async getOperationalModuleTypeList(request) {
        return axiosGet(GetAllModuleType, request);
    }
    async getLatestRequestById(request) {
        return axiosGet(GetLatestRequestById, request);
    }
    async updateOperationalModuleType(request) {
        return axiosPost(UpdateModuleType, request);
    }
    async getOperationalModuleDetailsById(request) {
        return axiosGet(GetOperationalModuleDetailsById, request);
    }

    async getAllScriptList(request) {
        return axiosGet(GetAllScriptList, request);
    }

    async deleteOperationalModuleType(request) {
        return axiosPost(DeleteModuleType, request);
    }

}