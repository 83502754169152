//** Component  */
import React from 'react'

const DashboardProgressBarStyle = () => {
  return (
    <style jsx global>
      {
        `           
        {
          /* .coloricons{
               color:white 
          }
           */
      }
       // .progress-bg{
           // background-color:#ffffffed;
           // 
      }
       #progress {
           position: relative;
           {/* margin-bottom: 30px; */}
      }
       #progress-bar {
           position: absolute;
           background: var(--primaryBgColor);
           height: 5px;
           width: 0%;
           top: 50%;
           left: 0;
      }
       #progress-check {
           margin: 0;
           padding: 0;
           list-style: none;
           display: flex;
           justify-content: center;
      }
       #progress-check .step {
           position: relative;
           width: 25%;
      }
       #progress-check .step::after {
           content: "";
           border-top: 2px solid #232446;
           width: 100%;
           transform: rotate(0deg);
           position: absolute;
           left: 98%;
           top: 30px;
           transform: translatex(-50%);
           z-index: 0;
      }
      #progress-check .step:last-child::after {
        display: none;
      }
       .main-timeline-sec{
           cursor:pointer;
           z-index: 1;
           position: relative;
           width: 100%;
           display: flex;
           flex-direction: column;
           justify-content: center;
           align-items: center;
           font-size: 18px;
           font-weight: bold;
           transition: 0.5s all;
           -webkit-transition: 0.5s all;
      }
       #progress-check .step label{
           font-weight:bold;
           font-size: 20px;
           color: #232446;
           transition:0.3s all;
      }
       #progress-check .step .icon-round{
           background-color: #fff;
           display: flex;
           align-items: center;
           justify-content: center;
           height: 60px;
           width: 60px;
           border-radius: 100%;
           border: 2px solid ;
           position: relative;
      }
      #progress-check .step.active.red-color{
           border-color: #c00000;
           color:#c00000;
      }
      #progress-check .step.active.red-color label{
        color:#c00000;
      }
      
      #progress-check .step.active.green-color {
           border-color: #3a8b32;
           color:#3a8b32;
           position:relative;
      }
      #progress-check .step.active.green-color label{
        color:#3a8b32;
      }
      #progress-check .step.active.yellow-color {
           border-color: #ffc300;
           color:#ffc300;
           position:relative;
      }
      #progress-check .step.active.yellow-color label{
        color:#ffc300;
      }
      #progress-check .step.active:first-child::before{
        display:none;
      }
      #progress-check .step.active::before{
        content: "";
        border-top: 2px solid;
        width: 100%;
        transform: rotate(0deg);
        position: absolute;
        left: 0%;
        top: 30px;
        transform: translatex(-50%);
        z-index: 0;
      }
       
       .color{
           border-color: green;
           background-color: green;
      }
       .btn {
           border: none;
           border-radius: 3px;
           padding: 6px 12px;
      }
       #progress label {
           display: flex;
           justify-content: center;
           line-height: 1.5rem;
           padding-top: 10px;
           font-size: 1rem;
           font-weight: 500;
      }
       .card.main-top-progress{
           padding: 3rem 3rem !important;
           border:none;
      }
      
                 
                

                    
                `
      }

    </style>
  )
}

export default DashboardProgressBarStyle