import { Navigate } from "react-router-dom";
import Table from "../../components/table/Table";
import BaseComponent from "../../shared/baseComponent";
import DatabaseAnalyzerServices from "../../services/domainServices/databaseAnalyzerServices";
import { encryptionData } from '../../../src/utils/Encryption';

export class DataAnalyzerList extends BaseComponent {


    constructor(props) {
        super(props);
        this.analyzerServices = new DatabaseAnalyzerServices();
        this.state = {
            pagination: {
                ...this.analyzerServices.pagination
            },
            analyzerDataList: [],
            setIsLoad: false
        };
        this.analyzerServices.onUpdateState = this.updateState.bind(this);
        this.analyzerServices.onActionCall = this.onActionCall.bind(this);
    }

    componentDidMount() {
        this.analyzerServices.onGetAnalyzerData(this.state.pagination);
    }

    // *** Other function **//

    onActionCall = (type, data) => {

        // *** getByID encryption ***//
        const encryptKey = encryptionData(data, 1);

        switch (type) {
            case "Edit":
                window.open(`/DatabaseAnalyzerDetails/id?=${encryptKey}`, '_blank');
                break;
            default:
                // Handle default case here
                break;
        }
    };
    render() {
        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} />;
        }
        return (
            <>
                <div className="data-analyzer-list">
                    <Table
                        columns={this.analyzerServices.gridColmns}
                        rows={this.state.analyzerDataList}
                        sortingColumns={[]}
                        pagination={this.state.pagination}
                        totalCount={this.state.TotalCount}
                        totalResultes={this.state.TotalCount}
                        setPagination={this.analyzerServices.onPageChange.bind(this)}
                        customScope={[
                            {
                                column: "dataAnalyzerId",
                                renderTableData:
                                    this.analyzerServices.gridActionsCol.bind(this),
                            },
                        ]} />
                </div>
            </>
        )
    }
}
export default DataAnalyzerList