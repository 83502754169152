//*** Lib */
import { axiosPostAuthorize,axiosPost , axiosGet } from '../AxiosRequests';
import { AddDataAnalyzer, GetAllDataAnalyzer ,GetDataAnalyzerById} from '../ApiEndPoints';

export default class DatabaseAnalyzerServices {

    // API function
    async addDataAnalyzer(request) {
        return axiosPostAuthorize(AddDataAnalyzer, request);
    }

    async getAllDataAnalyzer(request) {
        return axiosPost(GetAllDataAnalyzer, request);
    }
    async getDataAnalyzerById(request) {
        return axiosGet(GetDataAnalyzerById, request)
    }
}

