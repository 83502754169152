import { DataFormat } from "../../utils/DateFormat";
import { getValidationRuleList } from "../../utils/validation/generateValidationRule";
import BaseDomainService from "./baseDomainServices";

export default class RequestGroupDomainServices extends BaseDomainService {
    // API Get
    getList = this.apiService.rpaRequestGroupServices.getMiddlewareGroupRequestList;
    getMiddlewareGroupRequestById = this.apiService.rpaRequestGroupServices.getMiddlewareGroupRequestById;
    getLogsByProductDataId = this.apiService.rpaRequestGroupServices.getLogsById;

    //Table Grid columns
    gridColmns = [
        { name: "requestGroupName", title: "Request Name" },
        { name: "moduleName", title: "Module Name" },
        { name: "supplierName", title: "Supplier Name" },
        { name: "createdDate", title: "Created Date" },
        { name: "requestGroupStatusName", title: "Status" },
        { name: "middlewareRequestGroupId", title: "Actions" },
    ];

    // Define the state variables
    dataItem;
    validationRules = {};
    validationRulesForType = {};

    constructor() {
        super();
        this.onPaginationUpdate = this.onGetRequests.bind(this);
        this.actionKey = "middlewareRequestGroupId";
    }

    //** API Calls

    onGetRequests = (requst) => {
        this.apiService.onAPICall(
            "getRequest",
            this.getList,
            requst,
            this.onAPIResponse, this.onUpdateState
        );
    };

    onGetById = (requst) => {
        this.apiService.onAPICall("getMiddlewareGroupRequestById", this.getMiddlewareGroupRequestById, requst, this.onAPIResponse, this.onUpdateState);
    }

    onGetLogsByProductDataId = (request) => {
        this.apiService.onAPICall("getLogsByProductDataId", this.getLogsByProductDataId, request, this.onAPIResponse, this.onUpdateState);
    }

    //** Service Event */

    onInitService() {
        //This method only call when we use form component and we can add all items which we on on form load

        let valRule = getValidationRuleList(this.formFields);
        this.validationRules = valRule;
        this.validationRulesForType = valRule

        this.onUpdateState({
            "validationRules": valRule,
            "resetValidationRule": this.resetValidationRule,
        });
    }

    onRedirectoList = () => {
        this.onRedirect('/RPARequestGroup');
    };

    onAPIResponse = (key, isSuccess, responseData) => {
        this.onUpdateState({ setIsLoad: false });
        if (!isSuccess) {
            this.onUpdateState({ setIsLoad: false });
            return;
        }
        switch (key) {
            case "getRequest":
                if (responseData.itemList !== null) {
                    let itemList = responseData.itemList;
                    itemList.forEach(function (i) {
                        i.createdDate = i.createdDate ? DataFormat(
                            i.createdDate
                        ) : "NA"
                        i.requestType =
                            i.requestType === 1 ? "LinkUpdate" :
                                i.requestType === 2 ? "CatalogUpdate" :
                                    i.requestType === 3 ? "PartialUpdate" : null
                        i.createdDate = i.createdDate ? DataFormat(
                            i.createdDate
                        ) : "NA"
                    });
                }
                this.onUpdateState({
                    dataList: responseData.itemList,
                    TotalCount: responseData.totalCount,
                    setIsLoad: false,
                });
                break;
            case "getLogsByProductDataId":
                if (responseData !== null) {
                    const errorData = responseData.filter(x => x.logType === "ERROR").map(x => x.logs)
                    this.onUpdateState({
                        logsList: errorData,
                    });
                }
                this.onUpdateState({
                    "setIsLoad": false,
                });
                break;
                // if (responseData !== null) {
                //     const errorData = responseData
                //         .filter(log => log.logType === "ERROR")
                //         .map(log => ({ logId: log.logId, log: log.logs }));
                //     this.onUpdateState({
                //         logsList: errorData.map(log => log.log), // Only update state with error logs
                //         logIds: errorData.map(log => log.logId), // Separate array for log IDs
                //         setIsLoad: false,
                //     });
                //     console.log("Error logs:", errorData);
                // }
            case "getMiddlewareGroupRequestById":
                let data = responseData
                // const scrapperMiddlewareRequestIds = responseData.map(request => request.middlewareRequestList.filter(request => request.isScrapping && request.middlewareRequestGroupId));
                const filteredScrapper = data.middlewareRequestList.filter(request => request.isScrapping);
                const filteredCleanner = data.middlewareRequestList.filter(request => request.isCleaner || request.isDBUpdates);

                let scrappedDoneCountSum = filteredScrapper.reduce((sum, obj) => sum + obj.scrappedDoneCount, 0);
                let scrappedErrorCountSum = filteredScrapper.reduce((sum, obj) => sum + obj.scrappedErrorCount, 0);

                this.onUpdateState({
                    dataList: responseData,
                    scrapperData: filteredScrapper,
                    cleannerDataUpload: filteredCleanner,
                    scarpCount: scrappedDoneCountSum,
                    errorCount: scrappedErrorCountSum,
                });
                break;
            default:
                // Handle default case here
                break;
        }
    };
}
