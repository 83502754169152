
import BaseDomainService from "./baseDomainServices";
import { SuccessMessage } from "../../utils/Messages";
import { getValidationRuleList } from "../../utils/validation/generateValidationRule";
import { DataFormat } from "../../utils/DateFormat";

const formDetail = {
    linkupRequestName: "",
    linkupFileName: "",
    regulationName: "",
    regulationId: "",
    statusName: "",
    linkupCount: "",
    error: "",
    createdDate: "",
    createdBy: 1,
}

export default class LinkupDomainService extends BaseDomainService {

    // API Get
    add = this.apiService.linkupServices.addLinkupRequest;
    getList = this.apiService.linkupServices.getAllLinkupRequest;
    getById = this.apiService.linkupServices.getLinkupRequestById;
    getLogsByDataId = this.apiService.productDataSyncServices.getLogsById;
    getOutputFileById = this.apiService.linkupServices.getLinkupRequestOutputFileById;
    getRegulationNameList = this.apiService.regulationService.getAllRegulationNameList;

    //Table Grid columns
    gridColmns = [
        { name: "linkupRequestName", title: "Linkup Request Name" },
        { name: "statusName", title: "Status" },
        { name: "createdDate", title: "Created Date" },
        { name: "linkupRequestId", title: "Actions" },
    ];

    // Define the state variables
    dataItem;

    // define the form fields
    formFields = [
        {
            inputType: "text",
            tittle: "Linkup Request Name",
            placeholder: "Linkup Request Name",
            fieldName: "linkupRequestName",
            className: "col-md-6",
            validation: [{ type: "require" }]
        },
        {
            inputType: "select",
            tittle: "Regulation Name",
            placeholder: "Select Regulation Name",
            fieldName: "regulationId",
            options: [],
            optionFieldName: "regulationId",
            className: 'col-md-6',
            validation: [{ type: "require" }]
        },
    ];

    constructor() {
        super();
        this.onPaginationUpdate = this.onGetList.bind(this);
        this.actionKey = "linkupRequestId";
        this.dataItem = { ...formDetail };
    }

    //** API Calls
    onAdd = (request) => {
        this.apiService.onAPICall("add", this.add, request, this.onAPIResponse, this.onUpdateState)
    };
    onGetList = (request) => {
        this.apiService.onAPICall("getList", this.getList, request, this.onAPIResponse, this.onUpdateState)
    };
    onGetById = (request) => {
        this.apiService.onAPICall("getById", this.getById, request, this.onAPIResponse, this.onUpdateState)
    };
    onGetOutputFileById = (request) => {
        this.apiService.onAPICall("getOutputFileById", this.getOutputFileById, request, this.onAPIResponse, this.onUpdateState)
    };
    onGetLogsById = (request) => {
        this.apiService.onAPICall("getLogsByDataId", this.getLogsByDataId, request, this.onAPIResponse, this.onUpdateState);
    }
    onGetRegulationNameList = (request) => {
        this.apiService.onAPICall("getRegulationNameList", this.getRegulationNameList, request, this.onAPIResponse, this.onUpdateState)
    };

    //** Service Event */
    onInitService() {
        let valRule = getValidationRuleList(this.formFields);
        this.validationRules = valRule;

        this.onUpdateState({
            validationRules: valRule,
            resetValidationRule: this.resetValidationRule,
        });
    }

    onRedirectoList = () => {
        this.onRedirect('/ManageLinkupRequest');
    };

    //** Methods     */

    onAPIResponse = (key, isSuccess, responseData) => {
        if (!isSuccess) {
            this.onUpdateState({ setIsLoad: false });
            return;
        }
        switch (key) {

            case "add":
                // Need to check if user is exist or not.
                this.apiService.serviceManager.swalServices.Success(
                    SuccessMessage.Insert_Success.replace("{0}", "New Linkup Request")
                );
                this.onRedirectoList();
                this.onUpdateState({ setIsLoad: false });
                break;

            case "getList":
                if (responseData != null) {
                    let itemList = responseData.itemList;
                    itemList.forEach(function (i) {
                        i.createdDate = DataFormat(
                            i.createdDate
                        )
                    });
                    this.onUpdateState({
                        getList: responseData.itemList,
                        TotalCount: responseData.totalCount
                    });
                }
                this.onUpdateState({ setIsLoad: false });
                break;

            case "getById":
                if (responseData != null) {

                    this.onUpdateState({
                        detailData: responseData
                    });
                }
                this.onUpdateState({ setIsLoad: false });
                break;

            case "getLogsByDataId":
                if (responseData !== null) {
                    this.onUpdateState({
                        logsList: responseData
                    });
                }
                this.onUpdateState({
                    "setIsLoad": false,
                });
                break;

            case "getOutputFileById":
                if (responseData !== null) {
                    this.onUpdateState({
                        outPutFileData: responseData
                    });
                }
                this.onUpdateState({
                    "setIsLoad": false,
                });
                break;

            case "getRegulationNameList":
                if (responseData !== null) {
                    let getList = responseData;
                    let dataList = getList?.map((data) => ({
                        id: data.regulationID,
                        name: data.regulationName,
                    }));
                    this.onUpdateState({
                        "regulationNameList": dataList
                    });
                }
                this.onUpdateState({ setIsLoad: false });
                break;
                default:
                    // Handle default case here
                    break;
        }
    };
}
