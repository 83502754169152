import { Navigate } from "react-router-dom";
import { Bars } from "react-loader-spinner";
//** Lib's */
import Button from "../../components/common/Button";
import { ErrorMessage } from "../../utils/Messages";
import BaseComponent from "../../shared/baseComponent";
import FormFieldGroup from "../../components/UI Element/FormGrop/FormFieldGroup";
//** Service's */
import BingoProductImportDomainService from "../../services/domainServices/BingoProductImportDomainService";

export default class AddBingoProductImport extends BaseComponent {

    //** Constructor  and Component method */
    constructor(props) {
        super(props);
        this.domainServices = new BingoProductImportDomainService();
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            ...this.state,
            setIsLoad: false,
            appNameList: [],
            formField: this.domainServices.formDataItem
        }
        this.domainServices.onUpdateState = this.updateState.bind(this);
    }

    componentDidMount() {
        this.domainServices.onAppList();
        this.domainServices.onInitService();
    }

    //** Handle Changes */
    handleChange(name, value) {
        let detail = this.state.formField;
        detail[name] = value;
        this.setState({ formField: detail });
    }

    handleFileChange(event) {
        const { name } = event.target;
        const File = event.target.files[0];
        if (File && (File.type === "text/csv" || File.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
            if (File.size > 0) {
                let updatedState = { ...this.state.formField };
                if (name === 'intialFileName') {
                    updatedState = {
                        ...updatedState,
                        file: File,
                        intialFileName: File.name,
                        FileName: File.name,
                    };
                    this.setState({ formField: updatedState });
                }
            }
        } else {
            this.domainServices.apiService.serviceManager.swalServices.Toaster(ErrorMessage.InvalidFileTypeCSV);
        }
    }

    //** API's Call */
    add = () => {
        if (this.domainServices.isValidSubmit(this.state.formField, this.state.validationRules, this.state.validState)) {
            let request = {
                importTitle: this.state.formField.importTitle,
                appId: this.state.formField.appId,
                file: this.state.formField.file,
                intialFileName: this.state.formField.intialFileName,
                createdBy: this.domainServices.authUser.adminUserId,
            }
            this.domainServices.onAdd(request);
        }
    }

    render() {
        if (this.state.redirect != null) {
            return <Navigate to={this.state.redirect} />;
        }
        return (
            <div className="mb-4">
                <div className="accordion" id="accordionExample">
                    <div className="card accordion-item active">
                        <div id="accordionOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <div className="card-body">
                                    {this.state.setIsLoad ?
                                        <Bars
                                            className="row"
                                            wrapperStyle={{ marginTop: "150px" }}
                                            color="#3c4b64"
                                            height={55}
                                            width="100%"
                                            visible={this.state.setIsLoad}
                                        /> :
                                        <div className="row mb-4">
                                            {this.domainServices.formFields.map((Item, index) => {
                                                return (
                                                    <>
                                                        <FormFieldGroup
                                                            column={Item}
                                                            value={this.state.formField[Item.fieldName]}
                                                            error={this.state.validState.error[Item.fieldName]}
                                                            options={Item.options ? this.state.appNameList : null}
                                                            onChange={Item.inputType === "file" ? (event) => this.handleFileChange(event)
                                                                : (event) => this.handleChange(Item.fieldName, event.target.value)}
                                                            key={index}
                                                            onBlur={() => this.domainServices.validateField(Item.fieldName, this.state.formField, this.state.validationRules, this.state.validState)}
                                                        />
                                                    </>
                                                );

                                            })}
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="addnewuser-button mb-4">
                                <button className="tab-title tab-title--active  btn-hover  color-3 active" onClick={() => this.add()}> Save </button>
                                <Button type='cancel' title="Cancel" className="ml-2" onClick={() => this.domainServices.onRedirectoList()} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}