
import { axiosGet, axiosGetMultiParams, axiosPost } from '../AxiosRequests';
//*** Lib */
import {
    AddLinkBuilder , GetLinkBuilderList , GetLinkBuilderById , GetLinkBuilderOutputFileById , GetLogsById , GetModuleTypeForLinkBuilder
} from '../ApiEndPoints';

export default class LinkBuilderServices {

    async addLinkBuilder(request) {
        return axiosPost(AddLinkBuilder, request);
    }

    async getLinkBuilderList(request) {
        return axiosPost(GetLinkBuilderList, request);
    }

    async getLinkBuilderById(request) {
        return axiosGet(GetLinkBuilderById, request);
    }

    async getLinkBuilderOutputFileById(request) {
        return axiosGet(GetLinkBuilderOutputFileById, request);
    }

    async getLogsById(request) {
        return axiosGetMultiParams(GetLogsById, request);
    }

    async getModuleTypeForLinkBuilder(request) {
        return axiosGet(GetModuleTypeForLinkBuilder, request);
    }
   
    // async addLinkBuilderAutoSync(request) {
    //     return axiosPost(AddLinkBuilderAutoSync, request);
    // }

}

