import React from "react";

const InputField = (request) => {
    let props = request.request;
    return (<>
        <input
            className="form-control text"
            type={props.column.inputType}
            onChange={props.onChange}
            onBlur={() => props.onBlur(props.column.fieldName)}
            id={props.column.fieldName}
            placeholder={props.column.placeholder}
            value={props.value}
            onKeyPress={props.onKeyPress}
            onKeyDown={props.onKeyDown}
            maxLength={props.column.maxLength}
            autocomplete="off"
            disabled={props.disabled}
            hidden={props.hidden}
            pattern={props.column.pattern} />

    </>)
}


export default InputField;
