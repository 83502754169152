
import { DataFormat } from "../../utils/DateFormat";
import { ErrorMessage, SuccessMessage } from "../../utils/Messages";
import { getValidationRuleList } from "../../utils/validation/generateValidationRule";
import BaseDomainService from "./baseDomainServices";

const analyzerDetail = {
  dataAnalyzerTypeId: 1,
  sourceDB: "",
  destinationDB: "",
  supplierID: "",
  dataAnalyzerType: "1",
};

export default class DatabaseAnalyzerServices extends BaseDomainService {
  // API Get
  add = this.apiService.databaseAnalyzerServices.addDataAnalyzer;
  supplierList = this.apiService.manageSupplierServices.getAllSupplierNameList;
  getAnalyzerData = this.apiService.databaseAnalyzerServices.getAllDataAnalyzer;
  getAnalyzerDataId =
    this.apiService.databaseAnalyzerServices.getDataAnalyzerById;

  //Table Grid columns
  gridColmns = [
    { name: "sourceDB", title: "Source DB" },
    { name: "destinationDB", title: "Destination DB" },
    { name: "supplierName", title: "Supplier Name" },
    { name: "startDate", title: "Start Date" },
    { name: "endDate", title: "End Date" },
    { name: "dataAnalyzerTypeId", title: "Data Analyzer Type" },
    { name: "statusId", title: "Status" },
    { name: "dataAnalyzerId", title: "Actions" },
  ];

  // Define the state variables
  dataItem;

  // define the form fields

  formFields = [
    {
      inputType: "select",
      tittle: "Database Analyzer Type",
      placeholder: "",
      fieldName: "dataAnalyzerType",
      isDefault: true,
      Options: [
        { name: "Full", id: "1" },
        { name: "Partial", id: "2" },
      ],
      className: "col-md-4",
    },
    {
      inputType: "select",
      tittle: "Source DB",
      placeholder: "Source DB",
      fieldName: "sourceDB",
      isDefault: true,
      subRequest: "1",
      Options: [
        { name: "AurumPharmatech2.0", value: "AurumPharmatech2.0" },
        { name: "1ClickChemistry2.0", value: "1ClickChemistry2.0" },
      ],
      Option: [
        { name: "AurumPharmatech2.0", value: "AurumPharmatech2.0" },
        { name: "1ClickChemistry2.0", value: "1ClickChemistry2.0" },
        { name: "EchemPortalFinal", value: "EchemPortalFinal" },
      ],
      dropDown: "1",
      className: "col-md-6",
      validation: [{ type: "require" }],
    },
    {
      inputType: "text",
      tittle: "Destination DB",
      placeholder: "Destination DB",
      fieldName: "destinationDB",
      subRequest: "1",
      className: "col-md-6",
    },
    {
      inputType: "select",
      tittle: "Supplier Name",
      placeholder: "Select",
      fieldName: "supplierID",
      options: [],
      className: "col-md-6",
      hidden: true,
      dropDown: "1",
    },
  ];

  constructor() {
    super();
    this.onPaginationUpdate = this.onGetAnalyzerData.bind(this);
    this.actionKey = "dataAnalyzerId";
    this.dataItem = { ...analyzerDetail };
  }

  analyzerDetailForm = [
    // {
    //   inputType: "label",
    //   tittle: "Source DB",
    //   fieldName: "sourceDb",
    //   className: "col-md-5",
    // },
    {
      inputType: "label",
      tittle: "Destination DB",
      fieldName: "destinationDb",
      className: "col-md-4",
    },
    {
      inputType: "label",
      tittle: "Status",
      fieldName: "statusId",
      className: "col-md-4",
    },
    {
      inputType: "label",
      tittle: "Supplier",
      fieldName: "supplierName",
      className: "col-md-4",
    },
    {
      inputType: "label",
      tittle: "Initial Count",
      fieldName: "intialCount",
      className: "col-md-4",
    },
    {
      inputType: "label",
      tittle: "Processed Count",
      fieldName: "proceedCount",
      className: "col-md-4",
    },
    {
      inputType: "label",
      tittle: "Error Count",
      fieldName: "errorCount",
      className: "col-md-4",
    },
    {
      inputType: "label",
      tittle: "Start Date",
      fieldName: "startDate",
      className: "col-md-4",
    },
    {
      inputType: "label",
      tittle: "End Date",
      fieldName: "endDate",
      className: "col-md-4",
    },
    {
      inputType: "label",
      tittle: "Error",
      fieldName: "errorMessages",
      className: "col-md-12",
    },
  ];
  //** API Calls

  onAdd = (request) => {
    this.apiService.onAPICall(
      "addAnalyzerDetails",
      this.add,
      request,
      this.onAPIResponse,
      this.onUpdateState
    );
  };
  onGetSupplierList = (request) => {
    this.apiService.onAPICall(
      "supplierList",
      this.supplierList,
      request,
      this.onAPIResponse,
      this.onUpdateState
    );
  };
  onGetAnalyzerData = (request) => {
    this.apiService.onAPICall(
      "getAnalyzerData",
      this.getAnalyzerData,
      request,
      this.onAPIResponse,
      this.onUpdateState
    );
  };
  onGetAnalyzerDataId = (requst) => {
    this.apiService.onAPICall(
      "getAnalyzerDataId",
      this.getAnalyzerDataId,
      requst,
      this.onAPIResponse,
      this.onUpdateState
    );
  };

  //** Service Event */

  onInitService() {
    //This method only call when we use form component and we can add all items which we on on form load
    let valRule = getValidationRuleList(this.formFields);
    this.validationRules = valRule;
    this.onUpdateState({
      validationRules: valRule,
    });
  }

  //** Methods     */

  onResetData = () => {
    this.dataItem = { ...analyzerDetail };
    let resetValidation = {
      isValid: false,
      error: {},
    };
    this.onUpdateState({
      addAnalyzerDetails: this.dataItem,
      validState: resetValidation,
    });
  };

  onRedirectoList = () => {
    this.onRedirect("/DataAnalyzerList");
  };

  onAPIResponse = (key, isSuccess, responseData) => {
    if (!isSuccess) {
      this.onUpdateState({ setIsLoad: false });
      return;
    }
    switch (key) {
      case "addAnalyzerDetails":
        // Need to check if user is exist or not.
        if (responseData.errorType === "EXIST") {
          this.apiService.serviceManager.swalServices.Alert(
            ErrorMessage.Already_Exist.replace("{0}", "Request")
          );
        } else {
          if (
            responseData.errorType === "File Not Save" &&
            responseData.hasError === true
          ) {
            this.apiService.serviceManager.swalServices.Alert(
              ErrorMessage.NoRecordFound.replace("{0}", "for request")
            );
          } else {
            if (responseData.errorType === "File Already Exist") {
              this.apiService.serviceManager.swalServices.Alert(
                ErrorMessage.Already_Exist.replace("{0}", "File")
              );
            }
            if (responseData.errorType === "EXIST") {
              this.apiService.serviceManager.swalServices.Alert(
                ErrorMessage.Already_Exist.replace(
                  "{0}",
                  "This Component Request"
                )
              );
            } else {
              this.onUpdateState({ setIsLoad: false });
              this.apiService.serviceManager.swalServices.Success(
                SuccessMessage.Add_Success.replace(
                  "{0}",
                  "This Component Request"
                )
              );
              // this.resetValid();
              this.onRedirectoList();
              this.onResetData();
            }
          }
        }
        break;
      case "supplierList":
        let supplierData = responseData;
        let newDataList = supplierData?.map((data) => ({
          id: data.supplierID,
          name: data.supplierName,
        }));
        this.onUpdateState({
          setOperationalList: newDataList,
          setIsLoad: false,
        });
        break;
      case "getAnalyzerData":
        if (responseData.itemList !== null) {
          let itemList = responseData.itemList;
          itemList.forEach(function (i) {
            i.destinationDb = i.destinationDb ? i.destinationDb : "-";
            i.startDate = i.startDate
              ? DataFormat(
                i.startDate
              )
              : "-";
            i.endDate = i.endDate
              ? DataFormat(
                i.endDate
              )
              : "-";
            i.dataAnalyzerTypeId =
              i.dataAnalyzerTypeId === 1
                ? "Price Comparison"
                : i.dataAnalyzerTypeId === 2
                  ? "Price Validation"
                  : i.dataAnalyzerTypeId === 3
                    ? "Stock Validation"
                    : null;
            i.statusId =
              i.statusId === 1
                ? "Open"
                : i.statusId === 2
                  ? "Start"
                  : i.statusId === 3
                    ? "InProgress"
                    : i.statusId === 4
                      ? "Done"
                      : i.statusId === 5
                        ? "Error"
                        : i.statusId === 6
                          ? "OnHold"
                          : null;
          });
        }
        this.onUpdateState({
          analyzerDataList: responseData.itemList,
          TotalCount: responseData.totalCount,
          setIsLoad: false,
        });
        break;
      case "getAnalyzerDataId":
        if (responseData !== null) {
          responseData.dataAnalyzerTypeId =
            responseData.dataAnalyzerTypeId === 1
              ? "Price Comparison"
              : responseData.dataAnalyzerTypeId === 2
                ? "Price Validation"
                : responseData.dataAnalyzerTypeId === 3
                  ? "Stock Validation"
                  : null;
          responseData.statusId =
            responseData.statusId === 1
              ? "Open"
              : responseData.statusId === 2
                ? "Start"
                : responseData.statusId === 3
                  ? "InProgress"
                  : responseData.statusId === 4
                    ? "Done"
                    : responseData.statusId === 5
                      ? "Error"
                      : responseData.statusId === 6
                        ? "OnHold"
                        : null;
          responseData.supplierID = responseData.supplierID
            ? responseData.supplierID
            : "NA";
          responseData.intialCount = responseData.intialCount
            ? responseData.intialCount
            : "NA";
          responseData.proceedCount = responseData.proceedCount
            ? responseData.proceedCount
            : "NA";
          responseData.errorCount = responseData.errorCount
            ? responseData.errorCount
            : "NA";
          responseData.startDate = responseData.startDate
            ? DataFormat(
              responseData.startDate
            )
            : "NA";
          responseData.endDate = responseData.endDate
            ? DataFormat(
              responseData.endDate
            )
            : "NA";
          responseData.errorMessages = responseData.errorMessages
            ? responseData.errorMessages
            : "NA";
          responseData.destinationDb = responseData.destinationDb
            ? responseData.destinationDb
            : "NA";
          responseData.supplierName = responseData.supplierName
            ? responseData.supplierName
            : "NA";
          let progressCount =
            (responseData.proceedCount / responseData.intialCount) * 100;
          this.onUpdateState({
            analyzerDetails: responseData,
            totalProceedCount: progressCount,
            setIsLoad: false,
          });
        }
        break;
      default: // Handle default case here
        break;
    }
  };
}
