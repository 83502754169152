import React from 'react'
import BaseComponent from '../../shared/baseComponent';
import linkBuilderDomainServices from '../../services/domainServices/linkBuilderServices';
import Button from '../../components/common/Button';
import FormFieldGroup from '../../components/UI Element/FormGrop/FormFieldGroup';
import { Navigate } from 'react-router-dom';

export default class AddLinkBuilder extends BaseComponent {

    constructor(props) {
        super(props)
        this.linkBuilderServices = new linkBuilderDomainServices();
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            ...this.state,
            setIsLoad: false,
            operationalFormField: this.linkBuilderServices.dataItem,
            setOperationalList: []
        }
        this.linkBuilderServices.onUpdateState = this.updateState.bind(this);
    }

    handleChange(name, value) {
        let detail = this.state.operationalFormField;
        detail[name] = value;
        this.setState({ operationalFormField: { ...detail } });
    }

    componentDidMount() {
        this.linkBuilderServices.onInitService();
        this.linkBuilderServices.onGetModuleTypeForLinkBuilder();
    }

    add = () => {
        if (this.linkBuilderServices.isValidSubmit(this.state.operationalFormField, this.state.validationRules, this.state.validState)) {
            let request = {
                RequestName: this.state.operationalFormField.RequestName,
                moduleId: this.state.operationalFormField.moduleId,
                CreatedBy: this.state.operationalFormField.CreatedBy,
            }
            this.linkBuilderServices.onAdd(request);
        }
    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} />
        }
        return (
            <div>
                <div className="mb-4">
                    <div className="accordion" id="accordionExample">
                        <div className="card accordion-item active">
                            <div id="accordionOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className="card-body">

                                        <div className="row">
                                            {this.linkBuilderServices.formFields.map((Item, index) => {
                                                return (
                                                    <>
                                                        {/* Conditionally render based on serverLocationId */}
                                                        <FormFieldGroup
                                                            column={Item}
                                                            value={this.state.operationalFormField[Item.fieldName]}
                                                            error={this.state.validState.error[Item.fieldName]}
                                                            options={Item.fieldName === "moduleId" ? this.state.setOperationalList : null}
                                                            onKeyDown={Item.onkeydown ? Item.onkeydown.bind(this) : null}
                                                            onChange={Item.inputType === 'checkbox' ? (event) => this.handleChange(Item.fieldName, event.target.checked)
                                                                : Item.inputType === "file" ? (event) => this.handleFileChange(event) :
                                                                    Item.inputType === "select" || Item.inputType === "text" ? (event) => this.handleChange(Item.fieldName, event.target.value)
                                                                        : (event) => this.handleChange(Item.fieldName, event.target.value)}
                                                        />
                                                    </>
                                                );
                                            })}
                                        </div>

                                    </div>
                                </div>
                                <div className="addnewuser-button mb-4">
                                    <button
                                        className="tab-title tab-title--active  btn-hover  color-3 active"
                                        onClick={() => this.add()}
                                    >Save</button>
                                    <Button type='cancel' title="Cancel" className="ml-2" onClick={() => this.linkBuilderServices.onRedirectoList()} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
