import BaseDomainService from "./baseDomainServices";
import { ErrorMessage, SuccessMessage } from "../../utils/Messages";
import { getValidationRuleList } from "../../utils/validation/generateValidationRule";
import { DataFormat } from "../../utils/DateFormat";


const formDetail = {
    regulationID: "",
    regulationName: "",
    type: "",
    organization: "",
    country: "",
    sourceURL: "",
    numberOfEntries: "",
    importance: "",
    notes: "",
    description: "",
    updatePriority: "",
    updateFrequency: "",
    dataSourceLastRevieweddate: "",
    dataSourceLastUpdateddate: "",
    dataSourceNextUpdatedate: "",
    chemidexLastDataUpload: "",
    lastReviewedDate: "",
    lastReviewedBy: "",
    isEnable: true,
    createdBy: 1,
}

const regulationFormDetail = {
    requestName: "",
    regulationId: "",
    regulationFile: "",
    regulationFileName: "",
    createdBy: 1,
}

export default class RegulationDomainService extends BaseDomainService {

    // API Get
    add = this.apiService.regulationService.addRegulation;
    update = this.apiService.regulationService.updateRegulation;
    delete = this.apiService.regulationService.deleteRegulation;
    getList = this.apiService.regulationService.getAllRegulation;
    getById = this.apiService.regulationService.getRegulationById;
    addRegulationRequest = this.apiService.regulationService.addRegulationRequest;
    getRegulationRequestList = this.apiService.regulationService.getAllRegulationRequest;
    getRegulationNameList = this.apiService.regulationService.getAllRegulationNameList;
    getRegulationRequestById = this.apiService.regulationService.getRegulationRequestById;
    getLogsByDataId = this.apiService.productDataSyncServices.getLogsById;
    getRegulationOutPutById = this.apiService.regulationService.getRegulationOutputFileById;

    //Table Grid columns
    gridColmns = [
        { name: "regulationName", title: "Regulation Name" },
        { name: "type", title: "Type" },
        { name: "organization", title: "Organization" },
        { name: "country", title: "Country" },
        { name: "productDetailCounts", title: "Counts" },
        { name: "isEnable", title: "Is Enable" },
        { name: "regulationID", title: "Actions" },
    ];
    regulationRequestGridColmns = [
        { name: "requestName", title: "Request Name" },
        { name: "statusName", title: "Status Name" },
        { name: "createdDate", title: "CreatedDate" },
        { name: "regulationRequestId", title: "Actions" }
    ];


    // Define the state variables
    dataItem;
    regulationDataItem;

    formFieldsSearch = [
        {
            inputType: "text",
            tittle: "",
            placeholder: "Search ...",
            fieldName: "searchText",
            mode: 1,
            className: 'col-md-4 mb-0'
        },
    ]


    // define the form fields
    formFields = [
        {
            inputType: "text",
            tittle: "Regulation Name",
            placeholder: "Regulation Name",
            fieldName: "regulationName",
            className: "col-md-6",
            validation: [{ type: "require" }]
        },
        {
            inputType: "text",
            tittle: "Type",
            placeholder: "Type",
            fieldName: "type",
            className: "col-md-6",
            validation: [{ type: "require" }]
        },
        {
            inputType: "text",
            tittle: "Organization",
            placeholder: "Organization",
            fieldName: "organization",
            className: "col-md-6",
            validation: [{ type: "require" }]
        },
        {
            inputType: "text",
            tittle: "Country",
            placeholder: "Country",
            fieldName: "country",
            className: "col-md-6",
            validation: [{ type: "require" }]
        },
        {
            inputType: "text",
            tittle: "SourceURL",
            placeholder: "SourceURL",
            fieldName: "sourceURL",
            className: "col-md-6",
            validation: [{ type: "require" }]
        },
        {
            inputType: "number",
            tittle: "Number Of Entries",
            placeholder: "Number Of Entries",
            fieldName: "numberOfEntries",
            pattern: "[0-9]*",
            className: "col-md-6",
            validation: [{ type: "require" }],
        },
        {
            inputType: "number",
            tittle: "Importance",
            placeholder: "Importance",
            fieldName: "importance",
            pattern: "[0-9]*",
            className: "col-md-6",
            validation: [{ type: "require" }],
        },
        {
            inputType: "text",
            tittle: "Notes",
            placeholder: "Notes",
            fieldName: "notes",
            className: "col-md-6",
            validation: [{ type: "require" }]
        },
        {
            inputType: "text",
            tittle: "Description",
            placeholder: "Description",
            fieldName: "description",
            className: "col-md-6",
            validation: [{ type: "require" }]
        },
        {
            inputType: "number",
            tittle: "UpdatePriority",
            placeholder: "UpdatePriority",
            fieldName: "updatePriority",
            pattern: "[0-9]*",
            className: "col-md-6",
            validation: [{ type: "require" }],
        },
        {
            inputType: "select",
            tittle: "UpdateFrequency",
            placeholder: "Please select UpdateFrequency",
            fieldName: "updateFrequency",
            isDefault: true,
            Options: [
                { name: "3 months", id: "1" },
                { name: "6 months", id: "2" },
                { name: "Yearly", id: "3" }
            ],
            className: 'col-md-6',
            validation: [{ type: "require" }]
        },
        {
            inputType: "datepicker",
            tittle: "Data Source Last Reviewed Date",
            placeholder: "Data Source Last Reviewed Date",
            fieldName: "dataSourceLastRevieweddate",
            className: "col-md-6",
            validation: [{ type: "require" }]
        },
        {
            inputType: "datepicker",
            tittle: "Data Source Last Updated Date",
            placeholder: "Data Source Last Updated Date",
            fieldName: "dataSourceLastUpdateddate",
            className: "col-md-6",
            validation: [{ type: "require" }]
        },
        {
            inputType: "datepicker",
            tittle: "Data Source Next Update Date",
            placeholder: "Data Source Next Updated Date",
            fieldName: "dataSourceNextUpdatedate",
            className: "col-md-6",
            validation: [{ type: "require" }]
        },
        {
            inputType: "datepicker",
            tittle: "Chemidex Last Data Upload",
            placeholder: "Chemidex Last Data Upload",
            fieldName: "chemidexLastDataUpload",
            className: "col-md-6",
            validation: [{ type: "require" }]
        },
        {
            inputType: "datepicker",
            tittle: "Last Reviewed Date",
            placeholder: "Last Reviewed Date",
            fieldName: "lastReviewedDate",
            className: "col-md-6",
            validation: [{ type: "require" }]
        },
        {
            inputType: "text",
            tittle: "Last Reviewed By",
            placeholder: "Last Reviewed By",
            fieldName: "lastReviewedBy",
            className: "col-md-6",
            validation: [{ type: "require" }]
        },
        {
            inputType: "checkbox",
            tittle: "IsEnable",
            fieldName: "isEnable",
            checked: true,
            className: "col-md-4 mt-checkbox"
        },
    ];

    regulationFormFields = [
        {
            inputType: "text",
            tittle: "RequestName",
            placeholder: "RequestName",
            fieldName: "requestName",
            className: "col-md-6",
            validation: [{ type: "require" }]
        },
        {
            inputType: "select",
            tittle: "Regulation Name",
            placeholder: "Select Regulation Name",
            fieldName: "regulationId",
            options: [],
            optionFieldName: "regulationId",
            className: 'col-md-6',
            validation: [{ type: "require" }]
        },
        {
            inputType: "file",
            tittle: "RegulationFile",
            placeholder: "RegulationFile",
            fieldName: "regulationFile",
            className: 'col-md-6',
            validation: [{ type: "require" }]
        }
    ];

    constructor() {
        super();
        this.onPaginationUpdate = this.onGetList.bind(this);
        this.actionKey = "regulationID";
        this.regulationActionKey = "regulationRequestId";
        this.actionActive = "isEnable";
        this.actionCounts = "productDetailCounts";
        this.dataItem = { ...formDetail };
        this.regulationDataItem = { ...regulationFormDetail };
    }

    //** API Calls
    onAdd = (request) => {
        this.apiService.onAPICall("add", this.add, request, this.onAPIResponse, this.onUpdateState)
    };
    onUpdate = (request) => {
        this.apiService.onAPICall("update", this.update, request, this.onAPIResponse, this.onUpdateState)
    };
    onDelete = (request) => {
        this.apiService.onAPICall("delete", this.delete, request, this.onAPIResponse, this.onUpdateState)
    };
    onGetList = (request) => {
        this.apiService.onAPICall("getList", this.getList, request, this.onAPIResponse, this.onUpdateState)
    };
    onGetById = (request) => {
        this.apiService.onAPICall("getById", this.getById, request, this.onAPIResponse, this.onUpdateState)
    };
    onAddRegulationRequest = (request) => {
        this.apiService.onAPICall("addRegulationRequest", this.addRegulationRequest, request, this.onAPIResponse, this.onUpdateState)
    };
    onGetRegulationRequestList = (request) => {
        this.apiService.onAPICall("getRegulationRequestList", this.getRegulationRequestList, request, this.onAPIResponse, this.onUpdateState)
    };
    onGetRegulationNameList = (request) => {
        this.apiService.onAPICall("getRegulationNameList", this.getRegulationNameList, request, this.onAPIResponse, this.onUpdateState)
    };
    onGetRegulationRequestById = (request) => {
        this.apiService.onAPICall("getRegulationRequestById", this.getRegulationRequestById, request, this.onAPIResponse, this.onUpdateState)
    };
    onGetLogsById = (request) => {
        this.apiService.onAPICall("getLogsByDataId", this.getLogsByDataId, request, this.onAPIResponse, this.onUpdateState);
    }
    onGetRegulationOutPutById = (request) => {
        this.apiService.onAPICall("getRegulationOutPutById", this.getRegulationOutPutById, request, this.onAPIResponse, this.onUpdateState);
    }

    //** Service Event */
    onInitService() {
        let valRule = getValidationRuleList(this.formFields);
        this.validationRules = valRule;

        this.onUpdateState({
            validationRules: valRule,
            resetValidationRule: this.resetValidationRule,
        });
    }

    onRegulationInitService() {
        let valRule = getValidationRuleList(this.regulationFormFields);
        this.validationRules = valRule;

        this.onUpdateState({
            validationRules: valRule,
            resetValidationRule: this.resetValidationRule,
        });
    }

    onRedirectoList = () => {
        this.onRedirect('/ManageRegulation');
    };

    onRedirectoRegulationRequestList = () => {
        this.onRedirect('/ManageRegulationRequest');
    };

    //** Methods     */

    onAPIResponse = (key, isSuccess, responseData) => {
        if (!isSuccess) {
            this.onUpdateState({ setIsLoad: false });
            return;
        }
        switch (key) {

            case "add":
                // Need to check if user is exist or not.
                this.apiService.serviceManager.swalServices.Success(
                    SuccessMessage.Insert_Success.replace("{0}", "New Regulation")
                );
                this.onRedirectoList();
                this.onUpdateState({ setIsLoad: false });
                break;

            case "getList":
                if (responseData != null) {
                    this.onUpdateState({
                        getList: responseData.itemList,
                        TotalCount: responseData.totalCount
                    });
                }
                this.onUpdateState({ setIsLoad: false });
                break;

            case "getById":
                if (responseData != null) {
                    let dataSet = { ...responseData };

                    //** Date convert into this form - Wed Dec 27 2023 00:00:00 GMT+0530 (India Standard Time) */
                    dataSet.lastReviewedDate = new Date(dataSet.lastReviewedDate);
                    dataSet.chemidexLastDataUpload = new Date(dataSet.chemidexLastDataUpload);
                    dataSet.dataSourceNextUpdatedate = new Date(dataSet.dataSourceNextUpdatedate);
                    dataSet.dataSourceLastUpdateddate = new Date(dataSet.dataSourceLastUpdateddate);
                    dataSet.dataSourceLastRevieweddate = new Date(dataSet.dataSourceLastRevieweddate);

                    this.dataItem = { ...dataSet };
                    this.onUpdateState({
                        formField: dataSet
                    });
                }
                this.onUpdateState({ setIsLoad: false });
                break;

            case "update":
                if (responseData.errorType === "EXISTS" && responseData.hasError === true) {
                    this.apiService.serviceManager.swalServices.Alert(
                        ErrorMessage.Already_Exist.replace("{0}", "Regulation")
                    );
                } else {
                    this.apiService.serviceManager.swalServices.Success(
                        SuccessMessage.Update_Success.replace("{0}", "This Regulation")
                    );
                    this.onRedirectoList();
                }
                this.onUpdateState({ setIsLoad: false });
                break;

            case "delete":
                if (responseData !== null) {
                    this.apiService.serviceManager.swalServices.Success(SuccessMessage.Delete_Success.replace("{0}", "Regulation"));
                    this.onGetList(this.pagination);
                }
                this.onUpdateState({ "setIsLoad": false });
                break;

            case "addRegulationRequest":
                // Need to check if user is exist or not.
                this.apiService.serviceManager.swalServices.Success(
                    SuccessMessage.Insert_Success.replace("{0}", "New Regulation Request")
                );
                this.onRedirectoRegulationRequestList();
                this.onUpdateState({ setIsLoad: false });
                break;

            case "getRegulationRequestList":
                if (responseData != null) {
                    let listData = responseData.itemList;
                    listData.forEach(function (i) {
                        i.createdDate = DataFormat(
                            i.createdDate
                          );
                    });
                    this.onUpdateState({
                        getList: responseData.itemList,
                        TotalCount: responseData.totalCount
                    });
                }
                this.onUpdateState({ setIsLoad: false });
                break;

            case "getRegulationNameList":
                if (responseData !== null) {
                    let getList = responseData;
                    let dataList = getList?.map((data) => ({
                        id: data.regulationID,
                        name: data.regulationName,
                    }));
                    this.onUpdateState({
                        "regulationNameList": dataList
                    });
                }
                this.onUpdateState({ setIsLoad: false });
                break;

            case "getRegulationRequestById":
                if (responseData != null) {
                    responseData.createdDate = DataFormat(
                        responseData.createdDate
                      );
                    this.onUpdateState({
                        detailData: responseData
                    })
                }
                this.onUpdateState({ setIsLoad: false });
                break;

            case "getLogsByDataId":
                if (responseData !== null) {
                    this.onUpdateState({
                        logsList: responseData
                    });
                }
                this.onUpdateState({
                    "setIsLoad": false,
                });
                break;

            case "getRegulationOutPutById":
                if (responseData !== null) {
                    this.onUpdateState({
                        outPutFileData: responseData
                    });
                }
                this.onUpdateState({
                    "setIsLoad": false,
                });
                break;
                default:
                    // Handle default case here
                    break;
        }
    };
}
