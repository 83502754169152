import { ErrorMessage, SuccessMessage } from "../../utils/Messages";
import { getValidationRuleList } from "../../utils/validation/generateValidationRule";
import BaseDomainService from "./baseDomainServices";

const userDetail = {
  userId: 0,
  userName: "",
  hashedPassword: "",
  confirmPassword: "",
  isActive: true,
  firstName: "",
  lastName: "",
  phoneNo: "",
  designation: "",
  userType: "",
  password: "",
};

export default class UserDomainServices extends BaseDomainService {
  // API Get
  getList = this.apiService.manageOperationalServices.getAllUserList;
  getbyId = this.apiService.userServices.getUserDetailsByUserId;
  add = this.apiService.userServices.addUsers;
  update = this.apiService.userServices.updateUserDetails;
  updateUserDetail = this.apiService.userServices.updateUserDetails;
  updatePassword = this.apiService.userServices.updateUserpassword;

  //Table Grid columns
  gridColmns = [
    { name: "userName", title: "User Name" },
    { name: "firstName", title: "First Name" },
    { name: "lastName", title: "Last Name" },
    { name: "phoneNo", title: "Phone No" },
    { name: "designation", title: "Designation" },
    { name: "userId", title: "Actions" },
  ];

  // Define the state variables
  dataItem;
  userResetItem = {
    userId: null,
    password: "",
    confirmPassword: "",
  };

  // define the form fields
  PassformFields = [
    {
      inputType: "password",
      tittle: "New Password",
      fieldName: "password",
      className: "col-md-6",
      validation: [{ type: "require" }],
    },
    {
      inputType: "password",
      tittle: "Confirm Password",
      fieldName: "confirmPassword",
      className: "col-md-6",
      validation: [{ type: "require" }],
    },
  ];

  formFields = [
    {
      inputType: "text",
      tittle: "User Name",
      placeholder: "User Name",
      fieldName: "userName",
      className: "col-md-6",
      validation: [{ type: "require" }],
    },
    {
      inputType: "text",
      tittle: "First Name",
      placeholder: "First Name",
      fieldName: "firstName",
      className: "col-md-6",
      onkeydown: (e) => {
        /^[0-9\b]+$/.test(e.key) && e.preventDefault();
      },
      validation: [{ type: "require" }],
    },
    {
      inputType: "text",
      tittle: "Last Name",
      placeholder: "Last Name",
      fieldName: "lastName",
      className: "col-md-6",
      onkeydown: (e) => {
        /^[0-9\b]+$/.test(e.key) && e.preventDefault();
      },
      validation: [{ type: "require" }],
    },
    {
      inputType: "number",
      tittle: "Phone Number",
      placeholder: "Phone Number",
      fieldName: "phoneNo",
      pattern: "[0-9]*",
      className: "col-md-6",
      validation: [{ type: "require" }],
    },

    {
      inputType: "text",
      tittle: "Designation",
      placeholder: "Designation",
      fieldName: "designation",
      className: "col-md-6",
      onkeydown: (e) => {
        /^[0-9\b]+$/.test(e.key) && e.preventDefault();
      },
      validation: [{ type: "require" }],
    },
    {
      inputType: "select",
      tittle: "User Type",
      placeholder: "Select UserType",
      fieldName: "userType",
      isDefault: true,
      className: "col-md-6",
      Options: [
        { name: "Admin", id: "1" },
        { name: "Data uploader", id: "2" },
        { name: "Developer", id: "3" },
      ],
    },
    {
      inputType: "checkbox",
      tittle: "isActive",
      fieldName: "isActive",
      checked: true,
      className: "col-md-4",
    },
  ];

  // Validation

  validationRules = {};
  resetValidationRule = {
    password: [
      {
        type: "require",
        message: ErrorMessage.FieldRequired.replace("{0}", "password"),
      },
      {
        type: "password",
        message: ErrorMessage.PasswordNotValid,
      },
    ],
    confirmPassword: [
      {
        type: "compare",
        compareEle: "password",
        message: ErrorMessage.ComparePassword.replace("{0}", ""),
      },
    ],
  };

  constructor() {
    super();
    this.onPaginationUpdate = this.onGetUsers.bind(this);
    this.actionKey = "userId";
    this.dataItem = { ...userDetail };
  }

  //** API Calls

  onGetUsers = (requst) => {
    this.apiService.onAPICall(
      "getUser",
      this.getList,
      requst,
      this.onAPIResponse,
      this.onUpdateState
    );
  };

  onAdd = (request) => {
    this.apiService.onAPICall(
      "addUser",
      this.add,
      request,
      this.onAPIResponse,
      this.onUpdateState
    );
  };

  onUpdate = (request) => {
    this.apiService.onAPICall(
      "update",
      this.update,
      request,
      this.onAPIResponse,
      this.onUpdateState
    );
  };

  // On Update Password
  onUpdatePassword = (request) => {
    this.apiService.onAPICall(
      "upPassword",
      this.updatePassword,
      request,
      this.onAPIResponse,
      this.onUpdateState
    );
  };

  onGetByid = (request) => {
    this.apiService.onAPICall(
      "getByid",
      this.getbyId,
      request,
      this.onAPIResponse,
      this.onUpdateState
    );
  };
  onUpdateUserDetail = (request) => {
    this.apiService.onAPICall(
      "updateUserDetail",
      this.updateUserDetail,
      request,
      this.onAPIResponse,
      this.onUpdateState
    );
  };

  //** Service Event */

  onInitService() {
    //This method only call when we use form component and we can add all items which we on on form load

    let valRule = getValidationRuleList(this.formFields);
    this.validationRules = valRule;

    this.onUpdateState({
      validationRules: valRule,
      resetValidationRule: this.resetValidationRule,
    });
  }

  onResetData = () => {
    this.dataItem = { ...this.userDetail };

    this.onUpdateState({ addUserDetails: this.dataItem });
  };
  //** Methods     */
  //  When we get response from  API

  onRedirectoList = () => {
    this.onRedirect("/ManageUsers");
  };

  onRedirectoDashboard = () => {
    this.onRedirect("/Dashboard");
  };

  onAPIResponse = (key, isSuccess, responseData) => {
    if (!isSuccess) {
      this.onUpdateState({ setIsLoad: false });
      return;
    }
    switch (key) {
      case "getUser":
        this.onUpdateState({
          dataList: responseData.itemList,
          TotalCount: responseData.totalCount,
          setIsLoad: false,
        });
        break;

      case "getByid":
        let userDet = { ...responseData };
        this.dataItem = { ...userDet };
        this.onUpdateState({
          addUserDetails: {
            userName: userDet.userDisplayName,
            isActive: userDet.isActive,
            firstName: userDet.firstName,
            lastName: userDet.lastName,
            phoneNo: userDet.phoneNo,
            designation: userDet.designation,
            hashedPassword: userDet.hashedPassword,
            userId: userDet.userId,
            userType: userDet.userType,
          },
          updateUser: userDet.userId,
          setIsLoad: false,
        });

        break;
      case "addUser":
        // Need to check if user is exist or not.
        this.apiService.serviceManager.swalServices.Success(
          SuccessMessage.Insert_Success.replace("{0}", "New User")
        );
        this.onRedirectoList();
        this.onUpdateState({ setIsLoad: false });

        break;
      case "update":
        if (
          responseData.errorType === "EXISTS" &&
          responseData.hasError === true
        ) {
          this.apiService.serviceManager.swalServices.Alert(
            ErrorMessage.Already_Exist.replace("{0}", "User")
          );
        } else {
          this.apiService.serviceManager.swalServices.Success(
            SuccessMessage.Update_Success.replace("{0}", "This User")
          );
          this.onUpdateState({ setIsLoad: false });
          this.onRedirectoList();
        }
        break;

      case "upPassword":
        this.apiService.serviceManager.swalServices.Success(
          SuccessMessage.Update_Success.replace("{0}", "Password")
        );
        this.onUpdateState({ setIsLoad: false });

        break;
      case "updateUserDetail":
        if (
          responseData.errorType === "EXISTS" &&
          responseData.hasError === true
        ) {
          this.apiService.serviceManager.swalServices.Alert(
            ErrorMessage.Already_Exist.replace("{0}", "User")
          );
        } else {
          this.apiService.serviceManager.swalServices.Success(
            SuccessMessage.Update_Success.replace("{0}", "This User")
          );
          this.onUpdateState({ setIsLoad: false });
        }
        break;
        default:
          // Handle default case here
          break;
    }
  };
}
