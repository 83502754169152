import Button from "../../components/common/Button";
import FormFieldGroup from "../../components/UI Element/FormGrop/FormFieldGroup";
import DatabaseAnalyzerServices from "../../services/domainServices/databaseAnalyzerServices";
import BaseComponent from "../../shared/baseComponent";
import { ErrorMessage } from "../../utils/Messages";
import ButtonLoader from "../../components/common/buttonLoader/ButtonLoader";

export class StockValidation extends BaseComponent {
  constructor(props) {
    super(props);
    this.analyzerServices = new DatabaseAnalyzerServices();
    this.analyzerServices.onUpdateState = this.updateState.bind(this);
    this.state = {
      ...this.state, // Need to use parent state
      addAnalyzerDetails: this.analyzerServices.dataItem,
      setOperationalList: [],
      setIsLoad: false

    };
    this.handleChange = this.handleChange.bind(this);
  }
  //** Constructor  and Component method */
  componentDidMount() {
    this.analyzerServices.onInitService();
    this.analyzerServices.onGetSupplierList();
  }
  // *** Event function **//
  handleChange(name, value) {
    let detail = { ...this.state.addAnalyzerDetails };
    detail[name] = value;
    this.setState({ addAnalyzerDetails: { ...detail } });
  }
  handleInputChange = (name, value) => {
    let detail = { ...this.state.addAnalyzerDetails };
    detail[name] = value;
    this.setState({ addAnalyzerDetails: detail });
  };
  //** API  function */
  addAnalyzerRequest = () => {
    if (this.analyzerServices.isValidSubmit(this.state.addAnalyzerDetails, this.state.validationRules, this.state.validState)) {
      if (this.state.addAnalyzerDetails.sourceDB === "EchemPortalFinal" && this.state.addAnalyzerDetails.supplierID === "") {
        this.analyzerServices.apiService.serviceManager.swalServices.Alert(ErrorMessage.SelectRequired.replace("{0}", "Supplier Name"));
      }
      else {
        let request = {
          dataAnalyzerTypeId: 3,
          sourceDb: this.state.addAnalyzerDetails.sourceDB ? this.state.addAnalyzerDetails.sourceDB : "",
          destinationDb: this.state.addAnalyzerDetails.destinationDB ? this.state.addAnalyzerDetails.destinationDB : "",
          supplierID: this.state.addAnalyzerDetails.supplierID ? this.state.addAnalyzerDetails.supplierID : "",
          createdBy: this.analyzerServices.authUser.adminUserId
        };
        this.analyzerServices.onAdd(request);
      }
    }

  }

  render() {

    return (
      <>
      <div className="mb-4">
        <div className="wrapper">
          <div className="mb-5">
            <div className="accordion" id="accordionExample">
              <div className="card accordion-item active">
                <div
                  id="accordionOne"
                  className="accordion-collapse collapse show"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <div className="card-body">
                      <div className="row">
                        {/* <label
                          className="logdetails mb-3"
                          style={{ paddingLeft: "15px" }}
                        >
                          Database Analyzer Type
                            </label> */}
                        {this.analyzerServices.formFields.map((Item, index) => {
                          return (
                            <>
                            {Item.fieldName === "dataAnalyzerType" ? (
                              <FormFieldGroup
                                column={Item}
                                value={this.state.addAnalyzerDetails[Item.fieldName]}
                                options={Item.Options ? Item.Options : null}
                                onChange={(event) => this.handleChange(Item.fieldName, event.target.value)}
                                key={index}
                              />
                            ) : null}{" "}
                            </>
                          );
                        }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.addAnalyzerDetails.dataAnalyzerType === "1" ?
        <div className="accordion" id="accordionExample">
          <div className="card accordion-item active">
            <div
              id="accordionOne"
              className="accordion-collapse collapse show"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <div className="card-body">
                  <div className="row">
                    {this.analyzerServices.formFields.map((Item, index) => {
                      return (
                        <>
                        {this.state.addAnalyzerDetails.dataAnalyzerType ===
                          "1" ? (
                            <>
                            {Item.dropDown === "1" ? (
                              <FormFieldGroup
                                column={Item}
                                options={Item.Option ? Item.Option :
                                  Item.fieldName === "supplierID" ? this.state.setOperationalList : null}
                                value={this.state.addAnalyzerDetails[Item.fieldName]}
                                error={this.state.validState.error[Item.fieldName]}
                                onChange={Item.inputType === "select" ? (event) => this.handleChange(Item.fieldName, event.target.value)
                                  : null}
                                onBlur={() => this.analyzerServices.validateField(Item.fieldName, this.state.addAnalyzerDetails, this.state.validationRules, this.state.validState)}
                                onKeyDown={Item.onkeydown ? Item.onkeydown.bind(this) : null}
                                key={index}
                                hidden={this.state.addAnalyzerDetails.sourceDB !== "EchemPortalFinal" && Item.fieldName === "supplierID" && Item.hidden === true ? true
                                  : false
                                }
                              />
                            ) : null}
                            </>
                          ) :
                          null
                        }
                        </>
                      );
                    })}

                    <div className="col-md-12 addnewuser-button">
                      <button className="tab-title tab-title--active ml-2 btn-hover color-3 active" onClick={() => this.addAnalyzerRequest()}>
                        {this.state.setIsLoad ? <ButtonLoader /> : "Add "}      </button>
                      <Button type='cancel' title="Cancel" className="btn-hover color-3 tab-title ml-2" onClick={() => this.analyzerServices.onRedirectoList()} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        : "" }
      </div>
      </>
    )
  }
}
export default StockValidation
