//** Component  */
import React from 'react'

//** Services */
import { DownloadInputFile } from '../../services/axiosServices/ApiEndPoints';
import RequestDomainServices from '../../services/domainServices/requestServices';
import BaseComponent from '../../shared/baseComponent';

//*** Lib */
import TabProgressBarStyle from '../../styles/TabProgressBarStyle';
import { decryptionData } from '../../utils/Encryption';

export default class RequestImport extends BaseComponent {
    constructor(props) {
        super(props)
        this.requestServices = new RequestDomainServices();
        this.requestServices.onUpdateState = this.updateState.bind(this);
        this.state = {
            dataImportCount: []
        }
    }

    //** Constructor  and Component method */
    componentDidMount() {
        let params = new URLSearchParams(window.location.search);
        let reqId = params.get("");
        const decryptKey = decryptionData(reqId, false);
        const numberInt = parseInt(decryptKey, 10);
        if (!isNaN(numberInt) && numberInt > 0) {
            this.requestServices.onGetDataImportingById(decryptKey);
        }
    }

    render() {
        return (
            <>
                <TabProgressBarStyle />
                <div className="card-body custom-card-body-section main-card-sections pb-2">
                    <div className='card-title-main px-3 py-3 mb-3'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='card-title-text'>
                                    <h6 className='titletexts'>
                                        Importing Fields
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.dataImportCount.length > 0 ? this.state.dataImportCount.map((add, index) => <>
                        <div className="mb-4 px-3" index={index}>
                            <div className="accordion" id="accordionExample">
                                <div className="card accordion-item active">
                                    <div
                                        id="accordionOne"
                                        className="accordion-collapse collapse show"
                                        data-bs-parent="#accordionExample">
                                        <div className="card-body custom-card-body-section">

                                            <div className='p-3 pb-0'>
                                                <div className="row">
                                                    <div className="col-md-6 mb-3">
                                                        <div className='inner-detail-sec'>
                                                            <span className='detail-title'>Import Title  </span>

                                                            {add.importTitle ?

                                                                <span for="requestName" className="detail-desc">  {add.importTitle}
                                                                </span>
                                                                :
                                                                <span className='detail-desc'>
                                                                    No Record found
                                                                </span>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <div className='inner-detail-sec'>
                                                            <span className='detail-title'>Import Type   </span>

                                                            {add.importType ?

                                                                <span for="requestName" className="detail-desc">  {add.importType}
                                                                </span>
                                                                :
                                                                <span className='detail-desc'>
                                                                    No Record found
                                                                </span>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="mb-3 col-md-12">
                                                        <div className='inner-detail-sec'>
                                                            <span className='detail-title'>Import File Name   </span>

                                                            {add.importFileName ?

                                                                <span for="requestName" className="detail-desc">
                                                                    <a href={`${DownloadInputFile + `?FileName=${add.importFileName}&ModuleType=Cleaning`}`} target="_top"> <i className="fa fa-download"></i></a>
                                                                </span>
                                                                :
                                                                <span className='detail-desc'>
                                                                    No Record found
                                                                </span>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <div className='inner-detail-sec'>
                                                            <span className='detail-title'>Already Exists Records  </span>

                                                            {add.noOfAlreadyExitsRecords ?

                                                                <span for="requestName" className="detail-desc">  {add.noOfAlreadyExitsRecords}
                                                                    <a href={`${DownloadInputFile + `?FileName=${add.alreadyExistsFileName}&ModuleType=DataImportingType`}`} target="_top"> <i className="fa fa-download"></i></a>
                                                                </span>
                                                                :
                                                                <span className='detail-desc'>
                                                                    No Record found
                                                                </span>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <div className='inner-detail-sec'>
                                                            <span className='detail-title'>New Records  </span>
                                                            {add.noOfNewRecords ?
                                                                <span for="requestName" className="detail-desc">  {add.noOfNewRecords}
                                                                    <a href={`${DownloadInputFile + `?FileName=${add.newRecordsFileName}&ModuleType=DataImportingType`}`} target="_top"> <i className="fa fa-download"></i></a>
                                                                </span>
                                                                :
                                                                <span className='detail-desc'>
                                                                    No Record found
                                                                </span>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <div className='inner-detail-sec'>
                                                            <span className='detail-title'>Error Records </span>
                                                            {add.noOfErrorInRecords ?
                                                                <span for="requestName" className="detail-desc">  {add.noOfErrorInRecords}
                                                                    <a href={`${DownloadInputFile + `?FileName=${add.errorFileName}&ModuleType=DataImportingType`}`} target="_top"> <i className="fa fa-download"></i></a>
                                                                </span>
                                                                :
                                                                <span className='detail-desc'>
                                                                    No Record found
                                                                </span>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <div className='inner-detail-sec'>
                                                            <span className='detail-title'>Total Records </span>
                                                            {add.totalRecords ?
                                                                <span for="requestName" className="detail-desc">  {add.totalRecords}
                                                                    <a href={`${DownloadInputFile + `?FileName=${add.outputFileName}&ModuleType=DataImportingType`}`} target="_top"> <i className="fa fa-download"></i></a>
                                                                </span>
                                                                :
                                                                <span className='detail-desc'>
                                                                    No Record found
                                                                </span>
                                                            }
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>) :
                        <span className="p-3" >
                            No Record found
                        </span>}
                </div>
            </>
        )
    }
}
