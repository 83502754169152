import BaseDomainService from './baseDomainServices';
import {  SuccessMessage } from "../../utils/Messages";
import { getValidationRuleList } from "../../utils/validation/generateValidationRule";
import { DataFormat } from '../../utils/DateFormat';

const operationalFormField = {
    Name: "",
    FileName: "",
    Mode: "",
    productDataSyncImportTypeId: "",
    CreatedBy: 1,
    File: null,
    searchText: ""
}

export default class productDataSyncServices extends BaseDomainService {

    add = this.apiService.productDataSyncServices.addProductDataSync;
    addSync = this.apiService.productDataSyncServices.addProductDataAutoSync;
    getById = this.apiService.productDataSyncServices.getProductDataSyncById;
    getLogsByProductDataId = this.apiService.productDataSyncServices.getLogsById;
    getAllProductDataSync = this.apiService.productDataSyncServices.getAllProductDataSync;
    getProductDataOutputFileById = this.apiService.productDataSyncServices.getProductDataSyncOutputFileById;
    getAllProductDataSyncImportType = this.apiService.productDataSyncServices.getAllProductDataSyncImportType;
        getAllStatus = this.apiService.productImportServices.getAllStatus;
        deleteProductDataSyncByProductDataSyncId = this.apiService.productDataSyncServices.deleteProductDataSyncByProductDataSyncId;

    // define the form fields
    formFields = [
        {
            inputType: "text",
            tittle: "Request Name",
            placeholder: "Request Name",
            fieldName: "Name",
            className: 'col-md-6',
            maxLength: 200,
            validation: [{ type: "require" }]
        }, {
            inputType: "select",
            tittle: "Import Mode",
            placeholder: "Select Dropdown",
            fieldName: "Mode",
            Options: [
                { name: "Sync" },
                { name: "Manual" },
            ],
            className: 'col-md-6 mb-3',
            validation: [{ type: "require" }]
        },
        {
            inputType: "select",
            tittle: "Sync Type",
            placeholder: "Select Dropdown",
            fieldName: "productDataSyncImportTypeId",
            options: [],
            optionFieldName: "productDataSyncImportTypeName",
            subRequest: "1",
            className: 'col-md-6 mb-3',
            validation: [{ type: "require" }]
        },
        {
            inputType: "file",
            tittle: "Input File",
            placeholder: "Input File",
            fieldName: "File",
            subRequest: "1",
            className: 'col-md-6 PartielFile',
        },
    ]

    formFieldsSearch = [
        {
            inputType: "text",
            tittle: "",
            placeholder: "Search ...",
            fieldName: "searchText",
            mode: 1,
            className: 'col-md-4 mb-0'
        },
        {
            inputType: "select",
            tittle: "",
            placeholder: "Select Dropdown",
            fieldName: "productDataSyncImportTypeId",
            options: [],
            optionFieldName: "productDataSyncImportTypeName",      
            subRequest: "1",
            className: 'col-md-4',
        },
        {
            inputType: "select",
            tittle: "",
            placeholder: "Select Dropdown",
            fieldName: "statusId",
            options: [],
            optionFieldName: "statusName",
            subRequest: "1",
            className: 'col-md-4',
        },
    ]

    dataItem;

    gridColmns = [
        { name: 'Name', title: 'Request Name' },
        { name: 'Mode', title: 'Mode' },
        { name: 'productDataSyncImportTypeName', title: 'product Data Sync Type' },
        { name: 'statusName', title: 'Status' },
        { name:'startDate',title :'Start Date' },
        { name:'endDate',title :'End Date'  },
        { name:'processTime',title :'Process Time'  },
        { name: 'productDataSyncId', title: 'Action' },
    ];

    productDataDetailForm = [
        {
            inputType: "label",
            tittle: "Request Name",
            fieldName: "name",
            className: 'col-md-4',
        },
        {
            inputType: "label",
            tittle: "Mode",
            fieldName: "mode",
            className: 'col-md-4',
        },
        {
            inputType: "label",
            tittle: "Import Type Name",
            fieldName: "productDataSyncImportTypeName",
            className: 'col-md-4',
        },
        {
            inputType: "label",
            tittle: "Total Counts",
            fieldName: "totalCounts",
            className: 'col-md-4',
        },
        {
            inputType: "label",
            tittle: "Non Existing Counts",
            fieldName: "nonExistingCounts",
            className: 'col-md-4',
        },
        {
            inputType: "label",
            tittle: "Existing Data Counts",
            fieldName: "existingDataCounts",
            className: 'col-md-4',
        },
        {
            inputType: "label",
            tittle: "Error Count",
            fieldName: "errorCount",
            className: 'col-md-4',
        },
        {
            inputType: "label",
            tittle: "Status",
            fieldName: "statusName",
            className: 'col-md-4',
        },
        {
            inputType: "label",
            tittle: "Created Date",
            fieldName: "createdDate",
            className: 'col-md-4',
        },
        {
            inputType: "label",
            tittle: "Error Message",
            fieldName: "error",
            className: 'col-md-12',
        },
       
    ]

    constructor() {
        super();
        this.actionKey = "productDataSyncId";
        this.dataItem = { ...operationalFormField };
        this.onPaginationUpdate = this.onGetAllProductDataSync.bind(this);
    }

    onInitService() {
        //This method only call when we use form component and we can add all items which we on on form load 
        let valRule = getValidationRuleList(this.formFields)
        this.validationRules = valRule;
        this.onUpdateState({
            "validationRules": valRule,
            "resetValidationRule": this.resetValidationRule
        })
    }

    onAdd = (request) => {
        this.apiService.onAPICall("addProductDataSync", this.add, request, this.onAPIResponse, this.onUpdateState);
    }
    onAddSync = (request) => {
        this.apiService.onAPICall("addProductDataAutoSync", this.addSync, request, this.onAPIResponse, this.onUpdateState);
    }

    onGetAllProductDataSync = (requst, pagination) => {
        this.apiService.onAPICall("getAllProductDataSync", this.getAllProductDataSync, requst, this.onAPIResponse, this.onUpdateState, pagination);
    }
    onProductDataSyncImportType = (request) => {
        this.apiService.onAPICall("getAllProductDataSyncImportType", this.getAllProductDataSyncImportType, request, this.onAPIResponse, this.onUpdateState);
    }
    onGetById = (request) => {
        this.apiService.onAPICall("onGetById", this.getById, request, this.onAPIResponse, this.onUpdateState);
    }
    onGetLogsByProductDataId = (request) => {
        this.apiService.onAPICall("getLogsByProductDataId", this.getLogsByProductDataId, request, this.onAPIResponse, this.onUpdateState);
    }
    onGetProductDataOutputFileById = (request) => {
        this.apiService.onAPICall("getProductDataOutputFileById", this.getProductDataOutputFileById, request, this.onAPIResponse, this.onUpdateState);
    }

    onGetAllStatus = (request) => {
        this.apiService.onAPICall("getAllStatus", this.getAllStatus, request, this.onAPIResponse, this.onUpdateState);
    }
    onRedirectoList = () => {
        this.onRedirect("/SyncData");
    }
    onDelete = (request) => {
        this.apiService.onAPICall("deleteProductDataSyncByProductDataSyncId", this.deleteProductDataSyncByProductDataSyncId, request, this.onAPIResponse, this.onUpdateState)
    };
    onAPIResponse = (key, isSuccess, responseData, getRequest) => {
        if (!isSuccess) {
            this.onUpdateState({ "setIsLoad": false });
            return;
        }
        switch (key) {

            case "addProductDataSync":
        
                let data = responseData;
                if (data.errorType === 'EXIST') {
                    this.apiService.serviceManager.swalServices.Error(SuccessMessage.Already_Exist.replace("{0}", data.errorColumn))
                } else {
                    this.apiService.serviceManager.swalServices.Success(SuccessMessage.Add_Success.replace("{0}", "Product Data Sync"));
                    this.onRedirectoList();
                }
                this.onUpdateState({ "setIsLoad": false });
                break;
            case "addProductDataAutoSync":
          
                let dataSync = responseData;
                if (dataSync.errorType === 'EXIST') {
                    this.apiService.serviceManager.swalServices.Error(SuccessMessage.Already_Exist.replace("{0}", dataSync.errorColumn))
                } else {
                    this.apiService.serviceManager.swalServices.Success(SuccessMessage.Add_Success.replace("{0}", "Product Data Sync"));
                    this.onRedirectoList();
                }
                this.onUpdateState({ "setIsLoad": false });
                break;
            case "getAllProductDataSync":
                let itemList = responseData.itemList;
                if (responseData !== null) {
                    itemList.forEach(function (i) {
                      i.startDate =  i.startDate ? DataFormat(i.startDate) : "-";
                      i.endDate = i.endDate ? DataFormat(i.endDate) : "-";
                      i.processTime=i.processTime ? i.processTime : "-";
                      return { ...itemList}
                    });
                    this.onUpdateState({
                        "getList": itemList,
                        "setIsLoad": false,
                        "TotalCount": responseData.totalCount,
                    });
                }
                break;

            case "getAllProductDataSyncImportType":
           
                let itemList1 = responseData;
                if (itemList1 !== null) {
                    const productTypes = itemList1.map(item => ({
                        id: item.productDataSyncImportTypeId,
                        name: item.productDataSyncImportTypeName,
                    }));

                    const productlist = [
                        { id: 0, name: 'All' },
                        ...productTypes,
                    ];

                    this.onUpdateState({
                        setModuleList: productTypes,
                        setModulelists: productlist,
                        "setIsLoad": false,
                    });
                }
                break;
            case "onGetById":
         
                if (responseData != null) {
                    responseData.name = responseData.name ? responseData.name : "NA"
                    responseData.mode = responseData.mode ? responseData.mode : "NA"
                    responseData.productDataSyncImportTypeName = responseData.productDataSyncImportTypeName ? responseData.productDataSyncImportTypeName : "NA"
                    responseData.totalCounts = responseData.totalCounts ? responseData.totalCounts : "0"
                    responseData.nonExistingCounts = responseData.nonExistingCounts ? responseData.nonExistingCounts : "0"
                    responseData.existingDataCounts = responseData.existingDataCounts ? responseData.existingDataCounts : "0"
                    responseData.invalidCounts = responseData.invalidCounts ? responseData.invalidCounts : "0"
                    responseData.statusName = responseData.statusName ? responseData.statusName : "NA"
                    responseData.error = responseData.error ? responseData.error : "NA"
                    responseData.createdDate = responseData.createdDate ? DataFormat(
                        responseData.createdDate
                      ) : "NA"
                 
                      responseData.productImportName = responseData.productImportName ? responseData.productImportName : "NA"

                      responseData.linkUpCounts = responseData.linkUpCounts ? responseData.linkUpCounts:"0"
                   responseData.nonLinkUpCounts = responseData.nonLinkUpCounts ? responseData.nonLinkUpCounts:"0"

                    this.onUpdateState({
                        productDataSyncDetail: responseData
                    });
                }
                this.onUpdateState({
                    "setIsLoad": false
                });
                break;
            case "getLogsByProductDataId":
                if (responseData !== null) {
                    this.onUpdateState({
                        logsList: responseData
                    });
                }
                this.onUpdateState({
                    "setIsLoad": false,
                });
                break;
            case "getProductDataOutputFileById":
                if (responseData.length > 0) {
                    this.onUpdateState({
                        outputFileList: responseData
                    });
                }

                this.onUpdateState({
                    "setIsLoad": false,
                });
                break;
                case "getAllStatus":
                    let itemListData = responseData;
            
                    if (itemListData !== null) {
                        const stutusType = itemListData.map(item => ({
                            id: item.statusId,
                            name: item.statusName,
                        }));
    
                        const statuslist = [
                            { id: 0, name: 'All' },
                            ...stutusType,
                        ];
    
                        this.onUpdateState({
                            setStatusList: stutusType,
                            setStatuslists: statuslist,
                            "setIsLoad": false,
                        });
                    }
                    break;
                    case "deleteProductDataSyncByProductDataSyncId":
                   
                        if (responseData !== null) {
                            this.apiService.serviceManager.swalServices.Success(SuccessMessage.Delete_Success.replace("{0}", "Record"));
                            this.onGetAllProductDataSync(this.pagination);
                        }
                        this.onUpdateState({ "setIsLoad": false });
                        break;
                default:
                    // Handle default case here
                    break;
                   
        }
    }

}
