
import { axiosGet, axiosGetFileAuthorizeblob, axiosGetMultiParams, axiosPost, axiosPostAuthorize } from '../AxiosRequests';
//*** Lib */
import {
    AddProductDataSync, GetAllProductDataSync,ExportProductSyncAnalyzer,GetAllStatus, DeleteProductDataSyncByProductDataSyncId,GetAllProductDataSyncImportType, GetProductDataSyncById, GetLogsById, GetProductDataSyncOutputFileById, AddProductDataAutoSync
} from '../ApiEndPoints';

export default class ProductDataSyncServices {

    async getAllProductDataSync(request) {
        return axiosPost(GetAllProductDataSync, request);
    }

    async addProductDataAutoSync(request) {
        return axiosPost(AddProductDataAutoSync, request);
    }

    async getAllProductDataSyncImportType(request) {
        return axiosGet(GetAllProductDataSyncImportType, request);
    }

    async getProductDataSyncById(request) {
        return axiosGet(GetProductDataSyncById, request);
    }

    async exportProductSyncAnalyzer(request) {
        return axiosGetFileAuthorizeblob(ExportProductSyncAnalyzer, request);
    }
    async getProductDataSyncOutputFileById(request) {
        return axiosGet(GetProductDataSyncOutputFileById, request);
    }
    async deleteProductDataSyncByProductDataSyncId(request) {
        return axiosPost(DeleteProductDataSyncByProductDataSyncId, request);
    }
    async getLogsById(request) {
        return axiosGetMultiParams(GetLogsById, request);
    }
    async getAllStatus(request) {
        return axiosGet(GetAllStatus, request);
    }
    async addProductDataSync(request) {
        let formData = new FormData();
        formData.append("FileName", request.FileName);
        formData.append("File", request.File);
        formData.append("Name", request.Name);
        formData.append("Mode", request.Mode);
        formData.append("ProductDataSyncImportTypeId", request.ProductDataSyncImportTypeId);
        formData.append("CreatedBy", request.CreatedBy);
        return axiosPostAuthorize(AddProductDataSync, formData, true, request);
    }
}

