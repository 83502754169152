/* Component  */
import { axiosGet, axiosPost, axiosPostAuthorize } from '../AxiosRequests';
import { GetProductDataImportById, GetApplicationsList, ProductDataImportList, AddProductDataImport } from '../ApiEndPoints';

export default class BingoProductImportService {
    //** API function

    async addProductDataImport(request) {
        let formData = new FormData();
        formData.append("ImportTitle", request.importTitle);
        formData.append("AppId", request.appId);
        formData.append("File", request.file);
        formData.append("IntialFileName", request.intialFileName);
        formData.append("CreatedBy", request.createdBy);

        return axiosPostAuthorize(AddProductDataImport, formData, true, request);
    }

    async getProductDataImportList(request) {
        return axiosPost(ProductDataImportList, request);
    }

    async getProductDataImportById(request) {
        return axiosGet(GetProductDataImportById, request);
    }

    async getApplicationsList(request) {
        return axiosGet(GetApplicationsList, request);
    }
}
