import { getValidationRuleList } from '../../utils/validation/generateValidationRule';
import BaseDomainService from './baseDomainServices';
import { SuccessMessage } from "../../utils/Messages";
import { decryptionData } from '../../utils/Encryption';

const searchHistoryList = {
    searchText: ""
}
let onInitAddScriptDetails = {
    inputFile: null,
    scriptTitle: "",
    userId: "",
    supplierID: "",
    fileName: null,
    currentVersion: "V_",
    configureType: "",
    active: true
}

let onInitAddVersionDetails = {
    inputVersionFile: null,
    currentVersion: "V_",
    // fileName: null,
    // currentVersion: "",
    // configureType: "",
    // active: false
}



export default class CodeManagementServices extends BaseDomainService {
    addCodeManagement = this.apiService.codeManagementService.addCodeManagement;
    updateScript = this.apiService.codeManagementService.updateScriptData;
    getAllCodeManagementList = this.apiService.codeManagementService.getAllCodeManagementList;
    getCodeManagementById = this.apiService.codeManagementService.getCodeManagementById;
    getAllVersionByCodeManagementId = this.apiService.codeManagementService.getAllVersionByCodeManagementId;
    addVersion = this.apiService.codeManagementService.addVersion;
    delete = this.apiService.codeManagementService.deleteScriptVersion;
    deleteCodeManagementScript = this.apiService.codeManagementService.deleteCodeManagementScript;
    isActiveVersion = this.apiService.codeManagementService.activeVersion;
    supplierList = this.apiService.manageSupplierServices.getAllSupplierNameList;


    downloadScriptFile

    // Define the state variables
    dataItem;
    //Table Grid columns
    gridColmns = [
        { name: 'fileName', title: 'File Name' },
        { name: 'currentVersion', title: 'Version' },
        { name: 'configureType', title: 'Type' },
        { name: 'scriptName', title: 'Script Name' },
        // { name: 'active', title: 'Is Active' },
        { name: 'codeManagementId', title: 'Action' },
    ];

    gridColmnsVersion = [
        { name: 'fileName', title: 'File Name' },
        { name: 'currentVersion', title: 'Current Version' },
        { name: 'versionId', title: 'Action' }
    ]
    SearchformFields = [
        {
            inputType: "search",
            placeholder: "Search ...",
            fieldName: "searchText",
            labelStyleName: 'none'
        },
    ]

    formFields = [
        {
            inputType: "text",
            tittle: "Script Title",
            placeholder: "Script Title",
            fieldName: "scriptTitle",
            className: 'col-md-6',
            validation: [{ type: "require" }]
        },
        {
            inputType: "file",
            tittle: "Script File",
            placeholder: "Input File",
            fieldName: "fileName",
            className: 'col-md-6',
            validation: [{ type: "require" }]
        },
        {
            inputType: "select",
            tittle: "Supplier Name",
            placeholder: "Select supplier Name",
            fieldName: "supplierID",
            isDefault: true,
            options: [],
            className: 'col-md-6',
            validation: [{ type: "require" }]
        },
        {
            inputType: "text",
            tittle: "Script File",
            placeholder: "Input File",
            fieldName: "fileName",
            className: 'col-md-6',
            validation: [{ type: "require" }]
        },
        {
            inputType: "text",
            tittle: "Current Version",
            placeholder: "Current Version",
            fieldName: "currentVersion",
            className: 'col-md-6',
            validation: [{ type: "require" }]

        },
        {
            inputType: "select",
            tittle: "Configure Type",
            placeholder: "Please select Configure Type",
            fieldName: "configureType",
            isDefault: true,
            Options: [
                { name: "Scrapper", id: "1" },
                { name: "Cleaner", id: "2" },
                { name: "CatalogCleaner", id: "3" },
                { name: "LinkBuilder", id: "4" },
            ],
            className: 'col-md-6',
            validation: [{ type: "require" }]

        },
        {
            inputType: "checkbox",
            tittle: " Active?",
            fieldName: "active",
            checked: false,
            partialrequest: true,
            className: 'col-md-4 mt-4'
        }
    ];

    addversionFormFields = [
        {
            inputType: "file",
            tittle: "Script File",
            placeholder: "Input File",
            fieldName: "inputVersionFile",
            className: 'col-md-12',
            validation: [{ type: "require" }]
        },
        {
            inputType: "text",
            tittle: "Add Version",
            placeholder: "Add Version",
            fieldName: "currentVersion",
            className: 'col-md-12',
            validation: [{ type: "require" }]

        },
    ];

    addScriptDetails;

    addVersionDetails;

    constructor() {
        super();
        this.onPaginationUpdate = this.onGetCodeManagementList.bind(this);
        this.addScriptDetails = { ...onInitAddScriptDetails }
        this.addVersionDetails = { ...onInitAddVersionDetails }
        this.actionKey = "codeManagementId";
        this.actionId = "versionId";
        this.actionActive = "active";
        this.activeVestionAction = "versionId";
        this.dataItem = { ...searchHistoryList };
        this.activeCheck = false;

    }
    onAddCodeManagement = (request) => {
        this.apiService.onAPICall("addCodeManagement", this.addCodeManagement, request, this.onAPIResponse, this.onUpdateState);
    }
    onUpdateScript = (request) => {
        this.apiService.onAPICall("updateScript", this.updateScript, request, this.onAPIResponse, this.onUpdateState);
    }
    // API 
    onGetCodeManagementList = (requst) => {
        this.apiService.onAPICall("getAllCodeManagementList", this.getAllCodeManagementList, requst, this.onAPIResponse, this.onUpdateState);
    }

    onGetAllVersionByCodeManagementId = (requst) => {
        this.apiService.onAPICall("getAllVersionByCodeManagementId", this.getAllVersionByCodeManagementId, requst, this.onAPIResponse, this.onUpdateState);
    }
    onGetCodeManagementById = (requst) => {
        this.apiService.onAPICall("getCodeManagementById", this.getCodeManagementById, requst, this.onAPIResponse, this.onUpdateState);
    }
    onAddVersion = (request, getRequest) => {

        this.apiService.onAPICall("addVersion", this.addVersion, request, this.onAPIResponse, this.onUpdateState, getRequest);
    }
    onDelete = (request) => {
        this.apiService.onAPICall("delete", this.delete, request, this.onAPIResponse, this.onUpdateState);
    }
    onDeleteCodeManagementScript = (request) => {
        this.apiService.onAPICall("deleteCodeManagementScript", this.deleteCodeManagementScript, request, this.onAPIResponse, this.onUpdateState);
    }
    onIsActiveVersion = (request, codeManagementId) => {
        this.apiService.onAPICall("isActiveVersion", this.isActiveVersion, request, this.onAPIResponse, this.onUpdateState, codeManagementId);
    }
    onGetSupplierList = (request) => {
        this.apiService.onAPICall("supplierList", this.supplierList, request, this.onAPIResponse, this.onUpdateState);
    }

    //Init Services For Validation 
    onInitService() {
        let valRule = getValidationRuleList(this.formFields);
        this.validationRules = valRule;
        this.validationRulesForType = valRule
        this.onUpdateState({
            "validationRules": valRule,
            "resetValidationRule": this.resetValidationRule,
        });
    }
    onInitAddVersionScriptService() {
        let valRule = getValidationRuleList(this.addversionFormFields);
        this.validationRules = valRule;
        this.validationRulesForType = valRule
        this.onUpdateState({
            "validationRules": valRule,
            "resetValidationRule": this.resetValidationRule,
        });
    }

    //Reset Data & Validation
    onResetVersionData = () => {
        this.dataItem = { ...onInitAddVersionDetails }
        let resetValidation = {
            isValid: false,
            error: {}
        }
        this.onUpdateState({
            addVersionDetails: this.dataItem,
            show: false,
            validState: resetValidation
        });
    }

    onResetData = () => {
        this.dataItem = { ...onInitAddScriptDetails }
        let resetValidation = {
            isValid: false,
            error: {}
        }
        this.onUpdateState({
            addScriptDetails: this.dataItem,
            validState: resetValidation
        });
    }
    onRedirectoList = () => {
        this.onRedirect('/CodeManagement');
    };
    // API Responces
    onAPIResponse = (key, isSuccess, responseData, getRequest) => {
        if (!isSuccess) {
            this.onUpdateState({ "setIsLoad": false });
            return;
        }
        switch (key) {
            case "addCodeManagement":
                this.apiService.serviceManager.swalServices.Success(SuccessMessage.Add_Success.replace("{0}", "Script File"));
                this.onUpdateState({ "setIsLoad": false });
                this.onResetData();
                this.onRedirect('/CodeManagement');
                break;
            case "updateScript":
                this.onUpdateState({ "setIsLoad": false });
                this.apiService.serviceManager.swalServices.Success(SuccessMessage.Update_Success.replace("{0}", "Script"));
                break;

            case "getAllCodeManagementList":
                let itemList = responseData.itemList;
                itemList.forEach(function (i) {
                    i.configureType =
                        i.configureType === 1 ? "Scrapper" :
                            i.configureType === 2 ? "Cleaner" :
                                i.configureType === 3 ? "CatalogCleaner" :
                                    i.configureType === 4 ? "LinkBuilder" :
                                        null
                });
                this.onUpdateState({
                    "getAllCodeManagementList": responseData.itemList,
                    "setIsLoad": false,
                    "TotalCount": responseData.totalCount,
                });
                break;

            case "getCodeManagementById":
                let userDet = ({ ...responseData });
                this.dataItem = { ...userDet };
                this.onUpdateState({
                    addScriptDetails: {
                        codeManagementId: userDet.codeManagementId,
                        fileName: userDet.fileName,
                        currentVersion: userDet.currentVersion,
                        configureType: userDet.configureType,
                        active: userDet.active,
                        CreatedBy: userDet.userId,
                        scriptTitle: userDet.scriptName,
                        supplierID: userDet.supplierID
                    },
                    updateUser: userDet.codeManagementId,
                    "setIsLoad": false,
                    "IsUpdatedMode": true
                });
                break;

            case "addVersion":

                this.onUpdateState({
                    "setIsLoad": false,
                    "show": false
                });
                this.onGetAllVersionByCodeManagementId(getRequest);
                this.onResetVersionData();
                break;

            case "getAllVersionByCodeManagementId":
                this.onUpdateState({
                    "dataItems": responseData.itemList,
                    "TotalCount": responseData.totalCount,
                    "show": false,
                    "setIsLoad": false
                });
                break;
            case "deleteCodeManagementScript":
                this.apiService.serviceManager.swalServices.Success(SuccessMessage.Delete_Success.replace("{0}", "Script"));
                this.onUpdateState({ "setIsLoad": false });
                this.onGetCodeManagementList(this.pagination);
                break;
            case "delete":
                this.apiService.serviceManager.swalServices.Success(SuccessMessage.Delete_Success.replace("{0}", "Script Version"));
                this.onUpdateState({ "setIsLoad": false });
                let params = new URLSearchParams(window.location.search);
                let ManagemenId = params.get("");
                const decryptKey = decryptionData(ManagemenId, false);
                let request = {
                    ...this.pagination,
                    codeManagementId: decryptKey
                }
                this.onGetAllVersionByCodeManagementId(request);
                break;

            case "isActiveVersion":
                let paramsURL = new URLSearchParams(window.location.search);
                let cmId = paramsURL.get("");
                let decryptKeycmId = decryptionData(cmId, false);
                let requestData = {
                    ...this.pagination,
                    codeManagementId: decryptKeycmId
                }
                if (responseData.hasError === false) {
                    this.apiService.serviceManager.swalServices.Success(SuccessMessage.Active_version);
                    this.onGetAllVersionByCodeManagementId(requestData);
                    this.onGetCodeManagementById(decryptKeycmId);
                }
                break;
            case "supplierList":
                let supplierData = responseData;
                let newDataList = supplierData?.map((data) => ({
                    id: data.supplierID,
                    name: data.supplierName
                }));
                this.onUpdateState({
                    "setOperationalList": newDataList,
                    "setIsLoad": false
                });
                break;
                default:
                    // Handle default case here
                    break;
        }
    }
}

