import React from 'react';
import { Link } from 'react-router-dom';
import { Bars } from 'react-loader-spinner';
//** Lib's */
import Table from '../../../components/table/Table';
import BaseComponent from "../../../shared/baseComponent";

//** Service's */
import RegulationDomainService from '../../../services/domainServices/regulationDomainService';
import { encryptionData } from '../../../utils/Encryption';
import FormFieldGroup from '../../../components/UI Element/FormGrop/FormFieldGroup';

export default class ManageRegulationRequest extends BaseComponent {

    constructor(props) {
        super(props);
        this.domainServices = new RegulationDomainService();
        this.state = {
            getList: [],
            setIsLoad: false,
            pagination: this.domainServices.pagination,
            formDetail: this.domainServices.dataItem,
        };
        this.domainServices.onUpdateState = this.updateState.bind(this);
        this.domainServices.onActionCall = this.onActionCall.bind(this);
    }

    handleChange(name, value) {
        let detail = this.state.pagination;
        detail[name] = value.trim();
        let data = { ...this.state.pagination };
        data.pageNo = 1;
        this.domainServices.onGetRegulationRequestList(data);
        this.setState({ pagination: { ...data } });
    }

    componentDidMount() {
        this.domainServices.onGetRegulationRequestList(this.state.pagination);
    }

    //** Action Method's */
    onActionCall = (type, data) => {
        const encryptKey = encryptionData(data, 1);
        switch (type) {
            case "View":
                window.open(`/RegulationRequestDetails/id?=${encryptKey}`, "_blank");
                break;
            default:
                // Handle default case here
                break;
        }
    };

    render() {
        return (
            <div>
                <div className="top-search-add-button-sec mb-3">
                    <div className="row left-searchbar-sec">
                        {this.domainServices.formFieldsSearch.map((Item, index) => {
                            return (
                                <>
                                    {/* {Item.fieldName === "searchText" ? ( */}
                                    <FormFieldGroup
                                        className="col-md-6"
                                        column={Item}
                                        value={this.state.pagination[Item.fieldName]}
                                        options={Item.Options ? Item.Options : this.state.setModuleList}
                                        onChange={(event) =>
                                            this.handleChange(Item.fieldName, event.target.value)
                                        }
                                        key={index}
                                    />
                                    {/* ) : null} */}
                                </>
                            );
                        })}
                    </div>
                    <div className="right-button-sec">
                        <Link to="/AddRegulationRequest" className="tab-title btn-hover color-3 active" >
                            Add<span>+</span>
                        </Link>
                    </div>
                </div>
                <div className='regulation-list'>
                    {this.state.setIsLoad ? (
                        <Bars
                            className="row"
                            wrapperStyle={{ marginTop: "150px" }}
                            color="#3c4b64"
                            height={55}
                            width="100%"
                            visible={this.state.setIsLoad}
                        />
                    ) : (
                        <Table
                            columns={this.domainServices.regulationRequestGridColmns}
                            rows={this.state.getList}
                            sortingColumns={[]}
                            pagination={this.state.pagination}
                            totalCount={this.state.TotalCount}
                            totalResultes={this.state.TotalCount}
                            setPagination={this.domainServices.onPageChange.bind(this)}
                            customScope={[
                                {
                                    column: "regulationRequestId",
                                    renderTableData: this.domainServices.gridRegulationActionsViewCol.bind(this),
                                }
                            ]}
                        />
                    )}
                </div>
            </div>
        )
    }
}