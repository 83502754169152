//** Component  */
import BaseComponent from "../../shared/baseComponent";
import Table from "../../components/table/Table";

//** Services */
import UserDomainServices from "../../services/domainServices/userServices";

//*** Lib */
import { Navigate } from "react-router";
import { Bars } from "react-loader-spinner";
import { encryptionData } from "../../../src/utils/Encryption";
import { Link } from "react-router-dom";

export default class ManageUsers extends BaseComponent {
  constructor(props) {
    super(props);
    this.userServices = new UserDomainServices();
    this.state = {
      pagination: this.userServices.pagination,
      setIsLoad: false,
      dataList: [],
    };
    this.userServices.onUpdateState = this.updateState.bind(this);
    this.userServices.onActionCall = this.onActionCall.bind(this);
  }
  //** Constructor  and Component method */
  componentDidMount() {
    this.userServices.onGetUsers(this.state.pagination);
  }

  // *** Other function **//

  onActionCall = (type, data) => {
    // *** getByID encryption ***//
    const encryptKey = encryptionData(data, 1);

    switch (type) {
      case "Edit":
        window.open("/AddNewUsers/id?=" + encryptKey, "_blank");
        break;
      default:
        // Handle default case here
        break;
    }
  };

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <>
        <div className="">
          <div className="top-search-add-button-sec mb-3">
            <div className="row left-searchbar-sec"></div>
            <div className="right-button-sec">
              <Link
                to="/AddNewUsers"
                className="tab-title btn-hover color-3 active"
              >
                Add New User <span>+</span>
              </Link>
            </div>
          </div>
          <div className="manage-user-list">
          {this.state.setIsLoad ? (
            <Bars
              className="row"
              wrapperStyle={{ marginTop: "150px" }}
              color="#3c4b64"
              height={55}
              width="100%"
              visible={this.state.isLoading}
            />
          ) : (
            <Table
              columns={this.userServices.gridColmns}
              rows={this.state.dataList}
              sortingColumns={[]}
              pagination={this.state.pagination}
              totalCount={this.state.TotalCount}
              totalResultes={this.state.TotalCount}
              setPagination={this.userServices.onPageChange.bind(this)}
              customScope={[
                {
                  column: "userId",
                  renderTableData: this.userServices.gridActionsCol.bind(this),
                },
              ]}
            />
          )}
          </div>
        </div>
      </>
    );
  }
}
