
//*** Lib */
import { encryptionAPI } from '../../utils/Encryption';
import { APIURL, SSOAPIURL } from './ApiEndPoints';
import { axiosInstance, axiosInstanceWithoutEnrypt } from '../../utils/AxiosInterceptor';
import { IsEncryption, IsProdMode, IsTestMode } from '../../utils/AppSetting';
import { getAuthProps } from '../../utils/AuthenticationLibrary';
import { ssoAxiosInstance } from '../../utils/SSOAxiosInterceptor';

const ClientID = (IsProdMode) ? process.env.REACT_APP_CLIENTID : (IsTestMode) ? process.env.REACT_APP_Test_CLIENTID : process.env.REACT_APP_Test_CLIENTID;
const SecretKey = (IsProdMode) ? process.env.REACT_APP_SECRETKEY : (IsTestMode) ? process.env.REACT_APP_Test_SECRETKEY : process.env.REACT_APP_Test_SECRETKEY;

export function axiosPost(url, request) {
    var data = { data: (IsEncryption) ? encryptionAPI(request, 1) : request };
    return axiosInstance.post(APIURL + url, data);
}
export function axiosPostWithoutEncryption(url, request, isFormData) {
    if (isFormData) {
        let headers = {
            'content-type': 'multipart/form-data',
        };
        return axiosInstance.post(APIURL + url, request, { headers });
    }
    return axiosInstance.post(APIURL + url, request);
}

//** POST Authorize */
export function axiosPostAuthorize(url, request, isFormData) {
    let loginUser = getAuthProps();
    if (loginUser) {
        var data = { data: (IsEncryption) ? encryptionAPI(request, 1) : request };
        if (isFormData) {
            let headers = {
                Authorization: `Bearer ${loginUser.token.token}`,
                'content-type': 'multipart/form-data',
            };
            return axiosInstance.post(APIURL + url, request, { headers });
        }
        let headers = { Authorization: `Bearer ${loginUser.token.token}` };
        return axiosInstance.post(APIURL + url, data, { headers });
    }
    else {
        window.location.href = "/";
    }
}

export function axiosGetAuthorize(url, param) {
    let loginUser = getAuthProps();
    if (loginUser) {
        let headers = { Authorization: `Bearer ${loginUser.token.token}` };
        return axiosInstance.get(APIURL + url.replace("{0}", (IsEncryption) ? encryptionAPI(param, 0) : param), { headers });
    }
    else {
        window.location.href = "/";
    }
}

export function axiosGet(url, param) {
    return axiosInstance.get(APIURL + url.replace("{0}", (IsEncryption) ? encryptionAPI(param, 0) : param));
}

export function axiosWithoutEncryptionGet(url, param) {
    return axiosInstance.get(APIURL + url.replace("{0}", param));
}

export function axiosGetMultiParams(url, params) {
    params.forEach((value, key) => {
        url = url.replace("{" + key + "}", (IsEncryption) ? encryptionAPI(value, 0) : value)
    });
    return axiosInstance.get(APIURL + url);
}

export function axiosGetFileAuthorizeblob(url, params) {
    let loginUser = getAuthProps();
    if (loginUser) {
        if (loginUser.token.token) {
            // params.forEach((value) => {
            url = url.replace("{0}", params)
            // });

            let headers = { Authorization: `Bearer ${loginUser.token.token}` };
            return axiosInstanceWithoutEnrypt.get(APIURL + url, { headers, responseType: 'blob' });
        }
        else {
            window.location.href = "/";
        }
    }
    else {
        window.location.href = "/";
    }
}

export function axiosPostSSO(url, request) {
    var data = { data: (IsEncryption) ? encryptionAPI(request, 1) : request };
    const headers = {
        'ClientId': ClientID,
        'secretkey': SecretKey
    };
    return ssoAxiosInstance.post(SSOAPIURL + url, data, { headers });
}

export function axiosGetUserDetailsAuthorize(url, param) {
    let headers = {
        'ClientId': ClientID,
        'secretkey': SecretKey
    };
    return ssoAxiosInstance.get(SSOAPIURL + url.replace("{0}", (IsEncryption) ? encryptionAPI(param, 0) : param), { headers });
}