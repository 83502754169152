//** Component  */
import React from 'react';
import BaseComponent from '../../shared/baseComponent';
//** Services */
import serverDomainServices from '../../services/domainServices/serverServices';
//*** Lib */
import { Navigate } from 'react-router';
import Button from '../../components/common/Button';
import ButtonLoader from '../../components/common/buttonLoader/ButtonLoader';
import FormFieldGroup from '../../components/UI Element/FormGrop/FormFieldGroup';
import { decryptionData } from '../../../src/utils/Encryption';

export default class AddServerConfigurations extends BaseComponent {
    constructor(props) {
        super(props)
        this.serverService = new serverDomainServices();
        this.serverService.onUpdateState = this.updateState.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            ...this.state,
            pagination: this.serverService.pagination,
            updateServer: "",
            addServerDetails: this.serverService.dataItem,
            serverReset: this.serverService.serverResetItem,
            serverOptionList: [],
            isLoad: false,
            setIsLoad: false
        }
    }
    //** Constructor  and Component method */
    componentDidMount() {
        this.serverService.onInitService();
        let params = new URLSearchParams(window.location.search);
        let serverId = params.get("");

        if (serverId !== null && serverId !== undefined) {
            // *** getByID encryption ***//
            const decryptKey = decryptionData(serverId, false);
            if (decryptKey > 0) {
                this.serverService.onGetByid(decryptKey);
            }
        }
        this.serverService.onGetAllServerList(this.state.pagination);
    }

    // *** Event function **//
    handleChange(name, value) {
        let detail = { ...this.state.addServerDetails };
        detail[name] = value;

        if (name === "hostServerId") {
            let found = this.state.serverOptionList.find((x) => x.id === parseInt(value));
            if (found) {
                if (found.serverLocation === 1) {
                    detail.serverLocation = "USA";
                } else if (found.serverLocation === 2) {
                    detail.serverLocation = "INDIA";
                } else {
                    detail.serverLocation = "";
                }
                detail.serverIP = found.serverIP ? found.serverIP : "";
            }
            this.setState({ addServerDetails: { ...detail } });
        }
    }
    //** API  function */

    add = () => {
        if (this.serverService.isValidSubmit(this.state.addServerDetails, this.state.validationRules, this.state.validState)) {
            let request = {
                serverName: this.state.addServerDetails.serverName,
                serverPort: this.state.addServerDetails.serverPort,
                serverIP: this.state.addServerDetails.serverIP,
                isActive: this.state.addServerDetails.isActive,
                operatingSystem: this.state.addServerDetails.operatingSystem,
                ClientTypeId: this.state.addServerDetails.clientTypeId,
                ClientLocationId: this.state.addServerDetails.clientLocationId,
                parentServerIP: this.state.addServerDetails.parentServerIP,
                serverLocation: this.state.addServerDetails.serverLocation,
                middlewareServerIP: this.state.addServerDetails.middlewareServerIP,
                isVM: this.state.addServerDetails.isVM,
                isVPN: this.state.addServerDetails.isVPN,
                hostServerId: this.state.addServerDetails.hostServerId,
            }
            this.serverService.onAdd(request);
        }
    }

    updateServerConfigurationType = () => {
        let params = new URLSearchParams(window.location.search);
        let serverId = params.get("");
        if (this.serverService.isValidSubmit(this.state.addServerDetails, this.state.validationRules, this.state.validState)) {
            let request = {
                serverId: serverId,
                serverName: this.state.addServerDetails.serverName,
                serverPort: this.state.addServerDetails.serverPort,
                serverIP: this.state.addServerDetails.serverIP,
                isActive: this.state.addServerDetails.isActive,
                operatingSystem: this.state.addServerDetails.operatingSystem,
                clientTypeId: this.state.addServerDetails.clientTypeId,
                clientLocationId: this.state.addServerDetails.clientLocationId,
                parentServerIP: this.state.addServerDetails.parentServerIP,
                serverLocation: this.state.addServerDetails.serverLocation,
                middlewareServerIP: this.state.addServerDetails.middlewareServerIP,
                isVM: this.state.addServerDetails.isVM,
                isVPN: this.state.addServerDetails.isVPN,
                hostServerId: this.state.addServerDetails.hostServerId
            }
            this.serverService.onUpdate(request);
        }
    }
    render() {
        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} />
        }
        return (
            <div>
                {/* {redirect != null ? <Navigate to={redirect} /> : ""} */}

                <div className="mb-4">
                    <div className="accordion" id="accordionExample">
                        <div className="card accordion-item active">
                            <div
                                id="accordionOne"
                                className="accordion-collapse collapse show"
                                data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className="card-body">
                                        <div>
                                            <div className="row">
                                                {this.serverService.formFields.map((Item, index) => {
                                                    return (
                                                        <>
                                                            <FormFieldGroup
                                                                column={Item}
                                                                value={this.state.addServerDetails[Item.fieldName]}
                                                                error={this.state.validState.error[Item.fieldName]}
                                                                checked={Item.checked === true ? this.state.addServerDetails[Item.fieldName] : null}
                                                                onChange={Item.inputType === 'checkbox' ?
                                                                    (event) => this.handleChange(Item.fieldName, event.target.checked)
                                                                    : (event) => this.handleChange(Item.fieldName, event.target.value)}
                                                                options={Item.Options ? Item.Options :
                                                                    Item.fieldName === 'hostServerId' ? this.state.serverOptionList : null}
                                                                onKeyDown={Item.onkeydown ? Item.onkeydown.bind(this) : null}
                                                                onBlur={() => this.serverService.validateField(Item.fieldName, this.state.addServerDetails, this.state.validationRules, this.state.validState)}
                                                            />
                                                        </>)
                                                })}
                                                <div className="col-md-12 addnewuser-button">
                                                    {this.state.updateServer ?

                                                        <button className="tab-title tab-title--active ml-2 btn-hover color-3 active" onClick={() => this.updateServerConfigurationType()}>
                                                            {this.state.setIsLoad ? <ButtonLoader /> : "Update "}      </button>
                                                        :
                                                        <button className="tab-title tab-title--active ml-2 btn-hover color-3 active" onClick={() => this.add()}>
                                                            {this.state.setIsLoad ? <ButtonLoader /> : "Save "}      </button>

                                                    }
                                                    {this.state.updateServer ?
                                                        <Button type='cancel' title="Cancel" className="btn-hover color-3 tab-title ml-2" onClick={() => this.serverService.onRedirectoList()} />
                                                        :
                                                        <Button type='cancel' title="Cancel" className="btn-hover color-3 tab-title ml-2" onClick={() => this.serverService.onViewRequestDetail()} />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}