//** Component  */
import React from 'react'

//** Services */
import { DownloadInputFile } from '../../services/axiosServices/ApiEndPoints';
import RequestDomainServices from '../../services/domainServices/requestServices';

//*** Lib */
import BaseComponent from '../../shared/baseComponent';
import TabProgressBarStyle from '../../styles/TabProgressBarStyle';
import { decryptionData } from '../../utils/Encryption';
import axios from 'axios';
import FileViewer from 'react-file-viewer';
import "../../scss/scrollTable.scss"
import { Modal } from 'react-bootstrap';

export default class RequestScrapping extends BaseComponent {
    constructor(props) {
        super(props)
        this.requestServices = new RequestDomainServices();
        this.requestServices.onUpdateState = this.updateState.bind(this);
        this.state = {
            productsCounts: [],
            showModal: false,
            fileToView: null,
            fileContent: null,
            setIsLoad: false
        }
    }

    //** Constructor  and Component method */
    componentDidMount() {
        let params = new URLSearchParams(window.location.search);
        let reqId = params.get("");
        const decryptKey = decryptionData(reqId, false);
        if (reqId > 0) {
            this.requestServices.onGettingCountById(decryptKey);
        }
    }

    handleClose = () => {
        this.setState({ showModal: false })
        this.setState({ fileToView: null });
    }

    previewFile = () => {
        const downloadUrl = `${DownloadInputFile}?FileName=${encodeURIComponent(this.props.productsDetails.scraperFile)}&ModuleType=Scrapping`;
        // const downloadUrl = `https://localhost:44368/api/File/GetFile?FileName=${encodeURIComponent(this.props.productsDetails.scraperFile)}&ModuleType=Scrapping`;
        this.setState({ setIsLoad: true });
        axios.get(downloadUrl, { responseType: 'blob' })
            .then(response => {
                this.setState({ fileToView: response.data });
                this.setState({ showModal: true });
            })
            .catch(error => {
                console.error('Error downloading the file:', error);
            });
    };

    previewFileError = () => {
        const downloadUrl = `${DownloadInputFile}?FileName=${encodeURIComponent(this.props.productsDetails.invalidURL)}&ModuleType=Scrapping`;
        this.setState({ setIsLoad: true });
        axios.get(downloadUrl, { responseType: 'blob' })
            .then(response => {
                this.setState({ fileToView: response.data });
                this.setState({ showModal: true });
            })
            .catch(error => {
                console.error('Error downloading the file:', error);
            });
    };

    render() {
        const { showModal } = this.state
        return (
            <>
                <TabProgressBarStyle />
                <div className="card">
                    <div className='card-title-main px-3 py-2'>
                        <h6 className='titletexts font-size-16'>Scrapping Files</h6>
                    </div>
                    <div className="card-body custom-card-body">
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <div className='inner-detail-sec'>
                                    <span className='detail-title'>Scrapped Files</span>
                                    {this.props.productsCounts ? <>
                                        <span className='detail-desc'>{this.props.productsCounts.scrappedCount > 0 && this.props.productsDetails.scraperFile ? this.props.productsCounts.scrappedCount
                                            :
                                            null
                                        }
                                        </span>
                                        {this.props.productsDetails.scraperFile && this.props.productsCounts.scrappedCount > 0 ?
                                            <span for="requestName" className="detail-desc ml-3">
                                                <a href={`${DownloadInputFile + `?FileName=${this.props.productsDetails.scraperFile}&ModuleType=Scrapping`}`} target="_top"> <i className="fa fa-download"></i></a>
                                                <img src="/csv.png" className="csv-file ml-2 cursor-pointer" title="Preview" alt="Text icon" onClick={this.previewFile} />
                                            </span>

                                            :

                                            <span className='detail-desc'>
                                                No Record found
                                            </span>
                                        }

                                    </>
                                        : <span className='detail-desc'>No Record found</span>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <div className='inner-detail-sec'>
                                    <span className='detail-title'>File with Errorurl</span>
                                    {this.props.productsCounts ? <>
                                        <span className='detail-desc'>{this.props.productsCounts.errorUrlCount > 0 && this.props.productsDetails.invalidURL ? this.props.productsCounts.errorUrlCount : null}</span>
                                        {this.props.productsDetails.invalidURL && this.props.productsCounts.errorUrlCount > 0 ?
                                            <span for="requestName" className="detail-desc ml-3">
                                                <a href={`${DownloadInputFile + `?FileName=${this.props.productsDetails.invalidURL}&ModuleType=Scrapping`}`} target="_top"> <i className="fa fa-download"></i></a>
                                                <img src="/csv.png" className="csv-file ml-2 cursor-pointer" title="Preview" alt="Text icon" onClick={this.previewFileError} />
                                            </span>
                                            :
                                            <span className='detail-desc'>
                                                No Record found
                                            </span>
                                        }
                                    </>
                                        : <span className='detail-desc'>No Record found</span>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="regulation-card">
                    {this.state.showModal &&
                        <div className="card-regulation-popup">
                            {this.state.fileToView && (
                                <>
                                    <i className="fa fa-close cursor-pointer regulation-close" onClick={this.handleClose}></i>
                                    <div>
                                        <FileViewer
                                            fileType="csv" // Set the file type to csv
                                            filePath={URL.createObjectURL(this.state.fileToView)}
                                            onError={error => console.error('Error:', error)}
                                        />
                                    </div>
                                </>
                            )
                            }
                        </div>
                    }
                </div> */}
                <Modal className="scrolling-model" show={showModal} onHide={this.handleClose}>
                    <Modal.Header closeButton >
                        <Modal.Title>File Preview</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <div className="RequestDetails-modal-file">
                            {this.state.fileToView && (
                                <>
                                    <div>
                                        <FileViewer
                                            fileType="csv" // Set the file type to csv
                                            filePath={URL.createObjectURL(this.state.fileToView)}
                                            onError={error => console.error('Error:', error)}
                                        />
                                    </div>
                                </>
                            )
                            }
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}
