import React from 'react'
import UnAuthorized from '../pages/authentication/UnAuthorized'
import { IsAuthorizeUser } from '../utils/AuthenticationLibrary';

const AuthorizationWrap = (props) => {
    return (
        <>
            {
                (IsAuthorizeUser(props.authorizeUserType)) ?
                    props.children :
                    <UnAuthorized></UnAuthorized>
            }
        </>
    );
}

export default AuthorizationWrap