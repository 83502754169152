//** Component  */
import React from 'react';
import BaseComponent from '../../shared/baseComponent';
//** Services */
import serverDomainServices from '../../services/domainServices/serverServices';
//*** Lib */
import { Navigate } from 'react-router';
import FormFieldGroup from '../../components/UI Element/FormGrop/FormFieldGroup';

export default class GetConfiguration extends BaseComponent {
    constructor(props) {
        super(props)
        this.serverService = new serverDomainServices();
    }
    render() {
        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} />
        }
        return (
            <div className="mb-4">
                <div className="accordion" id="accordionExample">
                    <div className="card accordion-item active">
                        <h2 className="accordion-header" id="headingOne">
                            <button
                                type="button"
                                className="accordion-button"
                                data-bs-toggle="collapse"
                                data-bs-target="#accordionOne"
                                aria-expanded="true"
                                aria-controls="accordionOne">
                            </button>
                        </h2>
                        <div id="accordionOne" className="accordion-collapse collapse show"
                            data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <div className="card-body">
                                    <div className="row">
                                        {this.serverService.configformFields.map((Item, index) => {
                                            return (<>
                                                <FormFieldGroup
                                                    column={Item}
                                                    value={this.serverService.configformFields[Item.fieldName]}
                                                    error={this.state.validState.error[Item.fieldName]}
                                                    onKeyDown={Item.onkeydown ? Item.onkeydown.bind(this) : null}
                                                    onBlur={this.validateField.bind(this)}
                                                    disabled={true} />
                                            </>)
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}