
import React, { Component } from 'react';
import { encryptionData } from '../../utils/Encryption';
import { Badge } from 'react-bootstrap';
import RequestGroupDomainServices from '../../services/domainServices/requestGroupServices';
import BaseComponent from '../../shared/baseComponent';
import "../../scss/scrollTable.scss"
import { Bars } from 'react-loader-spinner';

export default class ScrappingReq extends BaseComponent {
  constructor(props) {
    super(props);
    this.requestGroupServices = new RequestGroupDomainServices();
    this.state = {
      logsList: [],
      showModal: false,
      active: 0,
      setIsLoad: false
    };
    this.requestGroupServices.onUpdateState = this.updateState.bind(this);
  }

  toggleTooltip = (data, index) => {
    let req = [
      data,
      'MiddlewareRequestId'
    ]
    this.setState({ active: index });
    this.requestGroupServices.onGetLogsByProductDataId(req);
    this.handleShow()
  };

  handleShow = () => {
    this.setState({ showModal: true })
  }

  handleClose = () => {
    this.setState({ showModal: false })
    this.setState({ logsList: [] })
  }

  onActionCall(type, data) {
    switch (type) {
      case "module":
        window.open(`/RequestDetails/id?=${data}`, '_blank');
        break;
      // case "logs":
      //   let req = [
      //     data,
      //     'MiddlewareRequestId'
      //   ]
      //   this.requestGroupServices.onGetLogsByProductDataId(req);
      //   this.handleShow()
      //   break;
      default:
        break;
    }
  }

  getStatusColorClass(statusName) {
    let textColorClass = "";
    switch (statusName) {
      case "Done":
        textColorClass = "success";
        break;
      case "Open":
        textColorClass = "warning";
        break;
      case "Start":
        textColorClass = "primary";
        break;
      case "InProgress":
        textColorClass = "info";
        break;
      case "Error":
        textColorClass = "danger";
        break;
      case "OnHold":
        textColorClass = "secondary";
        break;
      default:
        textColorClass = "primary";
        break;
    }
    return textColorClass;
  }

  render() {
    return (
      <>
        <div className='card mb-0'>
          <div className="row p-2">
            <div className="col-md-4 mb-2">
              <div className="inner-detail-sec">
                <span className="detail-title">Scrap File</span>
                <span className="font-size-16">{this.props.dataList.fileLength}</span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="inner-detail-sec">
                <span className="detail-title">Done File</span>
                <span className="font-size-16">{this.props.scarpCount}</span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="inner-detail-sec">
                <span className="detail-title">Error File</span>
                <span className="font-size-16">{this.props.errorCount}</span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="inner-detail-sec">
                <span className="detail-title">Number of Request </span>
                <span className="font-size-16">{this.props.dataList.totalRequest}</span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="inner-detail-sec">
                <span className="detail-title">Done Request </span>
                <span className="font-size-16">{this.props.dataList.totalDoneRequest}</span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="inner-detail-sec">
                <span className="detail-title">Erorr Request </span>
                <span className="font-size-16">{this.props.dataList.totalErrorRequest}</span>
              </div>
            </div>
          </div>
        </div>
        <div className='group-table mt-2'>
          <table className="table table-shadow rpa_table bg-white table-fixed">
            <thead>
              <tr className='text-left'>
                <th>Request Name</th>
                <th>Supplier Name</th>
                <th>Module Name</th>
                <th>Status Name</th>
                <th>Scrap Count</th>
                <th>Error Count</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="table-border-bottom-0 text-left">
              {this.props.scrapperData && this.props.scrapperData.length > 0 ? (
                this.props.scrapperData.map((data, idx) => (
                  <React.Fragment key={idx}>
                    <tr>
                      <td className="cursor-pointer" onClick={() => this.onActionCall('module', encryptionData(data.middlewareRequestId))}>
                        <strong className=' up_RPA_module cursor-pointer'>{data.requestName}</strong>
                      </td>
                      <td>{data.supplierName}</td>
                      <td>{data.moduleName}</td>
                      <td>
                        <Badge pill bg={this.getStatusColorClass(data.statusName)}>{data.statusName}</Badge>
                      </td>
                      <td>{data.scrappedDoneCount}</td>
                      <td>{data.scrappedErrorCount}</td>

                      <td className="">
                        {data.statusName === "Error" &&
                          <span className='request-td-span'>
                            <i
                              className="fa fa-history cursor-pointer actions-icon history-icon mr-3"
                              title='logs'
                              onClick={() => this.toggleTooltip(data.middlewareRequestId, idx)}
                            ></i>
                            {this.state.showModal && this.state.active === idx &&
                              <div className='card-tooltip'>
                                {this.state.setIsLoad ?
                                  <Bars
                                    className="row"
                                    wrapperStyle={{ margin: '100px 0' }}
                                    color="#3c4b64"
                                    height={30}
                                    width="100%"
                                    visible={this.state.setIsLoad}
                                  />
                                  :
                                  <>
                                    <i
                                      className="fa fa-close cursor-pointer actions-icon history-icon mr-3 cross-icon"
                                      title='logs'
                                      onClick={this.handleClose}
                                    ></i>
                                    <ul>
                                      {this.state.logsList.map((log) => (
                                        <li key={idx}>{log}</li>
                                      ))}
                                    </ul>
                                  </>
                                }
                              </div>
                            }
                          </span>
                        }
                        {data.statusName !== "Error" &&
                          <i className='add-icon-space mr-3'></i>
                        }
                        <i className="fa fa-eye cursor-pointer actions-icon" title='view' onClick={() => this.onActionCall('module', encryptionData(data.middlewareRequestId))}></i>
                      </td>
                    </tr>
                  </React.Fragment>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="d-flex justify-content-center p-3 font-size-16">No Data Found</td>
                </tr>
              )}
            </tbody>
          </table>

        </div>

      </>
    )
  }
}
