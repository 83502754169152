import React from 'react'
import { Bars } from 'react-loader-spinner';
import ExceptionServices from '../../services/domainServices/exceptionService';
import BaseComponent from "../../shared/baseComponent";
import FormFieldGroup from '../../components/UI Element/FormGrop/FormFieldGroup';
import Table from '../../components/table/Table';
import { encryptionData } from '../../utils/Encryption';

export default class Exception extends BaseComponent {
  constructor(props) {
    super(props)
    this.exceptionServices = new ExceptionServices();
    let newPagination = { ...this.exceptionServices.pagination }
    newPagination.pageSize = 25
    this.state = {
      ...this.state,
      setIsLoad: false,
      stockIndex: "",
      setOpenModal: false,
      ExceptionUpdate: this.exceptionServices.dataItem,
      searchList: this.exceptionServices.SearchdataItem,
      setCloseModal: false,
      dataList: [],
      pagination: newPagination,
    };
    this.exceptionServices.onUpdateState = this.updateState.bind(this);
    this.exceptionServices.onActionCall = this.onActionCall.bind(this);
  }

  componentDidMount() {
    this.exceptionServices.onExceptionList(this.state.pagination)
  }

  onActionCall = (type, data) => {
    switch (type) {
      case "View":
        const encryptKey = encryptionData(data, 1);
        window.open("/ExceptionDetails/id?=" + encryptKey, "_blank");
        break;
      default:
        // Handle default case here
        break;
    }
  };

  handleChangeSearch = (name, value) => {
    let detail = this.state.pagination;
    detail[name] = value;
    if (name === "searchText") {
      detail[name] = value.trim();
      let data = { ...this.state.pagination };
      data.pageNo = 1;
      this.exceptionServices.onExceptionList(data)
      this.setState({ pagination: { ...data } });
    }
    this.setState({ searchList: { ...detail } });
  }

  render() {
    return (
      <>
        <div className="d-flex justify-content-start exception-input pl-0">
          {this.exceptionServices.formFieldsSearch.map((Item, index) => {
            return (
              <>
                {
                  Item.fieldName === "searchText" ? (
                    <FormFieldGroup
                      className="ml-0 pl-0"
                      column={Item}
                      value={this.state.searchList[Item.fieldName]}
                      onChange={(event) =>
                        this.handleChangeSearch(Item.fieldName, event.target.value)
                      }
                      key={index}
                    />
                  ) : null}
              </>
            );
          })}
        </div>
        {
          this.state.setIsLoad ? (
            <Bars
              className="row"
              wrapperStyle={{ marginTop: "150px" }}
              color="#3c4b64"
              height={55}
              width="100%"
              visible={this.state.setIsLoad}
            />
          ) : (
            <div className='exception-user-list'>
              <Table
                columns={this.exceptionServices.gridColmns}
                rows={this.state.dataList}
                sortingColumns={[]}
                pagination={this.state.pagination}
                totalCount={this.state.TotalCount}
                totalResultes={this.state.TotalCount}
                setPagination={this.exceptionServices.onPageChange.bind(this)}
                customScope={[
                  {
                    column: 'errorLogId',
                    renderTableData: this.exceptionServices.gridActionErrorCols.bind(this)
                  },
                  {
                    column: 'isSolved',
                    renderTableData: this.exceptionServices.gridCheckBoxCol.bind(this)
                  },

                ]}
              />
            </div>
          )
        }
      </>
    )
  }

}
