import BaseComponent from "../../shared/baseComponent";
import DatabaseAnalyzerServices from "../../services/domainServices/databaseAnalyzerServices";
import { decryptionData } from "../../utils/Encryption";


export class DataAnalyzerProgress extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            analyzerDetails: [],
            totalProceedCount: 0,
        }
        this.analyzerServices = new DatabaseAnalyzerServices();
        this.analyzerServices.onUpdateState = this.updateState.bind(this);
        this.targetPercentage = this.props.maxValue;
    }

    componentDidMount() {
        let params = new URLSearchParams(window.location.search);
        let reqId = params.get("");
        const decryptKey = decryptionData(reqId, false);
        if (decryptKey > 0) {
            this.analyzerServices.onGetAnalyzerDataId(decryptKey);
        }
    }


    render() {

        return (
            <>
                <div class="progress DA-Progress data-analyzer-bar mt-4">
                    <div class="progress-bar" role="progressbar" style={{ width: Math.round(this.state.totalProceedCount ? this.state.totalProceedCount : "%") + '%' }}>
                        {Math.round(this.state.totalProceedCount ? this.state.totalProceedCount : "0") + " %"}
                    </div>
                </div>
            </>
        )
    }
}

export default DataAnalyzerProgress